// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import Loader from '../loader';
import cs from './styles.pcss';
import catchMouseFocus from '../../utils/hocs/catch-mouse-focus';

const cx = classNames.bind(cs);

export type Props = {
  className?: string,
  contentClassName?: string,
  children: React.Node,
  mode?: 'main' | 'secondary' | 'dangerous' | 'transparent' | 'inline',
  size?: 'small' | 'medium' | 'inModal' | 'big',
  loading?: boolean,
  disabled?: boolean,
  innerRef?: React.Ref<any>,
  target?: string,
  href?: string,
  formInput?: boolean,
  onClick?: (event: MouseEvent) => void | Promise<void>,
};

function hrefLeadsAway(href) {
  const tmpLink = document.createElement('a');

  tmpLink.href = href;

  return window.location.host !== tmpLink.host;
}

function Button({
  children,
  mode = 'main',
  size = 'medium',
  contentClassName,
  className,
  loading,
  disabled,
  href,
  target,
  innerRef,
  formInput,
  ...props
}: Props) {
  let Component = 'button';
  let additionalProps = {};

  if (href) {
    Component = 'a';

    let rel = null;

    if (target === '_blank') {
      // Add 'noreferrer' just for links that leads away from the mag to not break analytics on out pages.
      rel = `noopener${hrefLeadsAway(href) ? ' noreferrer' : ''}`;
    }

    additionalProps = {
      href,
      target,
      rel,
    };
  }

  if (formInput) {
    additionalProps = {
      type: 'submit',
    };
  }

  return (
    // $FlowFixMe
    <Component
      className={cx('button', size, mode, { loading }, className)}
      disabled={loading ? true : disabled}
      // $FlowFixMe
      {...props}
      {...additionalProps}
      ref={innerRef}
    >
      {loading && (
        <div className={cx('loader')}>
          <Loader size={size === 'small' ? 16 : 24} />
        </div>
      )}
      <div className={cx('content', contentClassName)}>{children}</div>
    </Component>
  );
}

const ButtonWithCatchedFocus: React.ComponentType<Props> =
  catchMouseFocus(Button);

export default ButtonWithCatchedFocus;
