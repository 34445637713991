// @flow

import * as React from 'react';
import cx from 'classnames';

import Icon from '../icon';

import t from './locale';
import cs from './styles.pcss';

export type Props = {
  uploadButtonLabel?: string,
  accept?: string | Array<string>,
  disabled?: boolean,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  size: 'normal' | 'big',
  id: string,
};

export default function UploadFileButton({
  uploadButtonLabel,
  accept = 'image/*',
  disabled,
  onChange,
  size,
  id,
}: Props): React.Node {
  return (
    <div className={cx(cs.uploadButton, cs[size], { [cs.disabled]: disabled })}>
      <Icon name="attach" className={cs.icon} />
      {uploadButtonLabel || t('attachFile')}
      <label className={cs.fileInputLabel} htmlFor={id}>
        <input
          disabled={disabled}
          type="file"
          onChange={onChange}
          className={cs.fileInput}
          accept={accept}
          multiple
          id={id}
        />
      </label>
    </div>
  );
}
