// @flow
import * as React from 'react';
import FieldSet from '../../field-set';
import Checkbox from '../../../basic-components/checkbox';
import Input from '../../../basic-components/input';
import { getCurrencySymbol } from '../../../utils/countries';
import Field from '../../../basic-components/field';
import useMediaQuery from '../../../hooks/use-media-query';
import SelectField from '../../../basic-components/select-field';
import Icon from '../../../basic-components/icon';
import ShipTo from '../ship-to';

import t from './locale';
import cs from './styles.pcss';
import type {
  ShippingProfileFragment_domesticShippingServices as DomesticShippingService,
  ShippingProfileFragment_internationalShippingServices as InternationalShippingService,
} from '../../../typings/product-query.flow';
import { transformToDropdownOptions, getRegionNames } from '../profile-utils';

type Props = {
  field: string, // 这里的filed相当于一个唯一标识
  optionType: 'domestic' | 'international',
  priority: number,
  siteId: number,
  currency: string,
  currentService: Object,
  shippingServiceDictionary: Array<
    DomesticShippingService | InternationalShippingService
  >,
  globalDisabled?: boolean,
  onDelete?: (number) => void,
};

const validNumberReg = /^\d+\.?\d*$/;

export default function ShippingPacket(props: Props): React.Node {
  const {
    field,
    optionType,
    priority,
    siteId,
    currency,
    currentService,
    shippingServiceDictionary,
    globalDisabled = true,
    onDelete: removeShipping,
  } = props;

  const veryLargeScreen = useMediaQuery(`(min-width: 1280px)`);
  const isMobileScreen = useMediaQuery(`(max-width: 768px)`);
  const firstDomesticShipping = optionType === 'domestic' && priority === 0;
  // 显示可选service
  const dropdownOptions = transformToDropdownOptions(shippingServiceDictionary);
  // currentService有可能是空对象
  const {
    shippingServiceCode,
    freeShipping,
    shippingCost,
    additionalShippingCost,
    // 国内运送没有ship to
    shipToLocations = null,
  } = currentService;

  const [selectedServiceValue, setSelectedServiceValue] = React.useState(
    shippingServiceCode || ''
  );
  // fs: freeShipping, 只有国内运送才能设置free shipping
  const [fsChecked, setFsChecked] = React.useState(freeShipping || false);
  const [firstCost, setFirstCost] = React.useState(shippingCost?.value || 0);
  const [additionalCost, setAdditionalCost] = React.useState(
    additionalShippingCost?.value || 0
  );
  const [costInputErrorTip, setCostInputErrorTip] = React.useState('');
  const [shipToErrorTip, setShipToErrorTip] = React.useState('');

  const validCostIsNumber = (costInput: string): boolean => {
    if (validNumberReg.test(costInput)) {
      setCostInputErrorTip('');
      return true;
    }
    setCostInputErrorTip(t('price.validate.notNumber'));
    return false;
  };

  const validateFirstCostLowerThanAdditionalCost = (
    first: number | string,
    additional: number | string
  ): boolean => {
    const firstParsed = parseFloat(first);
    const additionalParsed = parseFloat(additional);
    if (firstParsed < additionalParsed) {
      setCostInputErrorTip(t('price.validate.additionalHigher'));
      return false;
    }
    setCostInputErrorTip('');
    return true;
  };

  const handleFirstCostInputChange = (e) => {
    const inputValue = e.target.value;
    setFirstCost(inputValue);
    if (!validCostIsNumber(inputValue)) return;
    if (validateFirstCostLowerThanAdditionalCost(inputValue, additionalCost)) {
      setFirstCost(inputValue);
    }
  };

  const handleAdditionalCostInputChange = (e) => {
    const inputValue = e.target.value;
    setAdditionalCost(inputValue);
    if (!validCostIsNumber(inputValue)) return;
    if (validateFirstCostLowerThanAdditionalCost(firstCost, inputValue)) {
      setAdditionalCost(inputValue);
    }
  };

  const handleDelete = () => {
    if (removeShipping) {
      removeShipping(priority);
    }
  };

  const deleteButton = (
    <button
      type="button"
      className={cs.remove}
      onClick={handleDelete}
      disabled={globalDisabled}
    >
      <Icon name="trash" />
    </button>
  );

  const handleShipToError = (errorTip) => {
    setShipToErrorTip(errorTip);
  };

  // 这里不用对currentSerivce验证是否falsy，默认是空对象。
  // 如果是空对象，说明卖家点击ADD按钮准备添加新的shipping

  return (
    <FieldSet delimiter="narrow">
      <SelectField
        fieldClassName={cs.fieldClassName}
        key={`${field}.service`}
        label={t('service.label')}
        options={dropdownOptions}
        value={selectedServiceValue}
        onChange={(option) => setSelectedServiceValue(option?.value || '')}
        disabled={globalDisabled}
        description={!firstDomesticShipping && deleteButton}
      />
      <input
        type="hidden"
        name={`${field}.service`}
        value={selectedServiceValue}
      />

      {/* display shipping prices */}
      <>
        {veryLargeScreen && (
          <div className={cs.pricingHeader}>
            <div className={cs.pricingHeaderItem}>{t('price.first')}</div>
            <div className={cs.pricingHeaderItem}>{t('price.additional')}</div>
          </div>
        )}
        <Field
          label={t('price.label', {
            currency,
            currencySymbol: getCurrencySymbol(currency),
          })}
          fieldClassName={cs.fieldClassName}
          description={t('price.description')}
          error={costInputErrorTip}
        >
          {isMobileScreen && (
            <div className={cs.pricingHeaderItem}>{t('price.first')}</div>
          )}
          <Input
            key={`${field}.cost.price`}
            name={`${field}.cost.price`}
            className={cs.firstPrice}
            value={`${firstCost}`}
            grid={5}
            disabled={globalDisabled || fsChecked}
            onChange={handleFirstCostInputChange}
            autoComplete="off"
          />
          {isMobileScreen && (
            <div className={cs.pricingHeaderItem}>{t('price.additional')}</div>
          )}
          <Input
            key={`${field}.cost.additional`}
            name={`${field}.cost.additional`}
            className={cs.additionalPrice}
            value={`${additionalCost}`}
            grid={5}
            disabled={globalDisabled || fsChecked}
            onChange={handleAdditionalCostInputChange}
            autoComplete="off"
          />
        </Field>

        {/* 是否是free shipping，ebay规定只能有一个free shipping */}
        {firstDomesticShipping && (
          <Field
            label={t('free.label')}
            fieldClassName={cs.fieldClassName}
            noGrid={!veryLargeScreen}
          >
            <Checkbox
              key={`${field}.cost.free`}
              name={`${field}.cost.free`}
              checked={fsChecked}
              disabled={globalDisabled}
              onChange={(e) => {
                setFsChecked(e.target.checked);
                setFirstCost(0);
                setAdditionalCost(0);
                setCostInputErrorTip('');
              }}
            />
          </Field>
        )}
      </>

      {/* 国际运送显示Ship To */}
      {optionType === 'international' && (
        <Field
          label={t('shipTo.label')}
          error={shipToErrorTip}
          fieldClassName={cs.fieldClassName}
        >
          <ShipTo
            key={`${field}.shipto`}
            name={`${field}.shipto`}
            siteId={siteId}
            selectedLocations={getRegionNames(shipToLocations?.regionIncluded)}
            disabled={globalDisabled}
            onError={handleShipToError}
          />
        </Field>
      )}
    </FieldSet>
  );
}
