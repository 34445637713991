// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import Checkbox from '../../../basic-components/checkbox';
import Icon from '../../../basic-components/icon';

import Country from '../country';
import cs from './styles.pcss';

import type { ExcludedShowcaseApollo } from '../../../typings/showcase';
import type { Showcases_showcases as ShowcaseT } from '../../../typings/product-query.flow';

const cx = classNames.bind(cs);

type Props = {
  name: string,
  region: string,
  countries: Array<{
    key: string,
    name: string,
    forbidden?: boolean,
  }>,
  includedCountries: Array<string>,
  isIncluded: boolean,
  forceExpanded?: boolean,
  domesticShowcases?: { [string]: ShowcaseT },
  countryShowcases?: { [string]: ShowcaseT | ExcludedShowcaseApollo },
  currentShowcase?: ShowcaseT | ExcludedShowcaseApollo,
  frozenCountries?: Array<string>,
  toggleCountries: (Array<string>) => void,
};

export default function Region({
  domesticShowcases = {},
  countryShowcases,
  currentShowcase,
  frozenCountries,
  countries,
  toggleCountries,
  name,
  region,
  includedCountries,
  isIncluded,
  forceExpanded = false,
}: Props): React.Node {
  const [expanded, setExpanded] = React.useState(false);

  const servedByOtherShowcase = (
    countryCode: string
  ): ShowcaseT | ExcludedShowcaseApollo | null => {
    if (!countryShowcases || !currentShowcase) {
      return null;
    }

    const showcase = countryShowcases[countryCode];

    return showcase && currentShowcase.id !== showcase.id ? showcase : null;
  };

  const isFrozen = (country: { key: string }) =>
    !!(frozenCountries && frozenCountries.indexOf(country.key) !== -1);

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const toggleRegion = () => {
    // toggle all region's countries except domestic (their state is frozen) and forbidden
    toggleCountries(
      countries
        .filter(
          (c) => !domesticShowcases[c.key] && !c.forbidden && !isFrozen(c)
        )
        .map((c) => c.key)
    );
  };

  const includedCount = includedCountries.length;

  return (
    <div className={cx('region')}>
      <div className={cx('row', { opened: expanded })}>
        <Checkbox
          className={cx('checkbox')}
          checked={isIncluded}
          indeterminate={!isIncluded && includedCount > 0}
          onChange={toggleRegion}
          disabled={countries.length === 0}
        />
        <button className={cx('info')} onClick={toggleExpanded} type="button">
          <Icon name="selectSmall" className={cx('arrow')} />
          <span className={cx('regionName')}>{name}</span>
          {includedCount > 0 && (
            <div className={cx('counter')}>{includedCount}</div>
          )}
        </button>
      </div>
      {(forceExpanded || expanded) &&
        countries.map((country) => (
          <Country
            key={country.key}
            name={country.name}
            forbidden={country.forbidden}
            region={region}
            country={country.key}
            isIncluded={includedCountries.includes(country.key)}
            domesticShowcase={
              domesticShowcases && domesticShowcases[country.key]
            }
            servedByOtherShowcase={servedByOtherShowcase(country.key)}
            currentShowcase={currentShowcase}
            isFrozen={isFrozen(country)}
            toggleCountries={toggleCountries}
          />
        ))}
    </div>
  );
}
