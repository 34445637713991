// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import Logo from '../../basic-components/logo';
import LangSwitcher from '../lang-switcher';
import settings from '../../settings';
import UserInfo, { getUserOptions } from '../user';
import t from './locale/en.yml';
import NavLink from '../nav-link';
import { locationMatch } from '../../utils/url';
import { User as UserQuery } from '../../shared-queries/User.graphql';
import analytics from '../../analytics/navigation';
import HelpDropdown from '../help-dropdown';
import useMediaQuery from '../../hooks/use-media-query';
import BurgerMenu from '../../basic-components/burger-menu';
import FlagIcon from '../../basic-components/flag-icon';
import Icon, { type IconsNormal } from '../../basic-components/icon';
import Hr from '../../basic-components/hr';
import MobileMenu from './mobile/menu';

import { logout } from './actions';
import cs from './styles.pcss';
import { ScrollContext } from './context';

const cx = classNames.bind(cs);

type Link = {
  key: string,
  href: string,
  children?: Array<Link>,
  exact?: boolean,
  hidden?: boolean,
  getBadge?: (?number) => ?{ text: string, color: 'green' | 'yellow' },
  onboardingStep?: number,
};

type Props = {
  unauthorized?: boolean,
};

type RenderContentProps = {
  icon?: IconsNormal | string,
  customIcon?: React.Node,
  text: string,
  onClick?: (
    event: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void,
  href?: string,
  external?: boolean,
  flag?: boolean,
  selected?: boolean,
  tabIndex?: number,
};

type MobileOption = {
  external?: any,
  href?: string,
  onClick?: () => void,
  optionNoBorder?: boolean,
  text?: string,
};

const hasActiveChild = (link) =>
  link.children &&
  link.children.some((child) => locationMatch(child.href, child.exact));

const links: Array<Link> = [
  { key: 'items', href: settings.path.stock, exact: true },
  {
    key: 'sites',
    onboardingStep: 10,
    href: settings.path.showcases,
  },
  {
    key: 'policies',
    onboardingStep: 11,
    href: settings.path.delivery,
    exact: true,
    children: [
      { key: 'policiesPage', href: settings.path.delivery, exact: true },
      { key: 'gpsrPage', href: settings.path.gpsr, exact: true },
    ],
  },
  {
    key: 'orders',
    onboardingStep: 9,
    href: settings.path.parcels,
    getBadge: (count) =>
      count
        ? {
            text: count.toString(),
            color: 'green',
          }
        : null,
    exact: true,
    children: [
      { key: 'ordersPage', href: settings.path.parcels, exact: true },
      {
        key: 'addresses',
        href: `${settings.path.delivery}/store_addresses`,
        exact: true,
      },
    ],
  },
  {
    key: 'account',
    hidden: true,
    href: settings.path.accounts,
    children: [
      { key: 'accounts', href: settings.path.accounts, exact: true },
      {
        key: 'accountSettings',
        href: settings.path.settings,
      },
    ],
  },
];

// const renderChildLink = (child) => {
//   return (
//     <NavLink
//       href={child.href}
//       key={child.key}
//       active={locationMatch(child.href, child.exact)}
//       id={`nav-link-${child.key}`}
//       isSubLink
//     >
//       {t(child.key)}
//     </NavLink>
//   );
// };

const renderChildLink = (arr, isShowGpsr) => {
  // TODO: Temporarily add GPSR whitelist
  return arr.map((child) => {
    if (child.key === 'gpsrPage' && !isShowGpsr) {
      return null;
    }
    return (
      <NavLink
        href={child.href}
        key={child.key}
        active={locationMatch(child.href, child.exact)}
        id={`nav-link-${child.key}`}
        isSubLink
      >
        {t(child.key)}
      </NavLink>
    );
  });
  // TODO: end
};

export default function Header({ unauthorized }: Props): React.Node {
  const { scrolled } = React.useContext(ScrollContext);
  const { data } = useQuery(UserQuery);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const activeParcelsCount = pathOr(0, ['viewer', 'activeParcelsCount'], data);
  // TODO: Temporarily add GPSR whitelist
  const isShowGpsr = pathOr(false, ['viewer', 'gpsrWhitelist'], data);
  // TODO: end
  const smallScreen = useMediaQuery(`(max-width: 768px)`);

  // const autoReviseListings = pathOr(
  //   false,
  //   ['viewer', 'autoReviseListings'],
  //   data
  // );
  const accounts = pathOr([], ['viewer', 'accounts'], data);
  const useInfoName = pathOr<string, number | string, string, any>(
    t('accountsMany'),
    [0, 'name'],
    accounts
  );

  const handleExit = () => {
    analytics.logout().then(logout);
  };

  const secondLevel = links.some(hasActiveChild);

  const userOptions = getUserOptions({ onExit: handleExit, name: useInfoName });
  const renderContent = ({
    flag,
    icon,
    text,
    selected,
    customIcon,
  }: RenderContentProps) => (
    <>
      {customIcon}
      {flag
        ? icon && (
            <FlagIcon name={icon} wrapperClassName={cs.optionFlagIcon} active />
          )
        : // $FlowFixMe
          icon && <Icon name={icon} className={cs.optionIcon} />}
      <div className={cx(cs.optionText, { [cs.selectedOptionText]: selected })}>
        {text}
      </div>
      {selected && <Icon name="selectedOption" className={cs.selected} />}
    </>
  );

  const mobileOption = ({
    external,
    href,
    onClick,
    optionNoBorder,
    text,
    ...rest
  }: MobileOption) => {
    const linkExtraProps = external
      ? {
          target: '_blank',
          rel: 'noreferrer noopener',
        }
      : {};
    return href ? (
      <a
        className={cx(cs.option, optionNoBorder && cs.optionNoBorder)}
        href={href}
        onClick={onClick}
        {...linkExtraProps}
        key={text}
      >
        {/* $FlowFixMe */}
        {renderContent({ text, ...rest })}
      </a>
    ) : (
      <button
        type="button"
        className={cx(cs.option, optionNoBorder && cs.optionNoBorder)}
        onClick={onClick}
        key={text}
      >
        {/* $FlowFixMe */}
        {renderContent({ text, ...rest })}
      </button>
    );
  };

  const renderLink = (link) => (
    <div key={link.key}>
      {!link.hidden && (
        <NavLink
          onboardingStep={link.onboardingStep}
          className={cx('root')}
          href={link.href}
          active={
            (!link.children && locationMatch(link.href, link.exact)) ||
            hasActiveChild(link)
          }
          badge={
            // $FlowFixMe
            link.getBadge && link.getBadge(activeParcelsCount)
          }
          id={`nav-link-${link.key}`}
        >
          {t(link.key)}
        </NavLink>
      )}
      {link.children && !smallScreen && (
        <div
          className={cx('sub', {
            scrolled,
            visible:
              link.children &&
              link.children.some((child) =>
                locationMatch(child.href, link.exact)
              ),
          })}
        >
          {/* {link.children && link.children.map(renderChildLink)} */}
          {link.children && renderChildLink(link.children, isShowGpsr)}
        </div>
      )}
    </div>
  );

  React.useEffect(() => {
    if (!smallScreen && isMobileMenuOpen) setIsMobileMenuOpen(false);
  }, [smallScreen]);

  const subLinksArray = links.reduce((arr, link) => {
    if (
      link.children &&
      link.children.some((child) => locationMatch(child.href, link.exact))
    ) {
      arr.push(...link.children);
    }
    return arr;
  }, []);

  return (
    <div className={cx('container', { secondLevel, scrolled })}>
      <div className={cx('header')}>
        <div className={cx('content', { scrolled })}>
          <div className={cx('leftGroup')}>
            <Logo className={cx('logo')} />
          </div>
          <nav className={cx('navigation')}>
            {!unauthorized && !smallScreen && links.map(renderLink)}
          </nav>
          <div className={cx('rightGroup')}>
            {(!smallScreen || (smallScreen && unauthorized)) && (
              <LangSwitcher />
            )}
            {!unauthorized && !isMobileMenuOpen && (
              <HelpDropdown
                onStock={locationMatch(settings.path.stock)}
                className={cx('help')}
                accounts={accounts}
              />
            )}
            {!unauthorized && !smallScreen && (
              <div className={cs.profileWrapper}>
                <UserInfo
                  onExit={handleExit}
                  name={useInfoName}
                  multi={accounts ? accounts.length > 1 : false}
                  active={locationMatch(settings.path.accounts, true)}
                  activeParcelsCount={activeParcelsCount}
                  id="nav-link-account"
                />
                {/* {!autoReviseListings && <AutosyncAnnouncement />} */}
              </div>
            )}
            {smallScreen && !unauthorized && (
              <>
                <BurgerMenu
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                />

                <MobileMenu isOpen={isMobileMenuOpen} scrolled={scrolled}>
                  {links.map(renderLink)}
                  <Hr className={cs.hr} />
                  {userOptions.map((option) =>
                    // $FlowFixMe
                    mobileOption({
                      optionNoBorder: true,
                      ...option,
                    })
                  )}

                  {/* $FlowFixMe */}
                  <LangSwitcher isMobile mobileOption={mobileOption} />
                </MobileMenu>
              </>
            )}
          </div>
        </div>
        {smallScreen && subLinksArray.length > 0 && (
          <div className={cx('subMobile', { scrolled })}>
            {/* {subLinksArray.map(renderChildLink)} */}
            {renderChildLink(subLinksArray, isShowGpsr)}
          </div>
        )}
      </div>
    </div>
  );
}
