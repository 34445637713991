// @flow

import update from 'immutability-helper';

import { handleActions, type ActionType } from 'redux-actions';

import type { ParcelsState as State } from '../typings/parcels';

import { setActiveId } from '../actions/parcels';

const initialState: State = {
  state: {
    activeId: null,
  },
};

function handleSetActiveId(state: State, payload?: string) {
  return update(state, {
    state: {
      activeId: { $set: payload },
    },
  });
}

export default (handleActions(
  {
    [setActiveId.toString()]: (
      state: State,
      { payload }: ActionType<typeof setActiveId>
    ) => handleSetActiveId(state, payload),
  },
  initialState
): any);
