import { useMutation, useApolloClient } from '@apollo/client';
import { pathOr, take } from 'ramda';

import { UpdateRecommendedListings } from './operations.graphql';
import ListingPublishFragment from './ListingPublishFragment.graphql';

const getChanges = (productsData, activeSitesIds, maxPublishCount) => {
  const nodes = take(
    maxPublishCount,
    pathOr([], ['products', 'nodes'], productsData)
  );

  if (!nodes.length) {
    return [];
  }

  const listingsIds = [];

  const changes = nodes.reduce((result, product) => {
    const listings = product.listings
      .filter((l) => activeSitesIds.includes(l.site.id))
      .map((l) => {
        listingsIds.push(l.id);
        return { id: l.id, selected: true };
      });

    return [...result, ...listings];
  }, []);

  return { listingsIds, changes };
};

export function usePublishRecommended(
  productsData,
  activeSitesIds,
  onClose,
  maxPublishCount
) {
  const client = useApolloClient();

  const [confirm, { loading }] = useMutation(UpdateRecommendedListings);

  const onPublish = () => {
    const { listingsIds, changes } = getChanges(
      productsData,
      activeSitesIds,
      maxPublishCount
    );

    confirm({
      variables: {
        input: {
          changes,
        },
      },
    }).finally(() => {
      onClose();

      listingsIds.forEach((id) => {
        const fr = client.readFragment({
          fragment: ListingPublishFragment,
          id: `Listing:${id}`,
        });

        client.writeFragment({
          fragment: ListingPublishFragment,
          data: { ...fr, selected: true },
          id: `Listing:${id}`,
        });
      });
    });
  };

  return [onPublish, loading];
}
