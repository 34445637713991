// @flow

import { getInitialFilters } from '../../components/filters/utils';
import initialFiltersState from '../../components/parcels-table/filters/initialState';
import keys from '../../settings/storage';

export const parcelsInitialFilters: any = getInitialFilters(
  initialFiltersState,
  'ParcelFilters',
  undefined,
  keys.parcelsFilters
);
