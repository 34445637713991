// @flow
import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

type Props = {
  onClick?: () => void,
};

export default function BurgerMenu(props: Props): React.Node {
  const { onClick } = props;
  const [active, setActive] = React.useState(false);

  const burgerClasses = cx(cs.burger, active && cs.active);

  const handleToggle = () => {
    setActive(!active);
    if (onClick) onClick();
  };

  return (
    <button type="button" onClick={handleToggle} className={burgerClasses}>
      <div />
      <div />
      <div />
    </button>
  );
}
