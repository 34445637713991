// @flow

import { sendEvent, payloadCreator } from '../utils/analytics';

const category = 'Parcels Widget';
const payload = payloadCreator(category);

export default {
  click: (): Promise<void> => sendEvent(payload('Click')),
  selectYear: (): Promise<void> => sendEvent(payload('selectYear')),
};
