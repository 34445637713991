// @flow
import * as React from 'react';
import useMediaQuery from '../../../hooks/use-media-query';
import FieldSet from '../../field-set';
import FieldSetTitle from '../../field-set-title';
import Field from '../../../basic-components/field';
import Switcher from '../../../basic-components/switcher';
import FieldSetDescription from '../../field-set-description';
import OriginalPolicyView from '../original-policy-view';
import ShippingContainer from '../shipping-container';
import ExcludedCountriesLightbox from '../excluded-countries-lightbox';
import SelectField from '../../../basic-components/select-field';

import cs from './styles.pcss';
import t from './locale';
import { getSiteDomain, shipping } from '../../../settings';
import {
  getShippingServiceDictionaryBySiteId,
  handleMultiFreeShippingForDomestic,
  handleMultiFreeShippingForInternational,
} from '../profile-utils';

import type {
  ShippingProfileFragment_domesticShippingServices as DomesticShippingService,
  ShippingProfileFragment_internationalShippingServices as InternationalShippingService,
  ShippingProfileFragment_shippingEbayProfiles as ShippingEbayProfile,
  GetShowcases_showcases as ShowCase,
} from '../../../typings/product-query.flow';

type Props = {
  profileId: number,
  name: string, // this name corresponds to the selected tab name of profile tab
  siteId: number,
  currency?: string,
  domesticShippingServices: Array<DomesticShippingService>,
  internationalShippingServices: Array<InternationalShippingService>,
  shippingEbayProfiles: Array<ShippingEbayProfile>,
  showcases: Array<ShowCase>,
  isOriginalSite?: boolean,
  originalShippingPolicy?: any,
};

const DISPATCH_TIME_OPTIONS = shipping.dispatchTimeOptions.map((days) => ({
  label: String(days),
  value: String(days),
}));

const PREFIX = 'cp'; // cp: custom profile

export default function CustomProfile(props: Props): React.Node {
  const {
    profileId,
    name: selectedTabName,
    siteId,
    currency = 'USD',
    domesticShippingServices,
    internationalShippingServices,
    shippingEbayProfiles,
    showcases,
    isOriginalSite = false,
    originalShippingPolicy = null,
  } = props;
  // e.g. 172-cp-us
  const baseKeyPrefix = `${profileId}-${PREFIX}-${selectedTabName}`;
  // ds: domestic shipping. e.g 172-cp-us-ds
  const dsFieldKeyPrefix = `${baseKeyPrefix}-ds`;
  // is: international shipping, e.g. 172-cp-us-is
  const isFieldKeyPrefix = `${baseKeyPrefix}-is`;

  // 找到Mag在这个站点生成的国内运送
  const generatedPolicy =
    shippingEbayProfiles.find((item) => item.siteId === siteId) || {};
  const {
    id: generatedPolicyId,
    title,
    payload,
    managedByUser,
  } = generatedPolicy;
  const isGenerating = generatedPolicyId == null;

  const isMobileScreen = useMediaQuery('(max-width: 768px)');

  // 控制开关，允许（或关闭）用户自定义国内运送
  const [switcherOpen, setSwitcherOpen] = React.useState(false);
  const handleSwitcherChange = (e) => {
    setSwitcherOpen(e.target.checked);
  };
  const [dispatchTime, setDispatchTime] = React.useState(0);
  React.useEffect(() => {
    setSwitcherOpen(managedByUser);
    setDispatchTime(payload?.handlingTime?.value);
  }, [managedByUser, payload?.handlingTime?.value]);

  // 展示原始站点信息
  if (isOriginalSite) {
    if (!originalShippingPolicy) return null;
    return <OriginalPolicyView data={originalShippingPolicy} />;
  }

  // showcase
  const showcase = showcases.find((item) => item.site.id === siteId);
  if (showcase == null) {
    return null;
  }
  const showcaseActive = showcase.active;
  // 如果对应站点没有active，建议用户先turn on站点
  if (!showcaseActive) {
    return (
      <form
        name={baseKeyPrefix}
        className={cs.wrapper}
        onSubmit={(e) => e.preventDefault()}
      >
        <FieldSet delimiter="narrow">
          <FieldSetDescription>{t('showcaseInactive')}</FieldSetDescription>
        </FieldSet>
      </form>
    );
  }

  // 首次点击tab或正在调用api生成该站点的policy
  if (isGenerating) {
    return (
      <form
        name={baseKeyPrefix}
        className={cs.wrapper}
        onSubmit={(e) => e.preventDefault()}
      >
        <FieldSet delimiter="narrow">
          <FieldSetDescription>{t('isGenerating')}</FieldSetDescription>
        </FieldSet>
      </form>
    );
  }

  // ok，正常展示生成的policy
  const siteDomain = getSiteDomain(selectedTabName);

  // 每个站点可选择的国内和国际services
  const dsServiceDictionary = getShippingServiceDictionaryBySiteId(
    domesticShippingServices,
    siteId
  );
  const isServiceDictionary = getShippingServiceDictionaryBySiteId(
    internationalShippingServices,
    siteId
  );

  const domesticShippingOption =
    payload.shippingOptions.find((item) => item.optionType === 'DOMESTIC') ||
    {};
  const { shippingServices: dsServices = [] } = domesticShippingOption;

  const internationalShippingOption =
    payload.shippingOptions.find(
      (item) => item.optionType === 'INTERNATIONAL'
    ) || {};
  const { shippingServices: isServices = [] } = internationalShippingOption;

  return (
    <form
      name={baseKeyPrefix}
      className={cs.wrapper}
      onSubmit={(e) => e.preventDefault()}
    >
      <div className={cs.generatedPolicyId}>Policy ID: {generatedPolicyId}</div>
      <input
        type="hidden"
        name={`${baseKeyPrefix}-pid`}
        value={generatedPolicyId}
      />

      {/* switch开关，控制用户是否可以修改shipping */}
      <FieldSet delimiter="wide">
        <div className={cs.switcherContent}>
          <Switcher
            key={`${baseKeyPrefix}-switcher`}
            name={`${baseKeyPrefix}-switcher`}
            label={
              <>
                <div className={cs.switcherTitle}>
                  {t('switcherLabel', { siteDomain })}
                </div>
                <div className={cs.switcherInfo}>
                  {t('switcherDescription', { siteDomain })}
                </div>
                <div className={cs.switcherCaution}>
                  {t('switcherCaution', { siteDomain })}
                </div>
              </>
            }
            state={switcherOpen ? 'on' : 'off'}
            onChange={handleSwitcherChange}
            bigLabel
            darkLabel
            topAlign
          />
        </div>
      </FieldSet>

      {/* Policy 描述 */}
      <FieldSet delimiter="wide">
        <FieldSetTitle leftColumn={isMobileScreen ? '' : ' '}>
          {t('subtitle.description')}
        </FieldSetTitle>
        {/* Mag生成的policy name，不可修改 */}
        <Field
          className={cs.fieldWrapper}
          label={t('fields.title.label')}
          description={t('fields.title.description')}
          fieldClassName={cs.field}
        >
          {title}
        </Field>
        <SelectField
          fieldClassName={cs.selectFieldClassName}
          key={`${baseKeyPrefix}-dispatchTime`}
          name={`${baseKeyPrefix}-dispatchTime`}
          label={
            <>
              <div>{t('fields.handleTime.label')},</div>
              <div>{t('fields.handleTime.bizDays')}</div>
            </>
          }
          options={DISPATCH_TIME_OPTIONS}
          value={String(dispatchTime)}
          onChange={(option) => setDispatchTime(option?.value || 0)}
          grid={6}
          disabled={!switcherOpen}
        />
      </FieldSet>

      {/* 国内运送 */}
      <ShippingContainer
        optionType="domestic"
        fieldKeyPrefix={dsFieldKeyPrefix}
        siteId={siteId}
        maxServiceCount={4}
        currency={currency}
        // 国内运送, free shipping必须排在第一显示
        existingShippings={handleMultiFreeShippingForDomestic(dsServices)}
        shippingServiceDictionary={dsServiceDictionary}
        globalDisabled={!switcherOpen}
      />

      {/* 充当分割线 */}
      <FieldSet delimiter="wide" />

      {/* 国际运送 */}
      <ShippingContainer
        optionType="international"
        fieldKeyPrefix={isFieldKeyPrefix}
        siteId={siteId}
        maxServiceCount={5}
        currency={currency}
        existingShippings={handleMultiFreeShippingForInternational(isServices)}
        shippingServiceDictionary={isServiceDictionary}
        globalDisabled={!switcherOpen}
      />

      {/* 充当分割线 */}
      <FieldSet delimiter="wide" />

      {/* 放开这里可以获取excluded countries,用于保存，以便做international */}
      <ExcludedCountriesLightbox
        name={`${baseKeyPrefix}-excluded-countries`}
        excludedCountries={payload.excludedCountries || []}
        veryLargeScreen
        readOnly={!switcherOpen}
        currentShowcase={showcase}
      />
    </form>
  );
}
