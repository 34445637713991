// @flow

import * as React from 'react';
import { Field } from 'react-final-form';

import { getVisibleError } from '../utils';

type Props = {
  name: string,
  children: (value: any) => React.Node,
  noGrid?: boolean,
};

/**
 * `FieldError` wrapper helps to get field's error from the form state.
 * Usage:
 *   <FieldError name={fieldName}>
 *     (error => ...)
 *   </FieldError>
 */
function FieldError({ name, children, noGrid }: Props): React.Node {
  return (
    <Field
      name={name}
      subscription={{
        error: true,
        submitError: true,
        dirtySinceLastSubmit: true,
      }}
      noGrid={noGrid}
    >
      {({ meta }) => {
        const error = getVisibleError(meta);
        return children(typeof error === 'string' ? error : null); // filter errors for nested fields
      }}
    </Field>
  );
}

export default FieldError;
