// @flow
import * as React from 'react';
import cx from 'classnames';

import Icon from '../icon';
import ANALYTICS from '../../analytics/banners';
import usePersistState from '../../hooks/use-persist-state';

import cs from './styles.pcss';

type Props = {
  message?: React.Node,
  id: string,
  children?: React.Node,
  className?: string,
  color?: 'blue' | 'purple' | 'cyan' | 'darkBlue' | 'indigo',
  hidden: boolean,
  localKey: string,
  forceHidden?: boolean,
  buttonHide?: boolean,
};

export default function Banner({
  message,
  id,
  children,
  className,
  color = 'blue',
  hidden,
  localKey,
  forceHidden,
  buttonHide,
}: Props): React.Node {
  const [persistState, setPersistState] = usePersistState(localKey);

  React.useEffect(() => {
    if (forceHidden) {
      setPersistState('viewed');
    }
  }, [forceHidden]);

  const bannerHidden = persistState === 'viewed' || hidden || forceHidden;

  React.useEffect(() => {
    if (!bannerHidden) {
      ANALYTICS.show(id);
    }
  }, [bannerHidden]);

  const handleClose = () => {
    setPersistState('viewed');
    ANALYTICS.close(id);
  };

  if (bannerHidden) {
    return null;
  }

  return (
    <div className={cx(cs.banner, className, cs[color])}>
      <div className={cs.message}>{message}</div>
      <div className={cs.children}>{children}</div>
      <button
        type="button"
        style={{ display: buttonHide ? 'none' : 'block' }}
        className={cs.close}
        onClick={handleClose}
      >
        <Icon name="cancel" className={cs.icon} size={24} />
      </button>
    </div>
  );
}
