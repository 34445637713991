// @flow
import * as React from 'react';

import Icon from '../../basic-components/icon';
import Link from '../../basic-components/link';
import Button from '../../basic-components/button';
import OnboardingHighlight from '../../basic-components/onborading-highlight';
import ANALYTICS from '../../analytics/onboarding-tour';
import keys from '../../settings/storage';
import usePersistState from '../../hooks/use-persist-state';
import { joinAnd } from '../../utils/string';

import cs from './styles.pcss';
import t from './locale';
import { getStepData, TOTAL_STEPS } from './utils';

import type { OnboardingTourStatus_viewer_limits_site as SiteT } from '../../typings/product-query.flow';

const renderLink = (l) => (
  <Link href={l.href} className={cs.link} key={l.href} target="_blank">
    <Icon name="helpCenter" className={cs.linkIcon} />
    <div className={cs.linkText}>{l.text}</div>
  </Link>
);

type Props = {
  currentOnboardingStep: number,
  continuedOnboardingStep: number,
  onContinue: () => void,
  onBack: () => void,
  onFinishOnboarding: () => void,
  recommendedSite: SiteT | null,
  importedSites: SiteT[],
};

export default function OnboardingStep({
  currentOnboardingStep,
  continuedOnboardingStep,
  onContinue,
  onBack,
  onFinishOnboarding,
  recommendedSite,
  importedSites,
}: Props): React.Node {
  const [, setOnboardingTourStarted] = usePersistState(
    keys.onboardingTourStarted
  );

  let siteData;

  if (currentOnboardingStep === 1) {
    siteData = importedSites.length
      ? joinAnd(importedSites.map((s) => s.domain))
      : '';
  } else if (currentOnboardingStep === 2 && recommendedSite) {
    siteData = recommendedSite.domain;
  }

  const stepData = getStepData(currentOnboardingStep, siteData);
  const Image = stepData.image;

  const firstStep = currentOnboardingStep === 1;
  const lastStep = currentOnboardingStep === TOTAL_STEPS;

  const handleCancelClick = () => {
    if (firstStep) {
      ANALYTICS.cancel(currentOnboardingStep);
      setOnboardingTourStarted('false');
      onFinishOnboarding();
    } else {
      onBack();
    }
  };

  const handleConfirmClick = () => {
    if (lastStep) {
      ANALYTICS.finished();
      onFinishOnboarding();
      setOnboardingTourStarted('false');
    } else {
      onContinue();
    }
  };

  return (
    <>
      <div className={cs.imageWrapper}>
        {/* $FlowFixMe */}
        <Image className={cs.image} />
      </div>
      <div className={cs.content}>
        <div className={cs.title}>{stepData.title}</div>
        <div className={cs.description}>{stepData.description}</div>
        {stepData.links.map(renderLink)}

        {currentOnboardingStep === 3 && continuedOnboardingStep === 3 && (
          <div className={cs.error}>
            <Icon name="exclamationCircle" className={cs.errorIcon} />
            <div>{t('step3.error')}</div>
          </div>
        )}

        <div className={cs.footer}>
          <div className={cs.counter}>
            {currentOnboardingStep}/{TOTAL_STEPS}
          </div>
          <div className={cs.controls}>
            <Button
              size="small"
              className={cs.cancel}
              mode="transparent"
              onClick={handleCancelClick}
            >
              {t(firstStep ? 'decline' : 'back')}
            </Button>
            <OnboardingHighlight onlyConfirmed stepNumber={10}>
              <Button
                size="small"
                className={cs.confirm}
                onClick={handleConfirmClick}
              >
                {lastStep ? t('finish') : t(firstStep ? 'start' : 'next')}
              </Button>
            </OnboardingHighlight>
          </div>
        </div>
      </div>
    </>
  );
}
