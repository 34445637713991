// @flow
import * as React from 'react';
import cx from 'classnames';

import FeedbackButtons from '../feedback-buttons';
import Header from '../header';
import Footer from '../footer';
import BoundaryErrors from '../boundary-errors';
import BannersCenter from '../banners-center';
import UnsellableBanner from '../banners-center/unsellable-banner';
import NotificationsCenter from '../notifications-center';
import HappyLightboxFirstSale from '../happy-lightbox-first-sale';
import HappyLightboxSales from '../happy-lightbox-sales';
import useUrlActions from '../../hooks/use-url-actions';
import useDailyAnalytics from '../../hooks/use-daily-analytics';

import cs from './styles.pcss';

type Props = {
  children: React.Node,
  className?: string,
  hasChanges?: boolean,
  showRussianPostButton?: boolean,
  onClick?: () => void,
  unauthorized?: boolean,
  banners?: React.Node[],
  page?: string,
  windowWidth?: ?number,
  unsetOverflow?: boolean,
  minWidth?: string,
};

export default function Layout({
  children,
  className,
  hasChanges,
  showRussianPostButton,
  onClick,
  unauthorized,
  banners = [],
  page,
  windowWidth,
  unsetOverflow = false,
  minWidth = 'fit-content',
}: Props): React.Node {
  useUrlActions();
  useDailyAnalytics(unauthorized);

  return unauthorized ? (
    <div
      className={cx(
        cs.layout,
        unsetOverflow && cs.layoutUnsetOverflow,
        className
      )}
      style={minWidth && { minWidth }}
    >
      <Header unauthorized />
      <NotificationsCenter page={page} unauthorized />
      <BoundaryErrors>{children}</BoundaryErrors>
      <Footer />
    </div>
  ) : (
    // eslint-disable-next-line
    <div
      className={cx(
        cs.layout,
        unsetOverflow && cs.layoutUnsetOverflow,
        className
      )}
      style={minWidth && { minWidth }}
      onClick={onClick}
    >
      <Header />
      <BannersCenter
        banners={[<UnsellableBanner />, ...(banners || [])]}
        windowWidth={windowWidth}
      />
      <NotificationsCenter page={page} />

      <BoundaryErrors>{children}</BoundaryErrors>

      {/* only JP or KR will display feedback buttons */}
      <FeedbackButtons
        className={cx({ [cs.feedback]: hasChanges })}
        showRussianPostButton={showRussianPostButton}
      />
      <HappyLightboxFirstSale />
      <HappyLightboxSales />
    </div>
  );
}
