// @flow
import * as React from 'react';
import cs from './styles.pcss';
import t from './locale';

import settings from '../../settings';
import ANALYTICS from '../../analytics/shipping-profiles';
import HoverTooltip from '../../basic-components/hover-tooltip';
import Icon from '../../basic-components/icon';
import Link from '../../basic-components/link';

type Props = {
  location:
    | 'Stock table header row.'
    | 'Shipping table create policy button.'
    | 'Create policy popup. Policy description.'
    | 'Create policy popup. Tariffs.'
    | 'Create policy popup. Item location and return options.',
  noArrow?: boolean,
};

export default function ShippingPoliciesTooltip({
  location = 'Location is undefined.',
  noArrow = false,
}: Props): React.Node {
  return (
    <HoverTooltip
      direction="bottom"
      className={cs.icon}
      message={
        <div className={cs.content}>
          <span>{t('message')}</span>
          <Link
            target="_blank"
            href={settings.references.helpCenterLinks.shippingPolicies}
            className={cs.link}
            noUnderline={false}
            onClick={() => {
              ANALYTICS.goToHelpCenter(location);
            }}
          >
            {t('linkText')}
          </Link>
        </div>
      }
      noArrow={noArrow}
    >
      <Icon name="questionCircle" size={16} />
    </HoverTooltip>
  );
}
