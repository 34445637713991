// @flow

import * as React from 'react';
import { Field } from 'react-final-form';

type Props = {
  name: string,
  is: (value: any) => boolean,
  children: React.Node,
};

/**
 * `ShowWhen` wrapper renders content only if `name` field's value satisfys predicate
 * Usage:
 *   <ShowWhen name={'planet'} is={val => val === 'Moon'}>
 *     Landed!
 *   </ShowWhen>
 */
function ShowWhen({ name, is, children }: Props): React.Node {
  return (
    <Field name={name} subscription={{ value: true }}>
      {({ input: { value } }) => (is(value) ? children : null)}
    </Field>
  );
}

export default ShowWhen;
