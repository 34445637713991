// @flow

import * as React from 'react';
import { Field as FormField } from 'react-final-form';
import classNames from 'classnames/bind';

import { prettyArrayJoin } from '../../../utils/string';
import { getVisibleError } from '../../../hocs/final-form/utils';
import ANALYTICS from '../../../analytics/shipping-profiles';
import { useApolloLightbox } from '../../../hooks/use-apollo-lightbox';
import Icon from '../../../basic-components/icon';
import Field from '../../../basic-components/field';

import cs from './styles.pcss';
import t from '../locale';

const cx = classNames.bind(cs);

type Props = {
  name: string,
  countries: { [string]: string },
  veryLargeScreen?: boolean,
};

function formatExcludedCountriesTitle(
  codes: Array<string>,
  countries: { [string]: string }
): string {
  if (codes.length === 0) {
    return t('fields.excludedCountries.placeholder');
  }

  return prettyArrayJoin(
    codes.map((code) => countries[code]),
    3
  );
}

export default function ExcludedCountries({
  name,
  countries,
  veryLargeScreen = true,
}: Props): React.Node {
  const { openLightbox } = useApolloLightbox(
    'shippingExcludedCountriesLightbox'
  );

  const onOpen = () => {
    openLightbox();
    ANALYTICS.openExcludedCountriesLightbox();
  };

  return (
    <FormField name={name}>
      {({ input: { value }, meta }) =>
        (value && value.length > 0) || getVisibleError(meta) ? (
          <Field
            label={t('fields.excludedCountries.label')}
            error={getVisibleError(meta)}
            className={cx('fieldWrapper')}
            fieldClassName={cx('field')}
            descriptionClassName={cx('description')}
            description={
              <button
                type="button"
                className={cx('excludedCountriesButton')}
                onClick={onOpen}
              >
                <Icon
                  name="settings"
                  className={cx('excludedCountriesIcon')}
                  size={veryLargeScreen ? 16 : 24}
                />
              </button>
            }
          >
            <button
              type="button"
              className={cx('excludedCountriesButton')}
              onClick={onOpen}
            >
              {formatExcludedCountriesTitle(value || [], countries)}
            </button>
          </Field>
        ) : null
      }
    </FormField>
  );
}
