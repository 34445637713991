// @flow

import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

type Props = {
  children?: React.Node,
  className?: string,
};

function FieldSetDescription({
  children,
  className,
}: Props): React.Element<'div'> {
  const fieldSetClassName = cx(cs.description, className);

  return <div className={fieldSetClassName}>{children}</div>;
}

export default FieldSetDescription;
