// @flow
import * as React from 'react';

import Icon from '../../basic-components/icon';
import Link from '../../basic-components/link';

import cs from './styles.pcss';

export type Props = {
  title: string,
  description: string,
  actions?: React.Node,
  link?: { text: string, href: string },
  style?: any,
};

export default function TableEmptyResult({
  title,
  description,
  actions,
  link,
  style,
}: Props): React.Node {
  return (
    <div style={style} className={cs.wrapper}>
      <h2 className={cs.title}>{title}</h2>
      <div className={cs.description}>{description}</div>

      {actions}

      {link ? (
        <Link href={link.href} className={cs.link} target="_blank">
          <Icon name="helpCenter" className={cs.linkIcon} />
          <div className={cs.linkText}>{link.text}</div>
        </Link>
      ) : null}
    </div>
  );
}
