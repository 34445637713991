// @flow
import * as React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import Icon from '../../basic-components/icon';
import ANALYTICS from '../../analytics/onboarding-tour';
import settings from '../../settings';
import keys from '../../settings/storage';
import usePersistState from '../../hooks/use-persist-state';

import { useOnboardingTour } from '../../hooks/use-onboarding-tour';
import cs from './styles.pcss';
import OnboardingStep from './step';

type Props = {
  page?: string,
};

export default function OnboardingTour({ page }: Props): React.Node {
  const {
    currentOnboardingStep,
    onboardingShown,
    onFinishOnboarding,
    onNextOnboardingStep,
    onContinue,
    onBack,
    recommendedSite,
    importedSites,
    additionalSite,
    removeOnboardingStepsFromSkip,
    addOnboardingStepsToSkip,
    skipOnboarding,
    onConfirmPartOnboardingStep,
    resetConfirmedPartOnboardingStep,
    continuedOnboardingStep,
    backedOnboardingStep,
    onSkipOnboarding,
  } = useOnboardingTour();

  const [onboardingTourStarted, setOnboardingTourStarted] = usePersistState(
    keys.onboardingTourStarted
  );

  // For smooth fade out transition
  const [onboardingShownState, setOnboardingShownState] =
    React.useState(onboardingShown);

  React.useEffect(() => {
    if (onboardingShownState && !onboardingShown) {
      setTimeout(() => {
        setOnboardingShownState(false);
      }, 1200);
    } else {
      setOnboardingShownState(onboardingShown);
    }

    if (
      onboardingTourStarted !== 'true' &&
      onboardingShown &&
      currentOnboardingStep === 1
    ) {
      setOnboardingTourStarted('true');
      ANALYTICS.start();
    }
  }, [onboardingShown]);

  React.useEffect(() => {
    if (skipOnboarding) {
      setOnboardingTourStarted('false');
      onSkipOnboarding();
      ANALYTICS.skip();
    }
  }, [skipOnboarding]);

  React.useEffect(() => {
    if (recommendedSite) {
      removeOnboardingStepsFromSkip([2]);
    } else {
      addOnboardingStepsToSkip([2, 3]);
    }
  }, [recommendedSite]);

  React.useEffect(() => {
    if (additionalSite) {
      removeOnboardingStepsFromSkip([7]);
    } else {
      addOnboardingStepsToSkip([7]);
    }
  }, [additionalSite]);

  React.useEffect(() => {
    if (
      page === 'site' &&
      currentOnboardingStep === 7 &&
      backedOnboardingStep !== 8
    ) {
      onNextOnboardingStep();
    } else if (
      page === 'settings' &&
      currentOnboardingStep === 8 &&
      backedOnboardingStep !== 9
    ) {
      onNextOnboardingStep();
    } else if (
      page === 'parcels' &&
      currentOnboardingStep === 9 &&
      backedOnboardingStep !== 10
    ) {
      onNextOnboardingStep();
    } else if (page === 'sites' && currentOnboardingStep === 10) {
      onConfirmPartOnboardingStep();
    }
  }, [page, currentOnboardingStep]);

  React.useEffect(() => {
    if (currentOnboardingStep < 10 && currentOnboardingStep > 4) {
      resetConfirmedPartOnboardingStep();
    }
  }, [currentOnboardingStep]);

  if (!onboardingShownState || !currentOnboardingStep) {
    return null;
  }

  const handleClose = () => {
    ANALYTICS.cancel(currentOnboardingStep);
    setOnboardingTourStarted('false');
    onFinishOnboarding();
  };

  const handleContinue = () => {
    if (
      page === 'stock' ||
      (currentOnboardingStep && currentOnboardingStep !== 2)
    ) {
      onContinue();
    }
    if (
      currentOnboardingStep &&
      currentOnboardingStep < 7 &&
      page !== 'stock'
    ) {
      location.href = settings.path.stock;
    }
  };

  const handleBack = () => {
    onBack();
    if (
      currentOnboardingStep &&
      currentOnboardingStep < 10 &&
      page !== 'stock'
    ) {
      location.href = settings.path.stock;
    }
  };

  return (
    <CSSTransition
      in={onboardingShown}
      mountOnEnter
      unmountOnExit
      appear
      classNames={{
        appear: cs.enter,
        appearActive: cs.enterActive,
        enter: cs.enter,
        enterActive: cs.enterActive,
        exit: cs.exitShown,
        exitActive: cs.exitActiveShown,
      }}
      timeout={1200}
    >
      <SwitchTransition>
        <CSSTransition
          key={currentOnboardingStep}
          classNames={{
            appear: cs.enter,
            appearActive: cs.enterActive,
            enter: cs.enter,
            enterActive: cs.enterActive,
            exit: cs.exit,
            exitActive: cs.exitActive,
          }}
          timeout={400}
        >
          <div className={cs.container}>
            <OnboardingStep
              continuedOnboardingStep={continuedOnboardingStep}
              currentOnboardingStep={currentOnboardingStep}
              onContinue={handleContinue}
              onBack={handleBack}
              onFinishOnboarding={onFinishOnboarding}
              recommendedSite={recommendedSite}
              importedSites={importedSites}
            />

            <button type="button" className={cs.close} onClick={handleClose}>
              <Icon name="cancel" size={24} />
            </button>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </CSSTransition>
  );
}
