// @flow
import * as React from 'react';
import AutosizeTextarea from 'react-textarea-autosize';
import cx from 'classnames';

import cs from './styles.pcss';

export type Props = {
  value: string,
  size?: 'small' | 'medium',
  className?: string,
  maxLength?: number,
  disabled?: boolean,
  onFocus?: (event: SyntheticInputEvent<HTMLTextAreaElement>) => void,
  onBlur?: (event: SyntheticInputEvent<HTMLTextAreaElement>) => void,
  onChange: (event: SyntheticInputEvent<HTMLTextAreaElement>) => void,
  error?: boolean,
  minRows?: number,
  maxRows?: number,
  innerRef?: React.Ref<any>,
  allowOverLimit?: boolean,
};

export default function Textarea({
  size = 'medium',
  value = '',
  minRows = 3,
  maxRows = 20,
  onFocus = () => {},
  onBlur = () => {},
  onChange = () => {},
  maxLength,
  className,
  error,
  innerRef,
  allowOverLimit,
  ...restProps
}: Props): React.Node {
  const [focused, setFocused] = React.useState(false);

  const handleChange = (e: SyntheticInputEvent<HTMLTextAreaElement>) => {
    const {
      currentTarget: { value: newValue },
    } = e;
    if (maxLength && !allowOverLimit && newValue.length > maxLength) return;

    onChange(e);
  };

  const handleFocus = (e: SyntheticInputEvent<HTMLTextAreaElement>) => {
    setFocused(true);
    onFocus(e);
  };

  const handleBlur = (e: SyntheticInputEvent<HTMLTextAreaElement>) => {
    setFocused(false);
    onBlur(e);
  };

  const renderCounter = () => {
    if (!maxLength) return null;
    const commonMaxLenght = maxLength || 0;

    const current =
      value.length > commonMaxLenght && !allowOverLimit
        ? commonMaxLenght
        : value.length;

    return (
      <div className={cx(cs.counter, { [cs.full]: current >= maxLength })}>
        {`${current}/${maxLength}`}
      </div>
    );
  };

  const { disabled } = restProps;

  const getAllowedValue = () => {
    if (allowOverLimit) {
      return value;
    }

    return maxLength ? value.substring(0, maxLength) : value;
  };

  const wrapperClassName = cx(
    cs.wrapper,
    cs[size],
    { [cs.focused]: focused, [cs.error]: error, [cs.disabled]: disabled },
    className
  );

  return (
    <div className={wrapperClassName}>
      <AutosizeTextarea
        {...restProps}
        className={cs.input}
        value={getAllowedValue()}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        minRows={minRows}
        maxRows={maxRows}
        ref={innerRef}
      />
      {renderCounter()}
    </div>
  );
}
