/* eslint-disable consistent-return */
// @flow
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
} from '@apollo/client';
import { configureScope } from '@sentry/browser';
// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';

import ActionCable from 'actioncable';
import * as React from 'react';
import { uniq, path } from 'ramda';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

import settings from '../settings';
import { logError } from './sentry';
import { cache, notificationsVar } from './apollo-cache';

import typedefs from '../typedefs/Client.graphql';

import { isDefined } from './ramda';
import { checkIsEscapeError } from './http';

const sentryContextLink = new ApolloLink((operation, forward) =>
  forward(operation).map((data) => {
    if (data.data && data.data.viewer) {
      const name = path(['accounts', 0, 'name'], data.data.viewer);
      const id = path(['accounts', 0, 'id'], data.data.viewer);
      configureScope((scope) => {
        scope.setUser({
          id,
          name,
        });
      });
    }
    return data;
  })
);

const hasSubscriptionOperation = ({ query: { definitions } }) =>
  definitions.some(
    ({ kind, operation }) =>
      kind === 'OperationDefinition' && operation === 'subscription'
  );

const getHeaders = () => {
  const meta = document.querySelector('meta[name="csrf-token"]');
  if (meta) {
    const CSRFToken = meta.getAttribute('content');
    return { 'X-CSRF-Token': CSRFToken };
  }

  return {};
};

const isUnauthorized = (errors: any[]) =>
  errors.some(
    (error) =>
      (error.extensions &&
        error.extensions.code === 'AUTHENTICITY_TOKEN_FAILURE') ||
      error.message === 'Authentication failure'
  );

const openNotification = (graphQLErrors, operation) => {
  // pick up the operation name and errors
  const { operationName } = operation;
  const requestErrorIgnores = settings.ignoreErrors.request;
  // find the operation that shold be ignored
  const ignoreOperation = requestErrorIgnores.find(
    (ignoreObj) => ignoreObj.operationName === operationName
  );
  // check the ignore settings to decide open or not open the error notification dialog
  if (ignoreOperation) {
    // check the keyword to decide ignorance
    const keywords = ignoreOperation.keyWords;
    // one error
    if (graphQLErrors.length === 1) {
      const message = graphQLErrors[0]?.message;
      if (message && keywords.some((keyword) => message.includes(keyword))) {
        return;
      }
    }
  }
  // open the error notification
  notificationsVar([
    {
      type: 'error',
      id: 'globalError',
      message: 'defaultError',
    },
  ]);
};

const errorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    if (isDefined(graphQLErrors)) {
      try {
        if (
          isUnauthorized(graphQLErrors) &&
          !window.location.href.includes(settings.path.login)
        ) {
          window.location.href = settings.path.login;
        }
        const errorMessage = uniq(
          graphQLErrors.map((error) => error.message)
        ).join(', ');
        logError(`GraphQL - ${errorMessage}`, {
          errors: graphQLErrors,
          operationName: operation.operationName,
          variables: operation.variables,
        });
      } catch (e) {
        logError(`Error parsing GraphQL error. ${e.toString()}`, {
          errors: graphQLErrors,
          operationName: operation.operationName,
          variables: operation.variables,
        });
      }
      // check to open error notification
      if (isDefined(operation)) {
        openNotification(graphQLErrors, operation);
      }
    }
    if (checkIsEscapeError(networkError)) return null;
    if (isDefined(networkError)) {
      logError(
        `GraphQL - NetworkError, ${path(['statusCode'], networkError)}`,
        {
          error: networkError,
          operationName: operation.operationName,
          variables: operation.variables,
        }
      );
      // avoid to open error notification twice
      if (!isDefined(graphQLErrors)) {
        notificationsVar([
          {
            type: 'error',
            id: 'globalError',
            message: 'defaultError',
          },
        ]);
      }
    }
  });

const cable = ActionCable.createConsumer(settings.cable());
const client = new ApolloClient({
  cache,
  typedefs,
  link: ApolloLink.from([
    errorLink(),
    sentryContextLink,
    ApolloLink.split(
      hasSubscriptionOperation,
      new ActionCableLink({
        cable,
      }),
      new HttpLink({
        uri: '/graphql',
        credentials: 'include',
        headers: getHeaders(),
      })
    ),
  ]),
});

export const withApolloProvider =
  (Component: React.ComponentType<*>): (() => React.Node) =>
  () =>
    (
      <ApolloProvider client={client}>
        <Component />
      </ApolloProvider>
    );
