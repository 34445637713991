// @flow

import { sendEvent, payloadCreator } from '../utils/analytics';

const category = 'Help';
const payload = payloadCreator(category);

export default {
  helpClick: (): Promise<void> => sendEvent(payload('Open')),
  helpCenter: (): Promise<void> => sendEvent(payload('Help Center')),
  faq: (): Promise<void> => sendEvent(payload('FAQ')),
  help: (): Promise<void> => sendEvent(payload('Write to Support')),
  restartOnboarding: (): Promise<void> =>
    sendEvent(payload('restartOnboarding')),
};
