// @flow
import * as React from 'react';
import { path } from 'ramda';
import { useQuery, useSubscription } from '@apollo/client';

import NotificationProgress from '../../basic-components/notification-progress';
import ExternalLink from '../../basic-components/external-link';
import settings from '../../settings';
import keys from '../../settings/storage';
import usePersistState from '../../hooks/use-persist-state';
import { getItem } from '../../utils/local-storage';

import t from './locale';
import {
  ExcelImportStatus,
  ExcelImportSubscription,
} from './operations.graphql';

export default function XLSStatus(): React.Node {
  const { data } = useQuery(ExcelImportStatus);
  const [showNotifLocalStorage, setShowNotifLocalStorage] = usePersistState(
    keys.showNotifXLSSync
  );
  const [showState, setShowState] = React.useState(!!showNotifLocalStorage);

  const onSubscriptionData = ({ client, subscriptionData }) => {
    const productExcelImportState = path(
      ['data', 'productExcelImportStats'],
      subscriptionData
    );
    if (productExcelImportState) {
      client.writeQuery({
        query: ExcelImportStatus,
        data: {
          ...data,
          productExcelImportState,
        },
      });
    }
  };

  useSubscription(ExcelImportSubscription, {
    onSubscriptionData,
  });
  // FIXME
  React.useEffect(() => {
    const currentValue = getItem(keys.showNotifXLSSync);
    if (currentValue !== null) {
      setShowState(currentValue);
    }
  });

  if (!data || !data.productExcelImportState) {
    return null;
  }

  const { total, processed, completed, error } = data.productExcelImportState;
  const fixedProcessed = processed >= 0 ? processed : 0;
  const neutral = completed && !total;
  const percentage = neutral
    ? 100
    : Math.min(Math.round((100 * fixedProcessed) / total), 100);

  let message = t('message');
  if (completed) {
    if (error) {
      message = (
        <>
          {t('completedError.text')}{' '}
          <ExternalLink
            mode="white"
            href={settings.references.helpCenterLinks.xls}
          >
            {t('completedError.link')}
          </ExternalLink>
        </>
      );
    } else if (total) {
      message = t('completedMessage', { count: total });
    } else {
      message = t('completedEmpty');
    }
  }

  const handleClose = () => {
    setShowNotifLocalStorage(false);
  };

  return (
    <NotificationProgress
      id="xls-sync"
      percentage={error ? undefined : percentage}
      message={message}
      completed={completed}
      closable={completed}
      neutral={neutral}
      show={showState}
      onClose={handleClose}
    />
  );
}
