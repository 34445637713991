// @flow

import { sendEvent } from '../utils/analytics';

const defaultCategory = 'Stock-2';

export default {
  openLightbox: (
    id: string,
    category: string = defaultCategory
  ): Promise<void> =>
    sendEvent({ category, action: 'openLightbox', label: id }),
  closeLightbox: (
    id: string,
    category: string = defaultCategory
  ): Promise<void> =>
    sendEvent({ category, action: 'closeLightbox', label: id }),
};
