const initialState = {
  id: 0,
  url: '',
  site: {
    name: '',
    code: '',
  },
};

export default function showcase(state = initialState) {
  return state;
}
