// @flow
import * as React from 'react';
import Tabs from '../../../basic-components/tabs';
import TabItem from '../../../basic-components/tabs/tab-item';
import FlagIcon from '../../../basic-components/flag-icon';
import cs from './styles.pcss';

const GENERAL_TAB_NAME = 'general';
const DEFAULT_TABS = [
  GENERAL_TAB_NAME,
  'us',
  'uk',
  'de',
  'au',
  'fr',
  'it',
  'es',
  'ca',
];
const tabMapping = {
  [GENERAL_TAB_NAME]: { label: 'General Policy', siteCode: null },
  us: { label: 'ebay.com', siteCode: 'US' },
  uk: { label: 'ebay.co.uk', siteCode: 'UK' },
  de: { label: 'ebay.de', siteCode: 'DE' },
  fr: { label: 'ebay.fr', siteCode: 'FR' },
  it: { label: 'ebay.it', siteCode: 'IT' },
  es: { label: 'ebay.es', siteCode: 'ES' },
  au: { label: 'ebay.com.au', siteCode: 'AU' },
  ca: { label: 'ebay.ca', siteCode: 'CA' },
  other: { label: 'Other Site', siteCode: null },
};

type Props = {
  names: Array<string>,
  selectedTab?: string,
  onClick?: (string) => void,
};

function ProfileTabs(props: Props): React.Node {
  const { names, selectedTab, onClick } = props;
  return (
    <Tabs>
      {names.map((name) => (
        <TabItem
          key={name}
          name={name}
          selectedTab={selectedTab}
          onClick={onClick}
        >
          {tabMapping[name].siteCode && (
            <FlagIcon
              name={tabMapping[name].siteCode}
              wrapperClassName={cs.flagIcon}
            />
          )}
          {tabMapping[name].label}
        </TabItem>
      ))}
    </Tabs>
  );
}

export { ProfileTabs, GENERAL_TAB_NAME, DEFAULT_TABS };
