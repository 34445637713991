// @flow
import * as React from 'react';

import Hr from '../../hr';

import cs from './styles.pcss';

export default function DropdownSeparator(): React.Node {
  return <Hr className={cs.dropdownSeparator} />;
}
