// @flow
import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

type Props = {
  children: React.Node,
  className?: string,
  shift?: number | string,
  direction?: 'top' | 'bottom',
  hDirection?: 'left' | 'right',
  overlaid?: boolean,
};

export default function DropdownContent({
  children,
  className,
  shift = 0,
  direction = 'top',
  hDirection = 'right',
  overlaid = false,
}: Props): React.Node {
  const style =
    hDirection === 'right'
      ? {
          left: shift,
        }
      : {
          right: shift,
        };

  return (
    <div
      className={cx(
        cs.dropdownContent,
        cs[direction],
        cs[hDirection],
        className,
        { [cs.overlaid]: overlaid }
      )}
      style={style}
    >
      <div className={cs.dropdownContentInner}>{children}</div>
    </div>
  );
}
