// @flow
import * as React from 'react';
import cs from './styles.pcss';

type Props = {
  children: React.Node,
};

export default function NavTabs(props: Props): React.Node {
  return <ul className={cs.tabs}>{props.children}</ul>;
}
