// @flow
import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

type Props = {
  children: React.Node,
  largePadding?: boolean,
  className?: string,
  contentClassName?: string,
  noPadding?: boolean,
  sticky?: boolean,
};

export default function HeaderRow({
  children,
  largePadding,
  className,
  contentClassName,
  noPadding,
  sticky,
}: Props): React.Node {
  return (
    <div
      role="row"
      className={cx(cs.row, className, {
        [cs.largePadding]: largePadding,
        [cs.noPadding]: noPadding,
        [cs.sticky]: sticky,
      })}
    >
      <div className={cx(cs.content, contentClassName)}>{children}</div>
    </div>
  );
}
