import update from 'immutability-helper';
import { createReducer } from '../utils/reducers';

import { SET_CATEGORIES, SET_CATEGORIES_LOADING } from '../actions/product';

export const initialState = {
  sites: {},
  showcases: {},
  categories: [],
  categoriesLoading: false,
  defaults: {},
  currencies: [],
  regions: {},
};

export default createReducer(
  {
    [SET_CATEGORIES]: (state, { data = [] }) =>
      update(state, {
        categories: { $set: data },
      }),
    [SET_CATEGORIES_LOADING]: (state, { loading }) =>
      update(state, {
        categoriesLoading: {
          $set: loading,
        },
      }),
  },
  initialState
);
