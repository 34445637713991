// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import { getCurrencySymbol } from '../../../utils/countries';
import { parseNumber } from '../../../utils/form';

import { FormField } from '../../../hocs/final-form';
import Icon from '../../../basic-components/icon';
import InputField from '../../../basic-components/input-field';

import Prices from '../prices';
import ShippingLocations from '../shipping-locations';

import t from '../locale';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  field: string,
  isDeletable: boolean,
  hasPrices: boolean,
  hasLocations?: boolean,
  defaultCurrency: string,
  shippingDestinations: { [string]: string },
  onDelete?: () => void,
  veryLargeScreen?: boolean,
};

function Tariff({
  field,
  shippingDestinations,
  defaultCurrency,
  hasPrices,
  hasLocations,
  isDeletable,
  onDelete,
  veryLargeScreen = true,
}: Props): React.Node {
  // Delete button should be in ShippingLocations if they are shown and in prices otherwise
  const deleteButton = (
    <button
      type="button"
      onClick={onDelete}
      className={cx('removeTariff')}
      disabled={isDeletable}
    >
      <Icon name="trash" />
    </button>
  );

  return (
    <>
      {hasLocations && (
        <ShippingLocations
          name={`${field}.locations`}
          shippingDestinations={shippingDestinations}
          deleteButton={hasLocations && deleteButton}
        />
      )}
      {hasPrices && (
        <>
          {veryLargeScreen && (
            <div className={cx('pricingHeader')}>
              <div className={cx('pricingHeaderItem')}>
                {t('fields.price.first')}
              </div>
              <div className={cx('pricingHeaderItem')}>
                {t('fields.price.additional')}
              </div>
            </div>
          )}
          <FormField
            name={`${field}.prices`}
            Component={Prices}
            defaultCurrency={defaultCurrency}
            label={t('fields.price.label', {
              currency: defaultCurrency,
              currencySymbol: getCurrencySymbol(defaultCurrency),
            })}
            description={!hasLocations && deleteButton}
            priceFirstLabel={t('fields.price.first')}
            priceAdditionalLabel={t('fields.price.additional')}
            veryLargeScreen={veryLargeScreen}
          />
        </>
      )}
      <FormField
        name={`${field}.timeMax`}
        validate={(val) =>
          isNaN(parseInt(val, 10)) ? t('fields.timeMax.validate.empty') : null
        }
        Component={InputField}
        label={
          <>
            <div>{t('fields.timeMax.label')},</div>
            <div>{t('workingDays')}</div>
          </>
        }
        placeholder={t('fields.timeMax.placeholder')}
        grid={6}
        mask="number"
        parse={parseNumber}
        noGrid={!veryLargeScreen}
        fieldClassName={cs.fieldClassName}
      />
    </>
  );
}

export default Tariff;
