// @flow
import * as React from 'react';
import cx from 'classnames';

import useMediaQuery from '../../hooks/use-media-query';
import Tooltip, { type BaseProps as TooltipProps } from '../tooltip';

import cs from './styles.pcss';

type Props = {
  ...TooltipProps,
  children: React.Node,
  message: React.Node,
  className?: string,
  tooltipClassName?: string,
  arrowClassname?: string,
  inline?: boolean,
  onShow?: () => void,
  onClick?: (event: MouseEvent) => void,
};

export default function HoverTooltip({
  disabled = false,
  inline = false,
  children,
  message,
  className,
  tooltipClassName,
  onShow,
  onClose,
  contentClassName,
  arrowClassname,
  gap = 4,
  direction = 'top',
  noArrow = true,
  type = 'short',
  closable,
  onClick,
}: Props): React.Node {
  const [shown, setShown] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  const hiding = React.useRef();
  const hoverContainerRef = React.useRef();

  const isDesktopScreen = useMediaQuery();

  const handleShow = () => {
    if (!disabled) {
      hiding.current = false;
      if (!shown) {
        setShown(true);
        if (onShow) {
          onShow();
        }
      }
    }
  };

  const handleHide = () => {
    hiding.current = true;
    requestAnimationFrame(() => {
      if (hiding && hiding.current) {
        setShown(false);
      }
    });
  };

  React.useEffect(() => {
    if (disabled) {
      handleHide();
    }
  }, [disabled]);

  const handleClose = () => {
    setShown(false);

    if (onClose) {
      onClose();
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (!isDesktopScreen) {
      if (shown) {
        handleHide();
      } else {
        handleShow();
      }
    }

    if (onClick) {
      onClick((event: any));
    }
  };

  const handleKeyPress: (e: SyntheticKeyboardEvent<HTMLDivElement>) => void = (
    e: SyntheticKeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === 'Enter') {
      handleClick(e);
    }
  };

  const handleFocus = () => {
    if (!isDesktopScreen) {
      setFocused(true);
      handleShow();
    }
  };

  const handleBlur = () => {
    setFocused(false);
    handleHide();
  };

  const handleMouseLeave = () => {
    if (!focused) {
      handleHide();
    }
  };

  return (
    <>
      <div
        ref={hoverContainerRef}
        className={cx({ [cs.inline]: inline }, cs.button, className)}
        onMouseEnter={handleShow}
        onFocus={handleFocus}
        onMouseLeave={handleMouseLeave}
        onBlur={handleBlur}
        onClick={handleClick}
        role="button"
        tabIndex={-1}
        onKeyPress={handleKeyPress}
      >
        {children}
      </div>
      {shown && !disabled && (
        <div
          className={cx({ [cs.inline]: inline })}
          onMouseEnter={handleShow}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <Tooltip
            className={cx(cs.tooltip, tooltipClassName)}
            target={hoverContainerRef}
            onClose={handleClose}
            disabled={disabled}
            contentClassName={contentClassName}
            gap={gap}
            direction={direction}
            noArrow={noArrow}
            type={type}
            closable={closable}
            arrowClassname={arrowClassname}
          >
            {message}
          </Tooltip>
        </div>
      )}
    </>
  );
}
