// @flow
// fix for ios in incognito mode (localStorage === null)
const checkStorage = (type = 'localStorage') => {
  try {
    const storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export function setItem(key: string, value: any) {
  if (checkStorage()) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export function getItem(key: string): any {
  if (checkStorage()) {
    const value = localStorage.getItem(key);

    return typeof value === 'string' ? JSON.parse(value) : null;
  }
  return null;
}

export function removeItem(key: string): void {
  if (checkStorage()) {
    localStorage.removeItem(key);
  }
}
