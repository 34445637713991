// @flow

import * as React from 'react';
import getDisplayName from '../../../utils/getDisplayName';
import { mixToPropHandler } from '../../../utils/mix-to-prop-handler';
import { Consumer } from '../context';
import type { FilterValueT } from '../typings';

type WrapperProps = {
  validation?: (string) => boolean,
  onChange: (string, ?FilterValueT) => void,
  name: string,
  label: string,
  checkedValue: ?FilterValueT,
};

type FieldComponentPropsT = {
  onChange: (string, ?FilterValueT) => void,
  value: ?FilterValueT,
  label: string,
  checkedValue: ?FilterValueT,
  name: string,
};

function withFiltersContext<FT: {}>(
  FieldComponent: React.ComponentType<FieldComponentPropsT>
): (WrapperProps) => React.Node {
  function Wrapper({ validation, ...restProps }: WrapperProps) {
    return (
      <Consumer>
        {({
          values,
          onChange,
        }: {
          values: $Shape<FT>,
          onChange: (string, FilterValueT) => void,
        }) => (
          <FieldComponent
            {...restProps}
            onChange={mixToPropHandler(
              restProps.onChange,
              (parsedValue, originalValue) => {
                if (validation && !validation(originalValue)) {
                  return;
                }

                onChange(restProps.name, parsedValue);
              }
            )}
            value={values[restProps.name]}
          />
        )}
      </Consumer>
    );
  }

  Wrapper.displayName = `withFiltersContext(${getDisplayName(FieldComponent)})`;

  return Wrapper;
}

export default withFiltersContext;
