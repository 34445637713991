// @flow

import translateCommon from './i18n/common-locale';
import { getCurrencySymbol } from './countries';

export function format(str: string, ...args: Array<string>): string {
  return str.replace(/{(\d+)}/g, (match, num) =>
    typeof args[num] !== 'undefined' ? args[num] : match
  );
}

export function formatToDecimalString(value: string | number = ''): string {
  const normalizedValue = parseFloat(value.toString().replace(',', '.'));

  return parseFloat(Math.round(normalizedValue * 100) / 100).toFixed(2);
}

export function formatNumber(value: number): number | string {
  const rounded = value > 1e5 ? Math.round(value) : value.toFixed(2);

  try {
    return new Intl.NumberFormat('en-US').format(Number(rounded));
  } catch (e) {
    return rounded;
  }
}

export function formatPrice(
  value: number,
  currencyCode: string,
  locale: string
): string {
  const number = formatNumber(value);
  const code = getCurrencySymbol(currencyCode);
  if (locale === 'en') {
    return `${code}${number}`;
  }
  return `${number} ${code}`;
}

export function prettyArrayJoin(
  elements: Array<string>,
  othersCount: number
): string {
  if (elements.length < 2) {
    return elements.length > 0 ? elements[0] : '';
  }

  if (elements.length > othersCount) {
    const surplus = elements.length - othersCount;
    return `${elements.slice(0, othersCount).join(', ')} ${translateCommon(
      'andOther'
    )} ${surplus}`;
  }

  const result = [];
  elements.forEach((element, index) => {
    result.push(element);

    if (index < elements.length - 2) {
      result.push(', ');
    } else if (index < elements.length - 1) {
      result.push(` ${translateCommon('and')} `);
    }
  });

  return result.join('');
}

export function ellipsisize(string: string, limit: number): string {
  if (!string || string.length <= 3 || string.length <= limit || limit < 3)
    return string;

  const halfLimit = limit - 1;
  const start = Math.ceil(halfLimit / 2);
  const end = Math.floor(halfLimit / 2);

  return `${string.slice(0, start)}…${string.slice(-end)}`;
}

export function prettyFileSize(size: number): string {
  if (size === 0) {
    return '0 B';
  }

  const i = Math.floor(Math.log(size) / Math.log(1024));
  const n = Number((size / 1024 ** i).toFixed(2)) * 1;
  return `${n} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export const joinAnd = (array: string[]): string =>
  `${array.join(', ').replace(/, ([^,]*)$/, ` ${translateCommon('and')} $1`)}`;

export const firstLetterToLower = (str: string): string =>
  `${str[0].toLowerCase()}${str.slice(1)}`;
