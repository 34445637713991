import { fetchJSON } from '../../utils/http';

const goToRoot = () => window.location.replace('/');
export const logout = async () => {
  try {
    await fetchJSON('/logout', { method: 'DELETE' });
  } finally {
    goToRoot();
  }
};
