// @flow
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import cs from './styles.pcss';

type Props = {
  value: boolean,
  interval?: number,
  swapWith: React.Node,
  children: (animating: boolean) => React.Node,
};

export default function SwapForInterval({
  value,
  interval = 2000,
  swapWith,
  children,
}: Props): React.Node {
  const [swap, setSwap] = React.useState(false);

  const prevValue = React.useRef(value);

  const hideSuccessMessageTimeout = React.useRef<TimeoutID | null>(null);

  React.useEffect(
    () => () => {
      if (hideSuccessMessageTimeout && hideSuccessMessageTimeout.current) {
        clearTimeout(hideSuccessMessageTimeout.current);
        hideSuccessMessageTimeout.current = null;
      }
    },
    []
  );

  React.useEffect(() => {
    if (prevValue && !prevValue.current && value) {
      setSwap(true);
      hideSuccessMessageTimeout.current = setTimeout(() => {
        setSwap(false);
      }, interval);
    }

    if (!value) {
      setSwap(false);
      if (hideSuccessMessageTimeout && hideSuccessMessageTimeout.current) {
        clearTimeout(hideSuccessMessageTimeout.current);
        hideSuccessMessageTimeout.current = null;
      }
    }

    prevValue.current = value;
  }, [value]);

  return (
    <div className={cs.container}>
      <CSSTransition
        in={swap}
        unmountOnExit
        classNames={{
          enter: cs.enter,
          enterActive: cs.enterActive,
          exit: cs.exit,
          exitActive: cs.exitActive,
          exitDone: cs.exitDone,
          appear: cs.appear,
          appearActive: cs.appearActive,
        }}
        timeout={{ enter: 400, leave: 400, exit: 400, appear: 400 }}
      >
        <div className={cs.swapWith}>{swapWith}</div>
      </CSSTransition>

      <CSSTransition
        in={!swap}
        classNames={{
          enter: cs.enter,
          enterActive: cs.enterActive,
          exit: cs.exit,
          exitActive: cs.exitActive,
          exitDone: cs.exitDone,
          appear: cs.appear,
          appearActive: cs.appearActive,
        }}
        timeout={{ enter: 400, leave: 400, exit: 400, appear: 400 }}
      >
        <div className={cs.children}>{children(swap)}</div>
      </CSSTransition>
    </div>
  );
}
