// @flow
import debounce from 'lodash-es/debounce';

import { sendEvent, payloadCreator } from '../utils/analytics';

const category = 'Product';

const payload = payloadCreator(category);

const variationEditToDebounce = (field: string): Promise<void> =>
  sendEvent(payload('variationEdit', field));

const editToDebounce = (field: string): Promise<void> =>
  sendEvent(payload('edit', field));

export default {
  add: (): Promise<void> => sendEvent(payload('Open for Create')),
  openEdit: (): Promise<void> => sendEvent(payload('Open for Edit')),
  save: (success: boolean): Promise<void> =>
    sendEvent(payload('Save', success ? 'Success' : 'Error')),
  createSimilar: (): Promise<void> => sendEvent(payload('createSimilar')),
  republish: (): Promise<void> => sendEvent(payload('Republish')),
  delete: (): Promise<void> => sendEvent(payload('Delete')),
  archive: (): Promise<void> => sendEvent(payload('Archive')),
  unarchive: (): Promise<void> => sendEvent(payload('Unarchive')),
  variationAdd: (): Promise<void> => sendEvent(payload('variationAdd')),
  variationEdit: (debounce(variationEditToDebounce, 1000): any),
  variationDelete: (): Promise<void> => sendEvent(payload('variationDelete')),
  selectSuggestedCategory: (): Promise<void> =>
    sendEvent(payload('Select Category via Suggest')),
  selectManualCategory: (): Promise<void> =>
    sendEvent(payload('Select Category via Manual Search')),
  resetCategory: (): Promise<void> => sendEvent(payload('Reset Category')),
  limitReached: (): Promise<void> =>
    sendEvent(payload('limitReachedErrorShown')),
  allowPaidInsertion: (value: boolean, limitReached: boolean): Promise<void> =>
    sendEvent(
      payload(
        'allowPaidInsertion',
        limitReached ? 'limitReached' : undefined,
        Number(value)
      )
    ),
  productNavigation: (): Promise<void> =>
    sendEvent(payload('productNavigation')),
  outOfStockCompleteQuantity: (): Promise<void> =>
    sendEvent(payload('outOfStockCompleteQuantity')),
  outOfStockCancelListing: (): Promise<void> =>
    sendEvent(payload('outOfStockCancelListing')),
  edit: (debounce(editToDebounce, 1000): any),
  applyVatToListingsAboveMaxPrice: (): Promise<void> =>
    sendEvent(payload('applyVatToListingsAboveMaxPrice')),
  closeVatToListingsAboveMaxPricePopover: (): Promise<void> =>
    sendEvent(payload('closeVatToListingsAboveMaxPricePopover')),
};
