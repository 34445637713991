// @flow

import * as React from 'react';
import cx from 'classnames';

import Icon from '../../icon';
import catchMouseFocus from '../../../utils/hocs/catch-mouse-focus';

import cs from './styles.pcss';

type Props = {
  className?: string,
  disabled?: boolean,
  checked?: boolean,
  innerRef?: React.Ref<any>,
  name: string,
  index: number,
};

function Radio({
  className,
  checked,
  disabled,
  innerRef,
  name,
  index,
  ...props
}: Props): React.Node {
  return (
    <div className={cx(cs.wrapper, className)}>
      {/* $FlowFixMe */}
      <input
        type="radio"
        disabled={disabled}
        checked={checked}
        ref={innerRef}
        name={name || `radio-${index}`}
        {...props}
        className={cs.radio}
      />
      {disabled && !checked && (
        <Icon name="close" className={cs.disabledIcon} />
      )}
    </div>
  );
}

const RadioWithCatchedFocus: React.ComponentType<Props> =
  catchMouseFocus(Radio);

export default RadioWithCatchedFocus;
