// @flow

type FieldStateSection = {
  touched?: boolean,
  dirtySinceLastSubmit?: boolean,
  error: ?string,
  submitError: ?string,
};

/**
 * getVisibleError checks if field has error that should be displayed.
 * It accepts final-form's field state and returns a string with error if:
 * 1. If field was touched and has client validation error (will show on blur)
 * 2. If field was not changed yet and has an server error since last submit
 */
export function getVisibleError(state: ?FieldStateSection): ?string {
  if (!state) {
    return null;
  }

  return (
    (state.touched && state.error) ||
    (state.dirtySinceLastSubmit ? null : state.submitError)
  );
}
