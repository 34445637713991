// @flow
import * as React from 'react';

import { useOutsideClick } from '../use-outside-click';
import { useApolloDropdown } from '../use-apollo-dropdown';

export const useDropdown = (id: string, onOpen?: () => void): any => {
  const { openDropdown, closeDropdown, dropdowns } = useApolloDropdown(id);
  const opened = dropdowns.includes(id);

  const ref = React.useRef();

  useOutsideClick(ref, closeDropdown);

  const handleClose = React.useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      closeDropdown();
    },
    [closeDropdown]
  );

  const handleToggle = React.useCallback(
    (event: MouseEvent) => {
      if (opened) {
        handleClose(event);
      } else {
        event.stopPropagation();
        openDropdown();
        if (onOpen) {
          onOpen();
        }
      }
    },
    [opened, handleClose, openDropdown, onOpen]
  );

  return { opened, handleToggle, handleClose, ref };
};
