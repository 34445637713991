export const createReducer =
  (handlers = {}, defaultState) =>
  (state = defaultState, action) => {
    if (action && handlers[action.type]) {
      // If action confirms to standard action structure { type, payload, meta } pass
      // only `payload` and `meta` into a handler, otherwise pass the whole action.
      if (typeof action.payload !== 'undefined') {
        return handlers[action.type](state, action.payload, action.meta);
      }

      return handlers[action.type](state, action);
    }

    return state;
  };
