// @flow
import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';
import Loader from '../loader';

type Props = {
  className: string,
  loading: boolean,
  mode: 'success' | 'neutral' | 'waiting',
};

const icons = {
  success: (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 7L9.375 14.875L22.5 1.75"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        className={cs.mark}
      />
    </svg>
  ),
  neutral: (
    <svg
      width="20"
      height="3"
      viewBox="0 0 20 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.5"
        y1="1.5"
        x2="18.5"
        y2="1.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        className={cs.mark}
      />
    </svg>
  ),
  waiting: (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cs.clock}
    >
      <path
        d="M21 21H31"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        className={cs.hour}
      />
      <path
        d="M21 7V21"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        className={cs.minute}
      />
    </svg>
  ),
};

export default function StatusMark({
  className,
  loading,
  mode,
}: Props): React.Node {
  return (
    <div className={cx(cs.wrapper, className)}>
      <Loader
        mode="white"
        size={42}
        className={cx(cs.loader, { [cs.visible]: loading })}
      />

      <div
        className={cx(cs.result, cs[mode], {
          [cs.visible]: !loading,
        })}
      >
        {icons[mode]}
      </div>
    </div>
  );
}
