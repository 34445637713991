// @flow
import * as React from 'react';
import cx from 'classnames';

import Icon, { type IconsBig, type IconsNormal } from '../icon';
import FlagIcon from '../flag-icon';

import cs from './styles.pcss';

export type Props = {
  icon?: IconsNormal | IconsBig | string,
  customIcon?: React.Node,
  text: string,
  onClick?: (
    event: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void,
  href?: string,
  external?: boolean,
  flag?: boolean,
  selected?: boolean,
  tabIndex?: number,
};

const renderContent = ({ flag, icon, text, selected, customIcon }) => (
  <>
    {customIcon}
    {flag
      ? icon && (
          <FlagIcon name={icon} wrapperClassName={cs.optionFlagIcon} active />
        )
      : // $FlowFixMe
        icon && <Icon name={icon} className={cs.optionIcon} />}
    <div className={cx(cs.optionText, { [cs.selectedOptionText]: selected })}>
      {text}
    </div>
    {selected && <Icon name="selectedOption" className={cs.selected} />}
  </>
);

export default function VerticalMenuOption({
  icon,
  text,
  external,
  onClick,
  href,
  selected,
  flag,
  customIcon,
  tabIndex = 0,
}: Props): React.Node {
  const linkExtraProps = external
    ? {
        target: '_blank',
        rel: 'noreferrer noopener',
      }
    : {};

  return href ? (
    <a
      tabIndex={tabIndex}
      className={cs.option}
      href={href}
      onClick={onClick}
      {...linkExtraProps}
    >
      {renderContent({ flag, icon, text, selected, customIcon })}
    </a>
  ) : (
    <button
      tabIndex={tabIndex}
      type="button"
      className={cs.option}
      onClick={onClick}
    >
      {renderContent({ flag, icon, text, selected, customIcon })}
    </button>
  );
}
