// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import cs from './styles.pcss';
import InteractiveElement from '../../interactive-element';

const cx = classNames.bind(cs);

export type SortingDirection = 'asc' | 'desc';

export type Props = {
  children?: React.Node,
  flex?: string,
  ellipsis?: boolean,
  visibleOverflow?: boolean,
  align?: 'left' | 'right' | 'center',
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch',
  cellClassName?: string,
  className?: string,
  onClick?: (e: SyntheticKeyboardEvent<HTMLDivElement>) => void,
  sortingDirection?: ?SortingDirection,
  onSort?: (direction: ?SortingDirection) => void,
  sticky?: boolean,
};

const sortingHandler = (handler, currentValue) => () => {
  switch (currentValue) {
    case 'asc':
      return handler(null);
    case 'desc':
      return handler('asc');
    default:
      return handler('desc');
  }
};

function Cell({
  flex,
  align: textAlign,
  alignItems,
  cellClassName,
  className,
  ellipsis,
  visibleOverflow,
  children,
  onClick,
  sortingDirection,
  onSort,
  sticky,
}: Props): React.Element<'div'> | React.Node {
  let justifyContent;

  switch (textAlign) {
    case 'left':
      justifyContent = 'flex-start';
      break;
    case 'right':
      justifyContent = 'flex-end';
      break;
    case 'center':
      justifyContent = 'center';
      break;
    default:
      justifyContent = 'text-align';
  }

  const childrenContent = ellipsis ? (
    <div className={cx('ellipsis')}>{children}</div>
  ) : (
    children
  );

  if (onClick) {
    return (
      <InteractiveElement
        role="cell"
        className={cx('cell', { visibleOverflow, sticky }, cellClassName)}
        style={{ flex }}
        onClick={onClick}
      >
        <div
          className={cx('content', className)}
          style={{ alignItems, justifyContent, textAlign }}
        >
          {childrenContent}
        </div>
      </InteractiveElement>
    );
  }

  return (
    <div
      role="cell"
      className={cx('cell', { visibleOverflow, sticky }, cellClassName)}
      style={{ flex }}
    >
      <div
        className={cx('content', className)}
        style={{ alignItems, justifyContent, textAlign }}
      >
        {onSort ? (
          <div
            className={cx('sortable')}
            role="presentation"
            onClick={sortingHandler(onSort, sortingDirection)}
          >
            {childrenContent}
            <div className={cx('sortingArrows')}>
              <div
                className={cx(
                  'sortingArrow',
                  'sortingArrow_top',
                  sortingDirection === 'desc' && 'sortingArrow_disabled',
                  sortingDirection === 'asc' && 'sortingArrow_active'
                )}
              />
              <div
                className={cx(
                  'sortingArrow',
                  'sortingArrow_bottom',
                  sortingDirection === 'asc' && 'sortingArrow_disabled',
                  sortingDirection === 'desc' && 'sortingArrow_active'
                )}
              />
            </div>
          </div>
        ) : (
          childrenContent
        )}
      </div>
    </div>
  );
}

Cell.defaultProps = {
  flex: '0 0 auto',
  align: 'left',
  alignItems: 'center',
};

export default Cell;
