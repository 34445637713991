// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import Field from '../../../basic-components/field';
import Switcher from '../../../basic-components/switcher';

import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  label: string,
  value: boolean,
};

export default function PaidByField({
  label,
  value,
  ...props
}: Props): React.Node {
  return (
    <Field className={cx('fieldWrapper')} fieldClassName={cx('field')}>
      <Switcher {...props} />
      <span className={cx('label')}>{label}</span>
    </Field>
  );
}
