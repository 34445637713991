// @flow
import * as React from 'react';
import FieldSet from '../../field-set';
import FieldSetDescription from '../../field-set-description';
import ShippingView from './shipping-view';
import ExcludedCountriesLightbox from '../excluded-countries-lightbox';
import Field from '../../../basic-components/field';
import Status from '../../../basic-components/status';
import FieldSetTitle from '../../field-set-title';

import cs from './styles.pcss';
import t from './locale';
import useMediaQuery from '../../../hooks/use-media-query';
import { ebaySiteIdMapToDomain } from '../../../settings';

type Props = {
  data: any,
};

export default function OriginalPolicyView(props: Props): React.Node {
  const veryLargeScreen = useMediaQuery(`(min-width: 1280px)`);
  const isMobileScreen = useMediaQuery(`(max-width: 768px)`);
  const {
    site_id: siteId,
    name,
    description,
    handlingTime,
    shippingOptions,
    excludedCountries,
    freightShipping,
  } = props.data;

  return (
    <FieldSet className={cs.wrapper}>
      <FieldSet delimiter="wide">
        <FieldSetDescription>
          <Status mode="warning" icon="exclamationCircle">
            {t('statement', { site: ebaySiteIdMapToDomain[`${siteId}`] })}
          </Status>
        </FieldSetDescription>
        <FieldSetTitle leftColumn={isMobileScreen ? '' : ' '}>
          {t('title.policy')}
        </FieldSetTitle>
        <Field
          label={t('fields.policyName')}
          className={cs.fieldWrapper}
          fieldClassName={cs.fieldClassName}
        >
          {name}
        </Field>
        <Field
          label={t('fields.policyDesc')}
          className={cs.fieldWrapper}
          fieldClassName={cs.fieldClassName}
        >
          {description}
        </Field>
        <Field
          label={
            isMobileScreen ? (
              <div>
                {t('fields.handleTime')}, {t('fields.bizDays')}
              </div>
            ) : (
              <>
                <div>{t('fields.handleTime')},</div>
                <div>{t('fields.bizDays')}</div>
              </>
            )
          }
          className={cs.fieldWrapper}
          fieldClassName={cs.fieldClassName}
        >
          {handlingTime == null ? 'Not Set' : handlingTime.value}
        </Field>
      </FieldSet>

      {/* Domestic shipping */}
      <ShippingView
        siteId={siteId}
        shippingOptions={shippingOptions}
        international={false}
        veryLargeScreen={veryLargeScreen}
        isMobileScreen={isMobileScreen}
        freightShipping={freightShipping}
      />

      {/* international shipping */}
      <ShippingView
        siteId={siteId}
        shippingOptions={shippingOptions}
        international
        veryLargeScreen={veryLargeScreen}
        isMobileScreen={isMobileScreen}
        freightShipping={freightShipping}
      />

      <ExcludedCountriesLightbox
        name="original-excluded-countries"
        excludedCountries={excludedCountries}
        veryLargeScreen
        readOnly
      />
    </FieldSet>
  );
}
