// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  isOpen: boolean,
  children: React.Node,
  scrolled?: boolean,
};

export default function MobileMenu(props: Props): React.Node {
  const { isOpen, children, scrolled } = props;

  if (!isOpen) return null;

  return <div className={cx('menu', { scrolled })}>{children}</div>;
}
