// @flow
import * as React from 'react';

import Notification from '../notification';
import StatusMark from '../status-mark';

import cs from './styles.pcss';

type Props = {
  message: React.Node,
  percentage?: number,
  onClose?: () => void | Promise<void>,
  completed: boolean,
  neutral?: boolean,
  id: string,
  closable?: boolean,
  show: boolean,
};

export default function NotificationProgress({
  message,
  percentage,
  onClose,
  completed,
  neutral,
  id,
  show = false,
  closable = true,
}: Props): React.Node {
  const handleCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Notification
      id={id}
      show={show}
      onClose={handleCloseClick}
      closable={closable}
      message={
        <div className={cs.wrapper}>
          <StatusMark
            loading={!completed}
            className={cs.status}
            mode={neutral ? 'neutral' : 'success'}
          />

          <div>
            <div className={cs.message}>{message}</div>
            <div className={cs.percentage}>
              {isNaN(percentage) ? null : `${percentage || 0}%`}
            </div>
          </div>
        </div>
      }
    />
  );
}
