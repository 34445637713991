// @flow
import * as React from 'react';
import cx from 'classnames';
import { useQuery } from '@apollo/client';

import { useApolloDropdown } from '../../hooks/use-apollo-dropdown';
import ButtonDropdown from '../../basic-components/button-dropdown';
import DropdownOption from '../../basic-components/dropdown-option';
import { formatPrice } from '../../utils/string';
import { OrdersPageProfit } from './operations.graphql';
import { getLocale } from '../../utils/i18n';
import InteractiveElement from '../../basic-components/interactive-element';
import ANALYTICS from '../../analytics/profit-widget';
import settings from '../../settings';

import cs from './styles.pcss';
import USDIcon from './usd.svg';
import RURIcon from './rur.svg';
import EurIcon from './eur.svg';
import PoundIcon from './pound.svg';
import t from './locale';

type Props = {
  page: string,
};

const getCurrencyIcon = (currency: string) => {
  switch (currency) {
    case 'USD':
      return USDIcon;
    case 'EUR':
      return EurIcon;
    case 'RUB':
      return RURIcon;
    case 'GBP':
      return PoundIcon;
    default:
      return USDIcon;
  }
};

export default function ProfitWidget({ page }: Props): React.Node {
  const { data, loading } = useQuery(OrdersPageProfit);
  const { closeDropdown } = useApolloDropdown('profitYearDropdown');

  const today = new Date();
  const currentYear = today.getFullYear();

  const [selectedYear, setSelectedYear] = React.useState(currentYear);

  if (!data || loading) return null;

  const {
    currencies,
    viewer: { currency, addedProfitStatus },
  } = data;

  if (
    !addedProfitStatus.length ||
    !addedProfitStatus.some((s) => s.ordersCount)
  ) {
    return null;
  }

  const USDRateObj = currencies.find((c) => c.code === 'USD');
  if (!USDRateObj) return null;
  const USDRate = USDRateObj.rate;
  const Icon = getCurrencyIcon(currency);

  const profitByYear = addedProfitStatus.reduce((acc, { year, ...rest }) => {
    acc[year] = rest;
    return acc;
  }, {});

  const firstProfitYear = addedProfitStatus.map((p) => p.year).sort()[0];

  const allProfitsByYear = {};
  for (let i = firstProfitYear; i <= currentYear; i += 1) {
    allProfitsByYear[i] = profitByYear[i] || { ordersCount: 0, gmv: 0 };
  }

  const yearsToShow = Object.keys(allProfitsByYear);
  const currentData = allProfitsByYear[selectedYear];

  const renderOption = (year) => (
    <DropdownOption
      onClick={(event) => {
        event.stopPropagation();
        ANALYTICS.selectYear();
        setSelectedYear(year);
        closeDropdown();
      }}
      withSelected
      selected={selectedYear === year}
      size="small"
      key={year}
    >
      {year}
    </DropdownOption>
  );

  const renderYear = () =>
    yearsToShow.length > 1 ? (
      <ButtonDropdown
        title={selectedYear}
        mode="inline"
        id="profitYearDropdown"
        size="small"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {yearsToShow.map(renderOption)}
      </ButtonDropdown>
    ) : (
      currentYear
    );

  const clickable = page !== 'parcels';

  return (
    <InteractiveElement
      className={cx(cs.profitWidget, { [cs.clickable]: clickable })}
      onClick={() => {
        if (clickable) {
          location.href = settings.path.parcels;
          ANALYTICS.click();
        }
      }}
    >
      <Icon className={cs.icon} />
      <div className={cs.content}>
        <div className={cs.figures}>
          <div className={cs.block}>
            {t('ordersCount', { count: currentData.ordersCount })}
          </div>
          <div className={cs.block}>
            {formatPrice(currentData.gmv / USDRate, currency, getLocale())}
          </div>
        </div>
        {getLocale() === 'ko' ? (
          <div className={cs.clarification}>
            {renderYear()}
            {t('clarification')}
          </div>
        ) : (
          <div className={cs.clarification}>
            {t('clarification')} {renderYear()}
          </div>
        )}
      </div>
    </InteractiveElement>
  );
}
