// @flow
import { useEventListener } from './use-event-listener';

export const useOutsideClick = (
  element: { current?: HTMLElement | null },
  onClick: (event: Event) => void
) => {
  const handleClick = (event) => {
    const clickTarget: any = event.target;
    if (element && element.current && !element.current.contains(clickTarget)) {
      onClick(event);
    }
  };

  useEventListener('click', handleClick);
};
