// @flow
import * as React from 'react';
import { useQuery } from '@apollo/client';
import union from 'lodash-es/union';
import cx from 'classnames';

import Input from '../../basic-components/input';

import Region from './region';
import t from './locale';
import cs from './styles.pcss';
import { Regions } from './operations.graphql';

import type { ExcludedShowcaseApollo } from '../../typings/showcase';
import type {
  Showcases_showcases as ShowcaseT,
  Regions_regions as RegionT,
} from '../../typings/product-query.flow';

type Country = {
  key: string,
  name: string,
  forbidden?: boolean,
};

type RegionType = {
  key: string,
  name: string,
  countries: Country[],
  filteredCountries?: Country[],
  forceExpanded?: boolean,
};

export type Props = {
  selectedCountries: string[],
  frozenCountries?: string[],
  className?: string,
  domesticShowcases?: { [s: string]: ShowcaseT },
  countryShowcases?: { [s: string]: ShowcaseT | ExcludedShowcaseApollo },
  currentShowcase?: ShowcaseT | ExcludedShowcaseApollo,
  showTotalSelected?: boolean,
  titleRenderer?: (t: string[]) => React.Node,
  onChange: (v: string[]) => void,
};

/**
 * Convert flat list of regions into hierarchical tree (Region => Country)
 */
function buildRegionsTree(regions: RegionT[]): RegionType[] {
  const collectCountries = (countries: string[]): Country[] =>
    regions
      .filter((region: RegionT) => countries.includes(region.code))
      .map((r: RegionT): Country => ({
        name: r.name,
        key: r.code,
      }));

  return regions.reduce((acc: RegionType[], region: RegionT): RegionType[] => {
    if (region.countries && region.countries.length > 0) {
      acc.push({
        name: region.name,
        key: region.code,
        countries: collectCountries(region.countries),
      });
    }

    return acc;
  }, []);
}

export default function CountryInclusions({
  selectedCountries = [],
  domesticShowcases = {},
  showTotalSelected = false,
  className,
  frozenCountries,
  countryShowcases,
  currentShowcase,
  titleRenderer,
  onChange,
}: Props): React.Node {
  const { loading, error, data } = useQuery(Regions);

  const [filter, setFilter] = React.useState('');

  if (loading || error || !data) {
    return null;
  }

  const regions = buildRegionsTree(data.regions);

  const onFilterChange = (e: SyntheticInputEvent<HTMLElement>) => {
    setFilter(e.target.value);
  };

  const toggleCountries = (countriesOfRegion: string[]) => {
    const regionIncluded = countriesOfRegion.every((c) =>
      selectedCountries.find((e) => e === c)
    );

    if (regionIncluded) {
      onChange(
        selectedCountries.filter((e) => !countriesOfRegion.find((c) => c === e))
      );
      return;
    }

    onChange(union(selectedCountries, countriesOfRegion));
  };

  const getFilteredRegions = (): RegionType[] => {
    const clearFilter = filter ? filter.toLowerCase().trim() : '';

    if (clearFilter === '') {
      return regions;
    }

    const countryFilter = (country) =>
      country.name.toLowerCase().indexOf(clearFilter) !== -1;

    return regions
      .map((region) => {
        const filteredCountries = region.countries.filter(countryFilter);

        return {
          name: region.name,
          key: region.key,
          countries: region.countries,
          filteredCountries,
          forceExpanded: filteredCountries.length > 0,
        };
      })
      .filter(
        (region) => (region.filteredCountries || region.countries).length > 0
      );
  };

  const isRegionSelected = (region: RegionType) =>
    region.countries.every((c) => selectedCountries.find((e) => e === c.key));

  const selectedCountriesForRegion = (region: RegionType): string[] =>
    selectedCountries.filter((inc) =>
      region.countries.find((c) => c.key === inc)
    );

  let title;
  if (showTotalSelected) {
    title = titleRenderer
      ? titleRenderer(selectedCountries)
      : t('totalIncluded', { count: selectedCountries.length });
  }

  const filteredRegions = getFilteredRegions();

  const renderRegion = (region) => (
    <Region
      key={region.key}
      name={region.name}
      region={region.key}
      countries={region.filteredCountries || region.countries}
      isIncluded={isRegionSelected(region)}
      includedCountries={selectedCountriesForRegion(region)}
      domesticShowcases={domesticShowcases}
      countryShowcases={countryShowcases}
      currentShowcase={currentShowcase}
      forceExpanded={region.forceExpanded}
      frozenCountries={frozenCountries}
      toggleCountries={toggleCountries}
    />
  );

  return (
    <div className={cx(className, cs.wrapper)}>
      {title && <div className={cs.total}>{title}</div>}
      <Input
        autoFocus
        type="text"
        value={filter}
        className={cs.filterInput}
        onChange={onFilterChange}
        placeholder={t('filterPlaceholder')}
      />
      {filteredRegions.length === 0 && (
        <div className={cs.nothingFound}>{t('nothingFound')}</div>
      )}
      {filteredRegions.map(renderRegion)}
    </div>
  );
}
