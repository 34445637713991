// @flow
import * as React from 'react';
import { path } from 'ramda';
import { useQuery } from '@apollo/client';

import settings from '../../settings';
import keys from '../../settings/storage';
import HappyLightbox from '../../basic-components/happy-lightbox';
import useFeature from '../../hooks/use-feature';
import usePersistState from '../../hooks/use-persist-state';

import { GetFirstAcbtOrder } from './operations.graphql';
import t from './locale';

export default function HappyLightboxFirstSale(): React.Node {
  const feature = useFeature('a_ha_popup');
  const featureSales = useFeature('full_imported_best_seller');

  const [persistState, setPersistState] = usePersistState(keys.happyLightbox);

  const { data } = useQuery(GetFirstAcbtOrder, {
    skip: !feature || persistState === 'viewed' || featureSales,
    variables: {
      last: 1,
      filters: { fromAcbt: true },
    },
  });

  const orders = path(['parcels', 'nodes'], data);

  if (!orders || !orders[0] || !orders[0].id) {
    return null;
  }

  return (
    <HappyLightbox
      id={keys.happyLightbox}
      title={t('title')}
      text={t('text')}
      buttonProps={{
        href: settings.path.parcel(orders[0].id),
        children: t('button'),
      }}
      analyticsPrefix="firstPurchase"
      setPersistState={setPersistState}
    />
  );
}
