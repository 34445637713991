import smoothScroll from 'smoothscroll';

export function scrollToCenter(
  target,
  context = null,
  duration = 500,
  callback = null
) {
  if (!target) {
    return;
  }

  let contextElem = null;
  if (context) {
    if (typeof context === 'string') {
      contextElem = document.getElementById(context);
    } else {
      contextElem = context;
    }
  }

  if (!contextElem) {
    return;
  }

  const { height: contextHeight, top: contextTop } =
    contextElem.getBoundingClientRect();
  const { height: targetHeight, top: targetTop } =
    target.getBoundingClientRect();

  const top =
    targetTop -
    contextHeight / 2 -
    contextTop +
    Math.min(targetHeight / 2, contextHeight / 3) +
    contextElem.scrollTop;

  smoothScroll(top, duration, callback, contextElem);
}
