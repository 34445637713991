// @flow
import * as React from 'react';
import { path, isNil, pathOr } from 'ramda';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';

import { ShippingProfilesPaginated } from '../../shared-queries/ShippingProfiles.graphql';
import ANALYTICS from '../../analytics/shipping-profiles';
import { selectedShippingProfileVar } from '../../utils/apollo-cache';

import {
  ShippingProfile,
  ShippingProfileAdditional,
  ShippingProfileSave,
  GetShowcases,
} from './operations.graphql';
import ShippingProfileLightboxForm from './form';
import {
  createEmptyProfile,
  convertToFormProfile,
  getDisabledColors,
} from './profile-utils';

import type { ShippingProfileSave_upsertProfile_profile as SavedProfile } from '../../typings/product-query.flow';

type Props = {
  onSaveCompleted?: (profile: SavedProfile) => void,
};

// mock test
// const mockData = require('./mock/shipping-profile-data.json');
// const mockData = require('./mock/data2.json');

export default function ShippingProfileLightbox({
  onSaveCompleted,
}: Props): React.Node {
  const profileId = useReactiveVar(selectedShippingProfileVar);
  const isNew = profileId === '-1';

  const [duplicateProlife, setDuplicateProlife] = React.useState(null);
  const [cacheCount, setCacheCount] = React.useState(0);

  React.useEffect(() => {
    setDuplicateProlife(null);
  }, [isNew, profileId]);

  const [onSave, { loading: submitting }] = useMutation(ShippingProfileSave, {
    update: (cache, result) => {
      const newProfile = path(['data', 'upsertProfile', 'profile'], result);
      const otherProfile = path(
        ['data', 'upsertProfile', 'otherProfile'],
        result
      );
      const success = path(['data', 'upsertProfile', 'success'], result);

      if (!success && otherProfile) {
        setDuplicateProlife(otherProfile.title);
        ANALYTICS.updateProfile(isNew, 'Duplicate');
      } else {
        ANALYTICS.updateProfile(isNew, success ? 'Success' : 'Error');
      }

      if (success) {
        setCacheCount(cacheCount + 1);
      }

      if (success && duplicateProlife) {
        const { profiles } = cache.readQuery({
          query: ShippingProfilesPaginated,
          variables: { first: 30 },
        });

        const updatedProfilesNodes = [
          ...profiles.nodes.filter((p) => p.id !== profileId),
        ];

        const existingIndex = updatedProfilesNodes.findIndex(
          (p) => p.id === newProfile.id
        );

        if (existingIndex === -1) {
          updatedProfilesNodes.unshift(newProfile);
        } else {
          updatedProfilesNodes[existingIndex] = newProfile;
        }

        cache.writeQuery({
          query: ShippingProfilesPaginated,
          data: {
            profiles: {
              ...profiles,
              nodes: updatedProfilesNodes,
            },
          },
        });
        return;
      }

      if (success && isNew && newProfile) {
        const { profiles } = cache.readQuery({
          query: ShippingProfilesPaginated,
          variables: { first: 30 },
        });

        cache.writeQuery({
          query: ShippingProfilesPaginated,
          data: {
            profiles: {
              ...profiles,
              nodes: [newProfile, ...profiles.nodes],
            },
          },
        });
      }
    },
  });

  const {
    data: currenciesData,
    loading: currenciesLoading,
    error: currenciesError,
  } = useQuery(ShippingProfileAdditional);

  // 正常
  const { data, loading, error } = useQuery(ShippingProfile, {
    variables: { id: profileId },
    skip: isNew || isNil(profileId),
  });
  // mock test
  // const { loading, error } = useQuery(ShippingProfile, {
  //   variables: { id: profileId },
  //   skip: isNew || isNil(profileId),
  // });

  const { data: cases } = useQuery(GetShowcases);
  const showcases = pathOr([], ['showcases'], cases);

  if (
    currenciesLoading ||
    currenciesError ||
    !currenciesData ||
    !currenciesData.viewer ||
    !currenciesData.profiles
  ) {
    return null;
  }

  const { currencies, viewer, profiles, profileTariffLocations, regions } =
    currenciesData;

  const account = viewer.accounts[0];
  const defaultCurrency = viewer.currency;
  const defaultItemLocation = {
    country: account.country || '',
    city: account.city,
    postalCode: account.postalCode,
  };
  // convert [{ code, name }, ...] to { [code]: name, ... }
  const shippingDestinations = profileTariffLocations.reduce(
    (names, region) => ({ ...names, [region.code]: region.name }),
    {}
  );

  const countries = regions.filter((region) => region.leaf);

  // 正常的
  const profile = data ? data.profile : null;
  // Mock Test
  // const { profile } = mockData.data;

  return (
    <ShippingProfileLightboxForm
      shippingProfile={
        isNew
          ? createEmptyProfile(defaultItemLocation)
          : convertToFormProfile(profile, currencies, defaultCurrency, account)
      }
      duplicateProlife={duplicateProlife}
      countries={countries}
      defaultCurrency={defaultCurrency}
      disabledColorsMap={getDisabledColors(
        profiles.nodes,
        profile ? profile.id : null
      )}
      shippingDestinations={shippingDestinations}
      loading={loading && !isNew}
      submitting={submitting}
      error={error}
      onSave={(profileInput) =>
        onSave({
          variables: {
            input: {
              profile: profileInput,
              mergeEnabled: Boolean(duplicateProlife),
            },
          },
        })
      }
      onSaveCompleted={onSaveCompleted}
      showcases={showcases}
      cacheCount={cacheCount}
    />
  );
}
