// @flow

import * as React from 'react';
import Field from '../field';
import Input, { type Props as InputProps } from '../input';

import withResolver from './withResolver';

export type Props = {
  ...InputProps,
  label?: React.Node,
  description?: React.Node,
  error?: React.Node,
  grid?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  labelClassName?: string,
  errorClassName?: string,
  fieldClassName?: string,
  wrapperClassName?: string,
  descriptionClassName?: string,
  fieldRef?: React.Ref<any>,
  noGrid?: boolean,
  inputError?: boolean,
  useDefaultValue?: boolean,
};

function InputField({
  label,
  description,
  error,
  grid,
  labelClassName,
  fieldRef,
  noGrid,
  errorClassName,
  wrapperClassName,
  inputError,
  descriptionClassName,
  fieldClassName,
  ...props
}: Props): React.Node {
  const refInput = React.useRef();

  const handleLabelClick: () => void = () => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  };

  return (
    <Field
      innerRef={fieldRef}
      label={label}
      labelClassName={labelClassName}
      description={description}
      error={error}
      grid={grid}
      onLabelClick={handleLabelClick}
      noGrid={noGrid}
      errorClassName={errorClassName}
      className={wrapperClassName}
      fieldClassName={fieldClassName}
      descriptionClassName={descriptionClassName}
    >
      <Input innerRef={refInput} {...props} error={!!error || inputError} />
    </Field>
  );
}

export default (withResolver<React.Config<Props, any>>(
  InputField
): React.AbstractComponent<React.Config<Props, { ... }>>);
