// @flow
import { createAction } from 'redux-actions';
import { fetchJSON } from '../utils/http';
import settings from '../settings';

import type { SendTrackingStatus } from '../typings/parcels-send-tracking';

export const setStatus: {
  (
    payload: mixed,
    ...rest: Array<any>
  ): { error?: boolean, payload: any, type: string, ... },
  +toString: () => string,
  ...
} = createAction(
  'PARCELS_SEND_TRACKING_NUMBER/SET_STATUS',
  (parcelId: string, status: SendTrackingStatus = null) => ({
    parcelId,
    status,
  })
);

export const sendTracking =
  (id: string): ((dispatch: any) => Promise<void>) =>
  async (dispatch: any) => {
    dispatch(setStatus(id, 'LOADING'));

    try {
      await fetchJSON(`${settings.path.parcels}/${id}/send_shipping_update`, {
        method: 'PUT',
      });
    } catch (e) {
      dispatch(setStatus(id, 'ERROR'));
      return;
    }

    dispatch(setStatus(id, 'SUCCESS'));
  };
