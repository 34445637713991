// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import { FieldArray } from 'react-final-form-arrays';
import { pathOr } from 'ramda';

import { shipping } from '../../../settings';

import Button from '../../../basic-components/button';
import Field from '../../../basic-components/field';
import ShowWhen from '../../../hocs/final-form/show-when';

import Tariff from '../tariff';
import AddExcludedCountriesButton from '../add-excluded-countries-button';
import { createEmptyTariff } from '../profile-utils';

import t from '../locale';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

const MAX_TARIFFS = 5;

const FREE = shipping.pricing.free;
const CUSTOM = shipping.pricing.custom;

const noExcludedCountries = (countries) => !countries || countries.length === 0;

type Props = {
  type: string | null,
  error: string,
  defaultCurrency: string,
  shippingDestinations: { [string]: string },
  veryLargeScreen?: boolean,
};

/**
 * Tariffs block in ShippingProfilesLightbox.
 * It has two modes:
 * 1. when profile.type === 'FREE' (free shipping worldwide) it allows to change only timeMax for 1 tariff.
 * 2. when profile.type === 'FLAT' (flat rate worldwide) it allows to change price and timeMax for up to 5 tariffs.
 * 3. when profile.type -== 'CUSTOM' (Сost varies by buyer location and shipping service) -
 *   same as FLAT plus ability to configure shipping locations
 */
export default function Tariffs({
  error,
  type,
  defaultCurrency,
  shippingDestinations,
  veryLargeScreen = true,
}: Props): React.Node {
  if (type === FREE) {
    return (
      <>
        <Tariff
          field="tariffs.0"
          isDeletable={false}
          hasPrices={false}
          defaultCurrency={defaultCurrency}
          shippingDestinations={shippingDestinations}
          veryLargeScreen={veryLargeScreen}
        />
        {error && <Field error={error} />}
        <ShowWhen name="excludedCountries" is={noExcludedCountries}>
          <Field noGrid={!veryLargeScreen}>
            <AddExcludedCountriesButton />
          </Field>
        </ShowWhen>
      </>
    );
  }

  return (
    // $FlowFixMe
    <FieldArray
      name="tariffs"
      validate={(val) => {
        if (val && val.length) {
          const prices = val.map((tariff) =>
            pathOr(0, ['prices', defaultCurrency, 'price'], tariff)
          );

          const additionalPrices = val.map((tariff) =>
            pathOr(0, ['prices', defaultCurrency, 'additionalPrice'], tariff)
          );

          if (prices && prices.length) {
            for (let i = 0; i < prices.length; i += 1) {
              if (Number(prices[i] || 0) < Number(additionalPrices[i] || 0)) {
                return t('fields.price.validate.additionalHigher');
              }
            }
          }
        }
        return null;
      }}
    >
      {({ fields }) => (
        <div className={cx('tariffs')}>
          {fields.map((field, index) => (
            <div className={cx('tariff')} key={field}>
              <Tariff
                hasPrices
                hasLocations={type === CUSTOM}
                field={field}
                defaultCurrency={defaultCurrency}
                shippingDestinations={shippingDestinations}
                isDeletable={fields && fields.value && fields.value.length <= 1}
                onDelete={() => fields.remove(index)}
                veryLargeScreen={veryLargeScreen}
              />
            </div>
          ))}
          {error && <Field error={error} noGrid={!veryLargeScreen} />}
          <Field
            className={cx('addTariff')}
            noGrid={!veryLargeScreen}
            fieldClassName={cx('field')}
          >
            <Button
              mode="secondary"
              onClick={() => fields.push(createEmptyTariff())}
              disabled={
                fields && fields.value && fields.value.length >= MAX_TARIFFS
              }
            >
              {t('buttons.addTariff')}
            </Button>
            <span className={cx('tariffsCount')}>
              {fields && fields.value ? fields.value.length : 0}/{MAX_TARIFFS}
            </span>
            <ShowWhen name="excludedCountries" is={noExcludedCountries}>
              <AddExcludedCountriesButton
                className={cx('addExcludedCountriesButton')}
              />
            </ShowWhen>
          </Field>
        </div>
      )}
    </FieldArray>
  );
}
