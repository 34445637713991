// @flow
import * as React from 'react';

import { logError } from '../../utils/sentry';
import { notificationsVar } from '../../utils/apollo-cache';

type PropsT = {
  children: React.Node,
};

export default class BoundaryErrors extends React.Component<PropsT> {
  componentDidCatch(error: Error) {
    notificationsVar([
      {
        type: 'error',
        id: 'globalError',
        message: 'defaultError',
      },
    ]);
    logError(error);
  }

  render(): React.Node {
    return this.props.children;
  }
}
