import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import mapDataToGlobalState from './mapper';

import account from './account';
import notifications from './notifications';
import start from './start';
import parcels from './parcels';
import parcelsSendTracking from './parcels-send-tracking';
import product from './product';
import showcase from './showcase';
import showcases from './showcases';
import signIn from './sign-in';
import global from './global';
import upload from './upload';
import user from './user';
import { composeReducers } from '../utils/data-helpers';
import { onboardingMiddleware } from './middleware/onboarding';
import lightbox from './lightbox';
import onboarding from './onboarding';
import errors from './errors';
import getStarted from './get-started';

// create special root reducer for page
const makeReducer = (reducerObject, currentPage) =>
  reducerObject[currentPage]
    ? reducerObject[currentPage]
    : reducerObject.defaultReducer;

export default function createRootStore(initialData, mapDataToPageState) {
  const globalState = mapDataToGlobalState(initialData);
  const pageState =
    typeof mapDataToPageState === 'function'
      ? mapDataToPageState(initialData, globalState)
      : {};
  const initialState = { ...globalState, ...pageState };

  const allReducers = combineReducers({
    account,
    errors,
    settings: account,
    notifications,
    parcels,
    parcelsSendTracking,
    product,
    showcase,
    showcases,
    signIn,
    start,
    global,
    upload,
    user,
    lightbox,
    onboarding,
    page: () => initialData.page,
  });

  const rootReducer = composeReducers(
    makeReducer(
      {
        getStarted,
        defaultReducer: allReducers,
      },
      initialState.currentPage
    )
  );

  const middlewares = [onboardingMiddleware, thunk];

  let composeEnhancers = compose;

  // Enable redux-devtools extenstion if installed
  // otherwise enable redux-logger
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable no-underscore-dangle, global-require */
    if (
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    } else {
      const { createLogger } = require('redux-logger');
      const logger = createLogger({ collapsed: true });

      middlewares.push(logger);
    }
    /* eslint-enable no-underscore-dangle */
  }

  const store = composeEnhancers(applyMiddleware(...middlewares))(createStore)(
    rootReducer,
    initialState
  );

  return store;
}
