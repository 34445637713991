// @flow
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { pathOr, sort, descend, prop, dec, inc, uniq, without } from 'ramda';

import usePersistState from '../use-persist-state';
import storage from '../../settings/storage';
import {
  currentOnboardingStepVar,
  continuedOnboardingStepVar,
  backedOnboardingStepVar,
  confirmedPartOnboardingStepVar,
  onboardingSkipStepsVar,
  onboardingTipShownVar,
} from '../../utils/apollo-cache';

import {
  OnboardingTourStatus,
  SetOnboardingTourStatus,
} from './operations.graphql';

import type {
  OnboardingTourStatus_showcases_site as ShowcaseSiteT,
  OnboardingTourStatus_viewer_limits_site as LimitsSiteT,
} from '../../typings/product-query.flow';

type OnboardingTour = {
  onboardingStatusLoading: boolean,
  onboardingShown: boolean,
  skipOnboarding: boolean,
  currentOnboardingStep: number,
  isOnboardingStep: (step: number) => boolean,
  onNextOnboardingStep: () => void,
  onPrevOnboardingStep: () => void,
  onContinue: () => void,
  onBack: () => void,
  onFinishOnboarding: () => void,
  onStartOnboarding: () => Promise<void>,
  recommendedSite: LimitsSiteT | null,
  additionalSite: ShowcaseSiteT | null,
  importedSites: ShowcaseSiteT[],
  continuedOnboardingStep: number,
  backedOnboardingStep: number,
  resetContinuedOnboardingStep: () => void,
  resetBackedOnboardingStep: () => void,
  confirmedPartOnboardingStep: number,
  onConfirmPartOnboardingStep: () => void,
  resetConfirmedPartOnboardingStep: () => void,
  addOnboardingStepsToSkip: (steps: number[]) => void,
  removeOnboardingStepsFromSkip: (steps: number[]) => void,
  hideOnboardingTip: () => void,
  onSkipOnboarding: () => void,
};

export const useOnboardingTour = (): OnboardingTour => {
  const currentOnboardingStep = useReactiveVar(currentOnboardingStepVar);
  const continuedOnboardingStep = useReactiveVar(continuedOnboardingStepVar);
  const backedOnboardingStep = useReactiveVar(backedOnboardingStepVar);
  const confirmedPartOnboardingStep = useReactiveVar(
    confirmedPartOnboardingStepVar
  );

  const { data, loading } = useQuery(OnboardingTourStatus);

  const [updateStatus] = useMutation(SetOnboardingTourStatus);

  const [, setLSStep] = usePersistState(storage.onboardingTourStep);

  const onboardingFinished = pathOr(
    true,
    ['viewer', 'onboardingTourFinished'],
    data
  );

  const showcases = pathOr([], ['showcases'], data);

  const hasProducts =
    showcases.some((s) => s.anyImported) ||
    showcases.some((s) => s.anySelectable);

  const importedSites = showcases
    .filter((s) => s.anyImported)
    .map((s) => s.site);

  const activeShowcases = showcases.filter((s) => s.active && s.anySelectable);
  const nonActiveShowcases = showcases.filter((s) => !s.active);
  const avaliableSitesIds = nonActiveShowcases.map((s) => s.site.id);

  const limits = pathOr([], ['viewer', 'limits'], data);

  let sitesLimitsToShow =
    sort(
      descend(prop('weight')),
      limits.filter((l) => avaliableSitesIds.includes(l.site.id))
    ) || [];

  const showcasesNotInLimits = nonActiveShowcases.filter(
    (s) => !sitesLimitsToShow.some((l) => l.site.id === s.site.id)
  );
  sitesLimitsToShow = [...sitesLimitsToShow, ...showcasesNotInLimits];

  const recommendedSite = sitesLimitsToShow[0]
    ? sitesLimitsToShow[0].site
    : null;

  const additionalSite = activeShowcases[0] ? activeShowcases[0].site : null;

  const setOnboardingStep = (stepNumber: number) => {
    setLSStep(stepNumber);
    currentOnboardingStepVar(stepNumber);
  };

  const hideOnboardingTip = () => {
    onboardingTipShownVar(false);
  };

  const onFinishOnboarding = () => {
    updateStatus({
      variables: { input: { onboardingTourFinished: true } },
    }).then(() => {
      setTimeout(() => {
        onboardingTipShownVar(true);
      }, 1000);
    });
  };

  const onStartOnboarding = async () => {
    setOnboardingStep(1);
    await updateStatus({
      variables: { input: { onboardingTourFinished: false } },
    });
  };
  const onChangeOnboardingStep = (back: boolean) => () => {
    const proceedStep = back ? dec : inc;
    let newStep = proceedStep(currentOnboardingStep);

    while (onboardingSkipStepsVar().includes(newStep)) {
      newStep = proceedStep(newStep);
    }
    setOnboardingStep(newStep);
  };

  const onNextOnboardingStep = onChangeOnboardingStep(false);
  const onPrevOnboardingStep = onChangeOnboardingStep(true);

  // To perform external component action on click onboarding Continue button
  // For step 2 (open modal)
  const onContinue = () => {
    continuedOnboardingStepVar(currentOnboardingStep);

    if (currentOnboardingStep !== 3) {
      onNextOnboardingStep();
    }
  };

  const resetContinuedOnboardingStep = () => {
    continuedOnboardingStepVar(0);
  };

  // To perform external component action on click onboarding Back button
  // For step 3 (close modal)
  const onBack = () => {
    backedOnboardingStepVar(currentOnboardingStep);
    onPrevOnboardingStep();
  };

  const resetBackedOnboardingStep = () => {
    backedOnboardingStepVar(0);
  };

  const onConfirmPartOnboardingStep = () => {
    confirmedPartOnboardingStepVar(currentOnboardingStep);
  };

  const resetConfirmedPartOnboardingStep = () => {
    confirmedPartOnboardingStepVar(0);
  };

  const onboardingShown = !onboardingFinished && hasProducts;

  const isOnboardingStep = (stepNumber: number) =>
    onboardingShown && currentOnboardingStep === stepNumber;

  const addOnboardingStepsToSkip = (steps: number[]) => {
    onboardingSkipStepsVar(uniq([...onboardingSkipStepsVar(), ...steps]));
  };

  const removeOnboardingStepsFromSkip = (steps: number[]) => {
    onboardingSkipStepsVar(without(steps, onboardingSkipStepsVar()));
  };

  const onSkipOnboarding = () => {
    updateStatus({
      variables: { input: { onboardingTourFinished: true } },
    });
  };

  return {
    onboardingStatusLoading: loading,
    onboardingShown,
    skipOnboarding: showcases.length > 0 && !onboardingFinished && !hasProducts,
    onSkipOnboarding,
    currentOnboardingStep,
    isOnboardingStep,
    onNextOnboardingStep,
    onPrevOnboardingStep,
    confirmedPartOnboardingStep,
    onConfirmPartOnboardingStep,
    resetConfirmedPartOnboardingStep,
    onContinue,
    onBack,
    onFinishOnboarding,
    onStartOnboarding,
    recommendedSite,
    additionalSite,
    importedSites,
    continuedOnboardingStep,
    backedOnboardingStep,
    resetContinuedOnboardingStep,
    resetBackedOnboardingStep,
    addOnboardingStepsToSkip,
    removeOnboardingStepsFromSkip,
    hideOnboardingTip,
  };
};
