// @flow
import * as React from 'react';
import FieldSet from '../../field-set';
import FieldSetTitle from '../../field-set-title';
import Field from '../../../basic-components/field';
import Checkbox from '../../../basic-components/checkbox';
import {
  getCurrencySymbol,
  getCurrencyUnitBySiteId,
} from '../../../utils/countries';
import ShipTo from '../ship-to';

import t from './locale';
import cs from './styles.pcss';
import { getRegionNames } from '../profile-utils';

type Props = {
  siteId: number,
  shippingOptions: any,
  international: boolean,
  veryLargeScreen?: boolean,
  isMobileScreen?: boolean,
  freightShipping?: boolean,
};

// ds: domestic shipping
const dsCostTypeMap = {
  FLAT_RATE: t('domesticShippingCostType.flat'),
  CALCULATED: t('domesticShippingCostType.calculated'),
  FREIGHT: t('domesticShippingCostType.freight'),
  NO_SHIPPING: t('domesticShippingCostType.noShipping'),
};

// is: international shipping
const isCostTypeMap = {
  FLAT_RATE: t('internationalShippingCostType.flat'),
  CALCULATED: t('internationalShippingCostType.calculated'),
  NO_SHIPPING: t('internationalShippingCostType.noShipping'),
  // 国际运送不支持Freight，这里是为通过yarn flow check
  FREIGHT: t('domesticShippingCostType.freight'),
};

const disableChange = (e) => e.preventDefault();

export default function ShippingView(props: Props): React.Node {
  const {
    siteId,
    shippingOptions = [],
    international,
    veryLargeScreen = true,
    isMobileScreen = false,
    freightShipping = false,
  } = props;

  // No shipping(local pickup), the shippingOptions is empty array
  if (!shippingOptions || shippingOptions.length === 0) return null;

  const optionType = international ? 'INTERNATIONAL' : 'DOMESTIC';
  const shippingOption = shippingOptions.find(
    (o) => o.optionType === optionType
  );
  if (!shippingOption) return null;

  const costTypeMap = international ? isCostTypeMap : dsCostTypeMap;
  const { shippingServices } = shippingOption;
  let { costType } = shippingOption;
  // Freight shipping, there is no costType field in shippingOption, i.e. above costType will be undefined.
  if (freightShipping) {
    costType = 'FREIGHT';
  }

  return (
    <>
      <FieldSet delimiter={veryLargeScreen ? 'narrow' : 'wide'}>
        <FieldSetTitle leftColumn={isMobileScreen ? '' : ' '}>
          {international ? t('title.international') : t('title.domestic')}
        </FieldSetTitle>

        <Field
          label={t('fields.type')}
          className={cs.fieldWrapper}
          fieldClassName={cs.fieldClassName}
        >
          {costTypeMap[costType]}
        </Field>
      </FieldSet>

      {shippingServices.map((service, index) => {
        const showFreeShippingCheckbox = !international && service.freeShipping;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <FieldSet delimiter={veryLargeScreen ? 'narrow' : 'wide'} key={index}>
            <Field
              label={t('fields.service')}
              className={cs.fieldWrapper}
              fieldClassName={cs.fieldClassName}
            >
              {service.shippingServiceName}
            </Field>
            {showFreeShippingCheckbox && (
              <Field
                label={t('fields.free')}
                fieldClassName={cs.fieldClassName}
              >
                <Checkbox checked onChange={disableChange} />
              </Field>
            )}
            {!showFreeShippingCheckbox && (
              <>
                {veryLargeScreen && (
                  <div className={cs.pricingHeader}>
                    <div className={cs.pricingHeaderItem}>
                      {t('fields.firstCost')}
                    </div>
                    <div className={cs.pricingHeaderItem}>
                      {t('fields.additionalCost')}
                    </div>
                  </div>
                )}
                <Field
                  label={t('fields.cost', {
                    currency:
                      service?.shippingCost?.currency ||
                      getCurrencyUnitBySiteId(siteId),
                    currencySymbol: getCurrencySymbol(
                      service?.shippingCost?.currency ||
                        getCurrencyUnitBySiteId(siteId)
                    ),
                  })}
                  fieldClassName={cs.fieldClassName}
                >
                  {isMobileScreen && (
                    <div className={cs.pricingHeaderItem}>
                      {t('fields.firstCost')}
                    </div>
                  )}
                  <div className={cs.pricingItem}>
                    {service.shippingCost?.value ?? 'Not Set'}
                  </div>
                  {isMobileScreen && (
                    <div className={cs.pricingHeaderItem}>
                      {t('fields.additionalCost')}
                    </div>
                  )}
                  <div className={cs.pricingItem}>
                    {service.additionalShippingCost?.value ?? 'Not Set'}
                  </div>
                </Field>
              </>
            )}

            {international && (
              <Field
                label={t('fields.shipTo')}
                fieldClassName={cs.fieldClassName}
              >
                <ShipTo
                  name="original-international-shipto"
                  siteId={siteId}
                  selectedLocations={getRegionNames(
                    service.shipToLocations.regionIncluded
                  )}
                  readonly
                />
              </Field>
            )}
          </FieldSet>
        );
      })}

      {/* just take this as delimiter */}
      {veryLargeScreen && <FieldSet delimiter="wide" />}
    </>
  );
}
