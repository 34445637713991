// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../icon';
import cs from './styles.pcss';
import catchMouseFocus from '../../utils/hocs/catch-mouse-focus';

const cx = classNames.bind(cs);

type Props = {
  disabled?: boolean,
  dangerous?: boolean,
  mode?: 'main' | 'secondary' | 'success',
  state?: 'on' | 'off' | 'unclear',
  className?: string,
  focus?: boolean,
  hover?: boolean,
  id?: string,
  innerRef?: React.Ref<any>,
  label?: React.Node,
  onClick?: (event: SyntheticEvent<HTMLLabelElement>) => void,
  bigLabel?: boolean,
  darkLabel?: boolean,
  topAlign?: boolean,
  fieldClassName?: string,
};

const getCheckedState = (state?: 'on' | 'off' | 'unclear') => {
  if (state === 'on') return true;
  if (state === 'off' || state === 'unclear') return false;
  return undefined;
};

function Switcher({
  id,
  state,
  disabled,
  dangerous,
  mode = 'main',
  className,
  focus,
  hover,
  innerRef,
  label,
  bigLabel,
  darkLabel,
  onClick,
  topAlign,
  fieldClassName,
  ...rest
}: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <label
      className={cx(
        'switcherWrapper',
        {
          disabled,
          bigLabel,
          topAlign,
        },
        className
      )}
      htmlFor={id}
      onClick={onClick}
    >
      {/* $FlowFixMe */}
      <input
        type="checkbox"
        className={cx('input')}
        disabled={disabled}
        checked={getCheckedState(state)}
        {...rest}
        ref={innerRef}
        id={id}
      />
      <div
        className={cx('switcher', mode, state, {
          disabled,
          focus,
          hover,
        })}
      >
        <div className={cx('slider')}>
          {disabled && <Icon name="close" className={cx('disabledIcon')} />}
          {dangerous && !disabled && <span className={cx('sign')}>!</span>}
        </div>
      </div>
      {label && (
        <div
          className={cx('label', {
            bigLabel,
            darkLabel: darkLabel && !disabled,
          })}
        >
          {label}
        </div>
      )}
    </label>
  );
}

const SwitcherWithCatchedFocus: React.ComponentType<Props> =
  catchMouseFocus(Switcher);

export default SwitcherWithCatchedFocus;
