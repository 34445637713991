// @flow
import * as React from 'react';

export const useEventListener = (
  eventName: string,
  handler: (event: Event) => void,
  element: HTMLElement = window
): void => {
  const savedHandler = React.useRef<(event: Event) => void>(() => {});

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    if (!element || !savedHandler || !savedHandler.current) {
      return () => {};
    }

    const eventListener = (event: any) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
