// @flow
/**
 * Hook to reflect active item state in URL params:
 * - opens item's lightbox if URL contains item id in params (e.g. /parcels?orderId=20)
 * - reflects item id in URL params when we open its individual lightbox
 */

import * as React from 'react';

import { updateURLQuery, parseURLQuery } from '../utils/url';

export default function useShowItemURLParams(
  paramKey: string,
  onInitialParam: (id: string) => void,
  activeItemId?: string
): Array<any | void | string> {
  const firstRender = React.useRef(true);

  const [currentParam, setCurrentParam] = React.useState();

  React.useEffect(() => {
    const params = parseURLQuery(location.search);

    if (firstRender.current) {
      const queryItemId = params[paramKey];
      if (queryItemId) {
        setCurrentParam(queryItemId);
        onInitialParam(queryItemId);
      }

      firstRender.current = false;
      return;
    }

    setCurrentParam(activeItemId);
    updateURLQuery({ ...params, [paramKey]: activeItemId || null });
  }, [activeItemId]);

  return [currentParam];
}
