// @flow

import { path } from 'ramda';

type State = {};

export const onboardingDisabled = (state: State): boolean => {
  const status = path(['onboarding', 'server', 'status'], state);
  return status === 'finished' || status === 'cancelled';
};
