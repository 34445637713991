// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import { useOnboardingTour } from '../../hooks/use-onboarding-tour';
import useMediaQuery from '../../hooks/use-media-query';
import findAncestorByCSSProperty from '../../utils/findAncestorByCSSProperty';
import Icon from '../icon';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  children: React.Node,
  className?: string,
  onClose?: () => void,
  direction?: 'top' | 'bottom' | 'bottomCenter' | 'bottomLeft',
  contentClassName?: string,
  arrowRightMargin?: string, // Override automatic margin calculation for difficult cases
};

const POPUP_WIDTH = 363;

export default function NewInfo({
  children,
  className,
  arrowRightMargin,
  direction = 'bottom',
  onClose,
  contentClassName,
}: Props): React.Node {
  const container = React.useRef();
  const [arrowPos, setArrowPos] = React.useState(arrowRightMargin || '90px'); // 90px is an empiric default value that's good for typical buttons
  const mediumScreen = useMediaQuery(`(min-width: 768px)`);

  // Try to set the arrow tip of the NewInfo popup to the middle of the container for which it shows.
  React.useEffect(() => {
    if (!container.current || arrowRightMargin) {
      return;
    }

    const parent = findAncestorByCSSProperty(
      container.current,
      'position',
      'relative'
    );

    const { width } = parent.getBoundingClientRect();

    if (parent !== document.body && width) {
      const arrowRightPos = Math.min(POPUP_WIDTH, width / 2);
      setArrowPos(`${arrowRightPos}px`);
    }
  }, []);

  const { onboardingShown, onboardingStatusLoading } = useOnboardingTour();

  if (onboardingStatusLoading || onboardingShown) {
    return null;
  }

  return (
    <div className={cx('wrapper', direction)} ref={container}>
      <div
        className={cx('newInfo', className)}
        style={
          mediumScreen
            ? { width: `${POPUP_WIDTH}px` }
            : { width: 'calc(100vw - 40px)' }
        }
      >
        <div
          className={cx('arrow', { forceArrowPos: arrowRightMargin })}
          style={mediumScreen ? { right: arrowPos } : { right: '43px' }}
        />
        <div className={cx('content', contentClassName)}>{children}</div>
        <button
          type="button"
          className={cx('close')}
          onClick={(event) => {
            event.preventDefault();
            if (onClose) {
              onClose();
            }
          }}
        >
          <Icon name="cancel" size={24} />
        </button>
      </div>
    </div>
  );
}
