// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import Cell from '../cell';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  children?: React.Node,
  className?: string,
  sticky?: boolean,
};

function HeaderCell({
  className,
  children,
  sticky,
  ...params
}: Props): React.Node {
  return (
    // $FlowFixMe
    <Cell className={cx('headerCell', className)} {...params} sticky={sticky}>
      {children}
    </Cell>
  );
}

export default HeaderCell;
