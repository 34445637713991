// @flow

import * as React from 'react';

import Button from '../../../basic-components/button';
import ANALYTICS from '../../../analytics/shipping-profiles';
import { useApolloLightbox } from '../../../hooks/use-apollo-lightbox';

import t from '../locale';

type Props = {
  className?: string,
};

export default function AddExcludedCountriesButton({
  className,
}: Props): React.Node {
  const { openLightbox } = useApolloLightbox(
    'shippingExcludedCountriesLightbox'
  );

  const onOpen = () => {
    openLightbox();
    ANALYTICS.openExcludedCountriesLightbox();
  };

  return (
    <Button
      type="button"
      mode="secondary"
      onClick={onOpen}
      className={className}
    >
      {t('buttons.addExcludedCountries')}
    </Button>
  );
}
