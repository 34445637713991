// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';

import { hasParent } from '../../utils/dom';
import { acceptType } from '../../utils/form';

import Icon from '../icon';

import t from './locale';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  className?: string,
  children: React.Node,
  accept?: string | Array<string>,
  forceActive?: boolean,
  disabled?: boolean,
  onChange?: (files: Array<File>) => void,
  transparent?: boolean,
  onHover?: () => void,
  onUnhover?: () => void,
};

const hasFilesInDT = (e: SyntheticDragEvent<HTMLElement>): boolean => {
  if (!e.dataTransfer) {
    // $FlowFixMe
    return !!e.target && !!e.target.files;
  }

  return e.dataTransfer.types.some(
    (type) => type === 'Files' || type === 'application/x-moz-file'
  );
};

const getFilesFromDT = (
  dt: DataTransfer,
  accept: ?string | Array<string>
): Array<File> =>
  Array.from(dt.files).filter((f) => acceptType(f.type, accept));

const transitionAnimation = {
  enter: cx('enter'),
  enterActive: cx('enterActive'),
  exit: cx('exit'),
  exitActive: cx('exitActive'),
};

export default function UploadDropZone({
  children,
  className,
  forceActive = false,
  accept,
  disabled,
  onChange = () => {},
  transparent,
  onHover,
  onUnhover,
}: Props): React.Node {
  const [dropZoneVisible, setDropZoneVisible] = React.useState(false);
  const [isHovered, setHovered] = React.useState(false);

  React.useEffect(() => {
    if (disabled) {
      return undefined;
    }

    let enterCounter = 0;
    let exitCounter = 0;

    const enterListener = (e) => {
      enterCounter += 1;
      if (hasFilesInDT(e)) {
        setDropZoneVisible(true);
      }
    };

    const exitListener = () => {
      exitCounter += 1;
      if (exitCounter === enterCounter) {
        setDropZoneVisible(false);
      }
    };

    window.addEventListener('dragenter', enterListener);
    window.addEventListener('dragleave', exitListener);
    window.addEventListener('drop', exitListener);
    return () => {
      window.removeEventListener('dragenter', enterListener);
      window.removeEventListener('dragleave', exitListener);
      window.removeEventListener('drop', exitListener);
      setDropZoneVisible(false);
    };
  }, [disabled]);

  return (
    <div
      className={className}
      onDragEnter={(e: SyntheticDragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setHovered(true);
        if (onHover) {
          onHover();
        }
      }}
      onDragOver={(e: SyntheticDragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      }}
      onDragLeave={(e) => {
        if (
          e.relatedTarget === e.currentTarget ||
          hasParent(e.relatedTarget, e.currentTarget)
        ) {
          return;
        }

        setHovered(false);
        if (onUnhover) {
          onUnhover();
        }
      }}
      onDrop={(e: SyntheticDragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = getFilesFromDT(e.dataTransfer, accept);
        if (files.length > 0) {
          onChange(files);
        }
        setHovered(false);
        if (onUnhover) {
          onUnhover();
        }
      }}
    >
      {!transparent && (
        <CSSTransition
          appear
          unmountOnExit
          timeout={300}
          classNames={transitionAnimation}
          in={forceActive || dropZoneVisible}
        >
          <div className={cx('dropOverlayBackground')}>
            <div className={cx('dropOverlay', { isHovered })}>
              <Icon name="attach" className={cx('icon')} />
              <div className={cx('label')}>{t('drop')}</div>
            </div>
          </div>
        </CSSTransition>
      )}
      {children}
    </div>
  );
}
