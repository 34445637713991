// @flow
import { evolve } from 'ramda';

import { getInitialFilters } from '../../components/filters/utils';
import keys from '../../settings/storage';
import initialFilters from '../../components/site-products-filters/initialState';

export const getShowcaseId = (): string => {
  const pathname = window.location.pathname.split('/');
  return pathname[pathname.length - 1];
};

const normalizeMinMax = (value) =>
  value !== null ? { min: null, max: null, ...value } : value;

export const siteInitialFilters: {| __typename: string |} = getInitialFilters(
  initialFilters,
  'ListingFilter',
  ['search', 'sku', 'state', 'categoryIds', 'problems'],
  keys.siteFilters,
  evolve({
    price: normalizeMinMax,
    quantity: normalizeMinMax,
  })
);
