// @flow

import {
  identity,
  difference,
  toPairs,
  fromPairs,
  compose,
  omit,
  pick,
} from 'ramda';
import { addTypenames, omitTypenames } from '../../utils/apollo-helpers';
import { parseURLQuery, updateURLQuery } from '../../utils/url';
import { getItem } from '../../utils/local-storage';

export function getChangedFilters<FT: {}>(
  filters: FT,
  initialState: FT,
  ignoredFilters: string[] = []
): $Shape<FT> {
  return compose<any, any, any, any, any>(
    omit(ignoredFilters),
    fromPairs,
    difference(toPairs<any, any>(omitTypenames(filters))),
    toPairs
  )(omitTypenames(initialState));
}

export function addFiltersToQuery<FT: {}>(
  filters: FT,
  preserveQueryKeys: string[] = []
): void {
  const prevQuery = pick<any, any>(
    preserveQueryKeys,
    parseURLQuery(location.search)
  );
  return updateURLQuery({ ...prevQuery, ...omitTypenames(filters) });
}

export function getInitialFilters<FT: {}>(
  initialFilters: FT,
  typename: string,
  skipConvertingFor?: Array<string>,
  localStorageKey: ?string,
  fixFilterFunction?: (FT) => FT // hack that helps to avoid errors while reading from cache only min or max
): {| __typename: string |} {
  const fixFn = fixFilterFunction || identity;
  const addTypenamesAndFix = (filters: FT, typenameLocal: string) =>
    addTypenames(fixFn(filters), typenameLocal);
  const localStorageFilters = localStorageKey ? getItem(localStorageKey) : {};
  const URLFilters = Object.entries(
    parseURLQuery(location.search, skipConvertingFor)
  ).reduce((result, [key, value]) => {
    if (initialFilters[key] !== undefined) {
      return { ...result, [key]: value };
    }

    return result;
  }, {});

  if (Object.keys(URLFilters).length) {
    return addTypenamesAndFix(
      {
        ...initialFilters,
        ...URLFilters,
      },
      typename
    );
  }

  if (localStorageFilters && Object.keys(localStorageFilters).length) {
    return addTypenamesAndFix(
      {
        ...initialFilters,
        ...localStorageFilters,
      },
      typename
    );
  }

  return addTypenamesAndFix({ ...initialFilters }, typename);
}
