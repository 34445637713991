import storage from '../../settings/storage';
import { getItem } from '../../utils/local-storage';

let localOnboardingStep = getItem(storage.onboardingTourStep);

if (localOnboardingStep === 3) {
  localOnboardingStep = 2;
}

export const initialOnboardingStep = localOnboardingStep || 1;
