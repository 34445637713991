// @flow

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import * as React from 'react';
import cx from 'classnames';
import cs from './styles.pcss';
import ANALYTICS from '../../analytics/links';

export type Props = {
  className?: string,
  children: React.Node,
  href?: string,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  target?: string,
  mode?: 'main' | 'secondary' | 'dangerous' | 'white',
  area?: string,
  noUnderline?: boolean,
};

export default function Link({
  className,
  children,
  mode = 'main',
  target,
  noUnderline = true,
  ...props
}: Props): React.Element<'a'> | React.Element<'button'> {
  const { href, area, onClick } = props;
  const handleClick = React.useCallback(
    (event) => {
      ANALYTICS.click(href, area);
      if (onClick) {
        onClick(event);
      }
    },
    [href, area, onClick]
  );

  if (!href && onClick) {
    return (
      // $FlowFixMe
      <button
        type="button"
        className={cx('link', mode, className)}
        onClick={handleClick}
        {...props}
      >
        {children}
      </button>
    );
  }

  return (
    // $FlowFixMe
    <a
      onClick={handleClick}
      className={cx(
        cs.link,
        cs[mode],
        { [cs.noUnderline]: noUnderline },
        className
      )}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : null}
      {...props}
    >
      {children}
    </a>
  );
}
