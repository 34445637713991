// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import Checkbox from '../../../basic-components/checkbox';

import type { Region } from '../types';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  regions: Array<Region>,
  selected: Array<string>,
  toggle: (region: string) => void,
};

function RegionsSelector({
  regions,
  selected,
  toggle,
}: Props): React.Element<'div'> {
  return (
    <div className={cx('regions')}>
      {regions.map((region) => (
        <div key={region.code} className={cx('region')}>
          <Checkbox
            id={`ShippingLocation.${region.code}`}
            checked={selected.includes(region.code)}
            onChange={() => toggle(region.code)}
          />
          {/* eslint-disable-next-line */}
          <label
            htmlFor={`ShippingLocation.${region.code}`}
            className={cx('name')}
          >
            {region.name}
          </label>
        </div>
      ))}
    </div>
  );
}

export default RegionsSelector;
