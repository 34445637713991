// @flow
import * as React from 'react';
import { compose, isNil } from 'ramda';

import withFiltersContext from '../with-filters-context';
import withLabel from '../with-label';
import Checkbox from '../../../basic-components/checkbox';

type PropsT = {
  checked: boolean,
  onChange: (value1: any, value2: any) => void,
  isMobile?: boolean,
};

const setField = (option) => {
  if (isNil(option) || !option.target.checked) return false;

  return option.target.checked;
};
function CheckboxField({ checked, onChange, isMobile, ...restProps }: PropsT) {
  return (
    // $FlowFixMe
    <Checkbox
      checked={checked}
      isMobile={isMobile}
      size={isMobile ? 'medium' : 'small'}
      onChange={(option) => onChange(setField(option), option)}
      {...restProps}
    />
  );
}

// $FlowFixMe
export default compose(withLabel, withFiltersContext)(CheckboxField);
