// @flow
import * as React from 'react';

import { useImport } from '../../hooks/use-import';
import usePersistState from '../../hooks/use-persist-state';
import keys from '../../settings/storage';
import { getItem } from '../../utils/local-storage';
import NotificationProgress from '../../basic-components/notification-progress';
import t from './locale';

export default function ImportStatus(): React.Node {
  const importState = useImport();
  const [showNotifLocalStorage, setShowNotifLocalStorage] = usePersistState(
    keys.showNotifReimport
  );
  const [showState, setShowState] = React.useState(!!showNotifLocalStorage);

  // FIXME
  React.useEffect(() => {
    const currentValue = getItem(keys.showNotifReimport);
    if (currentValue !== null) {
      setShowState(currentValue);
    }
  });

  if (!importState.inProgress && !importState.completed) {
    return null;
  }

  const { percentage, completed, cancel, imported, truncated } = importState;

  let completedMessage = t('completedEmpty');

  if (imported) {
    if (truncated) {
      completedMessage = t('completedTruncated');
    } else {
      completedMessage = t('completedMessage', { count: imported });
    }
  }

  const handleClose = () => {
    cancel();
    setShowNotifLocalStorage(false);
  };

  return (
    <NotificationProgress
      id="reimport"
      percentage={percentage}
      message={completed ? completedMessage : t('message')}
      onClose={handleClose}
      completed={completed}
      neutral={completed && !imported}
      show={showState}
    />
  );
}
