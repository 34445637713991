// @flow
import * as React from 'react';

import DropdownOption from '../../dropdown-option';

import type { OptionRendererFunc, Option } from '../typings';

type Props = {
  option: Option,
  onMouseDown?: (value: Option) => void,
  onMouseEnter?: (value: any) => void,
  buttonRef: (el: ?HTMLButtonElement) => void,
  renderOption?: OptionRendererFunc,
  className?: string,
  keyword?: string,
  selected?: boolean,
  size?: 'small' | 'medium',
  focused?: boolean,
  notFound?: boolean,
  multiline?: boolean,
  loading?: boolean,
};

export default function DropdownItem({
  renderOption = ({ label }: Option) => label,
  onMouseDown = () => {},
  onMouseEnter = () => {},
  notFound = false,
  option,
  focused,
  className,
  size,
  buttonRef,
  keyword,
  selected,
  multiline,
  loading = false,
}: Props): React.Node {
  const handleMouseEnter = () => {
    onMouseEnter(option.value);
  };

  const handleMouseDown = (event: SyntheticMouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onMouseDown(option);
  };

  const { render = renderOption } = option;

  return (
    <DropdownOption
      innerRef={focused ? buttonRef : undefined}
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      size={size}
      focused={focused}
      notFound={notFound}
      loading={loading}
      selected={selected}
      multiline={multiline}
      hidden={option.hidden}
      withSelected
    >
      {render(option, keyword)}
    </DropdownOption>
  );
}
