// @flow
import * as React from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';

import ContentWrapper from '../../components/content-wrapper';
import { type Props as TableEmptyResultProps } from '../../components/table-empty-result';
import { type Props as PageSubheaderProps } from '../../components/page-subheader';

import VirtualizedTableList, {
  type RenderComponent,
} from './virtualized-table-list';
import cs from './style.pcss';

type Props = {
  renderer: RenderComponent,
  itemData: any,
  header: React.Node,
  items: any[],
  subheaderProps: PageSubheaderProps,
  massEditRow?: React.Node,
  subheaderSize?: number,
  onLoadMore?: () => Promise<void>,
  hasNextPage?: boolean,
  loadingMore?: boolean,
  className?: string,
  sizeMultipliers?: { [key: string]: number },
  innerRef?: any,
  rowSizes?: number[],
  emptySearch?: boolean,
  emptyProps?: TableEmptyResultProps,
  footerExtra?: React.Node,
  windowWidth?: ?number,
  isMobile?: boolean,
};

export default function VirtualizedTable({
  renderer,
  itemData,
  header,
  items,
  subheaderProps,
  massEditRow,
  subheaderSize = 91,
  onLoadMore,
  hasNextPage = false,
  loadingMore = false,
  className,
  sizeMultipliers,
  innerRef,
  rowSizes,
  emptySearch,
  emptyProps,
  footerExtra,
  windowWidth,
  isMobile,
}: Props): React.Node {
  React.useEffect(() => {
    if (document.body) {
      document.body.classList.add(cs.bodyWithTable);
    }
  }, []);

  const showMassEditRow = !emptySearch && Boolean(massEditRow);
  const showFooter = emptySearch || !hasNextPage;

  const itemCount =
    items.length + (showMassEditRow ? 4 : 3) + (emptySearch ? 1 : 0);

  const loadMoreItems = loadingMore ? () => {} : onLoadMore;

  const itemLoaded = (index) => showFooter || index < itemCount - 1;

  const listProps = {
    renderer,
    itemData,
    header,
    items,
    subheaderProps,
    massEditRow,
    subheaderSize,
    hasNextPage,
    loadingMore,
    className,
    sizeMultipliers,
    innerRef,
    rowSizes,
    emptySearch,
    emptyProps,
    footerExtra,
    itemCount,
    windowWidth,
    isMobile,
  };

  return (
    <ContentWrapper>
      <AutoSizer>
        {({ height, width }) =>
          onLoadMore ? (
            <InfiniteLoader
              isItemLoaded={itemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
              threshold={5}
            >
              {({ onItemsRendered, ref }) => (
                <VirtualizedTableList
                  {...listProps}
                  height={height}
                  width={width}
                  onItemsRendered={onItemsRendered}
                  listInnerRef={ref}
                />
              )}
            </InfiniteLoader>
          ) : (
            <VirtualizedTableList
              {...listProps}
              height={height}
              width={width}
            />
          )
        }
      </AutoSizer>
    </ContentWrapper>
  );
}
