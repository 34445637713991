// @flow
import * as React from 'react';
import settings from '../../settings';

import images from './images';
import t from './locale';

type OnboardingStep = {
  title: string,
  description: string | React.Node,
  image: React.Node,
  links: {
    text: string,
    href: string,
  }[],
};

const helpLinks = {
  step6: [settings.references.helpCenterLinks.addItems],
  step7: [settings.references.helpCenterLinks.siteMassEdit],
  step9: [settings.references.helpCenterLinks.ordersPage],
  step10: [
    settings.references.helpCenterLinks.setUpSites,
    settings.references.helpCenterLinks.multiAccount,
  ],
};

export const TOTAL_STEPS = 10;

export const getStepData = (step: number, site?: string): OnboardingStep => {
  const prefix = `step${step}`;

  const links = (helpLinks[prefix] || []).map((href, i) => ({
    text: t(`${prefix}.links.${i}`),
    href,
  }));

  let description;

  if (step === 1) {
    description = site
      ? t(`${prefix}.description`, { site })
      : t(`${prefix}.descriptionNoImported`);
  } else if (step === 2) {
    description = site ? (
      <>
        {t(`${prefix}.description1`)} <b>{site}</b>{' '}
        {t(`${prefix}.description2`)}
      </>
    ) : (
      t(`${prefix}.descriptionNoSite`)
    );
  } else {
    description = t(`${prefix}.description`);
  }

  return {
    title: t(`${prefix}.title`),
    description,
    image: images[step - 1],
    links,
  };
};
