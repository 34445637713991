// @flow
import * as React from 'react';
import memoize from 'memoize-one';
import { VariableSizeList, areEqual } from 'react-window';

import Table from '../table';
import TableEmptyResult, {
  type Props as TableEmptyResultProps,
} from '../../components/table-empty-result';
import PageSubheader, {
  type Props as PageSubheaderProps,
} from '../../components/page-subheader';
import Footer from '../../components/footer';
import { ScrollContext } from '../../components/header/context';

import LoaderRow from './loader-row';

const FOOTER_HEIGHT = 77;
const FOOTER_HEIGHT_MOBILE = 160;
const ROW_DEFAULT_HEIGHT = 51;
const PARCEL_ROW_DEFAULT_HEIGHT = 73;
const LOADER_ROW_HEIGHT = 89;
const FOOTER_EXTRA_HEIGHT = 160;

type RenderComponentProps = {|
  data: any,
  index: number,
  isScrolling?: boolean,
  style: Object,
  [key: string]: any,
|};

export type RenderComponent = React$ComponentType<$Shape<RenderComponentProps>>;

type ContextProps = {
  subheaderProps: PageSubheaderProps,
  header: React.Node,
  showMassEditRow: boolean,
  massEditRow?: React.Node,
  emptySearch?: boolean,
  emptyProps?: TableEmptyResultProps,
  height: number,
  subheaderSize: number,
  windowWidth?: ?number,
};

type Props = {
  renderer: RenderComponent,
  itemData: any,
  header: React.Node,
  items: any[],
  subheaderProps: PageSubheaderProps,
  massEditRow?: React.Node,
  subheaderSize?: number,
  hasNextPage?: boolean,
  loadingMore?: boolean,
  className?: string,
  sizeMultipliers?: { [key: string]: number },
  innerRef?: any,
  rowSizes?: number[],
  emptySearch?: boolean,
  emptyProps?: TableEmptyResultProps,
  footerExtra?: React.Node,
  height: number,
  width: number,
  onItemsRendered?: () => void,
  listInnerRef?: any,
  itemCount: number,
  windowWidth?: ?number,
  isMobile?: boolean,
};

type ItemProps = {
  index: number,
  style: {
    [string]: mixed,
  },

  data: {
    items: any[],
    renderer: React$ComponentType<any>,
    emptySearch: boolean,
    footerExtra: boolean,
    [key: string]: any,
  },
  windowWidth?: ?number,
};

const ItemRenderer = React.memo(
  ({
    data: {
      items,
      emptySearch,
      footerExtra,
      renderer,
      windowWidth,
      ...restData
    },
    index,
    style,
  }: ItemProps): React.Node => {
    const item = items[index];

    if (typeof item === 'string') {
      if (
        [
          'pageSubheaderRow',
          'headerRow',
          'massEditRow',
          'emptySearchRow',
        ].includes(item)
      ) {
        return null;
      }

      if (item === 'loaderRow') {
        return <LoaderRow style={style} />;
      }

      if (item === 'footerRow') {
        return (
          <div style={style}>
            {!emptySearch && footerExtra}
            <Footer windowWidth={windowWidth} />
          </div>
        );
      }

      return null;
    }

    const Renderer = renderer;

    return (
      // $FlowFixMe
      <Renderer
        key={item.id}
        style={style}
        item={item}
        index={index}
        windowWidth={windowWidth}
        {...restData}
      />
    );
  },
  areEqual
);

const createItemData = memoize((props) => props);

const getEmptySearchHeight = (height: number, subheaderSize: number): number =>
  Math.max(height - subheaderSize - ROW_DEFAULT_HEIGHT - FOOTER_HEIGHT, 312);

const VirtualizedTableContext: React$Context<ContextProps> =
  React.createContext<ContextProps>({});

const innerElementType = React.forwardRef(({ children, ...rest }: any, ref) => {
  const {
    subheaderProps,
    header,
    showMassEditRow,
    massEditRow,
    emptySearch,
    emptyProps,
    height,
    subheaderSize,
    windowWidth,
  } = React.useContext(VirtualizedTableContext);

  return (
    <div ref={ref} {...rest}>
      <PageSubheader {...subheaderProps} />
      <Table virtualized>
        {header}
        {showMassEditRow && massEditRow}
        {emptySearch && emptyProps && (
          <TableEmptyResult
            style={{
              height: getEmptySearchHeight(height, subheaderSize),
              width: windowWidth ? `${windowWidth}px` : '100%',
            }}
            {...emptyProps}
          />
        )}
        {children}
      </Table>
    </div>
  );
});

export default function VirtualizedTableList({
  renderer,
  itemData,
  header,
  items,
  subheaderProps,
  massEditRow,
  subheaderSize = 91,
  hasNextPage = false,
  loadingMore = false,
  className,
  sizeMultipliers,
  innerRef,
  rowSizes,
  emptySearch,
  emptyProps,
  footerExtra,
  height,
  width,
  onItemsRendered,
  listInnerRef,
  itemCount,
  windowWidth,
  isMobile,
}: Props): React.Node {
  const { scrolled, setScrolled } = React.useContext(ScrollContext);

  const showMassEditRow = !emptySearch && Boolean(massEditRow);
  const showFooter = emptySearch || !hasNextPage;

  const listRef = React.useRef();

  const rerenderBottomRows = () => {
    if (listRef && listRef.current) {
      (listRef.current: any).resetAfterIndex(itemCount - 3, false);
    }
  };

  const rerenderAllRows = () => {
    if (listRef && listRef.current) {
      (listRef.current: any).resetAfterIndex(0, true);
    }
  };

  React.useEffect(() => {
    rerenderBottomRows();
  }, [itemCount, showFooter, footerExtra, loadingMore]);

  React.useEffect(() => {
    rerenderAllRows();
  }, [subheaderSize]);

  const providerValue = React.useMemo(
    (): ContextProps => ({
      subheaderProps,
      header,
      showMassEditRow,
      massEditRow,
      emptySearch,
      emptyProps,
      height,
      subheaderSize,
      windowWidth,
    }),
    [
      subheaderProps,
      header,
      showMassEditRow,
      massEditRow,
      emptySearch,
      emptyProps,
      height,
      subheaderSize,
      windowWidth,
    ]
  );

  const getItemSize = (itemHeight) => (index) => {
    if (index === 0) {
      return subheaderSize;
    }
    if (index === 1) {
      return ROW_DEFAULT_HEIGHT;
    }
    if (index === itemCount - 1) {
      const footerHeight =
        (isMobile ? FOOTER_HEIGHT_MOBILE : FOOTER_HEIGHT) +
        (!emptySearch && footerExtra ? FOOTER_EXTRA_HEIGHT : 0);
      return showFooter ? footerHeight : LOADER_ROW_HEIGHT;
    }

    if (emptySearch) {
      return getEmptySearchHeight(itemHeight, subheaderSize);
    }

    if (rowSizes) {
      return rowSizes[index - (showMassEditRow ? 3 : 2)];
    }

    if (sizeMultipliers) {
      return (
        ROW_DEFAULT_HEIGHT *
        (sizeMultipliers[(index - (showMassEditRow ? 3 : 2)).toString()] || 1)
      );
    }

    return ROW_DEFAULT_HEIGHT;
  };

  const prevItems = ['pageSubheaderRow', 'headerRow'];
  if (showMassEditRow) {
    prevItems.push('massEditRow');
  } else if (emptySearch) {
    prevItems.push('emptySearchRow');
  }

  let postItems = ['loaderRow'];

  if (showFooter) {
    postItems = ['footerRow'];
  }

  const handleScroll = ({ scrollOffset }) => {
    if (!scrolled && scrollOffset > 190) {
      setScrolled(true);
    } else if (scrolled && scrollOffset <= 110) {
      setScrolled(false);
    }
  };

  return (
    <VirtualizedTableContext.Provider value={providerValue}>
      <VariableSizeList
        height={height}
        width={Math.min(width, 1700)}
        onItemsRendered={onItemsRendered}
        ref={(el) => {
          if (innerRef) {
            innerRef(el);
          }
          if (listInnerRef) {
            listInnerRef(el);
          }
          listRef.current = el;
        }}
        estimatedItemSize={
          rowSizes ? PARCEL_ROW_DEFAULT_HEIGHT : ROW_DEFAULT_HEIGHT
        }
        itemCount={itemCount}
        itemSize={getItemSize(height)}
        className={className}
        onScroll={handleScroll}
        itemData={createItemData({
          items: [...prevItems, ...items, ...postItems],
          renderer,
          footerExtra,
          emptySearch,
          windowWidth,
          isMobile,
          ...itemData,
        })}
        innerElementType={innerElementType}
        overscanCount={10}
      >
        {ItemRenderer}
      </VariableSizeList>
    </VirtualizedTableContext.Provider>
  );
}
