// @flow

import * as React from 'react';
import { compose, isNil } from 'ramda';
import Input from '../../../basic-components/input';
import withFiltersContext from '../with-filters-context';
import withLabel from '../with-label';

type PropsT = {
  onChange: (?string, string) => void,
  value: ?string,
  isMobile?: boolean,
  adaptive?: boolean,
};

function TextField({
  onChange,
  value,
  isMobile,
  adaptive,
  ...restProps
}: PropsT) {
  return (
    // $FlowFixMe
    <Input
      size={isMobile && adaptive ? 'medium' : 'small'}
      onChange={(e) => {
        onChange(isNil(e.target.value) ? null : e.target.value, e.target.value);
      }}
      value={isNil(value) ? '' : value}
      {...restProps}
    />
  );
}

// $FlowFixMe
export default compose(withLabel, withFiltersContext)(TextField);
