import { useState } from 'react';
import { pipe, trim, split, take, join, when, tap } from 'ramda';

import { getIn, isDefined, isNotDefined } from '../../../utils/ramda';

export const textShorter = (limit) =>
  when(isDefined, pipe(trim, split(''), take(limit), join(''), trim));

const useLimitTextState = ({ value = '', onChange, maxLength }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnChange = pipe(
    getIn('target.value'),
    tap(setInputValue),
    textShorter(maxLength || 0),
    onChange
  );

  if (isNotDefined(maxLength)) return { value, onChange };

  return { value: inputValue, onChange: handleOnChange, allowOverLimit: true };
};

export default useLimitTextState;
