// @flow

import * as React from 'react';
import { useQuery } from '@apollo/client';

import ApolloLightbox from '../apollo-lightbox';
import ThreeColumnsLayout from '../../basic-components/three-columns-layout';
import Button from '../../basic-components/button';
import Errors from '../errors';

import RegionsSelector from './regions-selector';

import type { Region } from './types';
import { ShippingLocations } from './shipping-locations.graphql';
import t from './locale';
import cs from './styles.pcss';

type Props = {
  name: string,
  selectedLocations: Array<string>,
  onChange: (selectedLocations: Array<string>) => void,
};

const getChildren = (regions: Array<Region>, code: string): Array<string> => {
  const region = regions.find((r) => r.code === code);

  if (!region) {
    return [];
  }

  return region.children;
};

const getParents = (regions: Array<Region>, code: string): Array<string> =>
  regions.filter((r) => r.children.includes(code)).map((r) => r.code);

export default function ShippingLocationsLightbox({
  selectedLocations,
  name,
  onChange,
}: Props): React.Node {
  const [selected, setSelected] = React.useState(selectedLocations);
  const [errors, setErrors] = React.useState([]);

  const { data, loading, error } = useQuery(ShippingLocations);

  const onToggleRegion: (
    regions: Array<Region>
  ) => (regionCode: string) => void =
    (regions: Array<Region>) => (regionCode: string) => {
      let newSelected = [...selected];

      if (newSelected.includes(regionCode)) {
        newSelected = newSelected.filter((r) => r !== regionCode);
      } else {
        // Regions have a hierarchical structure but selected region is not equal to all it's children.
        // For instance it's possible to select "Europe" or limit it to a couple of countries inside (but not any).
        // That's why when the region is selected we have to unselect all it's parents and children.
        const childRegions = getChildren(regions, regionCode);
        const parentRegions = getParents(regions, regionCode);
        setErrors([]);
        newSelected = newSelected
          .filter(
            (r) => !childRegions.includes(r) && !parentRegions.includes(r)
          )
          .concat([regionCode]);
      }

      setSelected(newSelected);
    };

  const renderFooter = React.useCallback(
    (closeOverlay: () => Promise<void>) => (
      <ThreeColumnsLayout
        left={
          <Button mode="secondary" onClick={closeOverlay}>
            {t('button.close')}
          </Button>
        }
        center={<Errors className={cs.errors} errors={errors} />}
        right={
          <Button
            onClick={() => {
              if (selected.length) {
                setErrors([]);
                onChange(selected);
                closeOverlay();
              } else {
                setErrors([t('notSelected')]);
              }
            }}
          >
            {t('button.select')}
          </Button>
        }
      />
    ),
    [selected, errors, setErrors, onChange]
  );

  return (
    <ApolloLightbox id={name} small header={t('title')} footer={renderFooter}>
      <div className={cs.wrapper}>
        <div className={cs.description}>{t('description')}</div>
        {loading || error || !data ? null : (
          <RegionsSelector
            regions={data.profileTariffLocations}
            selected={selected}
            toggle={onToggleRegion(data.profileTariffLocations)}
          />
        )}
      </div>
    </ApolloLightbox>
  );
}
