// @flow

import { handleActions, type ActionType } from 'redux-actions';
import update from 'immutability-helper';
import {
  setScopeErrors,
  setKeyErrors,
  clearScopeErrors,
  clearKeyErrors,
  clearErrors,
} from '../actions/errors';
import type { ErrorsState as State } from '../typings/errors';

const initialState: State = {};

function handleSetScopeErrors(state, { scope, errors }) {
  return state[scope]
    ? update(state, { [scope]: { $set: errors } })
    : update(state, { $merge: { [scope]: errors } });
}

function handleSetKeyErrors(state, { scope, key, errors }) {
  const stateWithScope = state[scope]
    ? state
    : update(state, { $merge: { [scope]: {} } });

  return stateWithScope[scope][key]
    ? update(stateWithScope, { [scope]: { [key]: { $set: errors } } })
    : update(stateWithScope, { [scope]: { $merge: { [key]: errors } } });
}

function handleClearScopeErrors(state, scope) {
  return state[scope] ? update(state, { $unset: [scope] }) : state;
}

function handleClearKeyErrors(state, { scope, key }) {
  return state[scope] && state[scope][key]
    ? update(state, { [scope]: { $unset: [key] } })
    : state;
}

export default (handleActions(
  {
    [setScopeErrors.toString()]: (
      state: State,
      { payload }: ActionType<typeof setScopeErrors>
    ) => handleSetScopeErrors(state, payload),
    [setKeyErrors.toString()]: (
      state: State,
      { payload }: ActionType<typeof setKeyErrors>
    ) => handleSetKeyErrors(state, payload),
    [clearScopeErrors.toString()]: (
      state: State,
      { payload }: ActionType<typeof clearScopeErrors>
    ) => handleClearScopeErrors(state, payload),
    [clearKeyErrors.toString()]: (
      state: State,
      { payload }: ActionType<typeof clearKeyErrors>
    ) => handleClearKeyErrors(state, payload),
    [clearErrors.toString()]: () => ({}),
  },
  initialState
): any);
