// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import { COLORS, getFirstAvailableColor } from '../../utils/delivery-profiles';
import Field from '../../basic-components/field';

import Checkmark from './checkmark.svg';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  value: number,
  disabledColors: Array<number>,
  onChange: (value: number) => void,
};

export default function ColorSelectorField({
  value,
  disabledColors = [],
  onChange,
  ...fieldProps
}: Props): React.Node {
  let selectedIndex = value;
  if (selectedIndex === -1) {
    selectedIndex = getFirstAvailableColor(disabledColors);
  }

  return (
    <Field {...fieldProps}>
      <div className={cs.wrapper}>
        {COLORS.map((color, index) => (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            type="button"
            key={color}
            className={cx('item', {
              isSelected: index === selectedIndex,
            })}
            style={{ backgroundColor: color }}
            onClick={() => onChange(index)}
            disabled={disabledColors.indexOf(index) !== -1}
          >
            {index === selectedIndex && <Checkmark />}
          </button>
        ))}
      </div>
    </Field>
  );
}
