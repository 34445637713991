import { path } from 'ramda';
import { stepsConfig, proceed, actions } from '../../actions/onboarding';
import { onboardingDisabled } from '../../utils/onboarding';

export const onboardingMiddleware = (store) => {
  // disable middleware for finished onboarding
  if (onboardingDisabled(store.getState())) {
    return (next) => (action) => next(action);
  }

  return (next) => (action) => {
    const step = path(['onboarding', 'step'], store.getState());
    const configForStep = stepsConfig[step];
    if (
      !configForStep ||
      path(['onboarding', 'transition'], store.getState())
    ) {
      return next(action);
    }
    const { page, proceedWhen } = configForStep;
    if (
      page &&
      page === store.getState().page &&
      proceedWhen &&
      proceedWhen(store.getState(), action)
    ) {
      next(actions.onboarding.transition(true));
      next(proceed());
      next(actions.onboarding.transition(false));
    }

    return next(action);
  };
};
