// @flow
import * as React from 'react';
import cx from 'classnames';

import Icon, { type IconsBig } from '../icon';
import useMediaQuery from '../../hooks/use-media-query';

import cs from './styles.pcss';
import { type StatusMode, statusIcons } from './mode';

type Props = {
  mode: StatusMode,
  className?: string,
  children?: React.Node,
  title?: React.Node,
  largePadding?: boolean,
  withHr?: boolean,
  icon?: IconsBig,
};

export default function Status({
  className,
  children,
  mode,
  title,
  largePadding,
  withHr,
  icon,
}: Props): React.Node {
  const smallScreen = useMediaQuery(`(max-width: 767px)`);

  return (
    <div
      className={cx(cs.wrapper, cs[mode], className, {
        [cs.largePadding]: largePadding,
      })}
    >
      <Icon
        size={smallScreen ? 24 : 18}
        name={icon || statusIcons[mode]}
        className={cs.icon}
      />
      <div>
        {title && <div className={cs.title}>{title}</div>}
        {withHr && <hr className={cs.hr} />}
        {children && <div className={cs.content}>{children}</div>}
      </div>
    </div>
  );
}
