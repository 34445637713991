// @flow

import { sendEvent, payloadCreator } from '../utils/analytics';

const payload = payloadCreator('Banner');
export default {
  close: (id: string): Promise<void> => sendEvent(payload('Close', id)),
  use: (id: string, value?: number): Promise<void> =>
    sendEvent(payload('Use', id, value)),
  show: (id: string): Promise<void> => sendEvent(payload('Show', id)),
};
