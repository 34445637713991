// @flow
import * as React from 'react';
import { path } from 'ramda';
import { useQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
// $FlowFixMe
import { enUS, ru, zhCN } from 'date-fns/locale';

import Banner from '../../../basic-components/banner';
import keys from '../../../settings/storage';
import { getLocale } from '../../../utils/i18n';

import { UnsellableData } from './operations.graphql';
import t from './locale';

export default function UnsellableBanner(): React.Node {
  const { data } = useQuery(UnsellableData);
  const language = getLocale();

  const unproductiveListingsMarkedAt = path(
    ['viewer', 'unproductiveListingsMarkedAt'],
    data
  );

  const show = Boolean(unproductiveListingsMarkedAt);

  if (!show) {
    return null;
  }

  const bannerDate = parseISO(unproductiveListingsMarkedAt);

  const translationDate = format(
    bannerDate,
    // eslint-disable-next-line no-nested-ternary
    language === 'ru'
      ? 'd MMMM yyyy'
      : language === 'zh'
      ? 'yyyy年M月d日'
      : 'MMMM d, yyyy',
    // eslint-disable-next-line no-nested-ternary
    { locale: language === 'ru' ? ru : language === 'zh' ? zhCN : enUS }
  );

  return (
    <Banner
      message={
        <>
          <b>{t(`policy.title`)}</b>
          <div>{t(`policy.message`, { date: translationDate })}</div>
        </>
      }
      id="unsellablePolicyBanner"
      hidden={!show}
      localKey={`${keys.unsellableBanner}${format(bannerDate, 'yyyyMMdd')}`}
      color="purple"
    />
  );
}
