// @flow
import * as React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createFocusOnError from 'final-form-focus';
import classNames from 'classnames/bind';
import { isNil } from 'ramda';

import { FormField } from '../../../hocs/final-form';
import useMediaQuery from '../../../hooks/use-media-query';

import ApolloLightbox from '../../apollo-lightbox';
import Button from '../../../basic-components/button';
import ThreeColumnsLayout from '../../../basic-components/three-columns-layout';

import t from '../locale';
import cs from '../styles.pcss';

import ProgramCheckboxField from '../programs-checkbox';
import StatementsMultiSelectField from '../statements-multi-select';
import TextareaField from '../../../basic-components/textarea-field';
import FieldSetDescription from '../../field-set-description';
import Link from '../../../basic-components/link';
import settings from '../../../settings';
import { useEffect } from 'react';

const cx = classNames.bind(cs);

type Props = {
  productSafety: any,
  onSave: (value: any) => void,
};

const focusOnErrors = createFocusOnError();
const renderFooter =
  (
    handleSubmit?: any,
    submitError?: boolean,
    submitting?: boolean,
    mediumScreen: boolean = true
  ) =>
  (closeOverlay: () => Promise<void>) =>
    (
      <ThreeColumnsLayout
        left={
          <Button
            onClick={closeOverlay}
            mode="secondary"
            size={mediumScreen ? 'medium' : 'inModal'}
          >
            {t('buttons.close')}
          </Button>
        }
        center={
          <Button
            loading={submitting}
            onClick={handleSubmit}
            size={mediumScreen ? 'medium' : 'inModal'}
          >
            {t('buttons.save')}
          </Button>
        }
        className={cs.footerWrapper}
      />
    );

function GpsrProductSafetyLightbox({
  productSafety,
  onSave,
}: Props): React.Node {
  const veryLargeScreen = useMediaQuery(`(min-width: 1000px)`);
  const mediumScreen = useMediaQuery(`(min-width: 600px)`);

  const [pictograms, setPictograms] = React.useState(
    productSafety?.pictograms || []
  );
  const [statements, setStatements] = React.useState(
    productSafety?.statements || []
  );
  const [component, setComponent] = React.useState(
    productSafety?.component || ''
  );
  useEffect(() => {
    setPictograms(productSafety?.pictograms || []);
    setStatements(productSafety?.statements || []);
    setComponent(productSafety?.component || '');
  }, [productSafety]);

  const ClearStatements = () => {
    setStatements([]);
  };

  return (
    <Form
      initialValues={productSafety}
      initialValuesEqual={() => false}
      subscription={{
        submitting: true,
        submitError: true,
      }} // rerender form component only when submitting is changed
      mutators={{ ...arrayMutators }}
      decorators={[focusOnErrors]}
      onSubmit={(data) => {
        if (
          (isNil(pictograms) || pictograms.length === 0) &&
          (isNil(statements) || statements.length === 0) &&
          (isNil(component) || component === '')
        ) {
          onSave(null);
          return;
        }
        onSave({
          ...data,
          pictograms,
          statements,
          component,
        });
      }}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <ApolloLightbox
          id="GpsrProductSafety"
          header={t('productSafety.title')}
          footer={renderFooter(
            handleSubmit,
            submitError,
            submitting,
            mediumScreen
          )}
        >
          <form className={cx(cs.wrapper)} onSubmit={(e) => e.preventDefault()}>
            <FieldSetDescription>
              {t('productSafety.formDescription')}
              <Link
                target="_blank"
                href={settings.references.gpsrLinks('safety')}
                className={cs.link}
                noUnderline={false}
                onClick={() => {
                  // ANALYTICS.goToHelpCenter(location);
                }}
              >
                {t('linkText')}
              </Link>
            </FieldSetDescription>
            <FormField
              name="pictograms"
              options={pictograms}
              Component={ProgramCheckboxField}
              label={t('productSafety.fields.pictograms.label')}
              onChanged={(value) => {
                setPictograms(value);
              }}
              value={pictograms}
            />
            <div className={cs.marginTop}>
              <FormField
                name="statements"
                Component={StatementsMultiSelectField}
                options={statements}
                label={t('productSafety.fields.statements.label')}
                noGrid={!veryLargeScreen}
                onChanged={(id) => {
                  const newStatements = [];
                  if (statements.indexOf(id) === -1) {
                    newStatements.push(...statements);
                    newStatements.push(id);
                  } else {
                    newStatements.push(
                      ...statements.filter((item) => item !== id)
                    );
                  }
                  setStatements(newStatements);
                }}
                searchable={false}
                values={statements}
                clearStatements={ClearStatements}
              />
            </div>
            <div className={cs.marginTop}>
              <FormField
                name="component"
                Component={TextareaField}
                label={t('productSafety.fields.component.label')}
                maxLength={120}
                value={component}
                onChange={(value) => {
                  setComponent(value);
                }}
              />
            </div>
          </form>
        </ApolloLightbox>
      )}
    </Form>
  );
}

export default GpsrProductSafetyLightbox;
