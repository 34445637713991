// @flow
import * as React from 'react';
import Checkbox from '../../../basic-components/checkbox';
import cs from './styles.pcss';

const regionData = require('../regions.json');

type Props = {
  siteId: number,
  name: string,
  selectedLocations: Array<string>,
  readonly?: boolean,
  disabled?: boolean,
  onError?: (string) => void,
};

const WORLD_WIDE = 'Worldwide';

export default function ShipTo(props: Props): React.Node {
  const {
    siteId,
    name,
    selectedLocations,
    readonly = false,
    disabled = false,
    onError,
  } = props;
  const [toWhere, setToWhere] = React.useState(selectedLocations);
  if (siteId == null) {
    return null;
  }

  // default using locations of ebay.com
  const candidateLocations = regionData[siteId.toString()] || regionData['0'];

  const handleChange = (e) => {
    if (readonly) return;

    if (e.target.checked) {
      if (onError) onError('');
      setToWhere((old) => {
        if (e.target.value === WORLD_WIDE) {
          return [WORLD_WIDE];
        }
        const rest = old.filter((v) => v !== WORLD_WIDE);
        return [...rest, e.target.value];
      });
    } else {
      setToWhere((old) => {
        const rest = old.filter((w) => w !== e.target.value);
        if (rest.length === 0) {
          if (onError)
            onError('Please select an international ship to location');
          return rest;
        }
        if (onError) onError('');
        return rest;
      });
    }
  };

  return (
    <div className={cs.locationBox}>
      <div className={cs.location}>
        <Checkbox
          key={WORLD_WIDE}
          className={cs.checkbox}
          value={WORLD_WIDE}
          checked={toWhere.includes(WORLD_WIDE)}
          onChange={handleChange}
          disabled={disabled}
        />
        <span>&nbsp;Worldwide</span>
      </div>
      {candidateLocations.map((l) => (
        <div className={cs.location} key={l.name}>
          <Checkbox
            className={cs.checkbox}
            value={l.name}
            checked={toWhere.includes(l.name)}
            onChange={handleChange}
            disabled={disabled}
          />
          <span>&nbsp;{l.value}</span>
        </div>
      ))}
      <input type="hidden" name={name} value={toWhere} />
    </div>
  );
}
