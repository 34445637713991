// @flow

/**
 * Feature hook that allows feature toggling
 * For more information see https://www.notion.so/ebaymag/RFC-12-Feature-toggling-9690d1c573d448f6aa912a2bda7afcb5
 * It fetches features from the server and returns the appropriate feature if this feature is enabled for the current user
 */

import { useQuery } from '@apollo/client';

import GetUserFeatures from './operations.graphql';

const useFeature = (featureID: string): boolean | null => {
  const { data, loading } = useQuery(GetUserFeatures);
  if (!data || loading || !data.viewer) return null;

  const feature = data.viewer.features.find((f) => f.feature === featureID);

  if (feature) return feature.enabled;
  return null;
};

export default useFeature;
