// @flow
import * as React from 'react';
import cx from 'classnames';

import Input from '../input';
import { useDropdown } from '../../hooks/use-dropdown';

import VerticalMenuOption, { type Props as OptionT } from './option';
import Arrow from './arrow.svg';
import cs from './styles.pcss';
import t from './locale';

type Props = {
  options: OptionT[],
  opened?: boolean,
  withSearch?: boolean,
  searchValue?: string,
  onSearch?: (value: string) => void,
  withSelected?: boolean,
  wide?: boolean,
  page?: string,
  className?: string,
  arrowClassName?: string,
  id: string,
};

export default function VerticalMenu({
  options,
  opened = true,
  withSearch,
  searchValue = '',
  onSearch,
  withSelected,
  wide,
  page,
  className,
  arrowClassName,
  id,
}: Props): React.Node {
  const menuEl = React.useRef();

  const { handleClose } = useDropdown(id);

  const [shiftFix, setShiftFix] = React.useState(0);

  const recalculatePosition = () => {
    if (menuEl && menuEl.current) {
      const rect = menuEl.current.getBoundingClientRect();
      const screenMargin = window.innerWidth >= 1024 ? 30 : 20;
      const shift = rect.right + screenMargin - window.innerWidth;

      if (shift !== 0 && shift !== shiftFix) {
        setShiftFix(shift < 0 ? 0 : shift);
      }
    }
  };

  React.useLayoutEffect(recalculatePosition, []);
  React.useLayoutEffect(recalculatePosition, [menuEl, page]);

  const handleSearch = (event) => {
    if (onSearch) {
      onSearch(event.target.value);
    }
  };

  const handleSearchClick = (event) => {
    event.stopPropagation();
  };

  const renderOption = (option, index) => (
    <VerticalMenuOption
      {...option}
      onClick={(e) => {
        handleClose(e);
        if (option.onClick) {
          option.onClick(e);
        }
      }}
      tabIndex={opened ? 0 : -1}
      key={`${option.text}${index}`}
    />
  );

  return (
    <>
      <div
        className={cx(cs.verticalMenu, className, {
          [cs.opened]: opened,
          [cs.withSelected]: withSelected,
          [cs.wide]: wide,
        })}
        style={{ left: `calc(50% - ${shiftFix}px)` }}
      >
        <div className={cs.content} ref={menuEl}>
          {withSearch && (
            <div className={cs.search}>
              <Input
                size="small"
                onChange={handleSearch}
                placeholder={t('searchPlaceholder')}
                value={searchValue}
                className={cs.input}
                onClick={handleSearchClick}
                tabIndex={opened ? 0 : -1}
              />
            </div>
          )}
          {options.map(renderOption)}
        </div>
      </div>
      <Arrow
        className={cx(cs.arrow, arrowClassName, {
          [cs.opened]: opened,
        })}
      />
    </>
  );
}
