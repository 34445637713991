// @flow
import * as React from 'react';

import Loader from '../loader';

import cs from './style.pcss';

type Props = {
  style: any,
};

export default function LoaderRow({ style }: Props): React.Node {
  return (
    <div className={cs.loader} style={style}>
      <Loader size={48} />
    </div>
  );
}
