// @flow
import * as React from 'react';
import cx from 'classnames';

import { useOutsideClick } from '../../hooks/use-outside-click';
import { useApolloDropdown } from '../../hooks/use-apollo-dropdown';
import useMediaQuery from '../../hooks/use-media-query';
import cs from './styles.pcss';
import Button from '../button';
import DropdownContent from './dropdown-content';
import Icon from '../icon';
import type { Props as ButtonProps } from '../button';
import Portal from '../portal';

type Props = {
  title: string | React.Node,
  children: React.Node,
  shift?: number,
  onReset?: () => void,
  resettable?: boolean,
  onButtonOpen?: (event: MouseEvent) => void,
  onClick?: (event: MouseEvent) => void,
  id: string,
  direction?: 'top' | 'bottom',
  hDirection?: 'left' | 'right',
  arrow?: boolean,
  dropdownClassName?: string,
  mode?: 'main' | 'secondary' | 'dangerous' | 'transparent' | 'inline',
  size?: 'small' | 'medium' | 'big' | 'inModal',
  wrapperClassName?: string,
  className?: string,
  adaptive?: boolean,
  isMobile?: boolean,
} & ButtonProps;

export default function ButtonDropdown({
  direction = 'top',
  arrow = true,
  title,
  children,
  shift,
  resettable,
  hDirection,
  onButtonOpen,
  onReset,
  loading,
  id,
  mode = 'main',
  size = 'medium',
  className,
  dropdownClassName,
  onClick,
  wrapperClassName,
  adaptive = false,
  isMobile = false,
  // $FlowFixMe
  ...rest
}: Props): React.Node {
  const resetButton = React.useRef();
  const wrapperRef = React.useRef();
  const verySmallScreen = useMediaQuery('all and (max-width: 509px)');

  const { openDropdown, closeDropdown, dropdowns } = useApolloDropdown(id);
  const active = dropdowns.includes(id);

  useOutsideClick(wrapperRef, closeDropdown);

  const handleToggle = (event: MouseEvent) => {
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }

    if (
      resetButton.current &&
      (resetButton.current === event.target ||
        (event.target instanceof Node &&
          resetButton.current.contains(event.target))) &&
      onReset
    ) {
      onReset();
    } else if (active) {
      closeDropdown();
    } else {
      openDropdown();
      if (onButtonOpen) {
        onButtonOpen(event);
      }
    }
  };

  return (
    <div
      className={cx(cs.buttonDropdown, wrapperClassName, {
        [cs.inlineWrapper]: mode === 'inline',
        [cs.stuckTopRight]: adaptive && isMobile && verySmallScreen,
      })}
    >
      <Button
        {...rest}
        className={cx(cs.button, className, {
          [cs.minimalistic]: adaptive && isMobile && verySmallScreen,
        })}
        onClick={handleToggle}
        loading={loading}
        mode={mode}
        size={size}
      >
        <span className={cs.content}>
          {title}
          {resettable && (
            <div ref={resetButton} className={cs.reset}>
              <Icon name="closeCircle" />
            </div>
          )}
          {arrow && !loading && (
            <Icon
              name={size === 'small' && title ? 'selectSmall' : 'selectNormal'}
              className={cx(cs.arrow, cs[mode], cs[size], {
                [cs.noText]: title === '',
              })}
            />
          )}
        </span>
      </Button>
      {active &&
        (adaptive && isMobile ? (
          <Portal id="portal-button-dropdown-content">
            <DropdownContent
              shift={shift}
              direction={direction}
              hDirection={hDirection}
              className={dropdownClassName}
              overlaid
            >
              {children}
            </DropdownContent>
          </Portal>
        ) : (
          <div ref={wrapperRef}>
            <DropdownContent
              shift={shift}
              direction={direction}
              hDirection={hDirection}
              className={dropdownClassName}
            >
              {children}
            </DropdownContent>
          </div>
        ))}
    </div>
  );
}
