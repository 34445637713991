// @flow
import * as React from 'react';
import { FormSpy } from 'react-final-form';
import type { FormSpyRenderProps } from 'react-final-form';

import { parseNumber, formatNumber } from '../../../utils/form';
import { getVisibleError } from '../../../hocs/final-form/utils';
import { FormField } from '../../../hocs/final-form';

import Field from '../../../basic-components/field';
import Input from '../../../basic-components/input';

import cs from './styles.pcss';
import t from '../locale';

type Props = {
  label: string,
  name: string,
  description: React.Node,
  defaultCurrency: string,
  veryLargeScreen?: boolean,
  priceFirstLabel?: string,
  priceAdditionalLabel?: string,
};

export default function Prices({
  name,
  label,
  description,
  defaultCurrency,
  veryLargeScreen,
  priceFirstLabel,
  priceAdditionalLabel,
}: Props): React.Node {
  return (
    <FormSpy>
      {({ form }: FormSpyRenderProps<any>) => {
        let error = null;
        const priceError = getVisibleError(form.getFieldState('tariffs'));
        if (priceError) {
          const price = form.getFieldState(`${name}.${defaultCurrency}.price`);
          const additional = form.getFieldState(
            `${name}.${defaultCurrency}.additionalPrice`
          );
          error =
            price &&
            additional &&
            Number(price.value || 0) < Number(additional.value || 0)
              ? t('fields.price.validate.additionalHigher')
              : null;
        }

        return (
          <Field
            label={label}
            description={description}
            error={error}
            fieldClassName={cs.fieldClassName}
            noGrid={!veryLargeScreen}
          >
            {!veryLargeScreen && (
              <div className={cs.priceLabel}>{priceFirstLabel}</div>
            )}
            <FormField
              name={`${name}.${defaultCurrency}.price`}
              parse={parseNumber}
              format={formatNumber}
              formatOnBlur
              Component={Input}
              grid={6}
              className={cs.firstPrice}
              error={error}
            />
            {!veryLargeScreen && (
              <div className={cs.priceLabel}>{priceAdditionalLabel}</div>
            )}
            <FormField
              name={`${name}.${defaultCurrency}.additionalPrice`}
              parse={parseNumber}
              format={formatNumber}
              formatOnBlur
              Component={Input}
              grid={6}
              className={cs.additionalPrice}
              error={error}
            />
          </Field>
        );
      }}
    </FormSpy>
  );
}
