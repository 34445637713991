// @flow
import * as React from 'react';
import cx from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';

import cs from './styles.pcss';

type Props = {
  children: React.Node,
  narrow?: boolean,
};

export default function ContentWrapper({
  children,
  narrow,
}: Props): React.Node {
  return (
    <div className={cx(cs.contentWrapper, { [cs.max]: !narrow })}>
      {narrow ? (
        <div className={cx(cs.content, cs.grid)}>{children}</div>
      ) : (
        <AutoSizer>
          {({ width, height }) => (
            <div className={cs.content} style={{ width, height }}>
              {children}
            </div>
          )}
        </AutoSizer>
      )}
    </div>
  );
}
