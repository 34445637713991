// @flow
import { sendEvent, payloadCreator } from '../utils/analytics';

const category = 'onboarding-3';
const payload = payloadCreator(category);

export default {
  start: (): Promise<void> => sendEvent(payload('start')),
  cancel: (step: number): Promise<void> =>
    sendEvent(payload('cancel', step.toString())),
  publish: (): Promise<void> => sendEvent(payload('publish')),
  publishSelected: (): Promise<void> => sendEvent(payload('publishSelected')),
  skip: (): Promise<void> => sendEvent(payload('skip')),
  finished: (): Promise<void> => sendEvent(payload('finished')),
};
