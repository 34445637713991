// @flow
import { isNil, omit } from 'ramda';
import settings, { shipping } from '../../settings';
import type {
  DomesticShippingProfileTariffInput,
  InternationalShippingProfileTariffInput,
  ProfileInput,
  ProfileTariffInput,
  ShippingEbayProfileInput,
  ShippingProfile_profile as Profile,
  ShippingProfile_profile_tariffs as Tariff,
  ShippingProfile_profile_tariffs_prices as PriceT,
  ShippingProfileAdditional_currencies as Currency,
  ShippingProfileAdditional_profiles_nodes as ProfileTitleColor,
  ShippingProfileFragment_domesticShippingServices as DomesticShippingService,
  ShippingProfileFragment_internationalShippingServices as InternationalShippingService,
  ShippingProfileFragment_shippingEbayProfiles as ShippingEbayProfile,
  ShippingProfileAdditional_viewer_accounts as ShippingProfileAccount,
} from '../../typings/product-query.flow';
import type { Option } from '../../basic-components/dropdown/typings';

const regionData = require('./regions.json');

export type FormPrices = {
  [string]: { price: number, additionalPrice: number },
};

export type FormTariff = {
  locations: Array<string>,
  timeMax: ?number,
  prices: FormPrices,
};

// Form operates with Profile of slightly different shape. It allows some values to be null and
// has Tariff's prices as map (which is easier to work with but seems to be bad for GraphQL API)
export type FormProfile = {
  title: string,
  type: string,
  tariffs: Array<FormTariff>,
  country: string,
  city: ?string,
  postalCode: ?string,
  id: ?string,
  color?: ?number,
  domesticShippingServices?: Array<DomesticShippingService>,
  internationalShippingServices?: Array<InternationalShippingService>,
  shippingEbayProfiles?: Array<ShippingEbayProfile>,
  whiteList?: boolean,
  originalShippingPolicy?: ?any,
};

function convertToFormTariff(tariff: Tariff): FormTariff {
  return {
    ...omit(['prices'], tariff),
    prices: tariff.prices.reduce((acc: any, price: PriceT) => {
      if (!price) {
        return acc;
      }
      return {
        ...acc,
        [price.currency]: {
          price: price.price,
          additionalPrice: price.additionalPrice,
        },
      };
    }, {}),
  };
}

export function convertToFormProfile(
  profile: ?Profile,
  currenceis?: Array<Currency>,
  defaultCurrency: string,
  account: ShippingProfileAccount
): ?FormProfile {
  if (!profile || !currenceis || !defaultCurrency || !account) {
    return null;
  }
  const { domesticShippingServices, internationalShippingServices } = profile;
  const speedApkKeys = settings.references.speedPakKeys;
  // only GC and JP seller can see the SpeedPAK options
  if (
    account.country === 'JP' ||
    settings.references.isGcCountry(account.country || '')
  ) {
    // filter by site id and domestic or international
    if (domesticShippingServices) {
      // eslint-disable-next-line no-param-reassign
      profile = {
        ...omit(['domesticShippingServices'], profile),
        domesticShippingServices: domesticShippingServices.filter((opt) => {
          const rule = settings.references.speedPakexcluded[`${opt.siteId}`];
          if (!rule) {
            return true;
          }
          if (!speedApkKeys.some((k) => opt.key.includes(k))) {
            return true;
          }
          const excludeValues = rule.excludedValues.domestic;
          if (excludeValues === null) {
            return true;
          }
          return !excludeValues.some((exclValue) =>
            opt.key.includes(exclValue)
          );
        }),
      };
    }
    if (internationalShippingServices) {
      // eslint-disable-next-line no-param-reassign
      profile = {
        ...omit(['internationalShippingServices'], profile),
        internationalShippingServices: internationalShippingServices.filter(
          (opt) => {
            const rule = settings.references.speedPakexcluded[`${opt.siteId}`];
            if (!rule) {
              return true;
            }
            if (!speedApkKeys.some((k) => opt.key.includes(k))) {
              return true;
            }
            const excludeValues = rule.excludedValues.international;
            if (excludeValues === null) {
              return true;
            }
            return !excludeValues.some((exclValue) =>
              opt.key.includes(exclValue)
            );
          }
        ),
      };
    }
  } else {
    // remove SpeedPAK options if seller is not in JP or GC
    if (domesticShippingServices) {
      // remove SpeedPAK options for domestic
      // eslint-disable-next-line no-param-reassign
      profile = {
        ...omit(['domesticShippingServices'], profile),
        domesticShippingServices: domesticShippingServices.filter(
          (shippingService) =>
            !speedApkKeys.some((k) => shippingService.key.includes(k))
        ),
      };
    }
    if (internationalShippingServices) {
      // remove SpeedPAK options for international
      // eslint-disable-next-line no-param-reassign
      profile = {
        ...omit(['internationalShippingServices'], profile),
        internationalShippingServices: internationalShippingServices.filter(
          (shippingService) =>
            !speedApkKeys.some((k) => shippingService.key.includes(k))
        ),
      };
    }
  }

  return {
    ...omit(['tariffs'], profile),
    tariffs: profile.tariffs.map((tariff) => convertToFormTariff(tariff)),
  };
}

export function createEmptyTariff(): FormTariff {
  return {
    locations: ['Worldwide'],
    timeMax: null,
    prices: {},
  };
}

export type DefaultItemLocation = {
  country: string,
  city: ?string,
  postalCode: ?string,
};

export function createEmptyProfile({
  country,
  city,
  postalCode,
}: DefaultItemLocation): FormProfile {
  return {
    title: '',
    type: shipping.pricing.free,
    tariffs: [createEmptyTariff()],
    returnsWithin: 30,
    returnsPaidByBuyer: true,
    country,
    city,
    postalCode,
    id: null,
  };
}

function hasPrice(prices: ?{ price: number }): boolean {
  return !!prices && prices.price !== undefined && prices.price !== null;
}

function createTariffInput(isFree: boolean, hasShippingZones: boolean) {
  return ({ prices, timeMax, locations }): ProfileTariffInput => ({
    locations:
      isNil(locations) || !hasShippingZones ? ['Worldwide'] : locations, // Default value before we will have shipping zones
    timeMax: isNil(timeMax) ? 0 : timeMax,
    prices: isFree
      ? []
      : Object.keys(prices)
          .filter((currency) => hasPrice(prices[currency]))
          .map((currency) => ({
            currency,
            price: prices[currency].price,
            additionalPrice: prices[currency].additionalPrice || 0,
          })),
  });
}

function collectDomesticEbayTariffs(
  form: HTMLFormElement,
  dsKey: string
): Array<DomesticShippingProfileTariffInput> {
  const ebayTariffs = [];

  // e.g dsKey = 357-cp-us-ds
  // 国内服务最多可以设置4个
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 4; i++) {
    const currentKey = `${dsKey}-${i}`;
    const serviceInput: HTMLInputElement = (form[`${currentKey}.service`]: any);
    const serviceCode = serviceInput?.value;

    // FIXME: 用户没有选择shipping service。后期前端给个提示
    // eslint-disable-next-line no-continue
    if (!serviceCode) continue;

    const freeInput: HTMLInputElement = (form[`${currentKey}.cost.free`]: any);
    const free = freeInput?.checked;

    const costInput: HTMLInputElement = (form[`${currentKey}.cost.price`]: any);
    const cost = costInput?.value;

    const additionalInput: HTMLInputElement = (form[
      `${currentKey}.cost.additional`
    ]: any);
    const additional = additionalInput?.value;

    ebayTariffs.push({
      shippingServiceCode: serviceCode || '',
      // 注意：只有第一个shipping才有free shipping checkbox。其他free值都会是undefined.
      freeShipping: free || false,
      shippingCost: +cost || 0,
      additionalShippingCost: +additional || 0,
    });
  }

  return ebayTariffs;
}

function collectInternationalEbayTariffs(
  form: HTMLFormElement,
  isKey: string
): Array<InternationalShippingProfileTariffInput> {
  const ebayTariffs = [];

  // e.g dsKey = 357-cp-us-ds
  // 国际运送最多可以设置5个
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 5; i++) {
    const currentKey = `${isKey}-${i}`;
    const serviceInput: HTMLInputElement = (form[`${currentKey}.service`]: any);
    const serviceCode = serviceInput?.value;

    // FIXME: 用户没有选择shipping service。后期前端给个提示
    // eslint-disable-next-line no-continue
    if (!serviceCode) continue;

    const costInput: HTMLInputElement = (form[`${currentKey}.cost.price`]: any);
    const cost = costInput?.value;

    const additionalInput: HTMLInputElement = (form[
      `${currentKey}.cost.additional`
    ]: any);
    const additional = additionalInput?.value;

    const shipToInput: HTMLInputElement = (form[`${currentKey}.shipto`]: any);
    const shipTo = shipToInput?.value;
    const includedRegions = shipTo ? shipTo.split(',') : [];

    ebayTariffs.push({
      shippingServiceCode: serviceCode || '',
      // 国际运送是没有free shipping标记的
      freeShipping: false,
      shippingCost: +cost || 0,
      additionalShippingCost: +additional || 0,
      includedRegions,
    });
  }

  return ebayTariffs;
}

function collectExcludedCountries(
  form: HTMLFormElement,
  key: string
): Array<string> {
  const excludedCountryInput: HTMLInputElement = (form[key]: any);
  const excludedCountries = excludedCountryInput?.value;
  return excludedCountries ? excludedCountries.split(',') : [];
}

// 按site tab收集用户修改的ebay profile的信息
function collectCustomProfileDataBySite(
  key: string
): ShippingEbayProfileInput | null {
  // e.g key = 357-cp-us
  const form: HTMLFormElement = (document.forms[key]: any);

  // 如果只是纯展示页面，所以不给form设置name
  if (!form) return null;

  // ebay policy id
  // flow类型断言，HTMLElement -> HTMLInputElement
  const pidInput: HTMLInputElement = (form[`${key}-pid`]: any);
  const pid = pidInput?.value;

  // 如果用户没有点击任何site tab，只浏览general policy的情况下并保存，
  // 8个site tab的页面是无法渲染的，因此获取不到表单数据。
  if (!pid) return null;

  // 用户是否打开自定义开关，
  const switcherInput: HTMLInputElement = (form[`${key}-switcher`]: any);
  const open = switcherInput?.checked;

  // 如果用户没有打开过，或者打开之后又关闭了。就不浪费时间收集数据了。
  if (!open) {
    return {
      id: pid,
      managedByUser: false,
      domsEbayTariffs: [],
      intlEbayTariffs: [],
      excludedCountries: [],
      dispatchTime: null,
    };
  }

  // dispatch time
  const dispatchTimeInput: HTMLInputElement = (form[
    `${key}-dispatchTime`
  ]: any);
  const dispatchTime = dispatchTimeInput?.value;

  const domesticEbayTariffs = collectDomesticEbayTariffs(form, `${key}-ds`);
  const internationalEbayTariffs = collectInternationalEbayTariffs(
    form,
    `${key}-is`
  );
  const excludedCountries = collectExcludedCountries(
    form,
    `${key}-excluded-countries`
  );

  return {
    id: pid,
    managedByUser: open,
    domsEbayTariffs: domesticEbayTariffs,
    intlEbayTariffs: internationalEbayTariffs,
    excludedCountries,
    dispatchTime: +dispatchTime,
  };
}

const big8Sites = ['us', 'uk', 'fr', 'de', 'it', 'es', 'au', 'ca'];
function assembleCustomProfileData(
  profileId: string | number
): Array<ShippingEbayProfileInput | null> {
  const baseKey = `${profileId}-cp`;
  const result = [];

  big8Sites.forEach((site) => {
    const key = `${baseKey}-${site}`;
    result.push(collectCustomProfileDataBySite(key));
  });

  // 如果用户没有点击任何site tab，只浏览general policy的情况下并保存，
  // 8个site tab的页面是无法渲染的，因此获取不到表单数据。
  // 此时collectCustomProfileDataBySite函数返回NULL
  return result.filter((item) => item !== null);
}

export function createProfileInput(profile: FormProfile): ProfileInput {
  let tariffs = [];
  const isFree = profile.type === shipping.pricing.free;
  const hasShippingZones = profile.type === shipping.pricing.custom;

  if (isFree) {
    // Profile with 'Free' shipping type should have only one tariff without price
    tariffs = profile.tariffs
      .slice(0, 1)
      .map(createTariffInput(true, hasShippingZones));
  } else {
    tariffs = profile.tariffs.map(createTariffInput(false, hasShippingZones));
  }

  // 如果是在Mag上create new policy，则不需要Custom Profiles信息
  if (!profile.id) {
    return ({
      title: profile.title ? profile.title.trim() : '',
      color: 0,
      ...omit(
        [
          'type',
          'numberOfProducts',
          'title',
          '__typename',
          'domesticShippingServices',
          'internationalShippingServices',
          'shippingEbayProfiles',
          'whiteList',
          'originalShippingPolicy',
        ],
        profile
      ),
      tariffs,
    }: any);
  }

  const customProfiles = assembleCustomProfileData(profile.id);
  // console.log('Custom eBay profiles', customProfiles);

  return ({
    title: profile.title ? profile.title.trim() : '',
    color: 0,
    ...omit(
      [
        'type',
        'numberOfProducts',
        'title',
        '__typename',
        'domesticShippingServices',
        'internationalShippingServices',
        'shippingEbayProfiles',
        'whiteList',
        'originalShippingPolicy',
      ],
      profile
    ),
    tariffs,
    ebayProfiles: customProfiles,
  }: any);
}

export function getFirstLetter(title: string): string {
  if (title) {
    return title[0].toLowerCase();
  }

  return '';
}

/**
 * Two profiles with starting with the same letter should have differnet colors.
 * `getDisabledColors` function builds a map of colors occupied by symbols.
 */
export function getDisabledColors(
  profiles: ?(ProfileTitleColor[]),
  currentProfileId: ?string
): { [key: string]: number[] } {
  if (!profiles) {
    return {};
  }

  return profiles.reduce(
    (acc: { [key: string]: number[] }, profile: ProfileTitleColor) => {
      if (profile.id === currentProfileId) {
        return acc;
      }

      const firstLetter = getFirstLetter(profile.title);
      return {
        ...acc,
        [firstLetter]: [...(acc[firstLetter] || []), profile.color],
      };
    },
    {}
  );
}

export function getShippingServiceDictionaryBySiteId(
  dataSource: Array<DomesticShippingService | InternationalShippingService>,
  siteId: number
): Array<DomesticShippingService | InternationalShippingService> {
  return dataSource.filter((item) => item.siteId === siteId);
}

export function transformToDropdownOptions(
  originalOptions: ?Array<
    DomesticShippingService | InternationalShippingService
  >
): Array<Option> {
  if (!originalOptions) return [{ label: '', value: '', type: 'notFound' }];
  const options = originalOptions.map((o) => {
    const { category, description, min, max, key } = o;
    const label =
      min === max
        ? `(${category}) ${description} (${min} business days)`
        : `(${category}) ${description} (${min} to ${max} business days)`;

    return {
      label,
      value: key,
      min,
      siteId: o.siteId,
      category: o.category,
    };
  });

  // divide them into groups
  const speedPakGroup = options.filter((option) => {
    const speedApkKeys = settings.references.speedPakKeys;
    // check whether is speed package
    return speedApkKeys.some((keyword) => option.value.includes(keyword));
  });
  const othersGroup = options.filter((option) => {
    const speedApkKeys = settings.references.speedPakKeys;
    // check whether is speed package
    return !speedApkKeys.some((keyword) => option.value.includes(keyword));
  });

  // sorted
  if (speedPakGroup.length > 0) {
    speedPakGroup.sort((a, b) => {
      let orders = settings.references.speedPakPriorityOrder;
      if (a.siteId === 2 && b.siteId === 2) {
        orders = settings.references.speedPakPriorityOrder4Ca;
      }

      const aIndex = orders.findIndex((v) => a.value.includes(v));
      const bIndex = orders.findIndex((v) => b.value.includes(v));

      // Compare by priority if found
      if (aIndex !== bIndex) {
        return (
          (aIndex === -1 ? orders.length : aIndex) -
          (bIndex === -1 ? orders.length : bIndex)
        );
      }

      // If priority is the same, compare by min value
      return a.min - b.min;
    });
  }
  othersGroup.sort((a, b) => {
    const orders = settings.references.otherOptionsPriorityOrder;

    const aIndex = orders.findIndex((c) => a.category.includes(c));
    const bIndex = orders.findIndex((c) => b.category.includes(c));

    // Compare by priority if found
    if (aIndex !== bIndex) {
      return (
        (aIndex === -1 ? orders.length : aIndex) -
        (bIndex === -1 ? orders.length : bIndex)
      );
    }

    // If priority is the same, compare by min value
    return a.min - b.min;
  });

  const sortedOptions = [...speedPakGroup, ...othersGroup];

  return sortedOptions.map((op) => ({
    label: op.label,
    value: op.value,
  }));
}

export function getShippingServiceName(
  dataSource: Array<Option>,
  serviceKey: string
): string {
  if (dataSource.length === 0) return '';
  const serviceFound = dataSource.find((item) => item.value === serviceKey);
  return serviceFound?.label || '';
}

/**
 * 后端的逻辑是如果cost为0，则被认为是free shipping,
 * 但ebay规定只在国内运送中只有第一个service可以标记free shippingb,
 * 因此需要对后端传过来的多个free shipping的情况进行处理
 */
export function handleMultiFreeShippingForDomestic(services: any): any {
  if (services.length <= 1) return services;

  const position = services.findIndex((s) => s.freeShipping);
  // 如果没有设置free shipping，就按原来的顺序
  if (position === -1) return services;

  const copy = [...services];
  const firstFreeOne = copy.splice(position, 1)[0]; // splice 返回的是数组
  const modifiedOnes = copy.map((s) => ({ ...s, freeShipping: false }));
  modifiedOnes.unshift(firstFreeOne);
  return modifiedOnes;
}

/**
 * 后端的逻辑是如果cost为0，则被认为是free shipping,
 * 但国际运送是不可标记free shipping,
 * 因此需要将所有free shipping设为false
 */
export function handleMultiFreeShippingForInternational(services: any): any {
  if (services.length === 0) return services;
  // eslint-disable-next-line no-return-assign,no-param-reassign
  return services.map((s) => ({ ...s, freeShipping: false }));
}

export function parseNumber(initialVal: string): number | string {
  const trimedVal = initialVal.trim();
  if (trimedVal === '') return trimedVal;
  if (trimedVal.lastIndexOf('.') === trimedVal.length - 1) return trimedVal;
  const num = window.parseFloat(trimedVal);
  return isNaN(num) ? 0 : num;
}

export function findOriginalSite(originalShippingPolicy: any): number | string {
  // originalShippingPolicy为null有两种情况
  // 1. 在Mag创建profile，非商品导入带进来的shipping policy
  // 2. 上线之前的profile，都没有原始policy信息
  if (!originalShippingPolicy) return 'unknown';
  return originalShippingPolicy.site_id;
}

const WORLDWIDE = 'Worldwide';

export function getRegionNames(
  regionArray: Array<{ regionName: string }> | null | void
): Array<string> {
  if (!regionArray) return [WORLDWIDE];
  return regionArray.map((r) => r.regionName);
}

function lookupReadableLocation(
  regionDictionary: Array<{ [string]: string }>,
  regionName: string
): string {
  const found = regionDictionary.find((item) => item.name === regionName);
  return found?.value || '';
}

export function transformToReadableLocations(
  siteId: number,
  shipToLocations: any
): Array<string> {
  const regionDictionary = regionData[siteId.toString()] || regionData['0'];
  const regionNames = getRegionNames(shipToLocations.regionIncluded);

  if (
    regionNames.length === 0 ||
    (regionNames.length === 1 && regionNames[0] === WORLDWIDE)
  )
    return [WORLDWIDE];

  return regionNames.map((name) =>
    lookupReadableLocation(regionDictionary, name)
  );
}
