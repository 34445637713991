// @flow
import * as React from 'react';
import cx from 'classnames';
import { isNil } from 'ramda';

import { useOnboardingTour } from '../../hooks/use-onboarding-tour';
import cs from './style.pcss';

type Props = {
  stepNumber?: number,
  children: React.Node,
  round?: boolean,
  rounded?: boolean,
  onlyConfirmed?: boolean,
  notConfirmed?: boolean,
  forceShow?: boolean,
};

export default function OnboardingHighlight({
  stepNumber,
  children,
  round,
  rounded,
  onlyConfirmed,
  notConfirmed,
  forceShow,
}: Props): React.Node {
  const { isOnboardingStep, confirmedPartOnboardingStep } = useOnboardingTour();

  const show =
    !isNil(stepNumber) &&
    isOnboardingStep(stepNumber) &&
    (!onlyConfirmed || confirmedPartOnboardingStep === stepNumber) &&
    (!notConfirmed || confirmedPartOnboardingStep !== stepNumber);

  return show || forceShow ? (
    <div
      className={cx(cs.wrapper, {
        [cs.round]: round,
        [cs.rounded]: rounded,
      })}
    >
      <div className={cs.border} />
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          className: cx(child.props.className, cs.highlight),
        })
      )}
    </div>
  ) : (
    children
  );
}
