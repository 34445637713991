// @flow

import { createActions } from 'redux-actions';
import { path, identity } from 'ramda';
import { fetchJSONWithValidation } from '../utils/http';
import { showGenericError, showError } from './notifications';
import settings from '../settings';
import { onboardingDisabled } from '../utils/onboarding';
import ANALYTICS from '../analytics/onboarding';
import type { ThunkDispatch, GetState } from '../typings/redux';
import type { Query } from '../typings/apollo';
import { ProductsCountOnboarding } from './ProductsCountOnboarding.graphql';
import type { ProductsCountOnboarding as ProductsCountOnboardingT } from '../typings/product-query.flow';

export const actions: any = createActions({
  ONBOARDING: {
    CANCEL: identity,
    INIT: identity,
    SET_STEP: identity,
    SET_SERVER_STATUS: identity,
    SET_SHOWCASES: identity,
  },
});

const finish =
  (): ((dispatch: ThunkDispatch, getState: GetState<any>) => any) =>
  (dispatch: ThunkDispatch) =>
    fetchJSONWithValidation(
      `${settings.onboarding.base}${settings.onboarding.finish}`,
      {
        method: 'post',
      }
    )
      .then((data) => dispatch(actions.onboarding.setServerStatus(data)))
      .then(ANALYTICS.finish)
      .catch((error) => dispatch(showError(error)));

const gaChangeStep = (step) => () => () => ANALYTICS.changeStep(step);
const gaStart = () => () => () => ANALYTICS.start();

export const stepsConfig = {
  '0.0': {
    screen: true, // show screen or tooltip
    resetTo: null, // reset to previous
    next: '1.0', // autoNext
    page: 'GetStarted', // page where to show current step
    onEnter: null, // action to dispatch on enter
    onTransition: null, // fn on transition
    afterTransition: (gaStart(): () => () => Promise<void>), // action after transition
  },

  '1.0': {
    screen: true,
    next: '2.0',
    page: 'GetStarted',
    afterTransition: (gaChangeStep('1.0'): () => () => Promise<void>),
  },

  '2.0': {
    page: 'GetStarted',
    screen: true,
    next: '3.0',
    nextManual: '1.0',
    afterTransition: (gaChangeStep('2.0'): () => () => Promise<void>),
  },

  '3.0': {
    page: 'Stock',
    success: true,
    closable: true,
    last: true,
    beforeTransition: (gaChangeStep('3.0'): () => () => Promise<void>),
    onEnter: finish,
  },
};

const defaultStep = '0.0';

const sync =
  (stepId, initialData = {}) =>
  (dispatch) =>
    fetchJSONWithValidation(
      `${settings.onboarding.base}${settings.onboarding.continue}`,
      {
        method: 'post',
        body: { step: stepId, data: initialData },
      }
    )
      .then((data) => dispatch(actions.onboarding.setServerStatus(data)))
      .catch((error) => dispatch(showError(error)));

const enter = (stepId) => (dispatch) => {
  const step = stepsConfig[stepId];
  if (!step) {
    dispatch(showGenericError());
    return Promise.resolve();
  }

  dispatch(actions.onboarding.setStep({ step: stepId, data: step }));

  if (step.onEnter) {
    return dispatch(step.onEnter());
  }

  return Promise.resolve();
};

export const proceed =
  (manual: ?boolean, additionalData: {} = {}): any =>
  (dispatch: ThunkDispatch, getState: GetState<*>) => {
    const currentStepId = path(['onboarding', 'step'], getState());
    const currentStep = stepsConfig[currentStepId];
    if (!currentStep) {
      return dispatch(showGenericError());
    }

    const nextStepId =
      manual && currentStep.nextManual
        ? currentStep.nextManual
        : currentStep.next;
    const nextStep = stepsConfig[nextStepId];
    if (!nextStepId || !nextStep) {
      dispatch(showGenericError());
      return Promise.resolve();
    }

    let transitionPromise = Promise.resolve();

    if (nextStep.beforeTransition) {
      transitionPromise = dispatch(nextStep.beforeTransition(manual));
    }

    const result = transitionPromise
      .then(() => dispatch(enter(nextStepId)))
      .then(() => dispatch(sync(nextStepId, additionalData)));

    if (nextStep.afterTransition) {
      return result
        .then(() => dispatch(nextStep.afterTransition(manual)))
        .catch((error) => dispatch(showError(error)));
    }

    return result.catch((error) => dispatch(showError(error)));
  };

const goToStock = () => {
  location.href = settings.path.stock;
};

export const initOnboarding =
  (): ((dispatch: ThunkDispatch, getState: GetState<any>) => any) =>
  (dispatch: ThunkDispatch, getState: GetState<*>) => {
    const state = getState();

    if (onboardingDisabled(state)) {
      return Promise.resolve();
    }

    // get current step from server
    const currentStep = path(['onboarding', 'server', 'step'], state);
    if (!currentStep) {
      return Promise.resolve();
    }

    if (currentStep === '0.0') {
      ANALYTICS.start();
    }

    const configForStep = stepsConfig[currentStep] || stepsConfig[defaultStep];

    const step = configForStep.resetTo || currentStep;
    return dispatch(enter(step));
  };

export const selectShowcaseAndProceed =
  (
    showcases: Array<string>
  ): ((dispatch: ThunkDispatch, getState: GetState<any>) => any) =>
  (dispatch: ThunkDispatch) => {
    dispatch(actions.onboarding.setShowcases(showcases));
    return dispatch(proceed(false, { showcases }));
  };

export const cancel =
  (
    withRedirect: ?boolean
  ): ((dispatch: ThunkDispatch, getState: GetState<any>) => any) =>
  (dispatch: ThunkDispatch, getState: GetState<*>): any =>
    fetchJSONWithValidation(
      `${settings.onboarding.base}${settings.onboarding.cancel}`,
      {
        method: 'post',
      }
    )
      .then((data) => dispatch(actions.onboarding.setServerStatus(data)))
      .then(() => {
        if (withRedirect) {
          const currentStep = path(['onboarding', 'step'], getState());
          ANALYTICS.cancel(currentStep).then(() => {
            goToStock();
          });
        }
      })
      .catch((error) => dispatch(showError(error)));

type Client = {
  query: ({ query: Query }) => Promise<{ data: ProductsCountOnboardingT }>,
};

type OnboardingResponse = {
  data: ProductsCountOnboardingT,
};

export const checkProductsAndProceed =
  (client: Client): ((dispatch: ThunkDispatch) => Promise<mixed>) =>
  (dispatch: ThunkDispatch) =>
    client
      .query({ query: ProductsCountOnboarding })
      .then((data: OnboardingResponse) => {
        const totalProducts = path(['data', 'products', 'totalCount'], data);
        if (totalProducts) {
          dispatch(proceed());
        } else {
          dispatch(finish())
            .then(ANALYTICS.skip())
            .then(() => goToStock())
            .catch((error) => {
              dispatch(showError(error));
              goToStock();
            });
        }
      })
      .catch((error) => dispatch(showError(error)));

export const goBack =
  (): ((dispatch: ThunkDispatch) => void) => (dispatch: ThunkDispatch) => {
    ANALYTICS.back('1.0').then(() => {
      dispatch(proceed(true));
    });
  };
