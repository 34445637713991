// @flow

import * as React from 'react';
import FocusLock from 'react-focus-lock';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import Portal from '../portal';
import { NotifyLatest } from './notify-latest';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  children: React.Node | ((closeFn: () => Promise<void>) => React.Node),
  transparent?: boolean,
  className?: string,
  onCloseStart: () => void,
  onCloseEnd: () => void,
};

const notifyLatest = new NotifyLatest();

export default function Overlay({
  onCloseStart,
  onCloseEnd,
  transparent,
  children,
  className,
}: Props): React.Node {
  const [shown, setShown] = React.useState(true);

  const storedScrollPosition = React.useRef<number>(0);

  const refOverlay = React.useRef();

  const hideRootScroll: () => void = () => {
    storedScrollPosition.current = window.pageYOffset;

    if (document.body && !transparent) {
      document.body.style.top = `${-storedScrollPosition.current}px`;
      document.body.classList.add(cx('bodyOverlayIsOpened'));
    }
  };

  const restoreRootScroll: () => void = () => {
    if (document.body && !transparent) {
      document.body.style.top = 'auto';
      document.body.classList.remove(cx('bodyOverlayIsOpened'));
    }
    window.scrollTo(0, storedScrollPosition.current);
  };

  const hide = () =>
    new Promise((resolve) => {
      if (shown) {
        onCloseStart();
        setShown(false);
        setTimeout(() => resolve(), 300);
      } else {
        resolve();
      }
    });

  const handleKeyDown: (KeyboardEvent) => void = ({
    keyCode,
    key,
  }: KeyboardEvent) => {
    if (keyCode === 27 || key === 'Escape') {
      hide();
    }
  };

  React.useEffect(() => {
    hideRootScroll();
    notifyLatest.addListener(handleKeyDown);

    return () => {
      restoreRootScroll();
      notifyLatest.removeListener(handleKeyDown);
    };
  }, []);

  const handleClick: (e: MouseEvent) => void = (e: MouseEvent) => {
    if (refOverlay && e.target === refOverlay.current) {
      hide();
    }
  };

  return (
    <Portal id="portal-overlay">
      <FocusLock>
        <CSSTransition
          appear
          in={shown}
          classNames={{
            exit: cs.exit,
            exitActive: cs.exitActive,
            appear: cs.appear,
            appearActive: cs.appearActive,
          }}
          onExited={onCloseEnd}
          timeout={300}
        >
          <div
            role="presentation"
            ref={refOverlay}
            className={cx(
              cs.overlay,
              { [cs.transparent]: transparent },
              className
            )}
            onClick={handleClick}
          >
            {typeof children === 'function' ? children(hide) : children}
          </div>
        </CSSTransition>
      </FocusLock>
    </Portal>
  );
}
