// @flow

import { assocPath } from 'ramda';

const nonDigitsRegExp = /\D+/g;

export function parseNumber(initialVal: string): mixed {
  const val = initialVal.replace(',', '.');
  const decSeparator = val.indexOf('.');
  let fracture = '';
  let integer = val;
  if (decSeparator !== -1) {
    integer = val.slice(0, decSeparator).replace(nonDigitsRegExp, '');
    fracture = val
      .slice(decSeparator + 1, val.length)
      .replace(nonDigitsRegExp, '');

    if (fracture === '') {
      return `${integer}.`;
    }

    return Number(`${integer}.${fracture}`);
  }

  const numString = val.replace(nonDigitsRegExp, '');
  if (numString === '') {
    return undefined;
  }

  return Number(numString);
}

export function formatNumber(val: mixed): mixed {
  const num = Number(val);

  return isNaN(num) ? 0 : num;
}

export type GraphqlValidationError = {
  message: string,
  fields: ?Array<string>,
  __typename: 'Error',
};

/**
 * Converts backend array of errors into map of a shape { field: error }
 */
export function convertGQLErrors(
  errors: Array<GraphqlValidationError>,
  genericErrorKey: string = 'form'
): { [string]: string } {
  // errors.reduce(e => assocPath(e.path.slice(1), e.message, res));
  // return res;

  return errors.reduce(
    (acc: { [key: string]: string }, err: GraphqlValidationError) => {
      // `fields` is a path to a field started from query name (that's why remove first element)
      // Example: ['profile', 'tariffs', '1', 'prices'] => 'tariffs.1.prices'
      const field =
        err.fields && err.fields.length > 1
          ? err.fields.slice(1)
          : [genericErrorKey];

      return assocPath<string, any, any>(field, err.message, acc);
    },
    {}
  );
}

/**
 * Compare two objects if they are equal by id
 */
export function isEqualById(a: ?{ id: any }, b: ?{ id: any }): boolean {
  return !!a && !!b && a.id === b.id;
}

export function validateEmail(error: string): (email: ?string) => ?string {
  return (email: ?string): ?string => {
    if (
      email &&
      email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return undefined;
    }

    return error;
  };
}

export function validateNotEmpty(error: string): (text: ?string) => ?string {
  return (text: ?string): ?string =>
    text && text.length > 0 ? undefined : error;
}

export function acceptType(
  fileType: string,
  acceptedTypes: ?string | ?Array<string>
): boolean {
  if (acceptedTypes) {
    const acceptedTypesArray = Array.isArray(acceptedTypes)
      ? acceptedTypes
      : acceptedTypes.split(',');

    const baseMimeType = fileType.replace(/\/.*$/, '');

    return acceptedTypesArray.some((type) => {
      const validType = type ? type.trim() : '';

      if (validType.endsWith('/*')) {
        // This is something like a image/* mime type
        return baseMimeType === validType.replace(/\/.*$/, '');
      }
      return fileType === validType;
    });
  }
  return true;
}
