import {
  assocPath,
  identity,
  curry,
  compose,
  set,
  __,
  apply,
  map,
  cond,
  is,
  T,
  lensIndex,
  lensProp,
} from 'ramda';

// This version of assocPath supports arrays and indexes in path!
export const accocPathA = curry((path, val, obj) =>
  compose(
    set(__, val, obj),
    apply(compose),
    map(
      cond([
        [is(Number), lensIndex],
        [T, lensProp],
      ])
    )
  )(path)
);

export const composeReducers = (...args) =>
  args.reduce(
    (result, reducer) => (state, action) =>
      reducer(result(state, action), action),
    identity
  );

export const isObject = (el) =>
  typeof el === 'object' && el !== null && !Array.isArray(el);

export const combineReducers = (reducers) =>
  composeReducers(
    ...Object.keys(reducers).map(
      (key) => (state, action) =>
        assocPath([key])(reducers[key](state[key], action))(state)
    )
  );
