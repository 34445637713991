// @flow
export type Manufacture = {
  companyName: string,
  street1: ?string,
  street2: ?string,
  cityName: string,
  stateOrProvince: string,
  postalCode: string,
  country: string,
  phone: string,
  email: string,
};

export type ResponsiblePerson = {
  companyName: string,
  street1: ?string,
  street2: ?string,
  cityName: string,
  stateOrProvince: string,
  postalCode: string,
  country: string,
  phone: string,
  email: string,
};

export type GpsrDocument = {
  documentType: string,
  languages: string[],
  documentFile: ?string,
};

export type ProductSafety = {
  pictograms: string[],
  statements: string[],
  component: string,
};

export type GpsrProfile = {
  id?: string,
  title?: string,
  color?: ?string,
  data?: {
    manufacturer?: Manufacture,
    responsiblePersons?: Array<ResponsiblePerson>,
    documents?: Array<GpsrDocument>,
    productSafety?: ProductSafety,
  },
};

// get GPSR configs
export function getOptions(
  gpsrConfigs: Array<any>,
  typeName: string
): Array<any> {
  return gpsrConfigs.filter((config) => config.typeName === typeName);
}
