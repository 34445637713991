// @flow
import { anyPass, pipe, equals, when } from 'ramda';
import { logError } from './sentry';

import settings from '../settings';
import translateErrors from './i18n/errors-locale';

import { jsonToSnakeCase, jsonToCamelCase } from './normalize';
import { isDefined } from './ramda';

type FetchConfig = {
  ...RequestOptions,
  body?: any,
};

type LocalResponse = {
  ...Response,
  data: any,
};

type CustomErrorType = 'BackendError';

let CSRFToken;

function getCSRFToken(): string {
  if (CSRFToken) {
    return CSRFToken;
  }

  const meta = document.querySelector('meta[name="csrf-token"]');

  if (meta) {
    CSRFToken = meta.getAttribute('content');

    return CSRFToken || '';
  }

  return '';
}

function prepareJSONInit(init) {
  const update = { ...init };

  if (init.method) {
    update.method = init.method.toUpperCase();
  }

  if (init.body) {
    update.body = JSON.stringify(jsonToSnakeCase(init.body));
  }

  return { ...init, ...update };
}

function getBaseRequest(input: RequestInfo) {
  const init = {
    credentials: 'same-origin',
    headers: new Headers({
      'X-CSRF-Token': getCSRFToken(),

      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  return new Request(input, init);
}

function getBaseFileRequest(input: RequestInfo) {
  const init = {
    credentials: 'same-origin',
    headers: new Headers({
      'X-CSRF-Token': getCSRFToken(),
      Accept: 'application/json, text/html, */*',
    }),
  };

  return new Request(input, init);
}

function checkStatus(response) {
  const { status } = response;

  if (
    response.status === 401 &&
    !window.location.href.includes(settings.path.login)
  ) {
    window.location.reload();
  }

  if (
    (status >= 200 && status < 300) ||
    status === 400 ||
    status === 422 ||
    status === 304 ||
    status === 409
  ) {
    return response;
  }

  const error = new Error(`${response.statusText} (${response.url})`);

  // $FlowFixMe
  error.response = response;

  throw error;
}

function parseJSON(response): Promise<LocalResponse> {
  return response
    .text()
    .then((data) => {
      if (data.length === 0) {
        // $FlowFixMe
        return Object.assign(response, { data: {} });
      }

      try {
        const parsedJSON = JSON.parse(data);
        // $FlowFixMe
        return Object.assign(response, {
          data: jsonToCamelCase(parsedJSON),
        });
      } catch (error) {
        logError(error, { extra: data });
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      throw Object.assign(error, { response });
    });
}

export function fetchJSON(
  input: RequestInfo,
  config: FetchConfig = {}
): Promise<LocalResponse> {
  return fetch(getBaseRequest(input), prepareJSONInit(config))
    .then(checkStatus)
    .then((response) => parseJSON(response));
}

export function fetchFile(
  input: RequestInfo,
  config: FetchConfig = {}
): Promise<LocalResponse> {
  return fetch(getBaseFileRequest(input), config)
    .then(checkStatus)
    .then((response) => parseJSON(response));
}

function namedError(name: string, message: string): Error {
  const error = new Error(message);
  error.name = name;
  return error;
}

const validateResponse = ({
  ok,
  data,
}: LocalResponse): Promise<LocalResponse> => {
  if (ok) {
    return Promise.resolve(data);
  }

  let errorMessage = translateErrors('generic_error');

  if (data.error) {
    errorMessage = data.error;
  } else if (data.errors) {
    const errorsArr = Array.isArray(data.errors)
      ? data.errors
      : Object.values(data.errors);
    errorMessage = errorsArr.join(', ');
  }

  throw namedError('BackendError', errorMessage);
};

export function fetchJSONWithValidation(
  input: RequestInfo,
  config: FetchConfig
): Promise<LocalResponse> {
  return fetchJSON(input, config).then(validateResponse);
}

export function isCustomError(
  error: Error,
  type: CustomErrorType = 'BackendError'
): boolean {
  return error.name === type;
}

// ------- http skip errors -------
const MOZILLA_CANCEL_ERROR =
  'TypeError: NetworkError when attempting to fetch resource.';

const getErrorString: any = (error) => error.toString();

export const checkIsEscapeError: (error?: Error) => boolean | null = when<
  any,
  any,
  any
>(isDefined, pipe(getErrorString, anyPass([equals(MOZILLA_CANCEL_ERROR)])));
