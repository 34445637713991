export default function getConstantPrefixer(prefix) {
  return (constant) => `${prefix}/${constant}`;
}

export const downloadFile = (downloadUrl, name) => {
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('href', downloadUrl);
  downloadLink.setAttribute('download', name);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink.remove();
};
