// @flow
import * as React from 'react';

import StatusMark from '../../basic-components/status-mark';

import t from './locale';
import cs from './styles.pcss';

type Props = {
  title?: React.Node,
  message: React.Node,
};

export default function SuccessOverlay({ title, message }: Props): React.Node {
  return (
    <div className={cs.block}>
      <StatusMark loading={false} className={cs.icon} mode="success" />
      <div className={cs.title}>{title || t('title')}</div>
      <div className={cs.message}>
        <div>{message}</div>
      </div>
    </div>
  );
}
