import { initialState as initialUserState } from './user';
import {
  normalizeInitialData as normalizeInitialNotificationsData,
  initialState as initialNotificationsState,
} from './notifications';
import { initialState as initialAccountState } from './account';
import { initialState as initialGlobalState } from './global';
import { normalizeGlobal } from '../normalizers/global';

const getUser = (user) => user || initialUserState;

const getAccount = (account) => account || initialAccountState;

const getGlobal = (sites, countries) => ({
  ...initialGlobalState,
  ...normalizeGlobal(sites),
  regions: countries,
});

const getNotifications = (notifications) =>
  notifications
    ? normalizeInitialNotificationsData(notifications)
    : initialNotificationsState;

export default function mapDataToGlobalState({
  user,
  account,
  sites,
  notifications,
  countries,
  onboarding,
  total,
}) {
  return {
    user: getUser(user),
    account: getAccount(account),
    global: getGlobal(sites, countries),
    notifications: getNotifications(notifications),
    onboarding: {
      server: onboarding,
      data: onboarding && onboarding.data ? onboarding.data : {},
      info: { total },
    },
  };
}
