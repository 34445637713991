// @flow
import * as React from 'react';

export type LightboxesContextType = {
  lightboxes: Array<string>,
  closing: Array<string>,
  registerLightbox: (name: string) => void,
  registerClosing: (name: string) => void,
  unregisterLightbox: (name: string) => void,
};

export const LightboxContext: React$Context<LightboxesContextType> =
  React.createContext<LightboxesContextType>({
    lightboxes: [],
    closing: [],
    registerLightbox: () => {},
    registerClosing: () => {},
    unregisterLightbox: () => {},
  });

type Props = {
  children: React.Node,
};

export function LightboxContextProvider({ children }: Props): React.Node {
  const [lightboxes, setLightboxes] = React.useState([]);
  const [closing, setClosing] = React.useState([]);

  const value: LightboxesContextType = React.useMemo(
    () => ({
      lightboxes,
      closing,
      registerLightbox: (name: string) => {
        setLightboxes((prev) => [...prev, name]);
      },
      registerClosing: (name: string) => {
        setClosing((prev) => [...prev, name]);
      },
      unregisterLightbox: (name: string) => {
        setLightboxes((prev) => prev.filter((l) => l !== name));
        setClosing((prev) => prev.filter((l) => l !== name));
      },
    }),
    [lightboxes, closing, setLightboxes, setClosing]
  );

  return (
    <LightboxContext.Provider value={value}>
      {children}
    </LightboxContext.Provider>
  );
}
