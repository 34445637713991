export const COLORS = [
  '#E62048',
  '#FF6932',
  '#F7B100',
  '#ACCF02',
  '#36CF57',
  '#02A2AC',
  '#3665F3',
  '#D11DA4',
  '#B03005',
  '#AA5404',
  '#4B7D06',
];

export function getFirstAvailableColor(disabledColors) {
  for (let i = 0; i < COLORS.length; i += 1) {
    if (disabledColors.indexOf(i) === -1) {
      return i;
    }
  }

  return -1;
}
