// @flow

import { sendEvent, payloadCreator } from '../utils/analytics';

const category = 'firstDailySession';
const createPayload = payloadCreator(category);

export default {
  ordersWaiting: (count: number): Promise<void> =>
    sendEvent(createPayload('ordersWaiting', undefined, count)),
};
