// @flow
import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

export type Props = {
  children?: React.Node,
  text?: React.Node,
  widgets?: React.Node,
  className?: string,
  multiline?: boolean,
  subheaderRef?: any,
  windowWidth?: ?number,
  isMobile?: boolean,
};

export default function PageSubheader({
  children,
  text,
  className,
  multiline,
  widgets,
  subheaderRef,
  windowWidth,
  isMobile,
}: Props): React.Node {
  const left = (
    <>
      {text && (
        <div className={cx(cs.text, { [cs.multiline]: multiline })}>{text}</div>
      )}
      {children && children}
    </>
  );

  return (
    <div className={cx(cs.pageSubheaderWrapper)}>
      <div
        className={cx(cs.pageSubheader, className)}
        ref={subheaderRef}
        style={{ width: isMobile && windowWidth }}
      >
        {multiline ? <div className={cs.container}>{left}</div> : left}
        {widgets && <div className={cs.widgets}>{widgets}</div>}
      </div>
    </div>
  );
}
