// @flow

import * as React from 'react';
import Field from '../field';
import Textarea, { type Props as TextareaProps } from '../textarea';

import withResolver from '../input-field/withResolver';

type Props = {
  ...TextareaProps,
  label: React.Node,
  description?: React.Node,
  error?: React.Node,
  grid?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  labelClassName?: string,
  errorClassName?: string,
  fieldRef?: React.Ref<any>,
  noGrid?: boolean,
};

function TextareaField({
  label,
  description,
  error,
  grid,
  labelClassName,
  fieldRef,
  noGrid,
  errorClassName,
  ...props
}: Props): React.Node {
  const refInput = React.useRef();

  const handleLabelClick: () => void = () => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  };

  return (
    <Field
      multiline
      innerRef={fieldRef}
      label={label}
      description={description}
      error={error}
      grid={grid}
      onLabelClick={handleLabelClick}
      labelClassName={labelClassName}
      noGrid={noGrid}
      errorClassName={errorClassName}
    >
      <Textarea innerRef={refInput} {...props} error={!!error} />
    </Field>
  );
}

export default (withResolver<React.Config<Props, any>>(
  TextareaField
): React.AbstractComponent<React.Config<Props, { ... }>>);
