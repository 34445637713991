import { nanoid } from 'nanoid';
import { path } from 'ramda';

import translateErrors from '../utils/i18n/errors-locale';
import translateNotifications from '../utils/i18n/notifications-locale';

import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../actions/notifications';

function createNotification(data, isClosable = false) {
  return { data, id: nanoid(), isClosable };
}

const getMessageKey = (type, messageId, params) => {
  if (type === 'error' || type === 'notice') {
    return translateErrors(messageId, params);
  }

  return translateNotifications(`${messageId}.text`, params);
};

const joinKeys = (messageId, key) => (key ? `${messageId}.${key}` : messageId);

const getButtons = (buttons, messageId) => {
  if (!buttons) return [];
  return buttons.map(({ key, href }) => ({
    title: translateNotifications(joinKeys(messageId, key)),
    href,
    type: 'link',
  }));
};

function initialDataMapper({
  type,
  messageId,
  params,
  buttons,
  ...additional
}) {
  const messageType = type || 'error';
  const message = getMessageKey(messageType, messageId, {
    ...(params || {}),
    ...additional,
  });
  const productId = path(['productId'], params);
  return createNotification({
    type: messageType,
    code: messageId,
    message,
    productId,
    ...additional,
    buttons: getButtons(buttons, messageId),
  });
}

// Public functions

export function normalizeInitialData(notificaions) {
  return { list: notificaions.map(initialDataMapper).filter((n) => n) };
}

export const initialState = { list: [] };

export default function notifications(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const { data, isClosable } = action;
      const notification = createNotification(data, isClosable);

      return { ...state, list: [...state.list, notification] };
    }
    case REMOVE_NOTIFICATION:
      return { ...state, list: state.list.filter((n) => n.id !== action.id) };
    default:
      return state;
  }
}
