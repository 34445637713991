// @flow

import { sendEvent } from '../utils/analytics';

export default {
  openProfileLightbox: (isNew: boolean): Promise<void> =>
    sendEvent({
      category: 'Shipping',
      action: isNew
        ? 'Open Policy Form from Add'
        : 'Open Policy Form from Edit',
    }),
  openExcludedCountriesLightbox: (): Promise<void> =>
    sendEvent({
      category: 'Shipping',
      action: 'Open Excluded Countries Lightbox',
    }),
  updateProfile: (
    isNew: boolean,
    label: 'Success' | 'Error' | 'Duplicate'
  ): Promise<void> =>
    sendEvent({
      category: 'Shipping',
      action: isNew ? 'Create Policy' : 'Update Policy',
      label,
    }),
  deleteProfile: (success: boolean): Promise<void> =>
    sendEvent({
      category: 'Shipping',
      action: 'Delete Policy',
      label: success ? 'Success' : 'Error',
    }),
  goToHelpCenter: (location: string): Promise<void> =>
    sendEvent({
      category: 'Shipping',
      action: 'Click on HelpCenter article link',
      label: `From: ${location}.`,
    }),
};
