// @flow

import * as React from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: React.Node,
  id: string,
};

const createMountPoint = (id) => {
  const current = document.getElementById(id);
  if (current) {
    return current;
  }

  const element = document.createElement('div');
  element.id = id;

  if (document.body) {
    document.body.appendChild(element);
  }
  return element;
};

const Portal = ({ children, id }: Props): any =>
  createPortal(children, createMountPoint(id));

export default Portal;
