// @flow
import * as React from 'react';
import { update } from 'ramda';

import translateErrors from '../../utils/i18n/errors-locale';
import { fetchFile } from '../../utils/http';

import type { Upload as UT } from './types';

function upsertUpload(uploads, item) {
  const index = uploads.findIndex((u) => u.id === item.id);
  if (index === -1) {
    // If item.state is not 'waiting' then it looks like a deleted item that notifies about changes
    if (item.state === 'waiting') {
      return [...uploads, item];
    }
    return uploads;
  }
  return update(index, item, uploads);
}

type Options = {|
  maxUploads?: number,
  form: { action: string, method: string },
|};

const defaults = {
  maxUploads: 5,
};

function uploadsCount(uploads) {
  return uploads.filter((u) => u.state !== 'error').length;
}

export function useFileUpload(options: Options): any {
  const maxUploads = (options && options.maxUploads) || defaults.maxUploads;
  const [uploads, setUploads] = React.useState([]);

  const deleteUpload = (uploadId: string): void => {
    setUploads((prev: Array<UT>): Array<UT> =>
      prev.filter((u) => u.id !== uploadId)
    );
  };

  const handleUpload = (files: Array<File> | FileList) => {
    Promise.all(
      [...files].slice(0, maxUploads - uploadsCount(uploads)).map((file) => {
        const body = new window.FormData();
        body.append('file', file);

        const fileUpload = { state: 'waiting', id: file.name, file };
        setUploads((prevUploads) => upsertUpload(prevUploads, fileUpload));

        return fetchFile(options.form.action, {
          method: options.form.method,
          body,
        })
          .then(({ ok, data }) => {
            if (ok) {
              setUploads((prevUploads) =>
                upsertUpload(prevUploads, {
                  ...fileUpload,
                  state: 'finished',
                  meta: { url: data.url },
                })
              );
              return Promise.resolve(data.url);
            }

            return Promise.reject(data && data.error);
          })
          .catch((data) => {
            setUploads((prevUploads) =>
              upsertUpload(prevUploads, {
                ...fileUpload,
                state: 'error',
                error:
                  (data && data.toString()) || translateErrors('generic_error'),
              })
            );
          });
      })
    );
  };

  const reset = (): void => {
    setUploads([]);
  };

  return {
    uploads,
    handleUpload,
    deleteUpload,
    reset,
    isCompleted: uploads.every(
      (u) => u.state === 'finished' || u.state === 'error'
    ),
    limitReached: uploadsCount(uploads) >= maxUploads,
  };
}
