export default function findAncestorByCSSProperty(
  rootElement,
  property,
  value
) {
  let styles;
  let currentElement = rootElement;

  do {
    currentElement = currentElement.parentElement;
    if (!currentElement) return document.body;
    styles = window.getComputedStyle(currentElement);
  } while (styles.getPropertyValue(property) !== value);

  return currentElement;
}
