// @flow
import * as React from 'react';
import cx from 'classnames';

import { logError } from '../../utils/sentry';
import Icon from '../icon';

import * as iconsNormal from './icons-16';
import * as iconsBig from './icons-48';
import cs from './styles.pcss';

type Props = {
  name: string,
  size?: 16 | 24 | 28 | 32 | 36 | 48,
  wrapperClassName?: string,
  className?: string,
  disabled?: boolean,
  paid?: boolean,
};

export default function FlagIcon({
  name,
  size = 16,
  className,
  wrapperClassName,
  disabled,
  paid,
}: Props): React.Node {
  const uppercaseName = name.toUpperCase();

  const IconSvg =
    // $FlowFixMe
    (size === 28 || size === 32 || size === 36 || size === 48) &&
    iconsBig[uppercaseName]
      ? iconsBig[uppercaseName]
      : // $FlowFixMe
        iconsNormal[uppercaseName];

  if (!IconSvg) {
    const err = new Error(`Can not find flag icon "${name}" in "${size}" size`);
    logError(err);
    return null;
  }

  return (
    <div
      className={cx(
        cs.wrapper,
        cs[`icon${size}`],
        { [cs.disabled]: disabled },
        wrapperClassName
      )}
    >
      <IconSvg className={cx(cs.flagIcon, className, cs[`icon${size}`])} />
      {paid && <Icon name="paid" className={cx(cs.paid, cs[`paid${size}`])} />}
    </div>
  );
}
