// @flow

import { sendEvent } from '../utils/analytics';

export default {
  show: (): Promise<void> => sendEvent({ category: 'Upload', action: 'Show' }),
  success: (): Promise<void> =>
    sendEvent({ category: 'Upload', action: 'Success' }),
  error: (label?: string): Promise<void> =>
    sendEvent({ category: 'Upload', action: 'Error', label }),
};
