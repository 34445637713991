// @flow

import * as React from 'react';
import { mixToPropHandler } from '../mix-to-prop-handler';

type Props = {
  onMouseDown?: (e: MouseEvent) => void,
  onMouseUp?: (e: MouseEvent) => void,
  onFocus?: (e: FocusEvent) => void,
};

export const handleFocus = (
  props: Props
): ({|
  onFocus: (...args: Array<mixed>) => any,
  onMouseDown: (...args: Array<mixed>) => any,
  onMouseUp: (...args: Array<mixed>) => any,
|}) => {
  let mouseIsDown = false;

  return {
    onMouseDown: mixToPropHandler(props.onMouseDown, () => {
      mouseIsDown = true;
    }),
    onMouseUp: mixToPropHandler(props.onMouseUp, () =>
      requestAnimationFrame(() => {
        mouseIsDown = false;
      })
    ),
    onFocus: mixToPropHandler(props.onFocus, (e) => {
      if (mouseIsDown && e.currentTarget) {
        e.currentTarget.blur();
      }
    }),
  };
};

export default function catchMouseFocus(
  Component: React.ComponentType<any>
): (props: Props) => React.Node {
  function CatchMouseFocusHOC(props: Props) {
    return <Component {...props} {...handleFocus(props)} />;
  }

  CatchMouseFocusHOC.displayName = 'catchMouseFocus()';

  return CatchMouseFocusHOC;
}
