// @flow
import * as React from 'react';

import ApolloLightbox from '../../components/apollo-lightbox';
import { useApolloLightbox } from '../../hooks/use-apollo-lightbox';
import Button from '../button';
import type { Props as ButtonProps } from '../button';
import ANALYTICS from '../../analytics/happy-lightbox';

import HappyUsd from './happy-usd.svg';
import cs from './styles.pcss';

type Props = {
  id: string,
  title: string,
  text: string,
  analyticsPrefix: string,
  buttonProps: ButtonProps,
  setPersistState: (state: string) => void,
};

export default function HappyLightbox({
  id,
  title,
  text,
  analyticsPrefix,
  buttonProps,
  setPersistState,
}: Props): React.Node {
  const { openLightbox } = useApolloLightbox(id);

  React.useEffect(() => {
    openLightbox();
    ANALYTICS.show(analyticsPrefix);
  }, []);

  const handleClose = React.useCallback(() => {
    setPersistState('viewed');
    ANALYTICS.close(analyticsPrefix);
  }, [setPersistState, analyticsPrefix]);

  const { onClick, ...buttonRestProps } = buttonProps;

  const hadleClick = React.useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
      ANALYTICS.use(analyticsPrefix);
      setPersistState('viewed');
    },
    [onClick, analyticsPrefix, setPersistState]
  );

  return (
    <ApolloLightbox
      happy
      className={cs.happyLightbox}
      onClose={handleClose}
      id={id}
    >
      <HappyUsd className={cs.icon} />
      <div className={cs.title}>{title}</div>
      <div className={cs.text}>{text}</div>
      {/* $FlowFixMe */}
      <Button className={cs.button} onClick={hadleClick} {...buttonRestProps} />
    </ApolloLightbox>
  );
}
