import { normalize, schema } from 'normalizr';

// Normalizr schemas

const siteSchema = new schema.Entity('sites');

const sitesSchema = [siteSchema];

// Final data

export function normalizeGlobal(sites) {
  let result = {};

  if (sites) {
    result = { ...normalize(sites, sitesSchema).entities };
  }

  return result;
}
