// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import { nanoid } from 'nanoid';

import Icon from '../icon';
import Loader from '../loader';
import Overlay from '../overlay';
import useWindowDimensions from '../../hooks/use-window-dimensions';
import cs from './styles.pcss';
import { LightboxContext } from './context';

const cx = classNames.bind(cs);
const WINDOW_HEIGHT_IS_TOO_SMALL = 400;

const getAnimation = (isClosing, isUnderTopLightbox, unstacking, happy) => {
  if (happy) return 'happy';
  return !isClosing && (isUnderTopLightbox || unstacking) ? 'fade' : 'slide';
};

function getLightboxProps(
  lightboxes: Array<string>,
  closing: Array<string>,
  id: string,
  happy?: boolean
) {
  const openedCount = lightboxes.length;
  const topLightbox = lightboxes[openedCount - 1];
  const isUnderTopLightbox =
    openedCount > 1 && lightboxes[openedCount - 2] === id;
  const isClosing = closing.includes(id);
  const topLightboxIsClosing = closing.includes(topLightbox);
  const unstacking = topLightboxIsClosing && isUnderTopLightbox;

  return {
    // `slide` animation for a top lightbox and `fade` for show/hide effects for lightboxes underneath
    animation: getAnimation(isClosing, isUnderTopLightbox, unstacking, happy),
    // lightbox is stacked when it is under other lightbox
    stacked: !(topLightbox === id),
    // lightbox is unstacking when it is second to the top and top lightbox is closing
    unstacking,
    // it is top lightbox and it is closing
    isClosing,
    // Show background only for the very first lightbox
    hideOverlay: openedCount > 1 && lightboxes[0] !== id,
  };
}

export type Props = {
  dangerousHeader?: boolean,
  lightboxName?: string,
  header?: React.Node,
  loading?: boolean,
  blurContent?: boolean,
  children: React.Node,
  onCloseStart?: () => void,
  onClose?: () => void | Promise<void>,
  footer?: React.Node | ((closeFn: () => Promise<void>) => React.Node),
  small?: boolean,
  className?: string,
  scrollareaId?: string,
  contentClass?: string,
  opened?: boolean,
  contentIsOutOfFlow?: boolean,

  /**
   * happy mode is a special mode to illustrate achievements.
   * It has a different animation, header, footer and text styles
   */
  happy?: boolean,
};

export default function Lightbox({
  children,
  dangerousHeader = false,
  header,
  footer,
  loading,
  blurContent = false,
  small,
  className,
  scrollareaId,
  contentClass,
  onClose,
  happy,
  lightboxName,
  onCloseStart,
  opened,
  contentIsOutOfFlow = false,
}: Props): React.Node {
  const {
    lightboxes,
    closing,
    registerLightbox,
    unregisterLightbox,
    registerClosing,
  } = React.useContext(LightboxContext);
  const { height } = useWindowDimensions();
  const [initialLoading, setInitialLoading] = React.useState(loading);

  const lightboxId = React.useRef<string>(lightboxName || nanoid());

  React.useEffect(() => {
    if (opened) {
      registerLightbox(lightboxId.current);
    } else {
      unregisterLightbox(lightboxId.current);
    }
  }, [opened]);

  const handleEntered = () => setInitialLoading(false);

  const handleOverlayCloseStart = () => {
    if (onCloseStart) {
      onCloseStart();
    }
    registerClosing(lightboxId.current);
  };

  const renderFooter = (hide: () => Promise<void>) =>
    typeof footer === 'function' ? footer(hide) : footer;

  const { animation, stacked, unstacking, hideOverlay, isClosing } =
    getLightboxProps(lightboxes, closing, lightboxId.current, happy);

  const show = (!stacked && !isClosing) || unstacking;
  const isLoading = loading || initialLoading;

  if (!opened) {
    return null;
  }

  return (
    <Overlay
      onCloseStart={handleOverlayCloseStart}
      onCloseEnd={() => {
        unregisterLightbox(lightboxId.current);
        if (onClose) {
          onClose();
        }
      }}
      transparent={hideOverlay}
    >
      {(closeOverlay) => (
        <CSSTransition
          appear
          classNames={{
            appear: cx(`${animation}Enter`),
            appearActive: cx(`${animation}EnterActive`),
            enter: cx(`${animation}Enter`),
            enterActive: cx(`${animation}EnterActive`),
            exit: cx(`${animation}Exit`),
            exitActive: cx(`${animation}ExitActive`),
          }}
          in={show}
          timeout={300}
          onEntered={handleEntered}
        >
          <div
            className={cx(
              'wrapper',
              {
                small: small || happy,
                stacked: stacked && !unstacking,
                happy,
              },
              className
            )}
            data-testid={lightboxName}
          >
            <div className={cx('header', { dangerousHeader, happy })}>
              <div>{header}</div>
              <button
                type="button"
                autoFocus={false} //eslint-disable-line
                className={cx('close', { dangerousHeader, happy })}
                onClick={closeOverlay}
              >
                <Icon className={cx('closeIcon')} name="cancel" size={24} />
              </button>
            </div>
            <div
              className={cx('contentWrapper', contentClass)}
              id={scrollareaId || ''}
            >
              <div
                className={cx('content', {
                  centered: isLoading,
                  happy,
                })}
              >
                <div
                  className={cx(
                    'blurredOverlay',
                    blurContent && 'blurredOverlay_visible'
                  )}
                />
                {isLoading ? <Loader size={48} /> : children}
                {height &&
                  height <= WINDOW_HEIGHT_IS_TOO_SMALL &&
                  footer &&
                  !contentIsOutOfFlow && (
                    <div className={cx('footer')}>
                      {!isLoading && renderFooter(closeOverlay)}
                    </div>
                  )}
              </div>
            </div>
            {height &&
              footer &&
              (height > WINDOW_HEIGHT_IS_TOO_SMALL || contentIsOutOfFlow) && (
                <div className={cx('footer')}>
                  {!isLoading && renderFooter(closeOverlay)}
                </div>
              )}
          </div>
        </CSSTransition>
      )}
    </Overlay>
  );
}
