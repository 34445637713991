// @flow
import * as React from 'react';
import { identity } from 'ramda';
import classNames from 'classnames/bind';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

export type Props = {
  children: React.Node,
  onMouseEnter?: (SyntheticEvent<HTMLDivElement>) => void,
  onMouseLeave?: (SyntheticEvent<HTMLDivElement>) => void,
  onFocus?: (SyntheticEvent<HTMLDivElement>) => void,
  withActiveElements?: boolean,
  onBlur?: (SyntheticEvent<HTMLDivElement>) => void,
  selected?: boolean,
  success?: boolean,
  error?: boolean,
  onClick?: (event: SyntheticEvent<HTMLDivElement>) => void,
  className?: string,
  contentClassName?: string,
  disabled?: boolean,
  changed?: boolean,
  tabIndex?: number,
  maxWidth?: boolean,
  largePadding?: boolean,
  innerRef?: React.Ref<any>,
  noPadding?: boolean,
  style?: any,
};

type ClickHandler = (SyntheticEvent<HTMLDivElement>) => void;

const createHandler = (clickHandler: ?ClickHandler) => {
  if (!clickHandler) return identity;

  return function handleClick(event) {
    if (event.key === 'Enter') {
      if (clickHandler) {
        clickHandler(event);
      }
    }
  };
};

export default function Row({
  children,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  withActiveElements = true,
  onBlur,
  selected,
  success,
  changed,
  error,
  onClick,
  className,
  contentClassName,
  disabled,
  tabIndex = 0,
  maxWidth,
  largePadding,
  innerRef,
  noPadding,
  style,
}: Props): React.Node {
  const additionalProps =
    withActiveElements && !disabled
      ? {
          tabIndex,
          onMouseEnter,
          onMouseLeave,
          onFocus,
          onBlur,
          onClick,
          onKeyPress: createHandler(onClick),
        }
      : {};
  return (
    // eslint-disable-next-line
    <div
      {...additionalProps}
      role="row"
      className={cx('row', className, {
        withActiveElements,
        success,
        selected,
        error,
        disabled,
        changed,
        largePadding,
        noPadding,
      })}
      ref={innerRef}
      style={style}
    >
      <div className={cx('content', { maxWidth }, contentClassName)}>
        {children}
      </div>
    </div>
  );
}
