// @flow
import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

type Props = {
  children: React.Node,
  className?: string,
  virtualized?: boolean,
};

function Table({ children, className, virtualized }: Props): React.Node {
  return (
    <div
      role="table"
      className={cx(cs.table, { [cs.virtualized]: virtualized }, className)}
    >
      {children}
    </div>
  );
}

export { default as Body } from './body';
export { default as HeaderRow } from './header-row';
export { default as HeaderCell } from './header-cell';
export { default as Row } from './row';
export { default as Cell } from './cell';

export default Table;
