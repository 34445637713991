import { combineReducers } from '../utils/data-helpers';
import notifications from './notifications';
import lightbox from './lightbox';
import onboarding from './onboarding';

export default combineReducers({
  notifications,
  lightbox,
  onboarding,
});
