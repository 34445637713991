// @flow
import * as React from 'react';
import { path, pathOr } from 'ramda';
import { useQuery } from '@apollo/client';

import keys from '../../settings/storage';
import HappyLightbox from '../../basic-components/happy-lightbox';
import { useApolloLightbox } from '../../hooks/use-apollo-lightbox';
import useFeature from '../../hooks/use-feature';
import usePersistState from '../../hooks/use-persist-state';

import {
  HapyLighboxSalesData,
  NotPublishedProducts,
  NotPublishedRecommendedProducts,
} from './operations.graphql';
import t from './locale';
import { usePublishRecommended } from './use-publish-recommended';

const PUBLISH_ITEMS_COUNT = 50;

const getActiveSitesIds = (result, showcase) => {
  if (!showcase.site || !showcase.active) {
    return result;
  }
  return [...result, showcase.site.id];
};

export default function HappyLightboxSales(): React.Node {
  const feature = useFeature('full_imported_best_seller');

  const [persistState, setPersistState] = usePersistState(
    keys.happySalesLightbox
  );

  const { data } = useQuery(HapyLighboxSalesData, {
    skip: !feature || persistState === 'viewed',
  });

  const { closeLightbox } = useApolloLightbox(keys.happySalesLightbox);

  const activeSitesIds = pathOr([], ['showcases'], data).reduce(
    getActiveSitesIds,
    []
  );

  const addedProfitStatus = pathOr([], ['viewer', 'addedProfitStatus'], data);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentYearProfit = addedProfitStatus.find(
    (s) => s.year === currentYear
  );
  const ordersCount = currentYearProfit ? currentYearProfit.ordersCount : 0;

  const recommendedMax = path(['viewer', 'recommendedMax'], data);

  const { data: productsTotalData } = useQuery(NotPublishedProducts, {
    skip: !feature || !data || !ordersCount,
    variables: {
      filters: {
        archived: false,
        selectedOnSiteId: activeSitesIds,
        status: 'unpublished',
      },
    },
  });

  const { data: productsData } = useQuery(NotPublishedRecommendedProducts, {
    skip: !feature || !data || !ordersCount,
    variables: {
      first: PUBLISH_ITEMS_COUNT,
      filters: {
        archived: false,
        recommended: recommendedMax,
        rating: true,
        selectedOnSiteId: activeSitesIds,
        status: 'unpublished',
      },
    },
  });

  const totalNotPublished = pathOr(
    0,
    ['products', 'totalCount'],
    productsTotalData
  );
  const totalNotPublishedRecommended = pathOr(
    0,
    ['products', 'totalCount'],
    productsData
  );

  // Publish 30% of not published products, only from recommended, and less than 50
  const maxPublishCount = Math.min(
    Math.round(totalNotPublished * 0.3),
    totalNotPublishedRecommended,
    PUBLISH_ITEMS_COUNT
  );

  const [publish, loading] = usePublishRecommended(
    productsData,
    activeSitesIds,
    closeLightbox,
    maxPublishCount
  );

  if (
    !ordersCount ||
    !productsData ||
    !productsData.products ||
    !productsTotalData ||
    !productsTotalData.products
  ) {
    return null;
  }

  const buttonProps = maxPublishCount
    ? {
        onClick: publish,
        children: t('button.publish', {
          count: maxPublishCount,
        }),
        loading,
      }
    : {
        onClick: closeLightbox,
        children: t('button.allPublished'),
      };

  return (
    <HappyLightbox
      id={keys.happySalesLightbox}
      title={t('title', { count: ordersCount })}
      text={t('text')}
      buttonProps={buttonProps}
      analyticsPrefix="salesResults"
      setPersistState={setPersistState}
    />
  );
}
