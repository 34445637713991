// @flow

import * as React from 'react';
import { compose, isNil } from 'ramda';
import Select from '../../../basic-components/select';
import withFiltersContext from '../with-filters-context';
import withLabel from '../with-label';

type PropsT = {
  onChange: (?string | ?number, string) => void,
  isMobile?: boolean,
  adaptive?: boolean,
};

const setField = (value) => {
  if (isNil(value)) return null;
  return value;
};

function OptionsField({ onChange, isMobile, adaptive, ...restProps }: PropsT) {
  return (
    // $FlowFixMe
    <Select
      isMobile={isMobile}
      adaptive={adaptive}
      size={isMobile && adaptive ? 'medium' : 'small'}
      onChange={(option) => onChange(setField(option.value), option)}
      {...restProps}
    />
  );
}

// $FlowFixMe
export default compose(withLabel, withFiltersContext)(OptionsField);
