export default {
  search: null,
  sku: null,
  price: { min: null, max: null },
  quantity: { min: null, max: null },
  state: null,
  categoryIds: [],
  problems: [],
  withSales: undefined,
  rating: false,
};
