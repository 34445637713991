// @flow
import * as React from 'react';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createFocusOnError from 'final-form-focus';
import classNames from 'classnames/bind';
import { path, isNil } from 'ramda';
import { useReactiveVar } from '@apollo/client';

import { shipping } from '../../settings';

import { convertGQLErrors, isEqualById } from '../../utils/form';
import translateErrors from '../../utils/i18n/errors-locale';

import { FormField, FieldValue, FieldError } from '../../hocs/final-form';
import { useApolloLightbox } from '../../hooks/use-apollo-lightbox';
import useMediaQuery from '../../hooks/use-media-query';

import ApolloLightbox from '../apollo-lightbox';
import InputField from '../../basic-components/input-field';
import Button from '../../basic-components/button';
import SelectField from '../../basic-components/select-field';
import ColorSelectorField from '../color-selector-field';
import FieldSet from '../field-set';
import FieldSetDescription from '../field-set-description';
import FieldSetTitle from '../field-set-title';
import ThreeColumnsLayout from '../../basic-components/three-columns-layout';
import Errors from '../errors';
import Link from '../../basic-components/link';
import ExcludedCountriesLightbox from '../shipping-excluded-countries-lightbox';
import ShippingPoliciesTooltip from '../shipping-policies-tooltip';
import { selectedShippingProfileVar } from '../../utils/apollo-cache';

import Tariffs from './tariffs';
import PaidByField from './paid-by-field';
import LocationField from './location-field';
import ExcludedCountries from './excluded-countries';
import { ProfileTabs, GENERAL_TAB_NAME, DEFAULT_TABS } from './profile-tabs';
import CustomProfileWithShowHide from './custom-profile/withShowHide';

import {
  createProfileInput,
  getFirstLetter,
  findOriginalSite,
} from './profile-utils';
import t from './locale';
import cs from './styles.pcss';

import type { FormProfile } from './profile-utils';

import type {
  ProfileInput,
  ShippingProfileSave as ShippingProfileSaveResult,
  ShippingProfileSave_upsertProfile_profile as SavedProfile,
  ShippingProfileAdditional_regions as Regions,
} from '../../typings/product-query.flow';

const cx = classNames.bind(cs);

const TabMapping = {
  us: 0,
  uk: 3,
  de: 77,
  fr: 71,
  it: 101,
  es: 186,
  au: 15,
  ca: 2,
};

type Props = {
  shippingProfile: ?FormProfile,
  countries: Array<Regions>,
  defaultCurrency: string,
  disabledColorsMap: { [string]: Array<number> },
  shippingDestinations: { [string]: string },
  loading: boolean,
  error: boolean,
  onSave: (
    changes: ProfileInput
  ) => Promise<{ data: ShippingProfileSaveResult }>,
  onSaveCompleted?: (profile: SavedProfile) => void,
  duplicateProlife: string | null,
  showcases: any,
  cacheCount: number,
};

const getShippingTypes = () => [
  { label: t('fields.type.options.free'), value: shipping.pricing.free },
  { label: t('fields.type.options.flat'), value: shipping.pricing.flat },
  { label: t('fields.type.options.custom'), value: shipping.pricing.custom },
];

const getReturnOptions = () =>
  shipping.returnsOptions.map((days) => ({
    label: t(`fields.returnsWithin.options.${days ? 'days' : 'no'}`, {
      count: days,
    }),
    value: days,
    hidden: [3, 7].includes(days),
  }));

const DISPATCH_TIME_OPTIONS = shipping.dispatchTimeOptions.map((days) => ({
  label: String(days),
  value: days,
}));

const getCountries = (regions: Array<Regions>) =>
  regions
    .map(({ code, name }) => ({
      label: name,
      value: code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

const getCountriesMap = (regions: Array<Regions>) =>
  regions.reduce(
    (acc: any, { code, name }: Regions) => ({
      ...acc,
      [code]: name,
    }),
    {}
  );

const focusOnErrors = createFocusOnError();

const renderFooter =
  (
    duplicateProlife: string | null,
    handleSubmit?: any,
    submitError?: boolean,
    submitting?: boolean,
    mediumScreen: boolean = true,
    errorHidden: boolean = false
  ) =>
  (closeOverlay: () => Promise<void>) =>
    (
      <>
        <ThreeColumnsLayout
          left={
            <Button
              onClick={closeOverlay}
              mode="secondary"
              size={mediumScreen ? 'medium' : 'inModal'}
            >
              {t('buttons.close')}
            </Button>
          }
          center={
            <Button
              loading={submitting}
              onClick={handleSubmit}
              size={mediumScreen ? 'medium' : 'inModal'}
            >
              {t(duplicateProlife ? 'buttons.save' : 'buttons.applyChanges')}
            </Button>
          }
          className={cs.footerWrapper}
        />
        {duplicateProlife && (
          <Errors
            compact
            errors={[
              <div>
                {`${t('duplicateError.reason')}: `}
                <span className={cs.duplicateTitle}>{duplicateProlife}.</span>
                <br />
                {t('duplicateError.tip')}.
              </div>,
            ]}
            className={cx('globalErrors')}
            hidden={errorHidden}
          />
        )}
        {!duplicateProlife && submitError && (
          <Errors
            compact
            errors={[submitError]}
            className={cx('globalErrors')}
            hidden={errorHidden}
          />
        )}
      </>
    );

function ShippingProfileLightboxForm({
  shippingProfile,
  countries,
  defaultCurrency,
  disabledColorsMap,
  shippingDestinations,
  loading,
  error,
  onSave,
  onSaveCompleted = () => {},
  duplicateProlife,
  showcases,
  cacheCount,
}: Props): React.Node {
  const veryLargeScreen = useMediaQuery(`(min-width: 1280px)`);
  const mediumScreen = useMediaQuery(`(min-width: 768px)`);
  const { closeLightbox } = useApolloLightbox('ShippingProfile');
  const profileId = useReactiveVar(selectedShippingProfileVar);
  const newProfile = !profileId || profileId === '-1';
  const profile = shippingProfile || {};
  const countriesMap = getCountriesMap(countries);

  const {
    // DB中所有domestic shipping service 可选列表
    domesticShippingServices = [],
    // DB中所有domestic shipping service 可选列表
    internationalShippingServices = [],
    // Mag根据General Policy计算得出的8大站点ebay shipping policy
    shippingEbayProfiles: computedEbayProfiles = [],
    // 功能展示，暂时白名单控制
    whiteList = false,
    originalShippingPolicy = null,
  } = profile;

  // const originalSite = findOriginalSite(profile);
  const originalSite = findOriginalSite(originalShippingPolicy);
  const notBig8Site =
    originalSite !== 'unknown' &&
    !Object.values(TabMapping).includes(originalSite);
  const profileTabNames = notBig8Site
    ? [...DEFAULT_TABS, 'other']
    : [...DEFAULT_TABS];

  const [selectedTab, setSelectedTab] = React.useState(GENERAL_TAB_NAME);
  const isGeneralProfileTab = selectedTab === GENERAL_TAB_NAME;
  const [shippingEbayProfiles, setShippingEbayProfiles] = React.useState([]);
  const [errorHidden, setErrorHidden] = React.useState(false);

  React.useEffect(() => {
    setShippingEbayProfiles([]);
    setSelectedTab(GENERAL_TAB_NAME);
    setErrorHidden(true);
  }, [profileId, cacheCount]);

  if (error) {
    return null;
  }

  const selectTab = (tabName: string) => {
    setSelectedTab(tabName);
    if (tabName !== GENERAL_TAB_NAME) {
      const siteId = TabMapping[tabName];
      let existed;

      // 如果Mag已经生成了eBay profile, computedEbayProfiles是非空数组
      if (
        shippingEbayProfiles.length === 0 &&
        computedEbayProfiles.length > 0
      ) {
        existed = computedEbayProfiles.find((item) => item.siteId === siteId);
        // 这里应该只会执行一次
        if (existed) {
          // 更新ShippingEbayProfiles，后面都会用ShippingEbayProfiles查询
          setShippingEbayProfiles([...computedEbayProfiles]);
          return;
        }
      }

      // 1. Mag还没有生成ebay profile， computedEbayProfiles为[]，每个tab都会call api
      // 2. 或者computedEbayProfiles有值，但没有当前tab的信息，也会call api
      existed = shippingEbayProfiles.find((item) => item.siteId === siteId);
      if (!existed) {
        fetch(`/shipping/ebay_profile/${profileId}/${siteId}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.id) {
              setShippingEbayProfiles((current) => [...current, data]);
            }
          });
      }
    }
  };

  return (
    <Form
      initialValues={profile}
      initialValuesEqual={isEqualById}
      subscription={{
        submitting: true,
        submitError: true,
      }} // rerender form component only when submitting is changed
      mutators={{ ...arrayMutators }}
      decorators={[focusOnErrors]}
      onSubmit={(data, form) =>
        // console.log('Submit form data', data);
        onSave(createProfileInput(data))
          .then((mutationData) => {
            if (!mutationData) {
              throw new Error();
            }

            const savedProfile = path(
              ['data', 'upsertProfile', 'profile'],
              mutationData
            );

            const errors = path(
              ['data', 'upsertProfile', 'errors'],
              mutationData
            );

            if (errors && errors.length > 0) {
              setErrorHidden(false);
              return convertGQLErrors(errors, FORM_ERROR);
            }

            if (savedProfile) {
              onSaveCompleted(savedProfile);
            }

            closeLightbox();
            if (newProfile) {
              form.reset();
            }
            return null;
          })
          .catch(() => ({
            [FORM_ERROR]: translateErrors('generic_error'),
          }))
      }
    >
      {({ handleSubmit, submitError, submitting }) => (
        <ApolloLightbox
          id="ShippingProfile"
          header={newProfile ? t('title.create') : profile.title}
          loading={loading}
          footer={renderFooter(
            duplicateProlife,
            handleSubmit,
            submitError,
            submitting,
            mediumScreen,
            errorHidden
          )}
        >
          {!newProfile && whiteList && (
            <ProfileTabs
              names={profileTabNames}
              selectedTab={selectedTab}
              onClick={selectTab}
            />
          )}

          {/* General policy setting */}
          <form
            className={cx(cs.wrapper)}
            onSubmit={(e) => e.preventDefault()}
            style={{ display: isGeneralProfileTab ? 'block' : 'none' }}
          >
            {profile.id ? (
              <div className={cx('profileId')}>Policy ID: {profile.id}</div>
            ) : null}

            <FieldSet delimiter="wide">
              <FieldSetDescription>
                {t('formDescription')}
                <br />
                <br />
                {t('statement')}
              </FieldSetDescription>
              <FieldSetTitle leftColumn="1.">
                <div className={cs.subtitleContainer}>
                  {t('subtitle.description')}
                  <ShippingPoliciesTooltip
                    location="Create policy popup. Policy description."
                    noArrow
                  />
                </div>
              </FieldSetTitle>
              <FormField
                name="title"
                validate={(val) =>
                  !val || !val.trim() ? t('fields.title.validate.empty') : null
                }
                Component={InputField}
                label={t('fields.title.label')}
                description={t('fields.title.description')}
                placeholder={t('fields.title.placeholder')}
                noGrid={!veryLargeScreen}
              />
              <FormField
                name="dispatchTime"
                validate={(val) =>
                  isNil(val) ? t('fields.dispatchTime.validate.empty') : null
                }
                Component={SelectField}
                options={DISPATCH_TIME_OPTIONS}
                label={
                  <>
                    <div>{t('fields.dispatchTime.label')},</div>
                    <div>{t('workingDays')}</div>
                  </>
                }
                description={t('fields.dispatchTime.description')}
                placeholder={t('fields.dispatchTime.placeholder')}
                grid={6}
                onChange={({ value }) => value}
                searchable={false}
                noGrid={!veryLargeScreen}
              />
              <FieldValue name="title">
                {(title) => (
                  <FormField
                    name="color"
                    Component={ColorSelectorField}
                    label={t('fields.color.label')}
                    description={t('fields.color.description')}
                    grid={8}
                    disabledColors={
                      disabledColorsMap[getFirstLetter(title)] || []
                    }
                    noGrid={!veryLargeScreen}
                  />
                )}
              </FieldValue>
            </FieldSet>

            <FieldSet delimiter="wide" className={cx('tariffs')}>
              <FieldSetTitle leftColumn="2.">
                <div className={cs.subtitleContainer}>
                  {t('subtitle.tariffs')}
                  <ShippingPoliciesTooltip
                    location="Create policy popup. Tariffs."
                    noArrow
                  />
                </div>
              </FieldSetTitle>
              <FormField
                name="type"
                Component={SelectField}
                label={t('fields.type.label')}
                options={getShippingTypes()}
                onChange={({ value }) => value}
                noGrid={!veryLargeScreen}
                fieldClassName={cs.fieldOnSmallScreen}
              />

              <FieldValue name="type">
                {(value) => (
                  <FieldError name="tariffs" noGrid={!veryLargeScreen}>
                    {(tariffError) => (
                      <Tariffs
                        type={value}
                        defaultCurrency={defaultCurrency}
                        shippingDestinations={shippingDestinations}
                        error={tariffError}
                        veryLargeScreen={veryLargeScreen}
                      />
                    )}
                  </FieldError>
                )}
              </FieldValue>
            </FieldSet>

            <ExcludedCountries
              name="excludedCountries"
              countries={countriesMap}
              veryLargeScreen={veryLargeScreen}
            />

            <FieldSet delimiter="wide">
              <FieldSetTitle leftColumn="3.">
                <div className={cs.subtitleContainer}>
                  {t('subtitle.returns')}
                  <ShippingPoliciesTooltip
                    location="Create policy popup. Item location and return options."
                    noArrow
                  />
                </div>
              </FieldSetTitle>
              <FormField
                name="country"
                validate={(val) =>
                  !val || !val.trim()
                    ? t('fields.country.validate.empty')
                    : null
                }
                Component={SelectField}
                searchable
                options={getCountries(countries)}
                label={t('fields.country.label')}
                onChange={({ value }) => value}
                fieldClassName={cs.fieldOnSmallScreen}
              />
              {/* Combined field for `city` and `postalCode` inputs */}
              <LocationField fieldClassname={cs.locationField} />
              <FormField
                name="returnsWithin"
                Component={SelectField}
                options={getReturnOptions()}
                label={t('fields.returnsWithin.label')}
                description={
                  <div>
                    {t('fields.returnsWithin.description')}{' '}
                    <Link
                      href="https://www.ebay.com/pages/help/sell/top-rated.html"
                      target="_blank"
                    >
                      {t('fields.returnsWithin.link')}
                    </Link>
                  </div>
                }
                onChange={({ value }) => value}
                fieldClassName={cs.fieldOnSmallScreen}
              />
              <FieldValue name="returnsWithin">
                {(returnsWithin) =>
                  returnsWithin !== 0 && (
                    <FormField
                      name="returnsPaidByBuyer"
                      type="checkbox"
                      Component={PaidByField}
                      label={t('fields.returnsPaidByBuyer.label')}
                      fieldClassName={cs.fieldOnSmallScreen}
                    />
                  )
                }
              </FieldValue>
            </FieldSet>

            <FormField
              name="excludedCountries"
              Component={ExcludedCountriesLightbox}
            />
          </form>

          {/* FIXME：Clumsy and repetitive writing */}
          {!newProfile && whiteList && (
            <>
              <CustomProfileWithShowHide
                key="cp-us"
                visible={!isGeneralProfileTab && selectedTab === 'us'}
                profileId={profile.id}
                name="us"
                siteId={0}
                currency="USD"
                domesticShippingServices={domesticShippingServices}
                internationalShippingServices={internationalShippingServices}
                shippingEbayProfiles={shippingEbayProfiles}
                showcases={showcases}
                isOriginalSite={originalSite === 0}
                originalShippingPolicy={originalShippingPolicy}
              />
              <CustomProfileWithShowHide
                key="cp-uk"
                visible={!isGeneralProfileTab && selectedTab === 'uk'}
                profileId={profile.id}
                name="uk"
                siteId={3}
                currency="GBP"
                domesticShippingServices={domesticShippingServices}
                internationalShippingServices={internationalShippingServices}
                shippingEbayProfiles={shippingEbayProfiles}
                showcases={showcases}
                isOriginalSite={originalSite === 3}
                originalShippingPolicy={originalShippingPolicy}
              />
              <CustomProfileWithShowHide
                key="cp-de"
                visible={!isGeneralProfileTab && selectedTab === 'de'}
                profileId={profile.id}
                name="de"
                siteId={77}
                currency="EUR"
                domesticShippingServices={domesticShippingServices}
                internationalShippingServices={internationalShippingServices}
                shippingEbayProfiles={shippingEbayProfiles}
                showcases={showcases}
                isOriginalSite={originalSite === 77}
                originalShippingPolicy={originalShippingPolicy}
              />
              <CustomProfileWithShowHide
                key="cp-au"
                visible={!isGeneralProfileTab && selectedTab === 'au'}
                profileId={profile.id}
                name="au"
                siteId={15}
                currency="AUD"
                domesticShippingServices={domesticShippingServices}
                internationalShippingServices={internationalShippingServices}
                shippingEbayProfiles={shippingEbayProfiles}
                showcases={showcases}
                isOriginalSite={originalSite === 15}
                originalShippingPolicy={originalShippingPolicy}
              />
              <CustomProfileWithShowHide
                key="cp-fr"
                visible={!isGeneralProfileTab && selectedTab === 'fr'}
                profileId={profile.id}
                name="fr"
                siteId={71}
                currency="EUR"
                domesticShippingServices={domesticShippingServices}
                internationalShippingServices={internationalShippingServices}
                shippingEbayProfiles={shippingEbayProfiles}
                showcases={showcases}
                isOriginalSite={originalSite === 71}
                originalShippingPolicy={originalShippingPolicy}
              />
              <CustomProfileWithShowHide
                key="cp-it"
                visible={!isGeneralProfileTab && selectedTab === 'it'}
                profileId={profile.id}
                name="it"
                siteId={101}
                currency="EUR"
                domesticShippingServices={domesticShippingServices}
                internationalShippingServices={internationalShippingServices}
                shippingEbayProfiles={shippingEbayProfiles}
                showcases={showcases}
                isOriginalSite={originalSite === 101}
                originalShippingPolicy={originalShippingPolicy}
              />
              <CustomProfileWithShowHide
                key="cp-es"
                visible={!isGeneralProfileTab && selectedTab === 'es'}
                profileId={profile.id}
                name="es"
                siteId={186}
                currency="EUR"
                domesticShippingServices={domesticShippingServices}
                internationalShippingServices={internationalShippingServices}
                shippingEbayProfiles={shippingEbayProfiles}
                showcases={showcases}
                isOriginalSite={originalSite === 186}
                originalShippingPolicy={originalShippingPolicy}
              />
              <CustomProfileWithShowHide
                key="cp-ca"
                visible={!isGeneralProfileTab && selectedTab === 'ca'}
                profileId={profile.id}
                name="ca"
                siteId={2}
                currency="CAD"
                domesticShippingServices={domesticShippingServices}
                internationalShippingServices={internationalShippingServices}
                shippingEbayProfiles={shippingEbayProfiles}
                showcases={showcases}
                isOriginalSite={originalSite === 2}
                originalShippingPolicy={originalShippingPolicy}
              />
              {/* 非8大站点 */}
              {notBig8Site && originalSite !== 'unknown' && (
                <CustomProfileWithShowHide
                  key="cp-other"
                  visible={!isGeneralProfileTab && selectedTab === 'other'}
                  profileId={profile.id}
                  name="other"
                  siteId={originalSite}
                  domesticShippingServices={domesticShippingServices}
                  internationalShippingServices={internationalShippingServices}
                  shippingEbayProfiles={shippingEbayProfiles}
                  showcases={showcases}
                  // 如果有非8大站点，那么该站点一定是原始站点
                  isOriginalSite
                  originalShippingPolicy={originalShippingPolicy}
                />
              )}
            </>
          )}
        </ApolloLightbox>
      )}
    </Form>
  );
}

export default ShippingProfileLightboxForm;
