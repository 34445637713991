// @flow
/**
 * Special hook for different state changes according to a specified URL.
 * For example ?openOldLightbox=feedbackRussianPostLightbox will open RuPostFeedback form
 */

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { omit } from 'ramda';
import qs from 'qs';
import { useReactiveVar } from '@apollo/client';

import { open } from '../../actions/lightbox';
import ANALYTICS from '../../analytics/feedback';
import LIGHTBOX_ANALYTICS from '../../analytics/lightbox';
import { lightboxesVar } from '../../utils/apollo-cache';

function removeParam(name: string, params: Object) {
  history.pushState({}, '', `?${qs.stringify(omit([name], params))}`);
}

function useUrlActions() {
  const lightboxes = useReactiveVar(lightboxesVar);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    // open connected lightbox
    const reduxLightboxId = params.openOldLightbox;
    if (reduxLightboxId) {
      dispatch(open(reduxLightboxId));
      removeParam('openOldLightbox', params);
    }
    // open apollo lightbox
    const apolloLightboxId = params.openNewLightbox;
    if (apolloLightboxId) {
      LIGHTBOX_ANALYTICS.openLightbox(apolloLightboxId);
      lightboxesVar([...lightboxes, apolloLightboxId]);
      if (apolloLightboxId === 'feedbackRussianPostLightbox') {
        ANALYTICS.showRuPost();
      }
      removeParam('openNewLightbox', params);
    }
  }, []);
}

export default useUrlActions;
