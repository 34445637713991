// @flow
import { path } from 'ramda';
import {
  useQuery,
  useSubscription,
  useMutation,
  useApolloClient,
} from '@apollo/client';

import {
  ProductImportState,
  StopImport,
  ImportSubscription,
} from './operations.graphql';

type UseImportResult = {
  inProgress: boolean,
  total: number,
  percentage: number,
  imported: number,
  completed: boolean,
  cancel: () => Promise<void>,
  cancelLoading: boolean,
  truncated: boolean,
};

export const useImport = (): UseImportResult => {
  const client = useApolloClient();

  const { data } = useQuery(ProductImportState);

  const productImportState = path(['productImportState'], data);

  const onSubscriptionData = ({ subscriptionData }) => {
    const newImportState = path(
      ['data', 'productImportStats'],
      subscriptionData
    );

    if (newImportState && newImportState?.total) {
      client.writeQuery({
        query: ProductImportState,
        data: {
          productImportState: newImportState,
        },
      });
    }
  };

  useSubscription(ImportSubscription, {
    onSubscriptionData,
  });
  const [cancel, { loading: cancelLoading }] = useMutation(StopImport);

  if (!productImportState) {
    return {
      inProgress: false,
      total: 0,
      percentage: 0,
      imported: 0,
      completed: false,
      truncated: false,
      cancel,
      cancelLoading,
    };
  }

  const { inProgress, total, processed, imported, completed, truncated } =
    productImportState;
  const percentage = Math.min(Math.round((100 * processed) / total), 100) || 0;

  return {
    inProgress,
    total,
    percentage,
    completed,
    truncated,
    imported,
    cancel,
    cancelLoading,
  };
};
