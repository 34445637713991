import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import { setIsUploading, setXlsContent, setXlsErrors } from '../actions/upload';

export const initialState = {
  isUploading: false,
  xls: {
    content: null,
    errors: [],
  },
};

export default handleActions(
  {
    [setIsUploading]: (state, { payload }) =>
      update(state, { isUploading: { $set: payload } }),
    [setXlsContent]: (state, { payload }) =>
      update(state, { xls: { content: { $set: payload } } }),
    [setXlsErrors]: (state, { payload }) =>
      update(state, { xls: { errors: { $set: payload } } }),
  },
  initialState
);
