// @flow
import * as React from 'react';

import type { FilterValueT } from './typings';

// $FlowFixMe
export const { Provider, Consumer } = (React.createContext({
  onChange: (key: string, value: ?FilterValueT) => {}, // eslint-disable-line no-unused-vars
  values: {},
}): React$Context<any>);
