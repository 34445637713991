// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import cs from './styles.pcss';
import catchMouseFocus from '../../utils/hocs/catch-mouse-focus';
import usePersistState from '../../hooks/use-persist-state';
import storage from '../../settings/storage';
import OnboardingHighlight from '../../basic-components/onborading-highlight';
import { useOnboardingTour } from '../../hooks/use-onboarding-tour';

const A = catchMouseFocus((props) => <a {...props} />); // eslint-disable-line jsx-a11y/anchor-has-content

const cx = classNames.bind(cs);

type Props = {
  active?: boolean,
  href: string,
  className?: string,
  children: React.Node,
  badge?: ?{
    text: string,
    color: 'green' | 'yellow',
    getVisibility?: (?string) => boolean,
  },
  id?: string,
  onboardingStep?: number,
  isSubLink?: boolean,
};

export default function NavLink({
  active,
  href,
  className,
  children,
  badge,
  id,
  onboardingStep,
  isSubLink = false,
}: Props): React.Node {
  const { isOnboardingStep, confirmedPartOnboardingStep } = useOnboardingTour();

  const [visited, setVisited] = usePersistState(
    `${storage.linkVisitedPrefix}${String(id)}`,
    true
  );

  const [viewedState, setViewedState] = usePersistState(
    `${storage.linkViewedPrefix}${String(id)}`,
    true
  );

  React.useEffect(() => {
    if (badge && badge.text) {
      setViewedState(badge.text);

      if (active && badge.text) {
        setVisited('visited');
      }
    }
  }, [!!badge]);

  const animate = badge ? String(viewedState) !== String(badge.text) : false;

  const badgeVisible =
    // $FlowFixMe
    badge && badge.getVisibility ? badge.getVisibility(String(visited)) : true;

  const onboardingHighlightShown =
    onboardingStep &&
    isOnboardingStep(onboardingStep) &&
    (onboardingStep !== 10 || confirmedPartOnboardingStep !== onboardingStep);

  const link = (
    <A
      href={href}
      className={cx('link', { isSubLink })}
      id={id}
      onClick={() => setVisited('visited')}
    >
      {children}
      {badge && (
        <div
          className={cx('badge', badge.color, {
            animate,
            withHighlight: onboardingHighlightShown,
            visible: badgeVisible,
          })}
        >
          <span className={cx('counterContent')}>{badge.text}</span>
        </div>
      )}
    </A>
  );

  return (
    <div
      className={cx(
        'linkWrapper',
        { active, isSubLinkWrapper: isSubLink },
        className
      )}
    >
      {onboardingStep ? (
        <OnboardingHighlight
          notConfirmed={onboardingStep === 10}
          stepNumber={onboardingStep}
        >
          {link}
        </OnboardingHighlight>
      ) : (
        link
      )}
    </div>
  );
}
