// @flow

export const mixToPropHandler =
  (
    defaultHandler?: (...args: Array<any>) => any,
    handlerToMix?: (...args: Array<any>) => any
  ): ((...args: Array<mixed>) => any) =>
  (...args: Array<mixed>): any => {
    if (handlerToMix) {
      handlerToMix(...args);
    }

    if (defaultHandler) {
      return defaultHandler(...args);
    }

    return undefined;
  };
