// @flow

import { sendEvent } from '../utils/analytics';
import type {
  ParcelRuPostPackageTypeT,
  ProviderNameT,
} from '../typings/parcels';

export default {
  openPopupEvent: (mode: string): Promise<void> =>
    sendEvent({
      category: 'Parcels',
      action: `Open Popup ${mode[0].toUpperCase() + mode.slice(1)}`,
    }),
  submitTrackingEvent: (): Promise<void> =>
    sendEvent({ category: 'Parcels', action: 'Submit Tracking' }),
  submitUnionEvent: (): Promise<void> =>
    sendEvent({ category: 'Parcels', action: 'Submit Union' }),
  submitDisunionEvent: (): Promise<void> =>
    sendEvent({ category: 'Parcels', action: 'Submit Disunion' }),
  submitDHLEvent: (): Promise<void> =>
    sendEvent({ category: 'Parcels', action: 'Submit DHL' }),
  submitRuPostEvent: (
    type: ParcelRuPostPackageTypeT,
    isPaid: boolean
  ): Promise<void> =>
    sendEvent({
      category: 'Parcels',
      action: 'Submit Russian Post',
      label: isPaid ? `${type}Paid` : type,
    }),
  markAsShipped: (): Promise<void> =>
    sendEvent({ category: 'Parcels', action: 'markAsShipped' }),
  cancel: (provider: ?ProviderNameT) => {
    if (provider === 'DHL') {
      sendEvent({ category: 'Parcels', action: 'Cancel DHL' });
    } else if (provider === 'RuPost') {
      sendEvent({ category: 'Parcels', action: 'Cancel Russian Post' });
    }
  },
  acceptTOC: (carrier: 'DHL'): Promise<void> =>
    sendEvent({ category: 'Parcels', action: `Accept TOC ${carrier}` }),
  bookCourier: (): Promise<void> =>
    sendEvent({
      category: 'Parcels',
      action: 'book courier',
    }),
};
