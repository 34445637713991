import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { setDhlTocAccepted } from '../actions/account';

export const initialState = {
  name: '',
  phone: null,
  address: null,
  dhlTosAccepted: false,
};

export default handleActions(
  {
    [setDhlTocAccepted]: (state) =>
      update(state, { dhlTosAccepted: { $set: true } }),
  },
  initialState
);
