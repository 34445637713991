// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  className?: string,
  leftColumn?: React.Node,
  leftColumnClassName?: string,
  children: React.Node,
  grid?: number,
  rightColumn?: React.Node,
  rightColumnClassNames?: string,
  description?: React.Node,
};

export default function FieldSetTitle({
  children,
  className,
  leftColumn,
  leftColumnClassName,
  grid = 10,
  rightColumn,
  rightColumnClassNames,
  description,
}: Props): React.Node {
  return (
    <>
      <div className={cx('titleRow', className)}>
        {leftColumn && (
          <div className={cx('leftColumn', leftColumnClassName)}>
            {leftColumn}
          </div>
        )}
        <div className={cx('label', { [`grid${grid}`]: grid })}>{children}</div>
        {rightColumn && (
          <div className={cx('rightColumn', rightColumnClassNames)}>
            {rightColumn}
          </div>
        )}
      </div>
      {description && <div className={cx('description')}>{description}</div>}
    </>
  );
}
