// @flow

import * as React from 'react';
import { useQuery } from '@apollo/client';
import { path } from 'ramda';
import { startOfToday, parseISO, formatISO, isBefore } from 'date-fns';

import { User } from '../shared-queries/User.graphql';
import ANALYTICS from '../analytics/daily';
import usePersistState from './use-persist-state';
import settings from '../settings/storage';

export default function useDailyAnalytics(skip?: boolean) {
  const [date, setDate] = usePersistState(settings.dailyAnalytics);

  const currentDate = new Date();
  const notReportedToday = !date || isBefore(parseISO(date), startOfToday());

  const { data } = useQuery(User, {
    skip: skip || !notReportedToday,
  });

  const activeParcelsCount = path(['viewer', 'activeParcelsCount'], data);

  React.useEffect(() => {
    if (notReportedToday && activeParcelsCount) {
      setDate(formatISO(currentDate));
      ANALYTICS.ordersWaiting(activeParcelsCount);
    }
  }, [activeParcelsCount]);
}
