// @flow
import { useReactiveVar } from '@apollo/client';
import { without } from 'ramda';

import { dropdownsVar } from '../utils/apollo-cache';

export function useApolloDropdown(id: string): {
  openDropdown: () => void,
  closeDropdown: () => void,
  dropdowns: string[],
} {
  const dropdowns = useReactiveVar(dropdownsVar);

  const openDropdown = () => {
    if (!dropdowns.includes(id)) {
      dropdownsVar([id]);
    }
  };

  const closeDropdown = () => {
    if (dropdowns.includes(id)) {
      dropdownsVar(without([id], dropdowns));
    }
  };

  return { openDropdown, closeDropdown, dropdowns };
}
