// @flow
import { getHref, getWindow } from '../utils/url';
import { getLocale } from '../utils/i18n';

const STOCK_PATH = 'stock';
const SHOWCASES_PATH = 'shops';
const ACCOUNT_PATH = 'accounts';
const PARCELS_PATH = 'parcels';
const SUPPORT_PATH = 'help/contact_us?id=4105&st=10';

const ebayDomains = {
  US: 'com', // 0
  CA: 'ca', // 2
  UK: 'co.uk', // 3
  GB: 'co.uk', // 3
  AU: 'com.au', // 15
  AT: 'at', // 16
  FR: 'fr', // 71
  DE: 'de', // 77
  IT: 'it', // 101
  NL: 'nl', // 146
  ES: 'es', // 186
  CH: 'ch', // 193
  HK: 'com.hk', // 201
  IN: 'in', // 203
  IE: 'ie', // 205
  MY: 'com.my', // 207
  PH: 'ph', // 211
  PL: 'pl', // 212
  SG: 'com.sg', // 216
};

export const ebaySiteIdMapToDomain = {
  '0': 'ebay.com',
  '2': 'ebay.ca',
  '3': 'ebay.co.uk',
  '15': 'ebay.com.au',
  '16': 'ebay.at',
  '23': 'befr.ebay.be',
  '71': 'ebay.fr',
  '77': 'ebay.de',
  '100': 'ebay.com',
  '101': 'ebay.it',
  '123': 'benl.ebay.be',
  '146': 'ebay.nl',
  '186': 'ebay.es',
  '193': 'ebay.ch',
  '201': 'ebay.com.hk',
  '203': 'ebay.in',
  '205': 'ebay.ie',
  '207': 'ebay.com.my',
  '210': 'cafr.ebay.ca',
  '211': 'ebay.ph',
  '212': 'ebay.pl',
  '216': 'ebay.com.sg',
};

export const JpKrCountry = ['JP', 'KR'];

export const GCCountry = ['CN', 'HK', 'TW'];

export const SEACountry = ['MY', 'SG', 'PH', 'ID', 'VN', 'TH'];

export const INCountry = ['IN'];

export const getSiteDomain = (country?: string | null): string => {
  if (!country) return 'ebay.com';
  return `ebay.${ebayDomains[country.trim().toUpperCase()]}`;
};

const getEbayDomain = (country?: string | null): string =>
  `https://www.ebay.${ebayDomains[country || 'US'] || 'com'}`;

export default {
  references: {
    helpEbay: (country?: string | null): string =>
      `${getEbayDomain(country)}/help/home`,
    faq: (locale?: string): string => {
      // faq link setting
      switch (locale) {
        case 'ja':
          return 'https://ebay.co.jp/ebaymag-faq';
        case 'ko':
          return 'https://www.ebay.co.kr/ebaymag-faq';
        case 'en':
          return 'https://help.ebaymag.com/en/articles/7941255-frequently-asked-questions';
        case 'es':
          return 'https://help.ebaymag.com/es/articles/7944505-preguntas-frecuentes';
        case 'zh':
          return 'https://help.ebaymag.com/zh-CN/articles/7941255-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98';
        case 'ru':
          return 'https://help.ebaymag.com/ru/articles/7944507-%D1%87%D0%B0%D1%81%D1%82%D0%BE-%D0%B7%D0%B0%D0%B4%D0%B0%D0%B2%D0%B0%D0%B5%D0%BC%D1%8B%D0%B5-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D1%8B';
        case 'it':
          return 'https://help.ebaymag.com/it/articles/7944503-domande-frequenti';
        case 'fr':
          return 'https://help.ebaymag.com/fr/articles/7944504-questions-frequemment-posees';
        default:
          return 'https://help.ebaymag.com/en/articles/7941255-frequently-asked-questions';
      }
    },
    support: (country: string): string => {
      if (GCCountry.includes(country)) {
        return `https://www.ebay.com.hk/${SUPPORT_PATH}`;
      }
      if (SEACountry.includes(country)) {
        return `https://www.ebay.com.sg/${SUPPORT_PATH}`;
      }
      if (INCountry.includes(country)) {
        return `https://www.ebay.com/${SUPPORT_PATH}`;
      }
      return '';
    },
    isJpKrCountry: (country: string): boolean => JpKrCountry.includes(country),
    isGcCountry: (country: string): boolean => GCCountry.includes(country),
    isSeaCountry: (country: string): boolean => SEACountry.includes(country),
    isInCountry: (country: string): boolean => INCountry.includes(country),
    speedPakKeys: [
      'ExpeditedSppedPAK',
      'StandardSppedPAK',
      'EconomySppedPAK',
      'CA_IntlExpeditedShippingFromGC',
      'CA_ExpeditedShippingFromGC',
      'CA_IntlStandardShippingFromGC',
      'CA_StandardShippingFromGC',
      'CA_IntlEconomyShippingFromGC',
      'CA_EconomyShippingFromGC',
      'SPEEDPAK_EU_DDU',
    ],
    speedPakPriorityOrder: [
      'ExpeditedSppedPAK',
      'StandardSppedPAK',
      'EconomySppedPAK',
      'SPEEDPAK_EU_DDU',
    ],
    speedPakPriorityOrder4Ca: [
      'CA_IntlExpeditedShippingFromGC',
      'CA_ExpeditedShippingFromGC',
      'CA_IntlStandardShippingFromGC',
      'CA_StandardShippingFromGC',
      'CA_IntlEconomyShippingFromGC',
      'CA_EconomyShippingFromGC',
      'SPEEDPAK_EU_DDU',
    ],
    otherOptionsPriorityOrder: [
      'ONE_DAY',
      'EXPRESS',
      'EXPEDITED',
      'STANDARD',
      'ECONOMY',
      'OTHER',
    ],
    speedPakexcluded: {
      '0': {
        excludedValues: {
          domestic: ['SPEEDPAK_EU_DDU'],
          international: null,
        },
      },
      '2': {
        excludedValues: {
          domestic: ['SPEEDPAK_EU_DDU', 'CA_ExpeditedShippingFromGC'],
          international: ['CA_IntlExpeditedShippingFromGC'],
        },
      },
      '3': {
        excludedValues: {
          domestic: ['ExpeditedSppedPAK'],
          international: ['ExpeditedSppedPAK'],
        },
      },
      '15': {
        excludedValues: {
          domestic: ['SPEEDPAK_EU_DDU', 'ExpeditedSppedPAK'],
          international: ['ExpeditedSppedPAK'],
        },
      },
      '71': {
        excludedValues: {
          domestic: ['ExpeditedSppedPAK'],
          international: ['ExpeditedSppedPAK'],
        },
      },
      '77': {
        excludedValues: {
          domestic: ['ExpeditedSppedPAK'],
          international: ['ExpeditedSppedPAK'],
        },
      },
      '101': {
        excludedValues: {
          domestic: ['ExpeditedSppedPAK'],
          international: ['ExpeditedSppedPAK'],
        },
      },
      '186': {
        excludedValues: {
          domestic: ['ExpeditedSppedPAK'],
          international: ['ExpeditedSppedPAK'],
        },
      },
    },
    shouldLink: (country: string): boolean =>
      GCCountry.concat(SEACountry, INCountry).includes(country),
    helpCenter: 'https://help.ebaymag.com',
    helpCenterLinks: {
      addItems:
        'https://help.ebaymag.com/en/articles/3779067-how-to-add-more-items',
      siteMassEdit:
        'https://help.ebaymag.com/en/articles/4473648-how-to-mass-edit-listings-via-the-site-management-interface',
      ordersPage:
        'https://help.ebaymag.com/en/articles/3778989-what-is-the-orders-page',
      setUpSites:
        'https://help.ebaymag.com/en/articles/3778937-setting-up-international-sites',
      multiAccount:
        'https://help.ebaymag.com/en/articles/3778954-linking-multiple-accounts',
      items:
        'https://help.ebaymag.com/en/articles/3778862-what-is-the-items-page',
      orders:
        'https://help.ebaymag.com/en/articles/3778989-what-is-the-orders-page',
      shippingPolicies:
        'https://help.ebaymag.com/en/articles/3974082-set-up-shipping-policies',
      freeListings:
        'https://help.ebaymag.com/en/articles/4957831-how-many-free-listings-are-available-on-each-site',
      xls: 'https://help.ebaymag.com/en/articles/4909846-how-to-add-items-via-excel-template',
    },
    gpsrLinks: (key:any): any => {
      const locale = getLocale();
      switch (key) {
        case 'manufacturer':
          switch (locale) {
            case 'en':
              return 'https://www.ebay.com/help/selling/selling/CE-mark-EU?id=5225';
            case 'de':
              return 'https://www.ebay.de/help/selling/selling/CE-mark-EU?id=5225';
            case 'fr':
              return 'https://www.ebay.fr/help/selling/selling/CE-mark-EU?id=5225';
            case 'it':
              return 'https://www.ebay.it/help/selling/selling/CE-mark-EU?id=5225';
            case 'es':
              return 'https://www.ebay.es/help/selling/selling/CE-mark-EU?id=5225';
            default:
              return 'https://www.ebay.com/help/account/regulatory/regulatory-contacts-responsible-parties?id=5480';
          }
        case 'responsible':
          switch (locale) {
            case 'en':
              return 'https://www.ebay.com/help/account/regulatory/regulatory-contacts-responsible-parties?id=5480';
            case 'de':
              return 'https://www.ebay.de/help/account/regulatory/regulatory-contacts-responsible-parties?id=5480';
            case 'fr':
              return 'https://www.ebay.fr/help/account/regulatory/regulatory-contacts-responsible-parties?id=5480';
            case 'it':
              return 'https://www.ebay.it/help/account/regulatory/regulatory-contacts-responsible-parties?id=5480';
            case 'es':
              return 'https://www.ebay.es/help/account/regulatory/regulatory-contacts-responsible-parties?id=5480';
            default:
              return 'https://www.ebay.com/help/account/regulatory/regulatory-contacts-responsible-parties?id=5480';
          }
        case 'safety':
          switch (locale) {
            case 'en':
              return 'https://www.ebay.com/help/selling/selling/hazardous-materials-labeling?id=5407';
            case 'de':
              return 'https://www.ebay.de/help/selling/selling/hazardous-materials-labeling?id=5407';
            case 'fr':
              return 'https://www.ebay.fr/help/selling/selling/hazardous-materials-labeling?id=5407';
            case 'it':
              return 'https://www.ebay.it/help/selling/selling/hazardous-materials-labeling?id=5407';
            case 'es':
              return 'https://www.ebay.es/help/selling/selling/hazardous-materials-labeling?id=5407';
            default:
              return 'https://www.ebay.com/help/selling/selling/hazardous-materials-labeling?id=5407';
          }
        default:
          return '';
      }
    },
    gpsrHelpCenter: ():any => {
      const locale = getLocale();
      switch (locale) {
        case 'ja':
          return 'https://help.ebaymag.com/ja/articles/10229336-ebaymag%E3%81%A7%E3%81%AE%E4%B8%80%E8%88%AC%E8%A3%BD%E5%93%81%E5%AE%89%E5%85%A8%E8%A6%8F%E5%89%87-gpsr-%E6%83%85%E5%A0%B1%E3%81%AE%E8%BF%BD%E5%8A%A0%E6%96%B9%E6%B3%95';
        case 'ko':
          return 'https://help.ebaymag.com/ko/articles/10229336-%EC%9D%BC%EB%B0%98-%EC%A0%9C%ED%92%88-%EC%95%88%EC%A0%84%EA%B7%9C%EC%A0%95-gpsr-%EC%A0%95%EB%B3%B4%EB%A5%BC-ebaymag%EC%97%90-%EC%97%85%EB%A1%9C%EB%93%9C%ED%95%98%EB%8A%94-%EB%B0%A9%EB%B2%95';
        case 'de':
          return 'https://help.ebaymag.com/de/articles/10272106-ratgeber-zum-hochladen-von-informationen-zur-verordnung-uber-die-allgemeine-produktsicherheit-gpsr-auf-ebaymag';
        default:
          return 'https://help.ebaymag.com/en/articles/10229336-guide-to-upload-general-product-safety-regulation-gpsr-information-on-ebaymag';
      }
    },
    eportalLinks: {
      ja: 'https://eportal.ebay.co.jp/portals/inquiry_input',
      ko: 'https://e-portal.ebay.co.kr/portals/inquiry_input',
    },
    feedbackLinks: {
      ja: {
        holiday: 'https://help.ebaymag.com/ja/articles/4219600-ebaymag',
      },
    },
    moreAboutPaindInsertions: {
      ru: 'https://export.ebay.com/ru/fees-and-payments/seller-fees/insertion-fees',
      en: 'https://export.ebay.com/en/start-sell/selling-basics/seller-fees/insertion-fees',
      es: 'https://export.ebay.com/es/start-sell/seller-fees/insertion-fees',
      pt: 'https://export.ebay.com/pt/start-sell/seller-fees/insertion-fees',
    },
    subscriptionsAndFees: {
      ru: 'https://export.ebay.com/ru/sell-even-more-ru/ebay-store-brand-development-and-cost-optimization-tool/subscriptions-and-fees-ru/',
      en: 'https://export.ebay.com/en/sell-even-more/ebay-store-brand-development-and-cost-optimization-tool/subscriptions-and-fees/',
    },
    exactFreeListings: (country?: string | null): string =>
      `${getEbayDomain(country)}/sh/ovw`,
    duplicateListings: (locale: string): string => {
      if (locale === 'ru') {
        return 'https://www.ebay.com/pages/ru/help/policies/listing-multi.html';
      }

      const ebayDomain =
        locale === 'en' || locale === 'pt' || locale === 'ja' || locale === 'ko'
          ? 'com'
          : locale;

      return `https://www.ebay.${ebayDomain}/help/policies/listing-policies/duplicate-listings-policy?id=4255`;
    },
    activeCbt: {
      en: 'https://export.ebay.com/en/tc/ebaymag-promo/',
      ru: 'https://export.ebay.com/ru/tc/ebaymag-promo/',
      au: 'https://sellercentre.ebay.com.au/promo/ebaymagpromotion1',
      in: 'https://sellglobal.ebay.in/seller-center/ebaymag-promo-terms-and-conditions/',
      sea: 'https://sellglobal.ebay.com.sg/ebaymag-promo-terms-and-conditions/',
    },
    dhl: 'https://export.ebay.com/ru/shipping/delivery-service-integration/dhl',
    checkLimitLinks: {
      US: 'https://www.ebay.com/sh/ovw',
      UK: 'https://www.ebay.co.uk/sh/ovw',
      DE: 'https://www.ebay.de/sh/ovw',
      IT: 'https://www.ebay.it/sh/ovw',
      FR: 'https://www.ebay.fr/sh/ovw',
      ES: 'https://www.ebay.es/sh/ovw',
      AU: 'https://www.ebay.com.au/sh/ovw',
      CA: 'https://www.ebay.ca/sh/ovw',
    },
  },
  showcaseOrder: ['US', 'CA', 'UK', 'DE', 'IT', 'FR', 'ES', 'AU'],
  contacts: {
    dhlSupportPhone: '+7 (495) 961-10-01',
  },
  store: (country?: string | null): string =>
    `${getEbayDomain(country)}/sub/subscriptions`,
  quotas: (domain: string): string => `https://www.${domain}/mys/overview`,
  path: {
    login: `/login`,
    stock: `/${STOCK_PATH}`,
    accounts: `/${ACCOUNT_PATH}`,
    showcases: `/${SHOWCASES_PATH}`,
    showcase: (id: string): string => `/${SHOWCASES_PATH}/${id}`,
    delivery: `/shipping`,
    parcels: `/${PARCELS_PATH}`,
    parcel: (id: string): string => `/${PARCELS_PATH}?orderId=${id}`,
    gpsr: `/gpsr`,
    settings: '/settings',
  },
  ignoreErrors: {
    request: [
      {
        operationName: 'GetZifInfo',
        keyWords: ['Cannot return null for non-nullable field Store.site'],
      },
    ],
  },
  auth: {
    link: '/auth/ebay?origin=%2Fstock&prompt=login',
  },

  cable(): void | string {
    if (!document.querySelector('meta[name=cable-token]')) return undefined;

    const protocol =
      getWindow().location.protocol === 'https:' ? 'wss:' : 'ws:';
    const host = getWindow().location.hostname;
    const port = process.env.CABLE_PORT || (protocol === 'wss:' ? 443 : 80);
    const token = global.document
      .querySelector('meta[name=cable-token]')
      .getAttribute('content');

    return `${protocol}//${host}:${port}/cable?token=${token}`;
  },
  form: {
    application: {
      method: 'POST',
      action: '/start/survey',
    },
    confirmation: {
      method: 'POST',
      action: (getHref(): any | string),
    },
    feedback: {
      method: 'POST',
      action: '/feedback',
    },
    feedbackUpload: {
      method: 'POST',
      action: `/feedback/upload`,
    },
    upload: {
      method: 'POST',
      action: `/${STOCK_PATH}/upload`,
    },
    uploadImage: {
      method: 'POST',
      action: `/${STOCK_PATH}/upload_image`,
    },
    deleteAccont: {
      method: 'DELETE',
      action: `/${ACCOUNT_PATH}`,
    },
    showcase: {
      getAll: {
        method: 'GET',
        action: `/${SHOWCASES_PATH}`,
      },
      deleteAllItems: {
        method: 'DELETE',
        action: `/${SHOWCASES_PATH}/{id}`,
      },
      patch: {
        method: 'PATCH',
        action: `/${SHOWCASES_PATH}`,
      },
    },
    assignGpsrTemplate: {
      method: 'POST',
      action: `/${STOCK_PATH}/assign_gpsr_template`,
    },
    gpsrUpload: {
      method: 'POST',
      action: `/gpsr/upload`,
    },
    list: {
      loadNextPage: {
        method: 'GET',
        action: (page: string): any | string => {
          const href = getHref();
          const pageRegExp = /page=([0-9]+)$/;
          const hasParams = href.indexOf('?') !== -1;
          const hasPage = pageRegExp.test(href);

          if (hasParams) {
            return `${href}&page=${page}`;
          }
          if (hasPage) {
            return href.replace(pageRegExp, `page=${page}`);
          }

          return `${href}?page=${page}`;
        },
      },
      saveChanges: {
        method: 'PUT',
        action: (productId: string): any | string => {
          const currentHref = getHref();

          if (!productId) {
            return currentHref;
          }
          if (productId && currentHref.indexOf('?') !== -1) {
            return `${currentHref}&product_id=${productId}`;
          }

          return `${currentHref}?product_id=${productId}`;
        },
      },
      updateVariations: {
        method: 'GET',
        action: (filter: string): string => {
          const href = getHref();

          if (href.indexOf('?') !== -1) {
            return `${href}&${filter}`;
          }

          return `${href}?${filter}`;
        },
      },
    },
    product: {
      add: {
        method: 'POST',
        action: '/products',
      },
      get: {
        method: 'GET',
        action: '/products/{id}',
      },
      getByListing: {
        method: 'GET',
        action: '/products/by_listing/{id}',
      },
      update: {
        method: 'PUT',
        action: '/products/{id}',
      },
      delete: {
        method: 'DELETE',
        action: '/products/{id}',
      },
      republish: {
        method: 'POST',
        action: '/products/{id}/publish',
      },
    },
    categories: {
      search: {
        method: 'GET',
        action: '/categories/{0}?search={1}&product_locale={2}',
      },
      suggest: {
        method: 'POST',
        action: '/categories/suggest',
      },
    },
    regions: {
      list: {
        method: 'GET',
        action: '/regions',
      },
    },
  },

  login: {
    link: '/login',
  },

  importPage: {
    base: '/import',
  },

  getStarted: {
    base: '/get_started',
    products: '/products',
    publish: '/publish',
    loadProductsKey: 'productsTable/load',
    loadMoreProductsKey: 'productsTable/loadMore',
    searchKey: 'productsTable/search',
    publishKey: 'productsTable/publish',
    info: '.json', // :))
  },

  onboarding: {
    base: '/onboarding',
    finish: '/finish',
    cancel: '/cancel',
    continue: '/continue',
  },
};

export const ruPost = {
  links: {
    terms:
      'https://www.pochta.ru/documents/10231/1724601026/Пользовательское+соглашение+Оплата+РПО+онлайн.pdf',
    types: 'https://www.pochta.ru/support/parcel/types-of-parcels',
    materials: 'https://www.pochta.ru/support/post-rules/package-materials',
    forbidden:
      'https://www.pochta.ru/support/post-rules/content-package-rules#forbiddenitems',
    map: 'https://www.pochta.ru/offices?location=г%20Москва',
    mapPayonline:
      'https://www.pochta.ru/offices?location=г%20Москва&payonline=true',
    about: 'https://export.ebay.com/russian_post//russian_post/',
  },
  support: '8 800-100-0000',
};

export const ruPostLimits = {
  package: {
    weight: 2,
    items: 4,
  },
  parcel: {
    weight: 20,
    items: 20,
  },
  ems: {
    weight: 31.5,
    items: 20,
  },
};

export const shipping = {
  pricing: {
    free: 'FREE',
    flat: 'FLAT',
    custom: 'CUSTOM',
  },
  returnsOptions: [0, 3, 7, 14, 30, 60],
  dispatchTimeOptions: [0, 1, 2, 3, 4, 5, 6, 7, 10, 15, 20, 30, 40],
};

export const skipOnboardingQuery = 'skip_onboarding=yes';

export const MISSING_SPECIFICS_ERROR_CODE = '21919303';

export const NOT_OPTED_POLICIES_ERROR_CODE = '21919446';

export const OUT_OF_STOCK_CODE = 'out_of_stock';

export const GROUPS = [
  // $FlowFixMe
  /^(shippingProfileId)$/,
  // $FlowFixMe
  /^(variations)\.?.*?$/,
  // $FlowFixMe
  /^(listings\.\d+).*?$/,
];

// $FlowFixMe
export const URL_REGEXPS = [/\bhttps?::\/\/\S+/gi, /\bhttps?:\/\/\S+/gi];

// $FlowFixMe
export const EMAIL_REGEX = /^[^\s]+@[^\s]+\.[^\s]{2,}$/;
