// @flow
/**
 * Constants for local/session storage keys
 */

export default {
  stockFilters: 'ebaymagStockFiltersNew',
  siteFilters: 'ebaymagSiteFilters',
  parcelsFilters: 'ebaymagParcelsFilters',
  dailyAnalytics: 'ebaymagDailyAnalytics',
  accountAdd: 'ebaymagMultiaccountAdd',
  ruPostFeedback: 'ebaymagRuPostFeedback',
  happyLightbox: 'ebaymagHappyLightbox',
  happySalesLightbox: 'ebaymagHappySalesLightbox',
  linkVisitedPrefix: 'ebaymagPersonalLinkVisited',
  linkViewedPrefix: 'ebaymagPersonalLinkViewed',
  zif: 'ebaymagZif',
  zif2021: 'ebaymagZif2021',
  unsellableBanner: 'ebaymagUnsellableBanner',
  maintenanceBanner: 'ebaymagMaintenanceBanner20240810',
  onboardingTourStep: 'ebaymagOnboardingTourStep',
  onboardingTourStarted: 'ebaymagOnboardingTourStarted',
  hideLimitReachedError: 'ebaymagHideLimitReachedError',
  autosyncAnnouncement: 'ebaymagAutosyncAnnouncement',
  autoImportLightbox: 'ebaymagAutoImportLightbox',
  auFreeListings: 'ebaymagAuFreeListings',
  showNotifSyncQuantities: 'ebaymagShowNotifSyncQuantities',
  showNotifSyncContent: 'ebaymagShowNotifSyncContent',
  showNotifReimport: 'ebaymagShowNotifReimport',
  showNotifXLSSync: 'ebaymagShowNotifXLSSync',
};
