// @flow

import * as React from 'react';
import cx from 'classnames';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import keys from '../../settings/storage';
import usePersistState from '../../hooks/use-persist-state';

import Icon from '../../basic-components/icon';
import Button from '../../basic-components/button';
import NewInfo from '../../basic-components/new-info';

import FeedbackForm from '../feedback-form';
import ConnectedFeedbackRussianPost from '../feedback-russian-post/connected';
import ANALYTICS from '../../analytics/feedback';
import { useApolloLightbox } from '../../hooks/use-apollo-lightbox';
import useFeedbackPriority from '../../hooks/use-feedback-priority';
import { UserCountry } from '../../shared-queries/AccountDataForFeedback.graphql';

import cs from './styles.pcss';
import t from './locale';
import settings from '../../settings';

type Props = {
  className?: string,
  showRussianPostButton?: boolean,
  isImportStep?: boolean,
};

export default function FeedbackButtons({
  showRussianPostButton,
  className,
  isImportStep = false,
}: Props): React.Node {
  const { openLightbox: openFeedbackLightbox } =
    useApolloLightbox('feedbackLightbox');
  const { openLightbox: openRuPostLightbox } = useApolloLightbox(
    'feedbackRussianPostLightbox'
  );

  const [feedbackLowPriority, feedbackPriorityLoading] = useFeedbackPriority();

  const [ruPostFeedbackViewed, setRuPostFeedbackViewed] = usePersistState(
    keys.ruPostFeedback
  );

  const { data } = useQuery(UserCountry);
  const accounts = pathOr([], ['viewer', 'accounts'], data);
  // check user account country
  let isJpOrKr = false;
  let country = 'EN';
  // get country from user accounts
  if (accounts.length > 0) {
    country = accounts[0].country;
    isJpOrKr = settings.references.isJpKrCountry(country);
  }

  if (isImportStep || !isJpOrKr) {
    return (
      <div className={cx(cs.placeholder, className)}>
        <FeedbackForm />
      </div>
    );
  }

  return (
    <div className={cx(cs.placeholder, className)}>
      {!feedbackPriorityLoading && (
        <Button
          onClick={() => {
            openFeedbackLightbox();
            ANALYTICS.show(feedbackLowPriority);
          }}
          size="small"
          className={cs.button}
          contentClassName={cs.buttonContent}
        >
          <Icon name="questionCircle" className={cs.icon} />
          <span className={cs.buttonText}>
            {t(feedbackLowPriority ? 'buttonLowPriority' : 'button')}
          </span>
        </Button>
      )}

      {showRussianPostButton && (
        <div className={cs.russianPost}>
          <Button
            onClick={() => {
              setRuPostFeedbackViewed('viewed');
              openRuPostLightbox();
              ANALYTICS.showRuPost();
            }}
            size="small"
            className={cs.button}
          >
            <span className={cs.buttonContent}>
              <Icon name="ask" className={cs.icon} />
              <span className={cs.buttonText}>{t('contactRussianPost')}</span>
            </span>
          </Button>
          {ruPostFeedbackViewed !== 'viewed' && (
            <NewInfo
              onClose={() => setRuPostFeedbackViewed('viewed')}
              direction="top"
            >
              <div className={cs.contactRussianPostPopup}>
                {t('contactRussianPostPopup')}
              </div>
            </NewInfo>
          )}
          <ConnectedFeedbackRussianPost />
        </div>
      )}

      <FeedbackForm />
    </div>
  );
}
