// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import { prettyFileSize } from '../../utils/string';
import { impossible } from '../../typings/utils';

import Loader from '../loader';
import Icon from '../icon';

import t from './locale';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type UploadState = 'waiting' | 'uploading' | 'error' | 'finished';

export type Props = {
  fileName: string,
  size: number,
  state: UploadState,
  error: *,
  onDelete: () => void,
};

function getError(error: mixed): string | false {
  if (!error) {
    return false;
  }

  if (typeof error === 'string' && error.length > 0) {
    return error;
  }
  if (error.errors && Array.isArray(error.errors) && error.errors.length) {
    // serverside validation error
    return error.errors.join(', ');
  }

  return false;
}

export default function UploadFile({
  fileName,
  size,
  state,
  error,
  onDelete,
}: Props): React.Element<'div'> {
  let content;

  switch (state) {
    case 'waiting':
    case 'uploading':
      content = (
        <div className={cx('loader')}>
          <Loader size={24} />
        </div>
      );
      break;
    case 'finished':
      content = (
        <div className={cx('file')}>
          <Icon name="file" className={cx('fileIcon')} />
          <div className={cx('fileInfo')}>
            <div className={cx('fileName')}>{fileName}</div>
            <div className={cx('fileSize')}>{prettyFileSize(size)}</div>
          </div>
          <button
            type="button"
            onClick={onDelete}
            className={cx('deleteButton')}
            title={t('delete')}
          >
            <Icon name="trash" />
          </button>
        </div>
      );
      break;
    case 'error':
      content = (
        <div className={cx('file')}>
          <Icon className={cx('fileIcon')} name="exclamationCircle" />
          <div className={cx('fileInfo')}>
            <div className={cx('fileName')}>{fileName}</div>
            <div className={cx('errorText')}>
              {getError(error) || t('error', { fileName })}
            </div>
          </div>
          <button
            type="button"
            onClick={onDelete}
            className={cx('deleteButton')}
            title={t('delete')}
          >
            <Icon name="trash" />
          </button>
        </div>
      );
      break;
    default:
      impossible(state);
  }

  return (
    <div className={cx('uploadContainer', { error: state === 'error' })}>
      {content}
    </div>
  );
}
