// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import Dropdown from '../dropdown';
import MultiInput from '../multi-input';
import Icon from '../icon';

import type {
  Option,
  DropdownInputProps,
  OptionRendererFunc,
} from '../dropdown/typings';

import t from './locale';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

const MULTI_SELECT_NOT_FOUND_VALUE = 'MULTI_SELECT_NOT_FOUND_VALUE';
const MULTI_SELECT_LOADING_VALUE = 'MULTI_SELECT_LOADING_VALUE';

export type Props = {
  innerRef?: React.Ref<any>,
  size?: 'small' | 'medium',
  options: string[],
  values: string[],
  onChange: (values: string[]) => void,
  renderOption?: OptionRendererFunc,
  onLoadMore?: () => void,
  disableCustomOptions?: boolean,
  onInputChange?: (keyword: string) => void,
  loading?: boolean,
  loadingMore?: boolean,
  renderValue?: (value: string) => string,
  loaderLabel?: string,
  multiline?: boolean,
  forceDirection?: 'up' | 'down',
  wrapperClassname?: string,
};

const renderToggleIcon = (size: string, toggle: () => void) => (
  <Icon
    className={cs.toggleIcon}
    name={size === 'small' ? 'selectSmall' : 'selectNormal'}
    onClick={toggle}
  />
);

export default function MultiSelect({
  size = 'medium',
  values,
  options,
  renderOption,
  disableCustomOptions,
  onLoadMore,
  loading,
  loadingMore,
  loaderLabel,
  renderValue,
  onInputChange,
  onChange,
  multiline,
  forceDirection,
  wrapperClassname,
  ...inputProps
}: Props): React.Node {
  const [keyword, setKeyword] = React.useState('');

  const handleInputChange = (newKeyword: string) => {
    if (newKeyword !== keyword) {
      if (onInputChange) {
        onInputChange(newKeyword);
      }
      setKeyword(newKeyword);
    }
  };

  const isExistingOption = (value: string) =>
    options.some((o) => o.toLowerCase() === value.toLowerCase());

  const isSelected = (value: string) =>
    values.some((v) => v.toLowerCase() === value.toLowerCase());

  const handleAdd = (value?: mixed): void => {
    if (
      typeof value !== 'string' ||
      value === MULTI_SELECT_NOT_FOUND_VALUE ||
      value === MULTI_SELECT_LOADING_VALUE
    ) {
      return;
    }

    const newVal = value ? value.trim() : '';

    if (newVal !== '' && !isSelected(newVal)) {
      // look for option with the same value to preserve case
      const selectedOption = options.find(
        (o) => o.toLowerCase() === newVal.toLowerCase()
      );

      onChange(values.concat(selectedOption || newVal));
      handleInputChange('');
    }
  };

  const handleChange = (newValues: string[]): void => {
    onChange(newValues);
    handleInputChange('');
  };

  const filterOptions = (): Option[] => {
    const trimmedKeyword = keyword ? keyword.trim() : '';
    const loader = {
      label: loaderLabel || '',
      value: MULTI_SELECT_LOADING_VALUE,
      type: 'loading',
    };
    if (loading) {
      return [loader];
    }
    let filteredOptions = options.filter((option) => !isSelected(option));

    if (trimmedKeyword !== '') {
      filteredOptions = filteredOptions.filter((option) =>
        option.toLowerCase().includes(trimmedKeyword.toLowerCase())
      );
    }

    const dropdownOptions = filteredOptions.map((option) => ({
      label: option,
      value: option,
    }));

    if (
      !disableCustomOptions &&
      keyword !== '' &&
      !isSelected(keyword) &&
      !isExistingOption(keyword)
    ) {
      dropdownOptions.push({
        label: t('addNewOption', { name: keyword }),
        value: keyword,
      });
    }

    if (loadingMore) {
      dropdownOptions.push(loader);
    }

    if (!dropdownOptions.length) {
      dropdownOptions.push({
        label: '',
        value: MULTI_SELECT_NOT_FOUND_VALUE,
        type: 'notFound',
      });
    }

    return dropdownOptions;
  };

  return (
    <Dropdown
      size={size}
      keyword={keyword}
      value={null}
      options={filterOptions()}
      onChange={handleAdd}
      spaceToggles={false}
      deleteRemoves={false}
      renderOption={renderOption}
      backspaceRemoves={false}
      onLoadMore={onLoadMore}
      multipleSelectedValues={values}
      multiline={multiline}
      forceDirection={forceDirection}
    >
      {({ toggleDropdown, handleKeyDown }: DropdownInputProps) => (
        <MultiInput
          {...inputProps}
          size={size}
          inputValue={keyword}
          values={values}
          renderValue={renderValue}
          addOnEnter={false}
          onInputChange={(val) => {
            if (val !== '') {
              toggleDropdown(true);
            }
            handleInputChange(val);
          }}
          icon={renderToggleIcon(size, toggleDropdown)}
          onChange={handleChange}
          onBlur={() => toggleDropdown(false)}
          onFocus={() => toggleDropdown(true)}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          className={cx(cs.pointable, wrapperClassname)}
          iconClassName={cs.pointable}
        />
      )}
    </Dropdown>
  );
}
