// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import { pathOr } from 'ramda';
import { CSSTransition } from 'react-transition-group';

import Icon from '../icon';
import Portal from '../portal';

import cs from './styles.pcss';
import { useEventListener } from '../../hooks/use-event-listener';

const cx = classNames.bind(cs);

type Direction = 'top' | 'right' | 'bottom' | 'left';

export type BaseProps = {|
  children: React.Node,
  className?: string,
  disabled?: boolean,
  contentClassName?: string,
  arrowClassname?: string,
  gap?: number,
  direction?: Direction,
  noArrow?: boolean,
  onClose?: () => void,
  type?: 'short' | 'complex',
  closable?: boolean,
|};

type Props = {|
  ...BaseProps,
  target?: React.Ref<any>,
  coordinates?: { top: number, left: number },
  absolute?: boolean,
|};

type StylesT = {
  tooltip: {
    left: number,
    top: number,
    paddingLeft?: number,
    paddingRight?: number,
    paddingTop?: number,
    paddingBottom?: number,
  },
  arrow: {
    top?: number,
    bottom?: number,
    left?: number,
    right?: number,
  },
};

function getBasePointCoords(target, direction): { left: number, top: number } {
  // $FlowFixMe
  if (!target.current) {
    return { left: 0, top: 0 };
  }
  // $FlowFixMe
  const { top, left, width, height } = target.current.getBoundingClientRect();

  switch (direction) {
    case 'top':
      return { left: left + width / 2, top };
    case 'right':
      return { left: left + width, top: top + height / 2 };
    case 'bottom':
      return { left: left + width / 2, top: top + height };
    case 'left':
      return { left, top: top + height / 2 };
    default:
      return { left: 0, top: 0 };
  }
}

const ARROW_HEIGHT = 9;

export default function Tooltip({
  direction = 'top',
  gap = 4,
  type = 'short',
  disabled,
  noArrow,
  children,
  className,
  contentClassName,
  arrowClassname,
  onClose,
  closable,
  coordinates,
  target,
  absolute,
}: Props): React.Node {
  const [shown, setShown] = React.useState(false);
  const [styles, setStyles] = React.useState<StylesT | null>(null);

  const refTooltip = React.useRef();

  const calculateStyles = (): StylesT | null => {
    let arrowPosition;

    if (coordinates) {
      arrowPosition = coordinates;
    } else if (target) {
      arrowPosition = getBasePointCoords(target, direction);
    } else {
      return null;
    }

    if (!refTooltip || !refTooltip.current) {
      return null;
    }

    const { width, height } = refTooltip.current.getBoundingClientRect();
    const padding = (noArrow ? 0 : ARROW_HEIGHT) + (gap || 0);
    const considerPaddings = !!styles;

    if (absolute) {
      return {
        tooltip: {
          // left: arrowPosition.left - width / 2,
          position: absolute,
          left: 0,
          top: 0,
          paddingTop: padding,
          transformOrigin: 'center top',
        },
        arrow: {
          top: gap,
        },
      };
    }

    switch (direction) {
      case 'top': {
        return {
          tooltip: {
            left: arrowPosition.left - width / 2,
            top: arrowPosition.top - height - (considerPaddings ? 0 : padding),
            paddingBottom: padding,
            transformOrigin: `center bottom`,
          },
          arrow: {
            bottom: gap,
          },
        };
      }
      case 'right': {
        return {
          tooltip: {
            left: arrowPosition.left,
            top: arrowPosition.top - height / 2,
            paddingLeft: padding,
            transformOrigin: 'left center',
          },
          arrow: {
            left: gap,
          },
        };
      }
      case 'bottom': {
        return {
          tooltip: {
            left: arrowPosition.left - width / 2,
            top: arrowPosition.top,
            paddingTop: padding,
            transformOrigin: 'center top',
          },
          arrow: {
            top: gap,
          },
        };
      }
      case 'left': {
        return {
          tooltip: {
            left: arrowPosition.left - width - (considerPaddings ? 0 : padding),
            top: arrowPosition.top - height / 2,
            paddingRight: padding,
            transformOrigin: 'right center',
          },
          arrow: {
            right: gap,
          },
        };
      }
      default:
        return null;
    }
  };

  const updateStyles = () => {
    if (!absolute && !disabled) {
      setStyles(calculateStyles());
    }
  };

  React.useEffect(() => {
    if (!disabled) {
      setStyles(calculateStyles());
    }
    setShown(true);
  }, []);

  React.useEffect(() => {
    if (!disabled) {
      setStyles(calculateStyles());
    }
  }, [children]);

  useEventListener('scroll', updateStyles);
  useEventListener('resize', updateStyles);

  const hide = () => {
    setShown(false);
  };

  const tooltip = (
    <CSSTransition
      in={shown}
      classNames={{
        exit: cx('exit'),
        exitActive: cx('exitActive'),
        enter: cx('enter'),
        enterActive: cx('enterActive'),
      }}
      timeout={{
        enter: 150,
        exit: 100,
      }}
      onExited={onClose}
    >
      <div
        className={cx('wrapper', className, direction, {
          hidden: !styles,
          absolute,
        })}
        ref={refTooltip}
        style={pathOr<any, string, any, any>({}, ['tooltip'], styles)}
      >
        <div className={cx('contentWrapper', type, contentClassName)}>
          {children}
          {closable && (
            <button type="button" className={cs.close} onClick={hide}>
              <Icon name="cancel" size={24} />
            </button>
          )}
        </div>
        {!noArrow && (
          <div
            className={cx('arrow', type, direction, arrowClassname)}
            style={pathOr<any, string, any, any>({}, ['arrow'], styles)}
          />
        )}
      </div>
    </CSSTransition>
  );

  return absolute ? tooltip : <Portal id="portal-tooltip">{tooltip}</Portal>;
}
