// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import Radio from './radio';
import cs from './styles.pcss';
import { handleFocus } from '../../utils/hocs/catch-mouse-focus';

const cx = classNames.bind(cs);

export type Option = {
  value: any,
  label: React.Node,
  disabled?: boolean,
};

export type Props = {
  className?: string,
  name: string,
  options?: Array<Option>,
  value?: ?any,
  disabled?: boolean,
  placeholder?: React.Node,
  onChange: (value: any) => void,
  firstControlRef?: React.Ref<any>,
  onMouseDown?: (e: MouseEvent) => void,
  onMouseUp?: (e: MouseEvent) => void,
  onFocus?: (e: FocusEvent) => void,
  renderChild?: (option?: Option, active?: boolean) => ?React.Node,
};

export default function RadioGroup({
  options,
  placeholder,
  className,
  onChange = () => {},
  renderChild,
  name,
  firstControlRef,
  onFocus,
  onMouseDown,
  onMouseUp,
  disabled: groupDisabled,
  value: groupValue,
}: Props): React.Node {
  const handleChange: (SyntheticInputEvent<HTMLInputElement>) => void = ({
    currentTarget: { value },
  }: SyntheticInputEvent<HTMLInputElement>) => {
    onChange(value);
  };

  const renderOption: (option: Option, index: number) => React.Node = (
    option: Option,
    index: number
  ) => {
    const { value, label, disabled } = option;

    const props = !index ? { innerRef: firstControlRef } : {};
    const handleOptionFocus = handleFocus({
      onFocus,
      onMouseDown,
      onMouseUp,
    });

    return (
      <div
        className={cx('option')}
        key={value}
        onMouseDown={handleOptionFocus.onMouseDown}
        onMouseUp={handleOptionFocus.onMouseUp}
        role="presentation"
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label
          htmlFor={value}
          className={cx('label', { disabled: disabled || groupDisabled })}
        >
          <Radio
            name={name}
            id={value}
            value={value}
            disabled={disabled || groupDisabled}
            checked={value === groupValue}
            className={cx('radio')}
            onChange={handleChange}
            onFocus={handleOptionFocus.onFocus}
            index={index}
            {...props}
          />
          <div className={cx('labelText')}>{label}</div>
        </label>
        {!!renderChild && renderChild(option, value === groupValue)}
      </div>
    );
  };

  return (
    <div className={cx('wrapper', className)}>
      {options && options.length ? options.map(renderOption) : placeholder}
    </div>
  );
}

export { default as Radio } from './radio';
