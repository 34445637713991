// @flow
import step1 from './step1.svg';
import step2 from './step2.svg';
import step3 from './step3.svg';
import step4 from './step4.svg';
import step5 from './step5.svg';
import step6 from './step6.svg';
import step7 from './step7.svg';
import step8 from './step8.svg';
import step9 from './step9.svg';
import step10 from './step10.svg';

export default [
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
];
