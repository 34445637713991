// @flow

import type { ProductCategory } from '../../typings/product';

import t from './locale';

type Product = {
  variations: Array<any>,
};

export function getCategoryErrors(
  product: Product,
  category: ProductCategory
): string | null {
  if (
    !category.variationsEnabled &&
    product.variations &&
    product.variations.length > 1
  ) {
    return t('categoryCanNotHaveVariations');
  }

  return null;
}
