import { formatToDecimalString } from '../utils/string';
import { camelCase } from '../utils/normalize';

export function normalizeData(data) {
  return {
    ...data,
    problems: data.problems.map((problem) => ({
      ...problem,
      field: problem.field ? camelCase(problem.field) : null,
    })),
    language: data.language ? data.language.toLowerCase() : '',
    conditionId: {
      value: (data.conditionId.value || '').toString(),
    },
    variations: data.variations.map((variation) => ({
      ...variation,
      price: {
        ...variation.price,
        value: formatToDecimalString(variation.price.value.toString()),
      },
      quantity: {
        ...variation.quantity,
        value: (variation.quantity.value || '').toString(),
      },

      listings: variation.listings.map((listing) => {
        const lData = {
          ...listing,
          price: {
            ...listing.price,
            value: formatToDecimalString(
              listing.price ? listing.price.value.toString() : ''
            ),
          },
        };
        return lData;
      }),
    })),
  };
}
