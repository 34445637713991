// @flow
import * as React from 'react';
import cx from 'classnames';

import HoverTooltip from '../../../basic-components/hover-tooltip';
import getDisplayName from '../../../utils/getDisplayName';
import cs from './styles.pcss';

type FieldComponentPropsT = { id: string, name: string };

type PropsT = {
  name: string,
  label: React.Node,
  tooltip?: string,
  isMobile?: boolean,
  adaptive?: boolean,
};

function withLabel(
  FieldComponent: React.ComponentType<FieldComponentPropsT>
): (PropsT) => React.Node {
  function Wrapper({ name, label, tooltip, ...restProps }: PropsT) {
    return (
      <div
        className={cx(
          cs.filter,
          restProps.adaptive && restProps.isMobile ? cs.filterCol : cs.filterRow
        )}
      >
        <label htmlFor={`filters_${name}`} className={cs.label}>
          {label}
        </label>
        <div className={cs.fieldWrapper}>
          {tooltip ? (
            <HoverTooltip
              message={tooltip}
              direction="bottom"
              className={cs.tooltipWrapper}
              tooltipClassName={cs.tooltip}
            >
              {/* $FlowFixMe */}
              <FieldComponent
                id={`filters_${name}`}
                name={name}
                {...restProps}
              />
            </HoverTooltip>
          ) : (
            // $FlowFixMe
            <FieldComponent id={`filters_${name}`} name={name} {...restProps} />
          )}
        </div>
      </div>
    );
  }

  Wrapper.displayName = `withLabel(${getDisplayName(FieldComponent)})`;

  return Wrapper;
}

export default withLabel;
