// @flow
import * as React from 'react';

import Field from '../field';
import NewMultiSelect, {
  type Props as MultiSelectProps,
} from '../multi-select/new_index';

type Props = {
  ...MultiSelectProps,
  label: React.Node,
  description?: React.Node,
  error?: React.Node,
  grid?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  noGrid?: boolean,
  labelClassName?: string,
  errorClassName?: string,
  fieldClassName?: string,
  hideErrorText?: boolean,
};

export default function NewMultiSelectField({
  label,
  description,
  error,
  grid,
  labelClassName,
  fieldClassName,
  hideErrorText,
  errorClassName,
  noGrid,
  ...props
}: Props): React.Node {
  const refInput = React.useRef();

  const handleLabelClick: () => void = () => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  };

  return (
    <Field
      label={label}
      labelClassName={labelClassName}
      fieldClassName={fieldClassName}
      description={description}
      error={!hideErrorText && error}
      grid={grid}
      noGrid={noGrid}
      onLabelClick={handleLabelClick}
      errorClassName={errorClassName}
    >
      <NewMultiSelect innerRef={refInput} {...props} error={!!error} />
    </Field>
  );
}
