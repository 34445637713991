// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  children?: React.Node,
  delimiter?: 'narrow' | 'wide',
  className?: string,
};

function FieldSet({
  children,
  className,
  delimiter,
}: Props): React.Element<'fieldset'> {
  const fieldSetClassName = cx(
    'field-set',
    delimiter && ['field-set-delimiter', `field-set-delimiter_${delimiter}`],
    className
  );

  return <fieldset className={fieldSetClassName}>{children}</fieldset>;
}

export default FieldSet;
