function normalize(source, transformer) {
  if (Array.isArray(source)) {
    return source.map((item) => normalize(item, transformer));
  }
  if (typeof source === 'object' && source !== null) {
    return Object.keys(source).reduce(
      (result, key) => ({
        ...result,
        [transformer(key)]: normalize(source[key], transformer),
      }),
      {}
    );
  }

  return source;
}

export function snakeCase(string) {
  return string
    .replace(/([A-Z])/g, (match, letter) => `_${letter.toLowerCase()}`)
    .replace(/^_/, '');
}

export function camelCase(string) {
  return string
    .split('_')
    .reduce(
      (result, segment) =>
        result +
        (result
          ? segment.charAt(0).toUpperCase() + segment.substr(1)
          : segment),
      ''
    );
}

export function jsonToSnakeCase(source) {
  return normalize(source, snakeCase);
}

export function jsonToCamelCase(source) {
  return normalize(source, camelCase);
}
