// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import { Form, FormField } from '../../hocs/final-form';
import { useFileUpload } from '../../hooks/use-file-upload';
import type { Upload } from '../../hooks/use-file-upload/types';
import { validateEmail, validateNotEmpty } from '../../utils/form';

import Icon from '../../basic-components/icon';
import ApolloLightbox from '../apollo-lightbox';
import InputField from '../../basic-components/input-field';
import TextareaField from '../../basic-components/textarea-field';
import Button from '../../basic-components/button';
import ThreeColumnsLayout from '../../basic-components/three-columns-layout';
import UploadFileField from '../../basic-components/upload-file-field';
import UploadDropZone from '../../basic-components/upload-drop-zone';
import HoverTooltip from '../../basic-components/hover-tooltip';
import SuccessOverlay from '../success-overlay';
import settings from '../../settings';

import cs from './styles.pcss';
import t from './locale';

const cx = classNames.bind(cs);

const MAX_UPLOADS = 5;
const ACCEPTED_UPLOAD_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'application/pdf',
  'application/excel',
  'application/x-excel',
  'application/x-msexcel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

type FeedbackRussianPostData = {|
  email: string,
  body: string,
  tracking: ?string,
  caseId: ?string,
|};

type Props = {
  email: string,
  sendFeedback: (data: {
    ...FeedbackRussianPostData,
    attachments: Array<Upload>,
  }) => Promise<mixed>,
};

const renderFooter =
  (submitSucceeded?: boolean, submitting?: boolean, handleSubmit?: any) =>
  (closeOverlay: () => Promise<void>) =>
    submitSucceeded ? (
      <ThreeColumnsLayout
        right={<Button onClick={closeOverlay}>{t('button.close')}</Button>}
      />
    ) : (
      <ThreeColumnsLayout
        right={
          <Button onClick={handleSubmit} loading={submitting}>
            {t('button.submit')}
          </Button>
        }
        left={
          <Button onClick={closeOverlay} loading={submitting} mode="secondary">
            {t('button.cancel')}
          </Button>
        }
      />
    );

function FeedbackRussianPost({ email, sendFeedback }: Props): React.Node {
  const {
    uploads,
    handleUpload,
    deleteUpload,
    limitReached,
    reset: resetUploads,
  } = useFileUpload({
    maxUploads: MAX_UPLOADS,
    form: settings.form.feedbackUpload,
  });

  return (
    <Form
      onSubmit={(data: FeedbackRussianPostData) =>
        sendFeedback({ ...data, attachments: uploads })
      }
      initialValues={{ email }}
    >
      {({
        handleSubmit,
        submitSucceeded,
        submitFailed,
        submitting,
        submitError,
        form: { change, reset: resetForm },
      }) => (
        <ApolloLightbox
          id="feedbackRussianPostLightbox"
          className={cx('lightbox')}
          header={t('header')}
          onClose={() => {
            if (submitSucceeded) {
              resetForm();
              resetUploads();
            }
          }}
          footer={renderFooter(submitSucceeded, submitting, handleSubmit)}
        >
          {submitSucceeded ? (
            <SuccessOverlay message={t('successMessage')} />
          ) : (
            <div
              className={cx('content')}
              data-testid="feedbackRussianPostLightbox-success"
            >
              <div className={cx('description')}>{t('description')}</div>
              <UploadDropZone
                onChange={handleUpload}
                disabled={limitReached}
                accept={ACCEPTED_UPLOAD_FORMATS}
              >
                <FormField
                  name="email"
                  Component={InputField}
                  validate={validateEmail(t('validate.email'))}
                  label={t('field.email.label')}
                  grid={6}
                  labelClassName={cx('label')}
                  onBlur={(e) => {
                    // Reset email to initial value if it is empty
                    if (e.target.value === '') {
                      change('email', email);
                    }
                  }}
                  errorClassName={cx('error')}
                />
                <FormField
                  name="tracking"
                  Component={InputField}
                  label={t('field.tracking.label')}
                  grid={6}
                  labelClassName={cx('label')}
                />
                <div className={cx('fieldDescription')}>
                  {t('field.tracking.description')}
                </div>

                <FormField
                  name="caseId"
                  Component={InputField}
                  label={
                    <div>
                      <HoverTooltip
                        inline
                        message={t('field.caseId.popup')}
                        className={cx('infoIconContainer')}
                        tooltipClassName={cx('labelInfoTooltip')}
                      >
                        <Icon
                          name="questionCircle"
                          className={cx('infoIcon')}
                        />
                      </HoverTooltip>
                      {t('field.caseId.label')}
                    </div>
                  }
                  grid={6}
                  labelClassName={cx('label')}
                />
                <div className={cx('fieldDescription')}>
                  {t('field.caseId.description')}
                </div>

                <FormField
                  name="body"
                  Component={TextareaField}
                  validate={validateNotEmpty(t('validate.body'))}
                  label={t('field.message.label')}
                  disabled={submitting}
                  labelClassName={cx('label')}
                  rows="6"
                  grid={6}
                  errorClassName={cx('error')}
                />
                <UploadFileField
                  label={t('field.attachments.label')}
                  labelClassName={cx('label')}
                  grid={6}
                  maxUploads={MAX_UPLOADS}
                  uploads={uploads}
                  onDelete={deleteUpload}
                  onChange={handleUpload}
                  accept={ACCEPTED_UPLOAD_FORMATS}
                  disabled={limitReached}
                  id="feedbackRuPostUpload"
                />
              </UploadDropZone>
              {submitFailed && submitError && (
                <div className={cx('formError')}>{submitError}</div>
              )}
            </div>
          )}
        </ApolloLightbox>
      )}
    </Form>
  );
}

export default FeedbackRussianPost;
