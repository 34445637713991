// @flow
import * as React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createFocusOnError from 'final-form-focus';
import classNames from 'classnames/bind';

import { useReactiveVar } from '@apollo/client';

import { isNil } from 'ramda';
import { FormField } from '../../../hocs/final-form';
import useMediaQuery from '../../../hooks/use-media-query';

import ApolloLightbox from '../../apollo-lightbox';
import InputField from '../../../basic-components/input-field';
import Button from '../../../basic-components/button';
import SelectField from '../../../basic-components/select-field';
import FieldSet from '../../field-set';
import FieldSetDescription from '../../field-set-description';
import ThreeColumnsLayout from '../../../basic-components/three-columns-layout';
import Link from '../../../basic-components/link';

import t from '../locale';
import cs from '../styles.pcss';

import settings from '../../../settings';
import { gpsrConfigsVar } from '../../../utils/apollo-cache';
import { getOptions } from '../../../store/gpsr';
import { emailValidate } from '../../../typings/utils';
import type { ResponsiblePerson } from '../../../store/gpsr';

const cx = classNames.bind(cs);

type Props = {
  responsiblePersons: Array<ResponsiblePerson>,
  selectedIndex: number,
  onChange: (value: any) => void,
};

const focusOnErrors = createFocusOnError();
const renderFooter =
  (
    handleSubmit?: any,
    submitError?: boolean,
    submitting?: boolean,
    mediumScreen: boolean = true
  ) =>
  (closeOverlay: () => Promise<void>) =>
    (
      <ThreeColumnsLayout
        left={
          <Button
            onClick={closeOverlay}
            mode="secondary"
            size={mediumScreen ? 'medium' : 'inModal'}
          >
            {t('buttons.close')}
          </Button>
        }
        center={
          <Button
            loading={submitting}
            onClick={handleSubmit}
            size={mediumScreen ? 'medium' : 'inModal'}
          >
            {t('buttons.save')}
          </Button>
        }
        className={cs.footerWrapper}
      />
    );

function GpsrResponsiblePersonLightbox({
  responsiblePersons,
  selectedIndex,
  onChange,
}: Props): React.Node {
  const veryLargeScreen = useMediaQuery(`(min-width: 1000px)`);
  const mediumScreen = useMediaQuery(`(min-width: 600px)`);
  const countries = getOptions(useReactiveVar(gpsrConfigsVar), 'country');
  const responsiblePerson =
    selectedIndex === -1 || !responsiblePersons[selectedIndex]
      ? {}
      : responsiblePersons[selectedIndex];
  const validateEmail = (email) => {
    if (!email || !email.trim()) {
      return null;
    }
    if (!emailValidate(email)) {
      return t('responsiblePerson.fields.email.validate.incorrect');
    }
    return null;
  };
  return (
    <Form
      initialValues={responsiblePerson}
      initialValuesEqual={() => false}
      subscription={{
        submitting: true,
        submitError: true,
      }} // rerender form component only when submitting is changed
      mutators={{ ...arrayMutators }}
      decorators={[focusOnErrors]}
      onSubmit={(data) => {
        onChange({
          ...data,
        });
      }}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <ApolloLightbox
          id="GpsrResponsiblePerson"
          header={t('responsiblePerson.title')}
          footer={renderFooter(
            handleSubmit,
            submitError,
            submitting,
            mediumScreen
          )}
        >
          <form className={cx(cs.wrapper)} onSubmit={(e) => e.preventDefault()}>
            <FieldSet>
              <FieldSetDescription>
                {t('responsiblePerson.formDescription')}
                <Link
                  target="_blank"
                  href={settings.references.gpsrLinks('responsible')}
                  className={cs.link}
                  noUnderline={false}
                  onClick={() => {}}
                >
                  {t('linkText')}
                </Link>
              </FieldSetDescription>
              <FormField
                name="companyName"
                validate={(val) =>
                  !val || !val.trim()
                    ? t('responsiblePerson.fields.companyName.validate.empty')
                    : null
                }
                Component={InputField}
                label={t('responsiblePerson.fields.companyName.label')}
                noGrid={!veryLargeScreen}
                maxLength={100}
                useDefaultValue
              />
              <FormField
                name="country"
                validate={(val) =>
                  isNil(val)
                    ? t('responsiblePerson.fields.country.validate.empty')
                    : null
                }
                Component={SelectField}
                options={countries}
                label={t('responsiblePerson.fields.country.label')}
                noGrid={!veryLargeScreen}
                onChange={({ value }) => value}
                searchable={false}
              />
              <FormField
                name="street1"
                validate={(val) =>
                  !val || !val.trim()
                    ? t('responsiblePerson.fields.street1.validate.empty')
                    : null
                }
                Component={InputField}
                label={t('responsiblePerson.fields.street1.label')}
                noGrid={!veryLargeScreen}
                maxLength={180}
                useDefaultValue
              />
              <FormField
                name="street2"
                Component={InputField}
                label={t('responsiblePerson.fields.street2.label')}
                noGrid={!veryLargeScreen}
                maxLength={180}
                useDefaultValue
              />
              <FormField
                name="cityName"
                validate={(val) =>
                  !val || !val.trim()
                    ? t('responsiblePerson.fields.cityName.validate.empty')
                    : null
                }
                Component={InputField}
                label={t('responsiblePerson.fields.cityName.label')}
                noGrid={!veryLargeScreen}
                maxLength={64}
                useDefaultValue
              />
              <FormField
                name="postalCode"
                validate={(val) =>
                  !val
                    ? t('responsiblePerson.fields.postalCode.validate.empty')
                    : null
                }
                Component={InputField}
                label={t('responsiblePerson.fields.postalCode.label')}
                noGrid={!veryLargeScreen}
                maxLength={9}
                useDefaultValue
              />
              <FormField
                name="stateOrProvince"
                validate={(val) =>
                  !val || !val.trim()
                    ? t(
                        'responsiblePerson.fields.stateOrProvince.validate.empty'
                      )
                    : null
                }
                Component={InputField}
                label={t('responsiblePerson.fields.stateOrProvince.label')}
                noGrid={!veryLargeScreen}
                maxLength={64}
                useDefaultValue
              />
              <FormField
                name="phone"
                Component={InputField}
                label={t('responsiblePerson.fields.phone.label')}
                noGrid={!veryLargeScreen}
                maxLength={64}
                mask="number"
                useDefaultValue
              />
              <FormField
                name="email"
                validate={(val) => validateEmail(val)}
                Component={InputField}
                label={t('responsiblePerson.fields.email.label')}
                noGrid={!veryLargeScreen}
                maxLength={180}
                useDefaultValue
              />
            </FieldSet>
          </form>
        </ApolloLightbox>
      )}
    </Form>
  );
}

export default GpsrResponsiblePersonLightbox;
