// @flow
import { connect } from 'react-redux';

import { close } from '../../actions/lightbox';
import type { State } from '../../typings/redux';
import Lightbox, { type Props as OwnProps } from '.';

type Props = {
  ...OwnProps,
  opened: boolean,
  onClose: () => void | Promise<void>,
};

const mapStateToProps = (
  { lightbox: { opened } }: State,
  { lightboxName }: OwnProps
): {| opened: boolean |} => ({
  opened: opened.includes(lightboxName),
});

const mapDispatchToProps = (
  dispatch,
  { onClose, lightboxName }: OwnProps
): ({| onClose: () => void |}) => ({
  onClose: () => {
    dispatch(close(lightboxName));
    if (onClose) {
      onClose();
    }
  },
});

// $FlowFixMe
export default (connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Lightbox): any);
