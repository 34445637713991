// @flow

import { sendEvent } from '../utils/analytics';

export default {
  click: (href: ?string, area: ?string): Promise<void> =>
    sendEvent({
      category: `LinkClick`,
      action: `LinkClick-${area || 'main'}`,
      label: href || 'default',
    }),
};
