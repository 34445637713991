// @flow

import qs from 'qs';

export function getWindow():
  | any
  | {| location: {| hostname: string, href: string, protocol: string |} |} {
  if (typeof window !== 'undefined') {
    return window;
  }

  return {
    location: {
      protocol: 'http',
      hostname: 'ebaymag.com',
      href: '/',
    },
  };
}

export function setHref(href: string) {
  getWindow().location.href = href;
}

export function getHref(): any | string {
  return getWindow().location.href;
}

function convertQueryValue(value) {
  const BOOLEAN = /^(true|false)$/;
  const INT = /^\d+$/;
  const FLOAT = /^\d+(?:\.\d+)$/;

  if (BOOLEAN.test(value)) {
    return value === 'true';
  }

  if (INT.test(value)) {
    return parseInt(value, 10);
  }

  if (FLOAT.test(value)) {
    return parseFloat(value);
  }

  return value;
}

function convertQueryObject(obj, skipConvertingFor, nesting = []) {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const fullPath = [...nesting, key];
    const handleValue = (v) =>
      skipConvertingFor.includes(fullPath.join('.')) ? v : convertQueryValue(v);

    if (Array.isArray(value)) {
      acc[key] = value.map(handleValue);
    } else if (typeof value === 'object') {
      acc[key] = convertQueryObject(value, skipConvertingFor, fullPath);
    } else {
      acc[key] = handleValue(value);
    }

    return acc;
  }, {});
}

export function parseURLQuery(
  searchStr: string,
  skipConvertingFor: Array<string> = []
): { ... } {
  const parsed = qs.parse(searchStr, { ignoreQueryPrefix: true });

  return convertQueryObject(parsed, skipConvertingFor);
}

export const updateURLQuery = (
  query: { [string]: mixed },
  historyTitle: string = document.title
) => {
  const updatedQuery = qs.stringify(query, {
    encodeValuesOnly: true,
    addQueryPrefix: true,
    skipNulls: true,
  });
  const { protocol, host, pathname, hash } = location;
  const url = `${protocol}//${host}${pathname}${updatedQuery}${hash}`;
  history.pushState(null, historyTitle, url);
};

export const locationMatch = (href: string, exact: ?boolean): boolean => {
  const { pathname } = location;
  if (exact) {
    return href === pathname;
  }
  return pathname.indexOf(href) !== -1;
};
