// @flow
import * as React from 'react';

import HoverTooltip from '../../basic-components/hover-tooltip';
import useMediaQuery from '../../hooks/use-media-query';

type Props = {
  children: React.Node,
  text: string,
  className?: string,
  tooltipHidden?: boolean,
  onClick: (e: MouseEvent) => void,
  gap?: number,
};

export default function ButtonWithTooltip({
  children,
  className,
  tooltipHidden,
  text,
  onClick,
  gap,
}: Props): React.Node {
  const largeScreen = useMediaQuery();

  return (
    <HoverTooltip
      direction="bottom"
      message={text}
      disabled={!largeScreen || tooltipHidden}
      onClick={onClick}
      gap={gap}
    >
      <button className={className} type="button">
        {children}
      </button>
    </HoverTooltip>
  );
}
