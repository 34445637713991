// @flow
import * as React from 'react';
import get from 'lodash-es/get';
import cx from 'classnames';

import { useOutsideClick } from '../../hooks/use-outside-click';
import findAncestorByCSSProperty from '../../utils/findAncestorByCSSProperty';
import Portal from '../portal';
import DropdownItem from './dropdown-item';
import DropdownSeparator from './dropdown-separator';

import type {
  DropdownInputProps,
  IndexUpdaterFunc,
  OptionRendererFunc,
  Option,
} from './typings';

import cs from './styles.pcss';

export type Props = {
  children?: (props: DropdownInputProps) => React.Node,
  component?: React.ComponentType<DropdownInputProps>,
  onChange: (value: mixed) => void,
  onKeyDown?: (e: SyntheticEvent<HTMLElement>) => void,
  directionHandlers?: {
    start: IndexUpdaterFunc,
    end: IndexUpdaterFunc,
    next: IndexUpdaterFunc,
    prev: IndexUpdaterFunc,
    pageUp: IndexUpdaterFunc,
    pageDown: IndexUpdaterFunc,
  },
  options?: Array<Option>,
  className?: string,
  justify?: 'left' | 'right' | 'stretch',
  size?: 'small' | 'medium',
  dropdownClassName?: string,
  renderOption?: OptionRendererFunc,
  renderEmptyDropdown?: () => React.Node,
  value?: mixed,
  pageSize?: number,
  isShown?: boolean,
  spaceToggles?: boolean,
  deleteRemoves?: boolean,
  backspaceRemoves?: boolean,
  keyword?: string,
  onLoadMore?: () => void,
  multipleSelectedValues?: string[],
  multiline?: boolean,
  inPortal?: boolean,
  forceDirection?: 'up' | 'down',
  disableToggleDown?: boolean,
};

const getFocusedValue = (options, value) => {
  const option = options.find((o) => o && !o.hidden && o.value === value);
  return option ? value : get(options, '[0].value');
};

const getFocusableOptions = (options: Array<Option>) =>
  options.filter(
    (o) =>
      o.type !== 'separator' &&
      o.type !== 'notFound' &&
      o.type !== 'loading' &&
      !o.hidden
  );

const defaultDirectionHandlers = {
  start: () => 0,
  end: (_: number, optionNumder: number) => optionNumder - 1,
  next: (index: number, optionNumder: number) => (index + 1) % optionNumder,
  prev: (index: number, optionNumder: number) =>
    index > 0 ? index - 1 : optionNumder - 1,
  pageUp: (index: number, _: number, pageSize: number) => {
    const potentialIndex = index - pageSize;
    return potentialIndex < 0 ? 0 : potentialIndex;
  },
  pageDown: (index: number, optionNumder: number, pageSize: number) => {
    const potentialIndex = index + pageSize;
    return potentialIndex > optionNumder ? optionNumder - 1 : potentialIndex;
  },
};

function Dropdown({
  options = [],
  directionHandlers = defaultDirectionHandlers,
  pageSize = 5,
  justify = 'stretch',
  size = 'medium',
  isShown = false,
  spaceToggles = true,
  deleteRemoves = true,
  backspaceRemoves = true,
  renderEmptyDropdown = () => null,
  onKeyDown = () => {},
  renderOption,
  dropdownClassName,
  keyword,
  value,
  multipleSelectedValues,
  multiline,
  children,
  onLoadMore,
  component,
  onChange,
  className,
  inPortal,
  forceDirection,
  disableToggleDown,
}: Props): React.Node {
  const focusableOptions = getFocusableOptions(options);
  const firstOpen = React.useRef(true);
  const [shown, setShown] = React.useState(isShown);
  const [focusedValue, setFocusedValue] = React.useState(() =>
    getFocusedValue(focusableOptions, value)
  );
  const [focusedOptionPosition, setFocusedOptionPosition] =
    React.useState(null);
  const [measuredFocusedOption, setMeasuredFocusedOption] =
    React.useState(null);

  const clickRef = React.useRef();
  const wrapper = React.useRef();
  const borderElement = React.useRef();
  const dropdown = React.useRef<?HTMLDivElement>();

  const focusedOptionRef = React.useCallback(
    (node) => {
      if (node) {
        const { top, bottom, height } = node.getBoundingClientRect();
        setFocusedOptionPosition({
          top,
          bottom,
          height,
          offsetHeight: node.offsetHeight,
          offsetTop: node.offsetTop,
        });
        setMeasuredFocusedOption(focusedValue);
      }
    },
    [focusedValue, setFocusedOptionPosition, setMeasuredFocusedOption]
  );

  const scrollTimeoutId = React.useRef(null);

  React.useEffect(() => {
    if (wrapper && wrapper.current) {
      borderElement.current = findAncestorByCSSProperty(
        wrapper.current,
        'overflow',
        'hidden'
      );
    }
  }, []);

  const scrollToFocusedOption = () => {
    if (focusedOptionPosition && dropdown && dropdown.current) {
      const dropdownRect = dropdown.current.getBoundingClientRect();

      // Scroll top to the focused option
      if (
        dropdown.current &&
        focusedOptionPosition.bottom > dropdownRect.bottom
      ) {
        dropdown.current.scrollTop =
          focusedOptionPosition.offsetTop +
          focusedOptionPosition.height -
          dropdown.current.offsetHeight;
      }

      // Scroll bottom to the focused option
      if (dropdown.current && focusedOptionPosition.top < dropdownRect.top) {
        dropdown.current.scrollTop =
          focusedOptionPosition.offsetTop +
          focusedOptionPosition.height -
          focusedOptionPosition.offsetHeight;
      }
    }
  };

  React.useEffect(() => {
    const hasFocusedOption = focusableOptions.some(
      (o) => o && o.value === focusedValue
    );
    if (!hasFocusedOption) {
      setFocusedValue(getFocusedValue(focusableOptions, value));
    }
  }, [focusableOptions]);

  React.useEffect(() => {
    if (value !== focusedValue) {
      setFocusedValue(getFocusedValue(focusableOptions, value));
    }
  }, [value]);

  React.useEffect(() => {
    if (
      shown &&
      firstOpen.current &&
      dropdown &&
      dropdown.current &&
      focusedOptionPosition
    ) {
      // Scroll to the selected option on dropdown open
      dropdown.current.scrollTop = focusedOptionPosition.offsetTop;
      firstOpen.current = false;
    } else if (measuredFocusedOption) {
      scrollToFocusedOption();
    }
  }, [measuredFocusedOption, shown]);

  const getDropdownDirection = (): string => {
    if (forceDirection) {
      return forceDirection;
    }

    const borderRect =
      borderElement && borderElement.current
        ? borderElement.current.getBoundingClientRect()
        : null;
    const wrapperRect =
      wrapper && wrapper.current
        ? wrapper.current.getBoundingClientRect()
        : null;

    if (!borderRect || !wrapperRect) return 'down';

    const spaceBelow = borderRect.bottom - wrapperRect.bottom;
    if (spaceBelow >= 310) return 'down';

    const spaceAbove = wrapperRect.top - borderRect.top;
    return spaceAbove > spaceBelow ? 'up' : 'down';
  };

  const getValueFocusIndex: (value: any) => number = (
    newValue: any
  ): number => {
    const index = focusableOptions.findIndex((o) => o && o.value === newValue);
    return index === -1 ? 0 : index;
  };

  const toggleDropdown: (showStatus?: boolean) => void = (
    showStatus?: boolean
  ) => {
    const newShown = typeof showStatus === 'boolean' ? showStatus : !shown;

    if (newShown === shown) return;

    firstOpen.current = newShown;

    if (newShown) {
      setFocusedValue(getFocusedValue(focusableOptions, value));
    }

    setShown(newShown);
  };

  useOutsideClick(clickRef, () => toggleDropdown(false));

  const selectFocusedOption: () => void = () => {
    onChange(focusedValue);
    if (undefined === disableToggleDown || !disableToggleDown) {
      toggleDropdown();
    }
  };

  const focusAdjacentOption = (indexUpdater: IndexUpdaterFunc) => {
    if (!shown) {
      toggleDropdown();
      return;
    }

    if (!focusableOptions.length) return;
    const focusedIndex = indexUpdater(
      getValueFocusIndex(focusedValue),
      focusableOptions.length,
      pageSize
    );

    setFocusedValue(focusableOptions[focusedIndex].value);
  };

  const handleKeyDown: (event: SyntheticKeyboardEvent<HTMLElement>) => void = (
    event: SyntheticKeyboardEvent<HTMLElement>
  ) => {
    if (onKeyDown(event)) {
      return;
    }

    switch (event.keyCode) {
      case 8: // backspace
        if (backspaceRemoves) {
          onChange();
        }
        return;
      case 46: // delete
        if (deleteRemoves) {
          onChange();
        }
        return;
      case 9: // tab
        if (shown) {
          toggleDropdown();
        }
        return;
      case 32: // space
        if (!spaceToggles) return;
        if (shown) {
          selectFocusedOption();
        } else {
          toggleDropdown();
        }
        event.stopPropagation();
        break;
      case 13: // enter
        if (shown) {
          selectFocusedOption();
        } else {
          toggleDropdown();
        }
        event.stopPropagation();
        break;
      case 27: // escape
        if (shown) {
          toggleDropdown();
          event.stopPropagation();
        }
        break;
      case 38: // up
        focusAdjacentOption(directionHandlers.prev);
        break;
      case 40: // down
        focusAdjacentOption(directionHandlers.next);
        break;
      case 33: // page up
        focusAdjacentOption(directionHandlers.pageUp);
        break;
      case 34: // page down
        focusAdjacentOption(directionHandlers.pageDown);
        break;
      case 35: // end key
        if (event.shiftKey) {
          return;
        }
        focusAdjacentOption(directionHandlers.end);
        break;
      case 36: // home key
        if (event.shiftKey) {
          return;
        }
        focusAdjacentOption(directionHandlers.start);
        break;
      default:
        return;
    }

    event.preventDefault();
  };

  const handleScroll: () => void = (): void => {
    if (onLoadMore && !scrollTimeoutId.current) {
      scrollTimeoutId.current = setTimeout(() => {
        scrollTimeoutId.current = null;
        if (dropdown && dropdown.current) {
          const loadMore =
            Math.abs(
              dropdown.current.scrollHeight -
                dropdown.current.scrollTop -
                dropdown.current.clientHeight
            ) < 50;
          if (loadMore && onLoadMore) {
            onLoadMore();
          }
        }
      }, 200);
    }
  };

  const renderDropdown = (): React.Node => {
    if (!shown) return null;

    const direction = getDropdownDirection();

    const items: React.Node =
      options.length === 0
        ? // Empty dropdown message
          renderEmptyDropdown()
        : // Options
          options.map((option, index) => {
            if (!option) {
              return null;
            }
            if (option.type === 'separator') {
              // eslint-disable-next-line react/no-array-index-key
              return <DropdownSeparator key={`${option.type}-${index}`} />;
            }

            if (option.type === 'loading' || option.type === 'notFound') {
              return (
                <DropdownItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${option.type}-${index}`}
                  size={size}
                  option={option}
                  buttonRef={() => {}}
                  focused={false}
                  loading={option.type === 'loading'}
                  notFound={option.type === 'notFound'}
                />
              );
            }

            return (
              <DropdownItem
                key={String(option.value)}
                size={size}
                option={option}
                keyword={keyword}
                renderOption={renderOption}
                buttonRef={focusedOptionRef}
                onMouseDown={selectFocusedOption}
                onMouseEnter={setFocusedValue}
                multiline={multiline}
                selected={
                  value === option.value ||
                  (multipleSelectedValues &&
                    multipleSelectedValues.includes(option.value))
                }
                focused={
                  option.value === focusedValue ||
                  (focusedValue === undefined && index === 0)
                }
              />
            );
          });

    if (!items) {
      return null;
    }

    const dropdownBody = (
      <div
        className={cx(cs.dropdown, dropdownClassName, cs[justify], cs[size], {
          [cs.isDirectionUp]: direction === 'up',
          [cs.isDirectionDown]: direction === 'down',
        })}
      >
        <div
          ref={dropdown}
          className={cx(cs.options, cs[justify])}
          onScroll={handleScroll}
        >
          {items}
        </div>
      </div>
    );

    if (inPortal) {
      const position =
        wrapper && wrapper.current && wrapper.current.getBoundingClientRect();

      return (
        <Portal id="portal-dropdowns">
          <div
            style={
              position
                ? {
                    position: 'absolute',
                    left: position.left,
                    top: direction === 'up' ? position.top : position.bottom,
                    width: position.width,
                    zIndex: 100,
                  }
                : {}
            }
          >
            {dropdownBody}
          </div>
        </Portal>
      );
    }

    return dropdownBody;
  };

  const renderDropdownInput = (inputProps: any): React.Node => {
    if (children) {
      return children(inputProps);
    }
    if (component) {
      return React.createElement(component, inputProps);
    }

    console.warn('Provide either children or component to Dropdown'); // eslint-disable-line no-console
    return null;
  };

  return (
    <div ref={clickRef} className={cs.fullWidth}>
      <div
        ref={wrapper}
        className={cx(cs.dropdownWrapper, className, cs[size])}
      >
        {renderDropdownInput({
          option: options.find((o) => o && o.value === value),
          value,
          isShown: shown,
          toggleDropdown,
          handleKeyDown,
        })}
        {renderDropdown()}
      </div>
    </div>
  );
}

export default Dropdown;
