// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import cs from './styles.pcss';
import Loader from '../../basic-components/loader';

const cx = classNames.bind(cs);

type Props = {
  children?: React.Node,
};

function LoadingScreen({ children }: Props): React.Element<'div'> {
  return (
    <div data-testid="loadingScreen" className={cx('loadingScreen')}>
      <Loader size={24} />
      {children && <div className={cx('text')}>{children}</div>}
    </div>
  );
}

export default LoadingScreen;
