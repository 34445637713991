// @flow
import * as React from 'react';

type ContextProps = {
  scrolled: boolean,
  setScrolled: (scrolled: boolean) => void,
};

export const ScrollContext: React$Context<ContextProps> =
  React.createContext<ContextProps>({
    scrolled: false,
    setScrolled: () => {},
  });

type Props = {
  children: React.Node,
};

export function ScrollContextProvider({ children }: Props): React.Node {
  const [scrolled, setScrolled] = React.useState(false);

  const value: ContextProps = React.useMemo(
    () => ({ scrolled, setScrolled }),
    [scrolled, setScrolled]
  );

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
}
