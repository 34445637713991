export function rollUpdatesByIndex(collection, updates) {
  const result = collection.map((item) => ({ ...item }));

  updates.forEach((update) => {
    if (update.addNew) {
      result.push(update.value);
    } else if (result.length - 1 < update.changeset.index) {
      result.push({
        [update.field]: update.changeset[update.field],
      });
    } else {
      result[update.changeset.index][update.field] =
        update.changeset[update.field];
    }
  });

  return result;
}

export function specificNotEmpty(specific) {
  return (
    (specific.name && specific.name !== '') ||
    (specific.value && specific.value !== '')
  );
}

/**
 * Returns translated title and title path with fallback to original language
 */
export function getTranslatedCategory(category) {
  if (category.translatedTitle) {
    return {
      title: category.translatedTitle,
      fullTitle: category.translatedFullTitle,
      titlePath: category.translatedTitlePath,
    };
  }

  return {
    title: category.title,
    fullTitle: category.fullTitle,
    titlePath: category.titlePath,
  };
}
