// @flow

import * as localeLoader from 'locale-loader';

import { logError } from '../sentry';

// Set default locale

let currentLocale = 'en';

const availableLocales = [
  'en',
  'ru',
  'es',
  'pt',
  'fr',
  'it',
  'de',
  'pl',
  'ja',
  'ko',
  'zh',
];

// Set fallback locale when there is no translation for target language
localeLoader.setFallbackLocale('en');

// Log missing translations to sentry (if fallback locale also fails)
localeLoader.onTranslationNotFound((locale, key) => {
  logError(`Missing translation for ${locale}:${key}`);
  return `${locale}:${key}`;
});

localeLoader.onTranslationFormatError((error, locale, key) => {
  logError(`
    Can not parse translation for ${locale}:${key}
    Error: ${error}
  `);
  return `${locale}:${key}`;
});

export function setLocale(locale: string) {
  if (availableLocales.indexOf(locale) !== -1) {
    currentLocale = locale;
    localeLoader.setLocale(locale);
  }
}

export function getLocale(): string {
  return currentLocale;
}

type FormatNumberOptions = {
  currency?: string,
  style?: string,
};

export function formatNumber(
  value: number | string,
  options: FormatNumberOptions = {}
): string {
  if (!options || !options.currency) return value.toString();

  if (window.Intl) {
    return new window.Intl.NumberFormat([getLocale(), 'en'], options).format(
      value
    );
  }

  if (options.style === 'currency') {
    return `${value} ${options.currency}`;
  }

  return value.toString();
}

export function formatDate(date: Date | string, options: Object): any | string {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  if (window.Intl) {
    return window.Intl.DateTimeFormat(getLocale(), options).format(parsedDate);
  }

  return parsedDate.toString();
}
