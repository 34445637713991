// @flow

import { createAction } from 'redux-actions';

import type { ThunkDispatch, GetState } from '../typings/redux';

export const open: {
  (
    payload: mixed,
    ...rest: Array<any>
  ): { error?: boolean, payload: any, type: string, ... },
  +toString: () => string,
  ...
} = createAction('LIGHTBOX/OPEN', (payload: string) => payload);

export const close: {
  (
    payload: mixed,
    ...rest: Array<any>
  ): { error?: boolean, payload: any, type: string, ... },
  +toString: () => string,
  ...
} = createAction('LIGHTBOX/CLOSE', (payload?: string) => payload);

export function switchTo(fromLightbox: string, toLightbox: string): any {
  return (dispatch: ThunkDispatch, getState: GetState<*>) => {
    dispatch(close(fromLightbox));

    const {
      lightbox: { opened },
    } = getState();
    if (!opened.includes(toLightbox)) {
      dispatch(open(toLightbox));
    }
  };
}
