// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  left?: React.Node,
  center?: React.Node,
  right?: React.Node,
  className?: string,
};

function ThreeColumnsLayout({
  left,
  center,
  right,
  className,
}: Props): React.Element<'div'> {
  return (
    <div className={cx('threeColumnsLayout', className)}>
      <div className={cx('center')}>{center}</div>
      <div className={cx('right')}>{right}</div>
      <div className={cx('left')}>{left}</div>
    </div>
  );
}

export default ThreeColumnsLayout;
