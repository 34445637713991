// @flow
import * as React from 'react';

export const useThrottle = (handler: () => void, ms: number): (() => void) => {
  const throttleTimeoutId = React.useRef();

  return () => {
    if (!throttleTimeoutId || !throttleTimeoutId.current) {
      throttleTimeoutId.current = setTimeout(() => {
        throttleTimeoutId.current = null;
        handler();
      }, ms);
    }
  };
};
