// @flow

import * as React from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { path } from 'ramda';
import cx from 'classnames';
import { connect } from 'react-redux';

import Portal from '../../basic-components/portal';
import Notification from '../../basic-components/notification';
import { notificationsVar } from '../../utils/apollo-cache';
import { NotificationsUserData } from '../../shared-queries/NotificationsUserData.graphql';
import { onRemoveNotification } from '../../actions/notifications';
import useFeedbackPriority from '../../hooks/use-feedback-priority';
import SyncStatus from '../sync/status';
import ImportStatus from '../import-status';
import XLSStatus from '../xls-status';
import OnboardingTour from '../onboarding-tour';
import settings from '../../settings';
import keys from '../../settings/storage';
import usePersistState from '../../hooks/use-persist-state';
import { useApolloLightbox } from '../../hooks/use-apollo-lightbox';
import ANALYTICS from '../../analytics/feedback';

import t from './locale';
import cs from './styles.pcss';

type OwnProps = {
  page?: string,
  className?: string,
  unauthorized?: boolean,
};

type Props = {
  ...OwnProps,
  onClose: (string) => void,
  notifications: Array<{
    type: 'success' | 'error' | 'info',
    message?: React.Node,
    id: string,
    code?: string,
  }>,
};

function NotificationsCenter({
  page,
  notifications,
  onClose,
  className,
  unauthorized,
}: Props) {
  const [feedbackLowPriority] = useFeedbackPriority(unauthorized);
  const { openLightbox: openFeedback } = useApolloLightbox('feedbackLightbox');
  const handleShowHelp = () => {
    openFeedback();
    ANALYTICS.show(feedbackLowPriority);
  };

  const [invalidTokenClosed, setInvalidTokenClosed] = React.useState(false);
  const { data, loading } = useQuery(NotificationsUserData, {
    skip: unauthorized,
  });

  const [hideLimitReachedError, setHideLimitReachedError] = usePersistState(
    keys.hideLimitReachedError
  );

  const apolloNotifications = useReactiveVar(notificationsVar);
  let allNotifications = [...apolloNotifications, ...notifications];
  if (hideLimitReachedError) {
    allNotifications = allNotifications.filter(
      (n) => n.code !== 'limit_reached'
    );
  }

  const accounts = path(['viewer', 'accounts'], data);
  const invalidAccount = accounts && accounts.find((acc) => !acc.tokenValid);

  return (
    <Portal id="portal-notifications">
      <div className={cx(cs.notifications, className)}>
        {invalidAccount && !loading && !invalidTokenClosed && (
          <Notification
            type="error"
            id="invalidToken"
            message={
              <>
                <span>{t('accountExpired.text.pre')}</span>
                <b>{invalidAccount.name}</b>
                <span>{t('accountExpired.text.post')}</span>
              </>
            }
            action={{
              text: t('accountExpired.button'),
              href: settings.auth.link,
            }}
            onClose={() => setInvalidTokenClosed(true)}
            onShowHelp={handleShowHelp}
            className={cx('notification')}
          />
        )}
        {allNotifications.map((notification, index) =>
          index === allNotifications.length - 1 ? (
            <Notification
              {...notification}
              key={notification.id}
              onClose={(id) => {
                if (notification.code === 'limit_reached') {
                  setHideLimitReachedError(true);
                }
                notificationsVar(
                  apolloNotifications.filter((n) => n.id !== id)
                );
                onClose(id);
              }}
              onShowHelp={handleShowHelp}
            />
          ) : null
        )}
        {page !== 'onboarding' && page !== 'login' && !unauthorized && (
          <>
            <SyncStatus />
            <ImportStatus />
            <XLSStatus />
          </>
        )}
        {page !== 'onboarding' && page !== 'login' && !unauthorized && (
          <OnboardingTour page={page} />
        )}
      </div>
    </Portal>
  );
}

export default (connect<Props, OwnProps, _, _, _, _>(
  (state) => ({
    notifications: state.notifications
      ? state.notifications.list.map((notification) => ({
          id: notification.id,
          ...notification.data,
        }))
      : [],
  }),
  {
    onClose: onRemoveNotification,
  }
)(NotificationsCenter): any);
