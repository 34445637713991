// @flow

import * as React from 'react';
import { Field } from 'react-final-form';

type Props = {
  name: string,
  children: (value: any) => React.Node,
};

/**
 * `FieldValue` wrapper helps to get field's value from the form state.
 * Usage:
 *   <FieldValue name={fieldName}>
 *     (value => ...)
 *   </FieldValue>
 */
function FieldValue({ name, children }: Props): React.Node {
  return (
    <Field name={name} subscription={{ value: true }}>
      {({ input: { value } }) => children(value)}
    </Field>
  );
}

export default FieldValue;
