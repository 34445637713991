// @flow
import * as React from 'react';

import { isEmpty } from 'ramda';
import ConnectedLightbox from '../../basic-components/lightbox/connected';
import Button from '../../basic-components/button';
import ThreeColumnsLayout from '../../basic-components/three-columns-layout';
import OneColumnLayout from '../../basic-components/one-column-layout';
import Errors from '../errors';
import { LightboxContextProvider } from '../../basic-components/lightbox/context';

import t from './locale';
import cs from './styles.pcss';

type Props = {
  title: string,
  lightboxName: string,
  children: React.Node,
  dangerous?: boolean,
  dangerousHeader?: boolean,
  confirmLabel: string,
  cancelLabel: string,
  disabled?: boolean,
  errors?: ?$ReadOnlyArray<React.Node>,
  onClose?: (confirmed: boolean) => void,
  onConfirm?: () => Promise<*> | void,
  isMobile?: boolean,
};

export default function ConfirmMessage({
  dangerous = false,
  onConfirm,
  onClose,
  title,
  children,
  dangerousHeader,
  confirmLabel,
  cancelLabel,
  lightboxName,
  disabled,
  errors,
  isMobile = false,
}: Props): React.Node {
  const [confirmed, setConfirmed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const createHandleClick: (
    closeLightbox: () => Promise<void>,
    confirmed: boolean
  ) => () => void =
    (closeLightbox: () => Promise<void>, newConfirmed: boolean) => () => {
      const close = () => {
        closeLightbox();
        setConfirmed(newConfirmed);
        setLoading(false);
      };

      if (onConfirm && newConfirmed) {
        setLoading(true);

        Promise.resolve(onConfirm())
          .then(close)
          .catch(() => {
            setLoading(false);
          });
      } else {
        close();
      }
    };

  const handleClose: () => void = () => onClose && onClose(confirmed);

  const renderCloseButton = (closeOverlay) => (
    <Button
      onClick={createHandleClick(closeOverlay, false)}
      mode="secondary"
      size={isMobile ? 'inModal' : 'medium'}
    >
      {cancelLabel || t('cancel')}
    </Button>
  );

  const renderErrors = () => <Errors className={cs.errors} errors={errors} />;

  const renderConfirmButton = (closeOverlay) => (
    <Button
      onClick={createHandleClick(closeOverlay, true)}
      mode={dangerous ? 'dangerous' : 'main'}
      disabled={disabled}
      loading={loading}
      size={isMobile ? 'inModal' : 'medium'}
    >
      {confirmLabel || t('confirm')}
    </Button>
  );

  const renderFooter = React.useCallback(
    (closeOverlay: () => Promise<void>) => {
      if (isMobile) {
        return (
          <OneColumnLayout>
            {renderConfirmButton(closeOverlay)}
            {errors && !isEmpty(errors) && renderErrors()}
            {renderCloseButton(closeOverlay)}
          </OneColumnLayout>
        );
      }
      return (
        <ThreeColumnsLayout
          left={renderCloseButton(closeOverlay)}
          center={renderErrors()}
          right={renderConfirmButton(closeOverlay)}
        />
      );
    },
    [
      cancelLabel,
      errors,
      dangerous,
      disabled,
      loading,
      confirmLabel,
      createHandleClick,
    ]
  );

  return (
    <LightboxContextProvider>
      <ConnectedLightbox
        dangerousHeader={dangerousHeader}
        small
        onClose={handleClose}
        lightboxName={lightboxName}
        header={title}
        footer={renderFooter}
      >
        <div className={cs.wrapper}>
          <div className={cs.content}>{children}</div>
        </div>
      </ConnectedLightbox>
    </LightboxContextProvider>
  );
}
