// @flow
import { useReactiveVar } from '@apollo/client';
import { without } from 'ramda';

import ANALYTICS from '../analytics/lightbox';
import { lightboxesVar } from '../utils/apollo-cache';

export function useApolloLightbox(
  id: string,
  analyticsCategory?: string
): {
  openLightbox: () => void,
  closeLightbox: () => void,
  lightboxes: string[],
} {
  const lightboxes = useReactiveVar(lightboxesVar);

  const openLightbox = () => {
    if (!lightboxes.includes(id)) {
      ANALYTICS.openLightbox(id, analyticsCategory);
      lightboxesVar([...lightboxes, id]);
    }
  };

  const closeLightbox = () => {
    if (lightboxes.includes(id)) {
      ANALYTICS.closeLightbox(id, analyticsCategory);
      lightboxesVar(without([id], lightboxes));
    }
  };

  return { openLightbox, closeLightbox, lightboxes };
}
