import update from 'immutability-helper';
import isEqual from 'lodash-es/isEqual';

import { normalizeData } from '../normalizers/product';
import { initialState } from './productInitialState';

import {
  SET_ACTIVE_ID,
  SET_ACTIVE_LISTING_ID,
  SET_DATA,
  CLEAR_DATA,
  SET_CATEGORIES,
  SET_CATEGORIES_LOADING,
  SET_SUGGESTED_CATEGORIES,
  SET_LOADING,
  SET_SAVING,
  SET_ARCHIVING,
  SHOW_SUCCESS_MESSAGE,
  UPDATE_FORM,
  SET_SAVE_ERROR,
  CLEAR_CHANGES,
} from '../actions/product';

function mergeCategories(listings, id, categories) {
  return listings.map((listing) => {
    if (listing.id === id) {
      return {
        ...listing,
        categories,
      };
    }
    return {
      ...listing,
    };
  });
}

function mergeSuggestedCategories(listings, id, suggestedCategories) {
  return listings.map((listing) => {
    if (listing.id === id) {
      return {
        ...listing,
        suggestedCategories,
      };
    }
    return {
      ...listing,
    };
  });
}

function getFieldUpdate(updates, data) {
  if (!data) {
    return updates;
  }

  const index = updates.findIndex((u) => u && u.id === data.id);

  if (
    isEqual(data.value, data.defaultValue) ||
    (data.value === '' && data.defaultValue === null)
  ) {
    if (index > -1) {
      return update(updates, { $splice: [[index, 1]] });
    }

    return updates;
  }
  if (index > -1) {
    return update(updates, { $splice: [[index, 1, data]] });
  }

  return update(updates, { $push: [data] });
}

export default function product(state = initialState, action) {
  switch (action && action.type) {
    case SET_DATA:
      return update(state, {
        data: {
          $set: normalizeData(action.data),
        },
        state: {
          changed: {
            $set: state.state.changed || !action.initial,
          },
          isNewProduct: {
            $set: action.isNewProduct,
          },
        },
      });
    case CLEAR_DATA:
      return update(state, {
        data: {
          $set: initialState.data,
        },
      });
    case SET_CATEGORIES:
      return update(state, {
        data: {
          listings: {
            $set: mergeCategories(state.data.listings, action.id, action.data),
          },
        },
      });
    case SET_CATEGORIES_LOADING:
      return update(state, {
        state: {
          categoriesLoading: {
            $set: action.loading,
          },
        },
      });
    case SET_SUGGESTED_CATEGORIES:
      return update(state, {
        data: {
          listings: {
            $set: mergeSuggestedCategories(
              state.data.listings,
              action.listingId,
              action.data
            ),
          },
        },
      });
    case SET_ACTIVE_ID:
      return update(state, {
        state: {
          activeId: {
            $set: action.id,
          },
        },
      });
    case SET_ACTIVE_LISTING_ID:
      return update(state, {
        state: {
          activeListingId: {
            $set: action.id,
          },
        },
      });
    case SET_LOADING:
      return update(state, {
        state: {
          loading: {
            $set: action.loading,
          },
        },
      });
    case SET_SAVING:
      return update(state, {
        state: {
          saving: {
            $set: action.saving,
          },
        },
      });

    case SET_ARCHIVING:
      return update(state, {
        state: {
          archiving: {
            $set: action.archiving,
          },
        },
      });

    case SHOW_SUCCESS_MESSAGE:
      return update(state, {
        state: {
          showSuccessMessage: {
            $set: action.visible,
          },
        },
      });
    case SET_SAVE_ERROR:
      return update(state, {
        state: {
          saveError: {
            $set: action.error,
          },
        },
      });
    case UPDATE_FORM:
      return update(state, {
        updates: {
          $set: getFieldUpdate(state.updates, action.data),
        },
      });
    case CLEAR_CHANGES:
      return update(state, {
        updates: {
          $set: [],
        },
      });

    default: {
      return state;
    }
  }
}
