// @flow
import * as React from 'react';
import { path, pathOr } from 'ramda';
import { useQuery, useSubscription } from '@apollo/client';

import SyncStatusNotification from './notification';
import { ProductSyncStatus, SyncSubscription } from '../operations.graphql';

export default function SyncStatus(): React.Node {
  const { data } = useQuery(ProductSyncStatus);
  const queryState = pathOr([], ['productSyncStates'], data);

  const onSubscriptionData = ({ client, subscriptionData }) => {
    const productSyncStats = path(
      ['data', 'productSyncStats'],
      subscriptionData
    );

    if (productSyncStats) {
      client.writeQuery({
        query: ProductSyncStatus,
        data: {
          ...data,
          productSyncStates: [
            ...queryState.filter(
              (s) => s.syncType !== productSyncStats.syncType
            ),
            productSyncStats,
          ],
        },
      });
    }
  };

  useSubscription(SyncSubscription, {
    onSubscriptionData,
  });

  const productSyncStates = pathOr([], ['productSyncStates'], data);

  return (
    <>
      <SyncStatusNotification
        syncType="quantities"
        productSyncStates={productSyncStates}
      />
      <SyncStatusNotification
        syncType="content"
        productSyncStates={productSyncStates}
      />
    </>
  );
}
