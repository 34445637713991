// @flow
import * as React from 'react';
import cx from 'classnames';

import { logError } from '../../utils/sentry';

import * as iconsNormal from './icons-16';
import * as iconsOthers from './icons-others';
import * as iconsBig from './icons-24';
import cs from './styles.pcss';

export type IconsNormal = $Keys<typeof iconsNormal>;
export type IconsBig = $Keys<typeof iconsBig>;
export type IconsOthers = $Keys<typeof iconsOthers>;

type Props = {
  name: IconsNormal | IconsBig | IconsOthers,
  size?: 16 | 17 | 18 | 21 | 22 | 24 | 32 | 42,
  className?: string,
};

export default function Icon({
  name,
  size = 16,
  className,
}: Props): React.Node {
  const IconSvg =
    // $FlowFixMe
    [24, 32, 42].includes(size) && iconsBig[name]
      ? iconsBig[name]
      : // $FlowFixMe
        iconsNormal[name] || iconsBig[name] || iconsOthers[name];

  if (!IconSvg) {
    const err = new Error(`Can not find icon "${name}" in "${size}" size`);
    logError(err);
    return null;
  }

  return <IconSvg className={cx(cs[`icon${size}`], className)} />;
}
