// @flow
import * as React from 'react';

import { useEventListener } from '../../hooks/use-event-listener';
import { useThrottle } from '../../hooks/use-throttle';

type Props = {
  children: React.Node,
  onTopEnter?: () => void,
  onTopLeave?: () => void,
  className?: string,
  onScroll?: ({
    currentScroll: number,
  }) => void,
};

export default function ScrollListener({
  children,
  onTopEnter,
  onTopLeave,
  className,
  onScroll,
}: Props): React.Node {
  const [top, setTop] = React.useState(0);

  const component = React.useRef();

  const checkPosition: () => void = () => {
    if (!component || !component.current) {
      return;
    }

    const { top: newTop } = component.current.getBoundingClientRect();
    const newScroll = window.pageYOffset;

    if (top > 0 && newTop < 0) {
      if (onTopLeave) {
        onTopLeave();
      }
    }

    if (top < 0 && newTop > 0) {
      if (onTopEnter) {
        onTopEnter();
      }
    }

    if (onScroll) {
      onScroll({ currentScroll: newScroll });
    }

    setTop(newTop);
  };

  const throttledPositionCheck = useThrottle(checkPosition, 200);
  useEventListener('scroll', throttledPositionCheck);

  React.useEffect(() => {
    checkPosition();

    if (component && component.current) {
      const { top: currentTop } = component.current.getBoundingClientRect();
      setTop(currentTop);
    }
  }, []);

  return (
    <div ref={component} className={className}>
      {children}
    </div>
  );
}
