// @flow

import * as React from 'react';
import { getItem, setItem } from '../utils/local-storage';

// set disableSync=true if you don't want to update a storage key after 'setPersistState' is called
const usePersistState = (key: string, disableSync: ?boolean): any[] => {
  const [persistState, setPersistStateLocal] = React.useState<any>(
    getItem(key)
  );

  const setPersistState = (value: mixed) => {
    setItem(key, value);

    if (!disableSync) {
      setPersistStateLocal(value);
    }
  };

  return [persistState, setPersistState];
};

export default usePersistState;
