// @flow

import * as React from 'react';
import { Form as FinalForm, type FormProps } from 'react-final-form';
import { formSubscriptionItems } from 'final-form';

const subscriptions = formSubscriptionItems
  .filter((prop) => prop !== 'active' && prop !== 'touched') // Fix bug with wrong tab behaviour when use FinalForm with ReactFocusLock
  .reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: true,
    }),
    {}
  );

function Form(props: FormProps<any>): React.Node {
  return <FinalForm {...props} subscription={subscriptions} />;
}

export default Form;
