// @flow
import * as React from 'react';

import Lightbox, {
  type Props as LightboxProps,
} from '../../basic-components/lightbox';
import { useApolloLightbox } from '../../hooks/use-apollo-lightbox';

type Props = {
  ...LightboxProps,
  id: string,
  analyticsCategory?: string,
};

export default function ApolloLightbox({
  analyticsCategory,
  id,
  ...props
}: Props): React.Node {
  const { closeLightbox, lightboxes } = useApolloLightbox(
    id,
    analyticsCategory
  );

  const opened = lightboxes.includes(id);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    closeLightbox();
  };

  return (
    <Lightbox
      {...props}
      opened={opened}
      onClose={handleClose}
      lightboxName={id}
    />
  );
}
