// @flow
import * as React from 'react';
import cx from 'classnames';
import { path } from 'ramda';

import HoverTooltip from '../hover-tooltip';
import Icon from '../icon';

import cs from './styles.pcss';
import t from './locale';

type Props = {
  children: React.Node,
  onClick?: (e: MouseEvent) => void,
  className?: string,
  tooltipText?: string,
  textToCopy: string,
};

export default function ClickToCopy({
  tooltipText = t('defaultText'),
  textToCopy,
  className,
  children,
  onClick,
}: Props): React.Node {
  const [copied, setCopied] = React.useState(false);

  const tooltipTimer = React.useRef<null | TimeoutID>(null);

  React.useEffect(
    () =>
      function cleanup() {
        if (tooltipTimer && tooltipTimer.current) {
          clearTimeout(tooltipTimer.current);
        }
      },
    []
  );

  const handleCopy = (e: MouseEvent) => {
    if (path(['clipboard', 'writeText'], navigator)) {
      e.stopPropagation();

      if (onClick) {
        onClick(e);
      }
      navigator.clipboard.writeText(textToCopy);
      setCopied(true);

      if (tooltipTimer && tooltipTimer.current) {
        clearTimeout(tooltipTimer.current);
      }

      tooltipTimer.current = setTimeout(() => {
        setCopied(false);
        tooltipTimer.current = null;
      }, 2000);
    }
  };

  const handleTooltipClose = () => {
    if (tooltipTimer && tooltipTimer.current) {
      clearTimeout(tooltipTimer.current);
      setCopied(false);
      tooltipTimer.current = null;
    }
  };

  const renderMessage = () =>
    copied ? (
      <>
        <Icon className={cs.successIcon} name="checkmarkCircle" />
        {t('copied')}
        <br />
        <span className={cs.textToCopy}>{textToCopy}</span>
      </>
    ) : (
      tooltipText
    );

  return (
    <HoverTooltip
      className={cx(cs.container, className)}
      onClick={handleCopy}
      contentClassName={cs.tooltipContent}
      message={renderMessage()}
      direction="top"
      onClose={handleTooltipClose}
    >
      {children}
    </HoverTooltip>
  );
}
