import { createActions } from 'redux-actions';

import { fetchFile } from '../utils/http';

import settings from '../settings';
import ANALYTICS from '../analytics/upload';

import { open as openLightbox, close as closeLightbox } from './lightbox';

export const {
  upload: { setIsUploading, setXlsContent, setXlsErrors },
} = createActions({
  UPLOAD: {
    SET_IS_UPLOADING: (payload) => payload,
    SET_XLS_CONTENT: (payload) => payload,
    SET_XLS_ERRORS: (payload) => payload,
  },
});

export function uploadShow() {
  return (dispatch) => {
    ANALYTICS.show();
    dispatch(openLightbox('uploadXLSLightbox'));
  };
}

export function uploadHide() {
  return (dispatch) => {
    dispatch(closeLightbox('uploadXLSLightbox'));
    dispatch(setXlsContent(null));
    dispatch(setXlsErrors([]));
  };
}

export function uploadXLS(onReject) {
  return (dispatch, getState) => {
    dispatch(setIsUploading(true));

    const { method, action } = settings.form.upload;

    const { content } = getState().upload.xls;

    const body = new window.FormData();

    body.append('file', content);

    return fetchFile(action, { method, body })
      .then(({ ok, data }) => {
        if (ok) {
          ANALYTICS.success().then(() => {
            dispatch(uploadHide());
          });
        } else {
          ANALYTICS.error();
          dispatch(setXlsErrors(data.errors));
          if (onReject) {
            onReject();
          }
        }
      })
      .catch(() => {
        ANALYTICS.error();
        dispatch(setXlsErrors(['Something went wrong']));
        if (onReject) {
          onReject();
        }
      })
      .then(() => dispatch(setIsUploading(false)));
  };
}
