// @flow
import * as React from 'react';
import { useMutation } from '@apollo/client';
import keys from '../../../settings/storage';
import usePersistState from '../../../hooks/use-persist-state';
import { getItem } from '../../../utils/local-storage';

import NotificationProgress from '../../../basic-components/notification-progress';

import t from './locale';
import { StopSync } from '../operations.graphql';

import type { SyncType } from '../../../typings/product';
import type { ProductSyncStatus_productSyncStates as StateT } from '../../../typings/product-query.flow';

type Props = {
  syncType: SyncType,
  productSyncStates: StateT[],
};

export default function SyncStatusNotification({
  syncType,
  productSyncStates,
}: Props): React.Node {
  const state = productSyncStates.find((s) => s.syncType === syncType);
  const key =
    keys[
      `showNotifSync${syncType?.charAt(0)?.toUpperCase()}${syncType?.slice(1)}`
    ];
  const [stopSync] = useMutation(StopSync, {
    variables: {
      input: { syncType },
    },
  });
  const [showNotifLocalStorage, setShowNotifLocalStorage] =
    usePersistState(key);
  const [showState, setShowState] = React.useState(!!showNotifLocalStorage);

  // FIXME
  React.useEffect(() => {
    const currentValue = getItem(key);
    if (currentValue !== null) {
      setShowState(currentValue);
    }
  });

  if (!state) {
    return null;
  }

  const { total, processed, completed } = state;
  const percentage = Math.min(Math.round((100 * processed) / total), 100);

  const handleClose = () => {
    stopSync();
    setShowNotifLocalStorage(false);
  };

  return (
    <NotificationProgress
      id={`sync-${syncType}`}
      percentage={percentage}
      message={t(`${syncType}.${completed ? 'completedMessage' : 'message'}`)}
      onClose={handleClose}
      completed={completed}
      show={showState}
    />
  );
}
