// @flow

import { sendEvent } from '../utils/analytics';

export default {
  show: (lowPriority?: boolean): Promise<void> =>
    sendEvent({
      category: 'Feedback',
      action: 'Show',
      label: lowPriority ? 'LowPriority' : undefined,
    }),
  attachFile: (lowPriority?: boolean): Promise<void> =>
    sendEvent({
      category: 'Feedback',
      action: 'Attach File',
      label: lowPriority ? 'LowPriority' : undefined,
    }),
  send: (success: boolean, lowPriority?: boolean): Promise<void> =>
    sendEvent({
      category: 'Feedback',
      action: 'Send',
      label: `${success ? 'Success' : 'Error'}${
        lowPriority ? 'LowPriority' : ''
      }`,
    }),

  showRuPost: (): Promise<void> =>
    sendEvent({ category: 'Feedback RuPost', action: 'Show' }),
  sendRuPost: (success: boolean): Promise<void> =>
    sendEvent({
      category: 'Feedback RuPost',
      action: 'Send',
      label: success ? 'Success' : 'Error',
    }),
};
