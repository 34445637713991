// @flow
import * as React from 'react';

import { getBoundingClientRect } from '@dnd-kit/core';
import t from './locale';
import cs from './styles.pcss';
import { getLocale } from '../../utils/i18n';
import settings from '../../settings';

const now = new Date();

type Props = {
  style?: any,
  windowWidth?: ?number,
};

export default function Footer({ style, windowWidth }: Props): React.Node {
  const [marginTop, setMarginTop] = React.useState(0);
  const ref = React.useRef();

  React.useEffect(() => {
    if (ref && ref.current) {
      const rect = getBoundingClientRect(ref.current);
      const margin = Math.max(window.innerHeight - rect.bottom, 0);
      if (margin !== marginTop) {
        setMarginTop(margin);
      }
    }
  }, [style, ref, windowWidth]);

  return (
    <div className={cs.footer}>
      <ul
        ref={ref}
        className={cs.disclaimer}
        style={{
          ...style,
          marginTop,
          width: `${windowWidth ? `${windowWidth}px` : '100%'}`,
        }}
      >
        <li>
          <a
            href={settings.references.helpCenter}
            className={cs.disclaimerLink}
          >
            {t('helpCenter')}
          </a>
        </li>
        <li>
          <a href="/articles/terms" className={cs.disclaimerLink}>
            {t('terms')}
          </a>
        </li>
        <li>
          <a
            href={settings.references.faq(getLocale())}
            className={cs.disclaimerLink}
          >
            {t('faq')}
          </a>
        </li>
        <li>{t('copyright', { year: now.getFullYear() })}</li>
        <li>{t('message')}</li>
      </ul>
    </div>
  );
}
