// @flow

import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import { setStatus } from '../actions/parcels-send-tracking';

import type {
  SendTrackingState as State,
  SetSendTrackingStatus,
} from '../typings/parcels-send-tracking';

const initialState: State = {};

export default (handleActions(
  {
    [setStatus.toString()]: (
      state: State,
      { payload }: { payload: SetSendTrackingStatus }
    ) =>
      update(state, {
        [payload.parcelId]: { $set: payload.status },
      }),
  },
  initialState
): any);
