// @flow

import * as React from 'react';
import cx from 'classnames';

import { mixToPropHandler } from '../../utils/mix-to-prop-handler';

import Icon from '../icon';
import DropdownOption from '../dropdown-option';
import Dropdown from '../dropdown';
import type {
  Option,
  DropdownInputProps,
  OptionRendererFunc,
} from '../dropdown/typings';
import Input from '../input';

import cs from './styles.pcss';

const SELECT_LOADING_VALUE = 'SELECT_LOADING_VALUE';

export type Props = {
  name?: string,
  className?: string,
  size?: 'small' | 'medium',
  inputClassName?: string,
  placeholder?: string,
  error?: boolean,
  value?: mixed,
  selectedOption?: Option,
  options: Array<Option>,
  renderOption?: OptionRendererFunc,
  searchable?: boolean,
  asyncSearchable?: boolean,
  disabled?: boolean,
  loading?: boolean,
  onChange?: (value?: Option) => void,
  onKeywordChange?: (keyword: string) => void,
  onBlur?: () => void,
  innerRef?: React.Ref<any>,
  noIcon?: boolean,
  inputWrapperClassName?: string,
  displayValue?: string,
  onFocus?: () => void,
  multiline?: boolean,
  arrowClassName?: string,
  isMobile?: boolean,
  adaptive?: boolean,
  dropdownForceDirection?: 'up' | 'down',
};

export default function Select({
  innerRef,
  size = 'medium',
  error = false,
  searchable = false,
  asyncSearchable = false,
  onKeywordChange = () => {},
  selectedOption,
  displayValue,
  onChange,
  noIcon,
  name,
  options,
  inputClassName,
  placeholder,
  disabled,
  onBlur,
  onFocus,
  inputWrapperClassName,
  loading,
  value,
  multiline,
  arrowClassName,
  isMobile,
  adaptive,
  dropdownForceDirection,
  ...rest
}: Props): React.Node {
  const [keyword, setKeyword] = React.useState(displayValue || '');
  const [isFocused, setIsFocused] = React.useState(false);

  const getCurrentValue = () => {
    if (selectedOption) {
      return selectedOption.value;
    }

    return value;
  };

  const getValue = (option, isShown, isInputEditable) => {
    const selected = option || selectedOption;

    if (isInputEditable) {
      if (isFocused) {
        return keyword;
      }
      if (selected && selected?.label) {
        return selected.label;
      }
      if (displayValue) {
        return displayValue;
      }
      return keyword;
    }

    if (!isInputEditable) {
      if (selected && selected?.label) {
        return selected.label;
      }

      if (displayValue) {
        return displayValue;
      }
    }
    return '';
  };

  const handleChange = (newValue: mixed) => {
    const newSelectedOption = options.find((o) => o && o.value === newValue);
    if (onChange) {
      if (newSelectedOption) {
        onChange(newSelectedOption);
      } else {
        onChange();
      }
    }
    if (
      typeof newSelectedOption?.label === 'string' &&
      newSelectedOption?.label?.length > 0
    ) {
      setKeyword(newSelectedOption?.label);
    }
  };

  const filterOptions = (isInputEditable: boolean): Array<Option> => {
    if (asyncSearchable) {
      if (loading) {
        return [
          {
            label: '',
            value: SELECT_LOADING_VALUE,
            type: 'loading',
          },
        ];
      }

      return options;
    }

    if (isInputEditable) {
      const trimmedKeyword = keyword ? keyword.trim() : '';
      if (trimmedKeyword !== '') {
        return options.filter((option) =>
          option.label.toLowerCase().includes(trimmedKeyword.toLowerCase())
        );
      }
    }

    return options;
  };

  const renderEmptyDropdown = () => {
    if (!asyncSearchable) {
      return (
        <DropdownOption size={size} notFound>
          {null}
        </DropdownOption>
      );
    }

    return null;
  };

  const renderToggleIcon = () => {
    if (noIcon) {
      return null;
    }

    return (
      <Icon
        name={size === 'small' ? 'selectSmall' : 'selectNormal'}
        className={cs.toggleIcon}
      />
    );
  };

  const isInputEditable = (searchable || asyncSearchable) && !disabled;

  return (
    <Dropdown
      {...rest}
      selectedOption={selectedOption}
      noIcon={noIcon}
      loading={loading}
      size={size}
      options={filterOptions(isInputEditable)}
      onChange={handleChange}
      onKeywordChange={onKeywordChange}
      value={getCurrentValue()}
      keyword={keyword ? keyword.trim() : ''}
      renderEmptyDropdown={renderEmptyDropdown}
      spaceToggles={false}
      deleteRemoves={false}
      backspaceRemoves={false}
      multiline={multiline}
      forceDirection={dropdownForceDirection}
    >
      {({
        toggleDropdown,
        handleKeyDown,
        option,
        isShown,
      }: DropdownInputProps) => (
        <Input
          autoComplete="off"
          name={name}
          size={size}
          innerRef={innerRef}
          error={error}
          value={getValue(option, isShown, isInputEditable)}
          placeholder={placeholder}
          icon={renderToggleIcon()}
          inputClassName={cx(inputClassName, {
            [cs.pointable]: !isInputEditable,
          })}
          className={cx(inputWrapperClassName, cs.pointable)}
          iconClassName={cx(arrowClassName, cs.pointable)}
          onKeyDown={handleKeyDown}
          onMouseDown={toggleDropdown}
          disabled={disabled}
          readOnly={!isInputEditable}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            if (isInputEditable) {
              const { value: newKeyword } = e.currentTarget;
              setKeyword(newKeyword);
              onKeywordChange(newKeyword);
              toggleDropdown(true);
            }
          }}
          onFocus={mixToPropHandler(onFocus, () => {
            setIsFocused(true);
            toggleDropdown(true);
          })}
          onBlur={mixToPropHandler(onBlur, () => {
            if (!displayValue) {
              setKeyword('');
            }
            setIsFocused(false);
            toggleDropdown(false);
          })}
        />
      )}
    </Dropdown>
  );
}
