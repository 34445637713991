// @flow

import * as React from 'react';
import cx from 'classnames';

// import RandomOrder from '../random-order';

import cs from './styles.pcss';

type Props = {
  banners?: React.Node[],
  windowWidth?: ?number,
};

export default function BannersCenter({
  banners,
  windowWidth,
}: Props): React.Node {
  return banners ? (
    <div
      style={windowWidth && { width: windowWidth }}
      className={cx(windowWidth && cs.banners)}
    >
      {/* <RandomOrder> */}
      {React.Children.map(banners, (child) =>
        React.cloneElement(child, {
          className: cx(child.className, cs.banner),
        })
      )}
      {/* </RandomOrder> */}
    </div>
  ) : null;
}
