// @flow
import * as React from 'react';
import cx from 'classnames';

import Icon from '../icon';
import Loader from '../loader';

import cs from './styles.pcss';
import t from './locale';

type Props = {
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void,
  children: React.Node,
  selected?: boolean,
  withSelected?: boolean,
  size?: 'small' | 'medium',
  innerRef?: React.Ref<any>,
  className?: string,
  focused?: boolean,
  notFound?: boolean,
  onMouseDown?: (event: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseEnter?: () => void,
  multiline?: boolean,
  loading?: boolean,
  disabled?: boolean,
  hidden?: boolean,
};

export default function DropdownOption({
  onClick,
  children,
  selected = false,
  withSelected,
  size = 'medium',
  innerRef,
  className,
  focused,
  notFound,
  onMouseDown,
  onMouseEnter,
  multiline,
  loading,
  disabled,
  hidden,
}: Props): React.Node {
  const renderContent = () => {
    if (notFound) {
      return <div className={cs.optionText}>{t('notFound')}</div>;
    }

    if (loading) {
      return (
        <div className={cs.textLoader}>
          <Loader className={cs.loader} />
          <div>{children || t('loading')}</div>
        </div>
      );
    }

    return (
      <>
        <div
          className={cx(cs.optionText, { [cs.selectedOptionText]: selected })}
        >
          {children}
        </div>
        {selected && <Icon name="selectedOption" className={cs.selected} />}
      </>
    );
  };

  if (hidden) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(cs.option, className, {
        [cs.withSelected]: withSelected,
        [cs.small]: size === 'small',
        [cs.focused]: focused,
        [cs.notFound]: notFound,
        [cs.multiline]: multiline,
        [cs.loading]: loading,
        [cs.disabled]: disabled,
      })}
      ref={innerRef}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
    >
      {renderContent()}
    </button>
  );
}
