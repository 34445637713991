// @flow
import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

type Props = {
  className?: string,
};

export default function Hr({ className }: Props): React.Node {
  return <hr className={cx(cs.hr, className)} />;
}
