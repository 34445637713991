export function getCurrencySymbol(currency) {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'AUD':
      return 'A$';
    case 'CAD':
      return 'C$';
    case 'RUB':
      return '₽';
    default:
      return '';
  }
}

// https://developer.ebay.com/devzone/finding/callref/Enums/currencyIdList.html
export function getCurrencyUnitBySiteId(siteId) {
  switch (siteId) {
    case 0:
    case 100:
      return 'USD';
    case 2:
      return 'CAD';
    case 3:
      return 'GBP';
    case 15:
      return 'AUD';
    case 16:
    case 23:
    case 71:
    case 77:
    case 101:
    case 123:
    case 146:
    case 186:
    case 205:
      return 'EUR';
    case 193:
      return 'CHF';
    case 201:
      return 'HKD';
    case 203:
      return 'INR';
    case 207:
      return 'MYR';
    case 211:
      return 'PHP';
    case 212:
      return 'PLN';
    case 216:
      return 'SGD';
    case 218:
      return 'SEK';
    default:
      return 'USD';
  }
}
