// @flow

import { sendEvent, payloadCreator } from '../utils/analytics';

const category = 'Stock-2';
const payload = payloadCreator(category);

export default {
  languageSwitch: (langKey: string): Promise<void> =>
    sendEvent(payload('language', langKey)),
  logout: (): Promise<void> => sendEvent(payload('logout')),
  closeAutoSyncAnnouncement: (): Promise<void> =>
    sendEvent(payload('closeAutoSyncAnnouncement')),
  clickOnAutoSyncAnnouncement: (): Promise<void> =>
    sendEvent(payload('clickOnAutoSyncAnnouncement')),
};
