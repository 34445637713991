// @flow

import { sendEvent, payloadCreator } from '../utils/analytics';

const category = 'achievementPopup';
const payload = payloadCreator(category);

export default {
  show: (prefix: string): Promise<void> => sendEvent(payload(`${prefix}Show`)),
  use: (prefix: string): Promise<void> => sendEvent(payload(`${prefix}Use`)),
  close: (prefix: string): Promise<void> =>
    sendEvent(payload(`${prefix}Close`)),
};
