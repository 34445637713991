// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import { useQuery, useMutation } from '@apollo/client';
import { pathOr, path } from 'ramda';
import { FORM_ERROR } from 'final-form';

import { Form, FormField } from '../../hocs/final-form';
import { useFileUpload } from '../../hooks/use-file-upload';
import { validateEmail, validateNotEmpty } from '../../utils/form';
import Hr from '../../basic-components/hr';
import ApolloLightbox from '../apollo-lightbox';
import InputField from '../../basic-components/input-field';
import TextareaField from '../../basic-components/textarea-field';
import SelectField from '../../basic-components/select-field';
import Button from '../../basic-components/button';
import ThreeColumnsLayout from '../../basic-components/three-columns-layout';
import OneColumnLayout from '../../basic-components/one-column-layout';
import UploadFileField from '../../basic-components/upload-file-field';
import UploadDropZone from '../../basic-components/upload-drop-zone';
import SuccessOverlay from '../success-overlay';
import Status from '../../basic-components/status';
import { FeedbackQuery } from '../../shared-queries/FeedbackQuery.graphql';
import Link from '../../basic-components/link';
import ExternalLink from '../../basic-components/external-link';
import { getLocale } from '../../utils/i18n';
import settings from '../../settings';
import useFeedbackPriority from '../../hooks/use-feedback-priority';
import { SendFeedback } from '../../shared-queries/SendFeedback.graphql';
import ANALYTICS from '../../analytics/feedback';
import translateErrors from '../../utils/i18n/errors-locale';

import cs from './styles.pcss';
import t from './locale';
import useMediaQuery from '../../hooks/use-media-query';

const cx = classNames.bind(cs);

const MAX_UPLOADS = 5;
const ACCEPTED_UPLOAD_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'application/pdf',
  'application/excel',
  'application/x-excel',
  'application/x-msexcel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

type FeedbackData = { email: string, body: string, subject: string };

const closeButton = (
  closeOverlay: () => Promise<void>,
  smallScreen?: boolean
) => (
  <Button onClick={closeOverlay} size={smallScreen ? 'inModal' : 'medium'}>
    {t('buttons.close')}
  </Button>
);

const cancelButton = (
  closeOverlay: () => Promise<void>,
  submitting?: boolean,
  smallScreen?: boolean
) => (
  <Button
    onClick={closeOverlay}
    loading={submitting}
    mode="secondary"
    size={smallScreen ? 'inModal' : 'medium'}
  >
    {t('buttons.cancel')}
  </Button>
);

const submitButton = (
  closeOverlay: () => Promise<void>,
  handleSubmit?: any,
  submitting?: boolean,
  smallScreen?: boolean
) => (
  <Button
    onClick={handleSubmit}
    loading={submitting}
    className={cs.confirm}
    size={smallScreen ? 'inModal' : 'medium'}
  >
    {t('buttons.submit')}
  </Button>
);

const renderFooter =
  (
    submitSucceeded?: boolean,
    submitting?: boolean,
    handleSubmit?: any,
    smallScreen?: boolean
  ) =>
  (closeOverlay: () => Promise<void>) => {
    if (submitSucceeded) {
      if (smallScreen) {
        return (
          <OneColumnLayout>
            {closeButton(closeOverlay, smallScreen)}
          </OneColumnLayout>
        );
      }
      return <ThreeColumnsLayout right={closeButton(closeOverlay)} />;
    }
    if (smallScreen) {
      return (
        <OneColumnLayout>
          {submitButton(closeOverlay, handleSubmit, submitting, smallScreen)}
          {cancelButton(closeOverlay, submitting, smallScreen)}
        </OneColumnLayout>
      );
    }

    return (
      <div className={cs.footer}>
        {cancelButton(closeOverlay, submitting)}
        {submitButton(closeOverlay, handleSubmit, submitting)}
      </div>
    );
  };

const renderCsFooter = () => (closeOverlay: () => Promise<void>) =>
  <ThreeColumnsLayout right={closeButton(closeOverlay, false)} />;

function FeedbackForm(): React.Node {
  const { data } = useQuery(FeedbackQuery);

  const [sendFeedback] = useMutation(SendFeedback);

  const [lowPriority] = useFeedbackPriority();

  const email = pathOr('', ['viewer', 'accounts', 0, 'email'], data);
  const site = pathOr('US', ['viewer', 'accounts', 0, 'site', 'code'], data);
  const smallScreen = useMediaQuery(`(max-width: 768px)`);

  const {
    uploads,
    handleUpload,
    deleteUpload,
    limitReached,
    reset: resetUploads,
  } = useFileUpload({
    maxUploads: MAX_UPLOADS,
    form: settings.form.feedbackUpload,
  });

  return (
    <Form
      onSubmit={(formData: FeedbackData) => {
        const files = uploads
          .map((u) =>
            u.state === 'finished' ? `${u.file.name} - ${u.meta.url}` : null
          )
          .filter(Boolean);

        if (files && files.length > 0) {
          ANALYTICS.attachFile(lowPriority);
        }

        return sendFeedback({
          variables: {
            input: {
              place: 'feedbackPage',
              contact: formData.email,
              body: formData.body,
              subject: formData.subject,
              selectedOptions: [formData.subject],
              imagesUrls: files && files.length > 0 ? files : undefined,
            },
          },
        })
          .then(({ data: feedbackData }) => {
            ANALYTICS.send(true, lowPriority);
            if (path(['feedbackCreate', 'errors', 0], feedbackData)) {
              return {
                [FORM_ERROR]: pathOr(
                  translateErrors('generic_error'),
                  ['feedbackCreate', 'errors', 0],
                  feedbackData
                ),
              };
            }

            return Promise.resolve();
          })
          .catch(() => {
            ANALYTICS.send(false, lowPriority);
            return { [FORM_ERROR]: translateErrors('generic_error') };
          });
      }}
      initialValues={{ email }}
    >
      {({
        handleSubmit,
        submitSucceeded,
        submitFailed,
        submitting,
        submitError,
        values,
        form: { change, reset: resetForm },
      }) => (
        <>
          {/* new feedback dialog for customer support */}
          <ApolloLightbox
            id="csFeedbackLightbox"
            small
            lightboxName="csFeedbackLightbox"
            header={t('header.others')}
            footer={renderCsFooter()}
          >
            <div className={cx('csContent')}>
              If you need help on eBayMag tool, you can send your questions to
              the email (
              <a href="mailto:ebaymagsupport@ebay.com" target="_black">
                ebaymagsupport@ebay.com
              </a>
              ). If you have other ebay related questions, to ensure you get
              quick and accurate answer, please go to your registered ebay site
              contact customer service.
            </div>
          </ApolloLightbox>
          {/* old feedback dialog */}
          <ApolloLightbox
            id="feedbackLightbox"
            small
            lightboxName="feedbackLightbox"
            header={t('header.jpOrKr')}
            className={cs.lightbox}
            onClose={() => {
              if (submitSucceeded) {
                resetForm();
                resetUploads();
              }
            }}
            footer={renderFooter(
              submitSucceeded,
              submitting,
              handleSubmit,
              smallScreen
            )}
          >
            <div className={cx('content')}>
              {submitSucceeded ? (
                <SuccessOverlay
                  message={
                    <>
                      <div>{t('successSubtitle')}</div>
                      <div>
                        {t(
                          lowPriority
                            ? 'successLowPriorityMessage'
                            : 'successMessage'
                        )}
                      </div>
                    </>
                  }
                />
              ) : (
                <>
                  <div className={cx('faq')}>
                    {`${t('description.first')} `}
                    <Link target="_blank" href={settings.references.helpCenter}>
                      {t('description.linkHelpCenter')}
                    </Link>
                    {` ${t('description.or')} `}
                    <Link
                      target="_blank"
                      href={settings.references.faq(getLocale())}
                    >
                      {t('description.linkFAQ')}
                    </Link>
                    {`${
                      !['ja', 'de', 'ko'].includes(getLocale()) ? '?' : ''
                    } ${t(
                      lowPriority
                        ? 'description.secondLowPriority'
                        : 'description.second'
                    )}`}
                    {['ja', 'ko'].includes(getLocale()) && (
                      <p>
                        {`${t('description.third')}`}
                        <Link
                          target="_blank"
                          href={settings.references.eportalLinks[getLocale()]}
                        >
                          {`${t('description.linkEportal')}`}
                        </Link>
                        {`${t('description.end')}`}
                      </p>
                    )}
                    {['ja'].includes(getLocale()) && (
                      <p className={cx('holiday-notice-4-ja')}>
                        <span className={cx('text')}>{`${t(
                          'description.holiday'
                        )}`}</span>
                        <Link
                          className={cx('link')}
                          target="_blank"
                          href={
                            settings.references.feedbackLinks[getLocale()]
                              .holiday
                          }
                        >
                          {`${t('description.linkEportal')}`}
                        </Link>
                        <span className={cx('text')}>{`${t(
                          'description.holidayEnd'
                        )}`}</span>
                      </p>
                    )}
                  </div>
                  <Hr />
                  <UploadDropZone
                    onChange={handleUpload}
                    disabled={limitReached}
                    accept={ACCEPTED_UPLOAD_FORMATS}
                  >
                    <FormField
                      name="email"
                      Component={InputField}
                      validate={validateEmail(t('validate.email'))}
                      label={t('field.email')}
                      className={cx('field')}
                      onBlur={(e) => {
                        // Reset email to initial value if it is empty
                        if (e.target.value === '') {
                          change('email', email);
                        }
                      }}
                      noGrid
                      errorClassName={cx('error')}
                      labelClassName={cx('label')}
                    />
                    <FormField
                      name="subject"
                      Component={SelectField}
                      label={t('field.subject.title')}
                      validate={validateNotEmpty(t('validate.selection'))}
                      options={Object.entries(t('options')).map(
                        ([value, label]) => ({
                          label,
                          value,
                        })
                      )}
                      parse={({ value }) => value}
                      placeholder={t('field.subject.placeholder')}
                      noGrid
                      errorClassName={cx('error')}
                      labelClassName={cx('label')}
                      multiline
                      dropdownForceDirection="down"
                    />
                    {values && values.subject && values.subject === '4' && (
                      <Status
                        icon="exclamationCircle"
                        className={cx('status')}
                        mode="warning"
                      >
                        {t('goToEbay.first')}{' '}
                        <ExternalLink
                          nowrap
                          href={settings.references.helpEbay(site)}
                        >
                          {t('goToEbay.link')}
                        </ExternalLink>{' '}
                        {t('goToEbay.last')}
                      </Status>
                    )}
                    <FormField
                      name="body"
                      Component={TextareaField}
                      label={t(
                        lowPriority
                          ? 'field.bodyLowPriority.title'
                          : 'field.body.title'
                      )}
                      placeholder={t(
                        lowPriority
                          ? 'field.bodyLowPriority.placeholder'
                          : 'field.body.placeholder'
                      )}
                      validate={validateNotEmpty(t('validate.body'))}
                      disabled={submitting}
                      rows="6"
                      noGrid
                      maxLength={2500}
                      errorClassName={cx('error')}
                      labelClassName={cx('label')}
                    />
                    <UploadFileField
                      label={t('field.attachments')}
                      uploadButtonLabel={t('buttons.attachments')}
                      maxUploads={MAX_UPLOADS}
                      uploads={uploads}
                      onDelete={deleteUpload}
                      onChange={handleUpload}
                      accept={ACCEPTED_UPLOAD_FORMATS}
                      disabled={limitReached}
                      noGrid
                      errorClassName={cx('error')}
                      labelClassName={cx('label')}
                      id="feedbackUpload"
                    />
                  </UploadDropZone>
                  {submitFailed && submitError && (
                    <div className={cx('formError')}>{submitError}</div>
                  )}
                </>
              )}
            </div>
          </ApolloLightbox>
        </>
      )}
    </Form>
  );
}

export default FeedbackForm;
