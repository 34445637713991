// @flow
import { createAction } from 'redux-actions';
import { open } from './lightbox';
import ANALYTICS from '../analytics/parcels';

export type ActiveMode =
  | 'parcelsInfo'
  | 'parcelsTracking'
  | 'parcelsTrackingNotRequired'
  | 'parcelsUnion'
  | 'parcelsDisunion'
  | 'dhlForm'
  | 'ruPostForm';

export type ActivePayload = { id: string, mode: ActiveMode };

// Standard actions
export const setActiveId: {
  (
    payload: mixed,
    ...rest: Array<any>
  ): { error?: boolean, payload: any, type: string, ... },
  +toString: () => string,
  ...
} = createAction('PARCELS/SET_ACTIVE_ID', (payload?: string) => payload);

// Page loader

export const setActive =
  (payload?: ActivePayload): ((dispatch: any) => void) =>
  (dispatch: any) => {
    dispatch(setActiveId(payload && payload.id));

    if (payload && payload.mode) {
      dispatch(open(payload.mode));
      ANALYTICS.openPopupEvent(payload.mode);
    }
  };
