// @flow
import { sendEvent, payloadCreator } from '../utils/analytics';

const category = 'onboarding-2';
const payload = payloadCreator(category);

export default {
  changeStep: (label: string): Promise<void> =>
    sendEvent(payload('change Step', label)),
  start: (): Promise<void> => sendEvent(payload('start')),
  finish: (): Promise<void> => sendEvent(payload('finish')),
  cancel: (step: string): Promise<void> => sendEvent(payload('cancel', step)),
  back: (step: string): Promise<void> => sendEvent(payload('back', step)),
  publish: (): Promise<void> => sendEvent(payload('publish')),
  skip: (): Promise<void> => sendEvent(payload('skip')),
  decline: (): Promise<void> => sendEvent(payload('decline')),
  toggleSite: (selected: boolean): Promise<void> =>
    sendEvent(payload('skip', selected ? 'on' : 'off')),
  confirmPaidSiteSelect: (): Promise<void> =>
    sendEvent(payload('confirmPaidSiteSelect')),
  learnMoreClick: (): Promise<void> => sendEvent(payload('learnMoreClick')),
  zeroFreePopoverShown: (): Promise<void> =>
    sendEvent(payload('zeroFreePopoverShown')),
};
