// @flow

import * as React from 'react';
import { Field } from 'react-final-form';
import { identity } from 'ramda';
import type { FieldProps } from 'react-final-form';

import { getVisibleError } from '../utils';
import { mixToPropHandler } from '../../../utils/mix-to-prop-handler';

type Props = FieldProps & {
  Component: any,
  onChange?: (any) => any,
  onBlur?: (SyntheticInputEvent<>) => void,
  onFocus?: (SyntheticInputEvent<>) => void,
  type?: string,
  innerRef?: React.Ref<any>,
  noGrid?: boolean,
};

/**
 * `FormField` connects final-form's Field component and mag's UI components (Input, Textarea, etc)
 * It takes `value`, `error` and event handlers from final-form and pass them into UI component in correct format.
 */
export default function FormField({
  name,
  parse,
  format,
  type,
  formatOnBlur,
  validate,
  allowNull,
  isEqual,
  subscription,
  validateFields,
  Component,
  onChange,
  onBlur,
  onFocus,
  innerRef,
  ...rest
}: Props): React.Node {
  return (
    <Field
      name={name}
      parse={parse}
      format={format}
      formatOnBlur={formatOnBlur}
      validate={validate}
      allowNull={allowNull}
      isEqual={isEqual}
      subscription={subscription}
      validateFields={validateFields}
      type={type}
    >
      {(
        { input, meta } // https://github.com/final-form/react-final-form#fieldrenderprops
      ) => (
        // $FlowFixMe
        <Component
          {...input}
          onChange={
            typeof onChange === 'function'
              ? (v) => input.onChange(onChange(v))
              : input.onChange
          }
          onBlur={mixToPropHandler(onBlur, input.onBlur)}
          onFocus={mixToPropHandler(onFocus, input.onFocus)}
          error={getVisibleError(meta)}
          innerRef={innerRef}
          {...rest}
        />
      )}
    </Field>
  );
}

FormField.defaultProps = {
  parse: identity,
  format: identity,
};
