import getConstantPrefixer from '../utils/actions';
import { isCustomError } from '../utils/http';

const prefix = getConstantPrefixer('NOTIFICATIONS');

// Constants

export const ADD_NOTIFICATION = prefix('ADD_NOTIFICATION');
export const REMOVE_NOTIFICATION = prefix('REMOVE_NOTIFICATION');

// Helper functions

function notificationExists(notifications, notification) {
  if (!notifications) return false;
  return notifications
    .map((n) => JSON.stringify(n.data))
    .includes(JSON.stringify(notification));
}

// Actions

function onAddNotification(data, isClosable = true) {
  return (dispatch, getState) => {
    const notifications = getState().notifications.list;

    if (notificationExists(notifications, data)) {
      return;
    }

    dispatch({ type: ADD_NOTIFICATION, data, isClosable });
  };
}

export const onRemoveNotification = (id) => (dispatch) => {
  dispatch({ type: REMOVE_NOTIFICATION, id });

  return Promise.resolve();
};

export function showError(error) {
  return onAddNotification(
    {
      message: isCustomError(error) ? error.message : undefined,
      type: 'error',
    },
    true
  );
}

export function showGenericError() {
  return onAddNotification(
    {
      type: 'error',
    },
    true
  );
}
