// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import Icon from '../icon';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

export type Props = {
  innerRef?: React.Ref<any>,
  grid?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  label?: React.Node,
  children?: React.Node,
  description?: React.Node,
  error?: React.Node,
  className?: string,
  onLabelClick?: () => void,
  labelClassName?: string,
  multiline?: boolean,
  noGrid?: boolean,
  errorClassName?: string,
  noLabel?: boolean,
  fieldClassName?: string,
  descriptionClassName?: string,
};

export default function Field({
  label,
  description,
  children,
  error,
  grid = 10,
  multiline = false,
  className,
  labelClassName,
  innerRef,
  noGrid = false,
  errorClassName,
  noLabel = false,
  onLabelClick = () => {},
  fieldClassName,
  descriptionClassName,
}: Props): React.Node {
  const handleLabelClick = () => {
    onLabelClick();
  };

  const currentGrid = grid > 10 ? 10 : grid;

  return (
    <>
      <div className={cx('wrapper', { multiline }, className)} ref={innerRef}>
        {!noLabel && (
          <div className={cx('label', labelClassName)}>
            <div
              role="presentation"
              className={cx('labelButton')}
              onClick={handleLabelClick}
            >
              {label || ''}
            </div>
          </div>
        )}
        <div
          className={cx('field', fieldClassName, `grid${currentGrid}`, {
            [cs.noGrid]: noGrid,
          })}
        >
          {children}
        </div>
        {description && (
          <div
            className={cx(
              'description',
              { inColumn: noGrid },
              descriptionClassName
            )}
          >
            {description}
          </div>
        )}
      </div>
      {error && (
        <div
          className={cx('error', errorClassName, `grid${currentGrid}`, {
            noLabel,
          })}
        >
          <Icon name="exclamationCircle" className={cx('errorIcon')} />
          <div className={cs.errorsContainer}>{error}</div>
        </div>
      )}
    </>
  );
}
