import {
  keys,
  reduce,
  anyPass,
  assoc,
  complement,
  compose,
  curry,
  is,
  isEmpty,
  isNil,
  join,
  path,
  pathOr,
  split,
  when,
} from 'ramda';

export const getPath = (_p) =>
  compose(
    when(is(String), (p) => [...split('.', p)]),
    when(is(Array), compose(getPath, join('.')))
  )(_p);

export const getIn = curry((p, v) => path(getPath(p), v));
export const getInOr = curry((defaultValue, p, v) =>
  pathOr(defaultValue, getPath(p), v)
);

export const isNotDefined = anyPass([isEmpty, isNil]);
export const isDefined = complement(isNotDefined);

export const fork = (joinFunction, f1, f2) => (val) =>
  joinFunction(f1(val), f2(val));

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);
