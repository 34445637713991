// @flow
import * as React from 'react';
import cx from 'classnames';
import { equals } from 'ramda';

import Icon from '../../../basic-components/icon';
import Button from '../../../basic-components/button';
import withFiltersContext from '../with-filters-context';

import cs from './styles.pcss';

import type { FilterValueT } from '../typings';

type Props = {
  onChange: (value: FilterValueT) => void,
  value: FilterValueT,
  label: string,
  checkedValue: FilterValueT,
  initialValue: FilterValueT,
};

function Toggler({
  onChange,
  value,
  label,
  checkedValue,
  initialValue,
}: Props) {
  const selected = value === checkedValue || equals(value, checkedValue);

  const handleClick = () => {
    onChange(selected ? initialValue : checkedValue);
  };

  return (
    <Button
      size="small"
      mode={selected ? 'main' : 'secondary'}
      onClick={handleClick}
      className={cx(cs.filter, cs.button)}
      contentClassName={cs.buttonContent}
    >
      {label}
      {selected && (
        <div className={cs.reset}>
          <Icon name="closeCircle" className={cs.cross} />
        </div>
      )}
    </Button>
  );
}

// $FlowFixMe
export default withFiltersContext(Toggler);
