export class NotifyLatest {
  q = [];

  constructor() {
    document.addEventListener('keydown', this.notifyListener);
  }

  addListener(listener) {
    this.q.push(listener);
  }

  removeListener(listener) {
    this.q = this.q.filter((l) => l !== listener);
  }

  notifyListener = (e) => {
    const l = this.q.length;
    if (l > 0) {
      this.q[l - 1](e);
    }
  };
}
