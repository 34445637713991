// @flow

import { handleActions } from 'redux-actions';
import { open, close } from '../actions/lightbox';
import type { LightboxState } from '../typings/lightbox';

const initialState: LightboxState = {
  opened: [],
  closing: [],
};

const pushIfNotIncludes = (arr, id) => (arr.includes(id) ? arr : [...arr, id]);
const filterIfIncludes = (arr, id) => {
  if (id) {
    return arr.includes(id) ? arr.filter((i) => i !== id) : arr;
  }

  return arr.slice(0, -1);
};

const lightbox: any = handleActions(
  {
    [open.toString()]: (
      state: LightboxState,
      { payload: id }
    ): LightboxState => ({
      ...state,
      opened: pushIfNotIncludes(state.opened, id),
    }),
    [close.toString()]: (
      state: LightboxState,
      { payload: id }
    ): LightboxState => ({
      ...state,
      opened: filterIfIncludes(state.opened, id),
      closing: filterIfIncludes(state.closing, id),
    }),
  },
  initialState
);

export default lightbox;
