// @flow
import * as React from 'react';
import CustomProfile from './index';
import type {
  GetShowcases_showcases as ShowCase,
  ShippingProfileFragment_domesticShippingServices as DomesticShippingService,
  ShippingProfileFragment_internationalShippingServices as InternationalShippingService,
  ShippingProfileFragment_shippingEbayProfiles as ShippingEbayProfile,
} from '../../../typings/product-query.flow';

type Props = {
  visible?: boolean,
  profileId: number,
  name: string, // this name corresponds to the selected tab name of profile tab
  siteId: number,
  currency?: string,
  domesticShippingServices: Array<DomesticShippingService>,
  internationalShippingServices: Array<InternationalShippingService>,
  shippingEbayProfiles: Array<ShippingEbayProfile>,
  showcases: Array<ShowCase>,
};

// 高阶组件，实现类似vue的v-show功能。
// React的if条件等同于vue的v-if，切换时会销毁组件，导致组件的state也会消失。
function withShowHide(Component): any {
  // eslint-disable-next-line react/function-component-definition
  return function ShowHideWrapper(props: Props) {
    const { visible = false, ...rest } = props;
    const displayStyle = { display: visible ? 'block' : 'none' };
    return (
      <div style={displayStyle}>
        <Component {...rest} />
      </div>
    );
  };
}

const CustomProfileWithShowHide: any = withShowHide(CustomProfile);
export default CustomProfileWithShowHide;
