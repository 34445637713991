// @flow
import * as React from 'react';
import { useReactiveVar } from '@apollo/client';

import Button from '../../basic-components/button';
import usePersistState from '../../hooks/use-persist-state';
import { addFiltersToQuery, getChangedFilters } from '../filters/utils';

type Props = {
  initialFilters: Object,
  ignoreFilters?: string[],
  localStorageKey: string,
  mutationVariableMapper?: (Object) => Object,
  onClick: () => void,
  preserveQueryKeys: string[],
  apolloVar: (filters: Object) => void,
};

export default function ResetFilters({
  localStorageKey,
  onClick,
  initialFilters,
  ignoreFilters = [],
  preserveQueryKeys,
  apolloVar,
  ...restProps
}: Props): React.Node {
  const filters = useReactiveVar(apolloVar);

  const [, setPersistState] = usePersistState(localStorageKey);

  const handleClick = () => {
    onClick();
    const preventedResetFilters = ignoreFilters.reduce(
      (result, f) => ({ ...result, [f]: filters[f] }),
      {}
    );
    const newFilters = { ...initialFilters, ...preventedResetFilters };
    const changedFilters = getChangedFilters(newFilters, initialFilters);
    addFiltersToQuery(changedFilters, preserveQueryKeys);
    setPersistState(changedFilters);

    apolloVar(newFilters);
  };

  // $FlowFixMe
  return <Button onClick={handleClick} {...restProps} />;
}
