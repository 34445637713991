// @flow
import * as React from 'react';

import ApolloLightbox from '../apollo-lightbox';
import CountryInclusions from '../country-inclusions';
import ThreeColumnsLayout from '../../basic-components/three-columns-layout';
import Button from '../../basic-components/button';
import { LightboxContextProvider } from '../../basic-components/lightbox/context';

import t from './locale';
import cs from './styles.pcss';
import type { GetShowcases_showcases as ShowCase } from '../../typings/product-query.flow';

type Props = {
  value: ?Array<string>,
  onChange: (Array<string>) => void,
  onClose?: () => void,
  id?: string,
  readOnly?: boolean,
  domesticShowcases?: { [s: string]: ShowCase },
  currentShowcase?: ShowCase,
};

export default function ShippingExcludedCountriesLightbox({
  value,
  onChange,
  onClose,
  id,
  readOnly = false,
  domesticShowcases,
  currentShowcase,
}: Props): React.Node {
  const [selected, setSelected] = React.useState(value || []);

  React.useEffect(() => {
    setSelected(value || []);
  }, [value]);

  const renderFooter = React.useCallback(
    (closeOverlay: () => Promise<void>) => (
      <ThreeColumnsLayout
        left={
          <Button mode="secondary" onClick={closeOverlay}>
            {t('button.close')}
          </Button>
        }
        right={
          <Button
            onClick={() => {
              onChange(selected);
              closeOverlay();
            }}
          >
            {t('button.select')}
          </Button>
        }
      />
    ),
    [selected, onChange]
  );

  const countriesChange = (v) => {
    if (readOnly) {
      return;
    }
    setSelected(v);
  };

  return (
    <LightboxContextProvider>
      <ApolloLightbox
        id={id || 'shippingExcludedCountriesLightbox'}
        small
        className={cs.wrapper}
        onClose={onClose}
        header={t('title')}
        footer={renderFooter}
      >
        <CountryInclusions
          selectedCountries={selected}
          totalSelected={false}
          onChange={countriesChange}
          domesticShowcases={domesticShowcases}
          currentShowcase={currentShowcase}
        />
      </ApolloLightbox>
    </LightboxContextProvider>
  );
}
