// @flow

import * as React from 'react';
import cx from 'classnames';
import { compose, isNil } from 'ramda';
import Input from '../../../basic-components/input';
import withFiltersContext from '../with-filters-context';
import withLabel from '../with-label';
import type { RangeValueT } from '../typings';

import cs from './styles.pcss';

type PropsT = {
  id: string,
  onChange: (?RangeValueT, string) => void,
  float?: boolean,
  placeholder?: ?{ min: ?string, max: ?string },
  value: RangeValueT,
  isMobile?: boolean,
  adaptive?: boolean,
};

const getValue = (value, key) =>
  value && typeof value === 'object' && !isNil(value[key]) ? value[key] : '';

const getPlaceholder = (placeholder, key) =>
  placeholder && typeof placeholder === 'object' && placeholder[key];

const handleChange = (onChange, currentValue, key, shouldBeFloat) => (e) => {
  const value = currentValue ? { ...currentValue } : {};
  const parser = shouldBeFloat
    ? (v) => {
        if (v === null) {
          return null;
        }

        return parseFloat(v);
      }
    : (v) => {
        if (v === null) {
          return null;
        }

        return parseInt(v, 10);
      };

  if (e.target.value) {
    value[key] = parser(e.target.value);
  } else {
    delete value[key];
  }

  // it fixes broken query if only one key is provided
  if (value.min === undefined) {
    value.min = null;
  }

  if (value.max === undefined) {
    value.max = null;
  }

  onChange(Object.keys(value).length ? value : null, e.target.value);
};

function RangeField({
  id,
  onChange,
  placeholder,
  value,
  float = false,
  isMobile,
  adaptive,
  ...restProps
}: PropsT) {
  return (
    <div className={cs.range}>
      {/* $FlowFixMe */}
      <Input
        className={cx(cs.from, { [cs.fromInModal]: isMobile && adaptive })}
        size={isMobile && adaptive ? 'medium' : 'small'}
        {...restProps}
        id={id}
        placeholder={getPlaceholder(placeholder, 'min')}
        onChange={handleChange(onChange, value, 'min', float)}
        value={getValue(value, 'min')}
      />
      <div className={cs.delimiter} />
      {/* $FlowFixMe */}
      <Input
        className={cx(cs.to, { [cs.toInModal]: isMobile && adaptive })}
        size={isMobile && adaptive ? 'medium' : 'small'}
        {...restProps}
        placeholder={getPlaceholder(placeholder, 'max')}
        onChange={handleChange(onChange, value, 'max', float)}
        value={getValue(value, 'max')}
      />
    </div>
  );
}

// $FlowFixMe
export default compose(withLabel, withFiltersContext)(RangeField);
