import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import './utils/sentry';
import { setLocale } from './utils/i18n';
import createStore from './store';
import { jsonToCamelCase } from './utils/normalize';
import { withApolloProvider } from './utils/apollo';
import { LightboxContextProvider } from './basic-components/lightbox/context';
import { ScrollContextProvider } from './components/header/context';

import './index.pcss';

export default function renderApp(pageData) {
  // Detect language
  const language = document.documentElement.lang;

  // Configure locale
  setLocale(language);

  // Mount point for page
  const mount = document.getElementById('mount');

  // Extract page name from mount point
  const { page } = mount.dataset;

  // Extract page initial data from mount point
  let initialData = JSON.parse(mount.dataset.initialData || '{}');
  initialData.page = page;

  if (!['Parcels'].includes(page)) {
    initialData = jsonToCamelCase(initialData);
  }

  // Extract page component and initial state extractor
  const { Page, mapDataToState } = pageData;

  // Create application store
  const store = createStore(initialData, mapDataToState);

  // Render page
  function Root() {
    const ApolloPage = withApolloProvider(Page);
    return (
      <Provider store={store}>
        <LightboxContextProvider>
          <ScrollContextProvider>
            <ApolloPage />
          </ScrollContextProvider>
        </LightboxContextProvider>
      </Provider>
    );
  }

  render(<Root />, mount);
}
