// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import cs from './styles.pcss';
import Icon from '../icon';
import catchMouseFocus from '../../utils/hocs/catch-mouse-focus';

const cx = classNames.bind(cs);

type Props = {
  checked?: boolean,
  disabled?: boolean,
  indeterminate?: boolean,
  className?: string,
  focus?: boolean,
  innerRef?: React.Ref<any>,
};

function Checkbox({
  disabled,
  checked,
  indeterminate,
  className,
  focus,
  innerRef,
  ...rest
}: Props) {
  return (
    <div className={cx('checkbox', { disabled }, className)}>
      {/* $FlowFixMe */}
      <input
        ref={innerRef}
        type="checkbox"
        className={cx('realCheckbox', { focus })}
        checked={checked}
        disabled={disabled}
        {...rest}
      />
      <Icon name="checkmark" className={cx('checkmark')} />
      {disabled && !checked && !indeterminate && (
        <Icon name="close" className={cx('cross')} />
      )}
      {indeterminate && !checked && <div className={cx('indeterminateIcon')} />}
      <div className={cx('visualCheckbox', { disabled, indeterminate })} />
    </div>
  );
}

const CheckboxWithCatchedFocus: React.ComponentType<Props> =
  catchMouseFocus(Checkbox);

export default CheckboxWithCatchedFocus;
