// @flow
import * as React from 'react';
import cs from '../../product-form/shipping-profile-select/styles.pcss';
import Checkbox from '../../../basic-components/checkbox';

type Props = {
  label: string,
  value: string,
  checked: boolean,
  disableCheckbox: boolean
};
export default function StatementOption({
  label,
  value,
  checked,
  disableCheckbox
}: Props): React.Node {
  return (
    <div className={cs.statementMultiple}>
      <Checkbox key={`${value}`} name={`${value}`} checked={checked} disabled={disableCheckbox}/>
      {label}
    </div>
  );
}
