// @flow
import * as React from 'react';
import cx from 'classnames';

import Icon from '../../../basic-components/icon';
import Checkbox from '../../../basic-components/checkbox';
import HoverTooltip from '../../../basic-components/hover-tooltip';
import translateLanguages from '../../../utils/i18n/languages-locale';

import cs from './styles.pcss';
import t from './locale';

import type { ExcludedShowcaseApollo } from '../../../typings/showcase';
import type { Showcases_showcases as ShowcaseT } from '../../../typings/product-query.flow';

type Props = {
  name: string,
  country: string,
  forbidden?: boolean,
  isIncluded?: boolean,
  domesticShowcase?: ShowcaseT,
  servedByOtherShowcase?: ShowcaseT | ExcludedShowcaseApollo | null,
  currentShowcase?: ShowcaseT | ExcludedShowcaseApollo,
  isFrozen: boolean,
  toggleCountries: (Array<string>) => void,
};

function getCurrentShowcaseName(
  showcase: ShowcaseT | ExcludedShowcaseApollo
): string {
  if (showcase.site.id === -1) {
    return t('excludedCountries');
  }

  if (
    showcase.site.code === 'CA' &&
    showcase.site.domain &&
    showcase.site.language
  ) {
    return `${showcase.site.domain} (${translateLanguages(
      showcase.site.language.code
    )})`;
  }

  return showcase.site.domain || '';
}

export default function Country({
  currentShowcase,
  domesticShowcase,
  name,
  toggleCountries,
  country,
  isIncluded,
  servedByOtherShowcase,
  forbidden,
  isFrozen,
}: Props): React.Node {
  const getMessage = (): string | null => {
    let message = null;

    if (!(currentShowcase && domesticShowcase)) {
      return null;
    }

    if (currentShowcase === domesticShowcase) {
      // home country can not be turned off on it's own showcase
      message = t('domesticCountryMessage');
    } else if (currentShowcase.site.id === -1) {
      // home country of active showcase can not be added to excluded countries
      message = t('lockedCountryOnExcludedShowcaseMessage', {
        country: name,
        lockedByShowcase: getCurrentShowcaseName(domesticShowcase),
      });
    } else {
      // home country of active showcase can not be added to other showcase
      message = t('lockedCountryMessage', {
        country: name,
        lockedByShowcase: getCurrentShowcaseName(domesticShowcase),
        currentShowcase: getCurrentShowcaseName(currentShowcase),
      });
    }

    return message;
  };

  const toggleCountryInclusion = () => {
    toggleCountries([country]);
  };

  const renderHelpTip = () => {
    if (domesticShowcase) {
      return (
        <span className={cx(cs.selectedBy, cs.isHomeCountry)}>
          {getCurrentShowcaseName(domesticShowcase)}
          <HoverTooltip className={cs.lockIconContainer} message={getMessage()}>
            <Icon name="exclamationCircle" className={cs.lockIcon} />
          </HoverTooltip>
        </span>
      );
    }
    if (servedByOtherShowcase) {
      return (
        <span className={cx(cs.selectedBy, { [cs.isChanged]: isIncluded })}>
          {getCurrentShowcaseName(servedByOtherShowcase)}
        </span>
      );
    }

    return null;
  };

  const disabled = !!domesticShowcase || forbidden || isFrozen;

  return (
    <button
      className={cx(cs.row, cs.countryRow, { [cs.isDisabled]: disabled })}
      onClick={!disabled ? toggleCountryInclusion : undefined}
      type="button"
    >
      <Checkbox
        readOnly
        checked={isIncluded}
        className={cs.checkbox}
        disabled={disabled}
      />
      <div className={cs.name}>
        {name}
        {renderHelpTip()}
      </div>
    </button>
  );
}
