// @flow
import * as React from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Icon from '../icon';
import Button from '../button';

import cs from './styles.pcss';
import t from './locale';

type Props = {
  type?: 'success' | 'error' | 'info',
  message?: React.Node,
  onShowHelp?: () => void,
  onClose: (string) => void,
  id: string,
  action?: {
    text: string,
    href: string,
  },
  show?: boolean,
  closable?: boolean,
};

export default function Notification({
  type = 'info',
  show = true,
  message,
  onShowHelp,
  id,
  onClose,
  action,
  closable = true,
}: Props): React.Node {
  const [shown, setShown] = React.useState(show);

  React.useEffect(() => {
    setShown(show);
  }, [show]);

  let content = message;

  if ((!message && type === 'error') || message === 'defaultError') {
    content = (
      <>
        {t('defaultError')}{' '}
        <button
          type="button"
          onClick={() => onShowHelp && onShowHelp()}
          className={cs.support}
        >
          {t('support')}
        </button>
      </>
    );
  }

  const handleCloseClick = () => {
    setShown(false);
  };

  const handleExited = () => onClose(id);

  return (
    <CSSTransition
      in={shown}
      mountOnEnter
      unmountOnExit
      appear
      onExited={handleExited}
      classNames={{
        appear: cs.enter,
        appearActive: cs.enterActive,
        enter: cs.enter,
        enterActive: cs.enterActive,
        exit: cs.exit,
        exitActive: cs.exitActive,
      }}
      timeout={400}
    >
      <div className={cx(cs.container, cs[type])}>
        <div className={cs.content}>
          <div>{content}</div>
          {action && (
            <Button
              mode="transparent"
              href={action.href}
              size="small"
              className={cs.action}
              target="_blank"
            >
              {action.text}
            </Button>
          )}
        </div>
        {closable && (
          <button type="button" className={cs.close} onClick={handleCloseClick}>
            <Icon name="cancel" size={24} />
          </button>
        )}
      </div>
    </CSSTransition>
  );
}
