// @flow
import { useQuery } from '@apollo/client';

import { FeedbackQuery } from '../shared-queries/FeedbackQuery.graphql';

export default function useFeedbackPriority(skip?: boolean): Array<any> {
  const { data, loading } = useQuery(FeedbackQuery, {
    skip,
  });

  const user = data && data.viewer;

  const feedbackLowPriority = user
    ? !user.gmvInUsd || user.gmvInUsd < 1000 || (!user.hipo && !user.frites)
    : true;

  return [feedbackLowPriority, loading];
}
