import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { actions } from '../actions/onboarding';

const onboarding = handleActions(
  {
    [actions.onboarding.setShowcases]: (state, { payload }) =>
      update(state, {
        data: { showcases: { $set: payload } },
      }),
    [actions.onboarding.setStep]: (state, { payload }) =>
      update(state, {
        step: { $set: payload.step },
        page: { $set: payload.data.page },
        screen: { $set: payload.data.screen },
        success: { $set: payload.data.success },
        last: { $set: payload.data.last },
        closable: { $set: payload.data.closable },
      }),
    [actions.onboarding.setServerStatus]: (state, { payload }) =>
      update(state, {
        server: { $set: payload },
      }),
  },
  {}
);

export default onboarding;
