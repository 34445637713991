// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  name: string,
  selectedTab?: string,
  onClick?: (string) => void,
  children: React.Node,
};

export default function TabItem(props: Props): React.Node {
  const { name, selectedTab, onClick, children } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(name);
    }
  };

  const isSelected = name === selectedTab;
  const classStyle = isSelected ? cx('tabItem', 'active') : cx('tabItem');
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <li className={classStyle} onClick={handleClick}>
      {children}
    </li>
  );
}
