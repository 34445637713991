// @flow

import * as React from 'react';
import { Field as FormField } from 'react-final-form';
import classNames from 'classnames/bind';

import { prettyArrayJoin } from '../../../utils/string';
import { getVisibleError } from '../../../hocs/final-form/utils';

import { useApolloLightbox } from '../../../hooks/use-apollo-lightbox';
import Icon from '../../../basic-components/icon';
import Field from '../../../basic-components/field';
import ShippingLocationsLightbox from '../../shipping-locations-lightbox';

import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  name: string,
  shippingDestinations: { [string]: string },
  deleteButton: React.Node,
};

let resetStateCounter = 0;

function formatDestinationsTitle(
  codes: Array<string>,
  shippingDestinations: { [string]: string }
): string {
  return prettyArrayJoin(
    codes.map((code) => shippingDestinations[code]),
    3
  );
}

export default function ShippingLocations({
  name,
  shippingDestinations,
  deleteButton,
}: Props): React.Node {
  const lightboxName = `shippingTariffLocationLightbox.${name}`;
  const { openLightbox } = useApolloLightbox(lightboxName);

  return (
    <FormField name={name}>
      {({ input: { value, onChange }, meta }) => (
        <Field description={deleteButton} error={getVisibleError(meta)}>
          <button
            type="button"
            className={cx('locationsButton')}
            onClick={() => {
              // Reinitialize ShippingLocationsLightbox component on every new opening
              resetStateCounter += 1;
              openLightbox();
            }}
          >
            {formatDestinationsTitle(value || [], shippingDestinations)}
            <Icon name="settings" className={cx('locationsIcon')} />
          </button>

          <ShippingLocationsLightbox
            key={resetStateCounter}
            name={lightboxName}
            selectedLocations={value}
            onChange={onChange}
          />
        </Field>
      )}
    </FormField>
  );
}
