// @flow
import * as React from 'react';
import cx from 'classnames';
import { useReactiveVar } from '@apollo/client';

import ANALYTICS from '../../analytics/help-dropdown';
import { getLocale } from '../../utils/i18n';
import settings from '../../settings';
import Icon from '../../basic-components/icon';
import { useDropdown } from '../../hooks/use-dropdown';
import VerticalMenu from '../../basic-components/vertical-menu';
import ButtonWithTooltip from '../button-with-tooltip';
import NewInfo from '../../basic-components/new-info';
import useFeedbackPriority from '../../hooks/use-feedback-priority';
import { useOnboardingTour } from '../../hooks/use-onboarding-tour';
import { useApolloLightbox } from '../../hooks/use-apollo-lightbox';
import { onboardingTipShownVar } from '../../utils/apollo-cache';
import ANALYTICS_FEEDBACK from '../../analytics/feedback';

import cs from './styles.pcss';
import t from './locale';
import OnboardingTip from './onboarding-tip.svg';

type Account4Feedback = {
  country: string,
  customerType?: string,
  id?: string,
  managedPayments?: boolean,
  name?: string,
  ordersImportFinished?: boolean,
  site?: { id?: number },
  store?: { subscription?: string },
};

type Props = {
  className?: string,
  onStock: boolean,
  accounts: Account4Feedback[],
};

export default function HelpDropdown({
  onStock,
  className,
  accounts,
}: Props): React.Node {
  const [feedbackLowPriority, feedbackPriorityLoading] = useFeedbackPriority();
  const onboardingTipShown = useReactiveVar(onboardingTipShownVar);
  const { onStartOnboarding, hideOnboardingTip } = useOnboardingTour();
  const { openLightbox: openFeedback } = useApolloLightbox('feedbackLightbox');
  const { openLightbox: openCsFeedback } =
    useApolloLightbox('csFeedbackLightbox');
  const { opened, handleToggle, ref } = useDropdown(
    'helpDropdown',
    hideOnboardingTip
  );

  let isJpOrKr = false;
  let shouldLink = false;
  let country = 'EN';
  if (accounts && accounts.length > 0) {
    country = accounts[0].country;
    // check accounts country
    isJpOrKr = settings.references.isJpKrCountry(country);
    shouldLink = settings.references.shouldLink(country);
  }

  const options = [
    {
      icon: 'helpCenter',
      text: t(`items.helpCenter`),
      href: settings.references.helpCenter,
      external: true,
      onClick: () => {
        ANALYTICS.helpCenter();
      },
    },
    {
      icon: 'faq',
      text: t(`items.faq`),
      href: settings.references.faq(getLocale()),
      external: true,
      onClick: () => {
        ANALYTICS.faq();
      },
    },
    {
      icon: 'onboarding',
      text: t(`items.restartOnboarding`),
      onClick: () => {
        ANALYTICS.restartOnboarding();
        onStartOnboarding().then(() => {
          if (!onStock) {
            location.href = settings.path.stock;
          }
        });
      },
    },
    {
      icon: isJpOrKr ? 'write' : 'customerSupport',
      text: t(
        `items.help${feedbackLowPriority ? 'LowPriority' : ''}${
          isJpOrKr ? 'JpOrKr' : ''
        }`
      ),
      onClick: () => {
        ANALYTICS.help();
        if (shouldLink) {
          const url = settings.references.support(country);
          if (!url) return;
          window.open(url, '_blank');
        } else if (isJpOrKr) {
          openFeedback();
        } else {
          openCsFeedback();
        }
        ANALYTICS_FEEDBACK.show(feedbackLowPriority);
      },
    },
  ];

  return (
    <div className={cx(cs.helpDropdown, className)} ref={ref}>
      <div>
        <ButtonWithTooltip
          className={cx(cs.button, { [cs.opened]: opened })}
          onClick={handleToggle}
          text={t('tooltip')}
          tooltipHidden={opened}
          gap={-6}
        >
          <Icon name="questionCircle" size={24} className={cs.icon} />
        </ButtonWithTooltip>
        {onboardingTipShown && (
          <NewInfo
            className={cs.newInfo}
            onClose={hideOnboardingTip}
            arrowRightMargin="64px"
          >
            <div className={cs.onboardingTipContent}>
              <OnboardingTip className={cs.onboardingTipIcon} />
              <div>
                <div className={cs.onboardingTipHeader}>
                  {t('onboardingTip.header')}
                </div>
                <div className={cs.onboardingTipText}>
                  {t('onboardingTip.text')}
                </div>
              </div>
            </div>
          </NewInfo>
        )}
      </div>

      {!feedbackPriorityLoading && (
        <VerticalMenu
          id="helpDropdown"
          className={cs.menu}
          arrowClassName={cs.menu}
          opened={opened}
          options={options}
        />
      )}
    </div>
  );
}
