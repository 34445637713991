// @flow
import * as React from 'react';

type Props = {
  onClick: (e: SyntheticKeyboardEvent<HTMLDivElement>) => void,
  className?: string,
  children: React.Node,
};

export default function InteractiveElement({
  className,
  children,
  onClick,
  ...rest
}: Props): React.Node {
  const handleKeyPress: (e: SyntheticKeyboardEvent<HTMLDivElement>) => void = (
    e: SyntheticKeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === 'Enter') {
      onClick(e);
    }
  };

  return (
    // $FlowFixMe
    <div
      onClick={onClick}
      className={className}
      role="button"
      tabIndex={-1}
      onKeyPress={handleKeyPress}
      {...rest}
    >
      {children}
    </div>
  );
}
