// @flow
import * as React from 'react';

import Field from '../field';
import Select, { type Props as SelectProps } from '../select';
import type { Option } from '../dropdown/typings';

type Props = {
  ...SelectProps,
  label: React.Node,
  value: string,
  options: Array<Option>,
  description?: React.Node,
  error?: React.Node,
  grid?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  labelClassName?: string,
  errorClassName?: string,
  fieldClassName?: string,
  fieldRef?: React.Ref<any>,
  noGrid?: boolean,
};

export default function SelectField({
  label,
  description,
  error,
  grid,
  fieldRef,
  labelClassName,
  fieldClassName,
  noGrid,
  errorClassName,
  ...props
}: Props): React.Node {
  const refInput = React.useRef();

  const handleLabelClick: () => void = () => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  };

  return (
    <Field
      innerRef={fieldRef}
      label={label}
      labelClassName={labelClassName}
      fieldClassName={fieldClassName}
      description={description}
      error={error}
      grid={grid}
      onLabelClick={handleLabelClick}
      noGrid={noGrid}
      errorClassName={errorClassName}
    >
      <Select {...props} innerRef={refInput} error={!!error} />
    </Field>
  );
}
