// @flow
import * as React from 'react';
import { compose } from 'ramda';

import MultiSelect from '../../../basic-components/multi-select';
import withFiltersContext from '../with-filters-context';
import withLabel from '../with-label';

import type { Option } from '../../../basic-components/dropdown/typings';

type Props = {
  onChange: (string[] | null) => void,
  value: ?(string[]),
  options: Option[],
  allowEmptyArray?: boolean,
  isMobile?: boolean,
  adaptive?: boolean,
};

const toValues = (
  labels: ?(string[]),
  options: Option[],
  selectedOptions: Option[] = []
): string[] => {
  if (!labels) {
    return [];
  }

  const allOptions = [...options, ...selectedOptions];

  return labels
    .map((l: string) => allOptions.find((o: Option) => o.label === l))
    .filter((o: ?Option): boolean => Boolean(o))
    .map((o: ?Option) => (o ? o.value : ''));
};

const getSelectedOptions = (
  values: ?(string[]),
  options: Option[],
  selectedOptions: Option[] = []
): Option[] => {
  if (!values) {
    return [];
  }

  const allOptions = [...options, ...selectedOptions];

  return (
    values
      // $FlowFixMe
      .map((v: string) => allOptions.find((o) => o.value === v))
      .filter((o: ?Option) => Boolean(o))
  );
};

const getLabel = (option: Option): string => option.label;

function MultiSelectField({
  allowEmptyArray,
  onChange,
  value,
  options,
  isMobile,
  adaptive,
  ...restProps
}: Props) {
  const [selectedOptions, setSelectedOptions] = React.useState(() =>
    getSelectedOptions(value, options || [])
  );

  React.useEffect(() => {
    setSelectedOptions((prevSelectedOptions) =>
      getSelectedOptions(value, options, prevSelectedOptions)
    );
  }, [value]);

  const handleChange = (labels) => {
    let newValue = toValues(labels, options, selectedOptions);
    if (!allowEmptyArray && !newValue.length) {
      newValue = null;
    }

    setSelectedOptions((prevSelectedOptions) =>
      getSelectedOptions(newValue, options, prevSelectedOptions)
    );
    onChange(newValue);
  };

  return (
    // $FlowFixMe
    <MultiSelect
      size={isMobile && adaptive ? 'medium' : 'small'}
      onChange={handleChange}
      values={selectedOptions.map(getLabel)}
      options={options.map(getLabel)}
      disableCustomOptions
      {...restProps}
    />
  );
}

// $FlowFixMe
export default compose(withLabel, withFiltersContext)(MultiSelectField);
