// @flow
import * as React from 'react';
import { useImmer } from 'use-immer';
import useMediaQuery from '../../../hooks/use-media-query';
import Field from '../../../basic-components/field';
import Button from '../../../basic-components/button';
import FieldSet from '../../field-set';
import FieldSetTitle from '../../field-set-title';
import ShippingPacket from '../shipping-packet';

import type {
  ShippingProfileFragment_domesticShippingServices as DomesticShippingService,
  ShippingProfileFragment_internationalShippingServices as InternationalShippingService,
} from '../../../typings/product-query.flow';
import cs from './styles.pcss';
import t from './locale';

type Props = {
  fieldKeyPrefix: string,
  optionType: 'domestic' | 'international',
  siteId: number,
  maxServiceCount: number,
  currency: string,
  existingShippings: Array<Object>,
  shippingServiceDictionary: Array<
    DomesticShippingService | InternationalShippingService
  >,
  globalDisabled?: boolean,
};

const newControlList = (size) => {
  const controlList = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < size; i++) {
    controlList.push({ priority: i, visible: false });
  }
  return controlList;
};

export default function ShippingContainer(props: Props): React.Node {
  const {
    // e.g. 172-cp-us-ds 或 172-cp-us-is
    fieldKeyPrefix,
    optionType,
    siteId,
    maxServiceCount,
    currency,
    existingShippings,
    shippingServiceDictionary,
    globalDisabled = true,
  } = props;

  const [count, setCount] = React.useState(0);
  const [dsList, setDsList] = useImmer([]);
  const existingShippingsRef = React.useRef([...existingShippings]);
  const isMobileScreen = useMediaQuery('(max-width: 768px)');

  React.useEffect(() => {
    let initialCount = 0;
    const controlList = newControlList(maxServiceCount);
    existingShippings.forEach((item, index) => {
      controlList[index].visible = true;
      initialCount += 1;
    });
    setCount(initialCount);
    setDsList(controlList);
  }, []);

  // 在control list中找出第一个invisible的项，使其变成visible
  const addShipping = () => {
    setDsList((draft) => {
      const nextVisible = draft.find((item) => !item.visible);
      if (!nextVisible) return;
      nextVisible.visible = true;
      setCount((old) => old + 1);
    });
  };

  const removeShipping = (priority) => {
    setDsList((draft) => {
      // 1. 将当前DS设置不可见
      const current = draft.find((item) => item.priority === priority);
      current.visible = false;
      setCount((old) => old - 1);

      // 2. 将当前DS移到数组最后，留下次使用
      const position = draft.indexOf(current);
      draft.splice(position, 1);
      draft.push(current);

      // 3. 将对应shipping设置为空对象
      existingShippingsRef.current[priority] = {};
    });
  };

  return (
    <>
      <FieldSet delimiter="narrow">
        <FieldSetTitle leftColumn={isMobileScreen ? '' : ' '}>
          {optionType === 'international'
            ? t('title.international')
            : t('title.domestic')}
        </FieldSetTitle>

        <Field
          className={cs.fieldWrapper}
          label={t('costType.label')}
          description={t('costType.description')}
          fieldClassName={cs.field}
        >
          {t('costType.options.flat')}
        </Field>
      </FieldSet>

      {dsList
        .filter((ds) => ds.visible)
        .map((ds) => {
          const { priority } = ds;
          // 通过add button新加的，是没有current service，因此考虑默认值（给个空对象）
          const currentService = existingShippingsRef.current[priority] || {};
          return (
            <ShippingPacket
              key={`${fieldKeyPrefix}-${priority}`}
              field={`${fieldKeyPrefix}-${priority}`}
              siteId={siteId}
              optionType={optionType}
              priority={priority}
              currency={currency}
              currentService={currentService}
              shippingServiceDictionary={shippingServiceDictionary}
              globalDisabled={globalDisabled}
              onDelete={removeShipping}
            />
          );
        })}

      <Field
        className={cs.addShipping}
        noGrid={false}
        fieldClassName={cs.field}
      >
        <Button
          mode="secondary"
          onClick={addShipping}
          disabled={globalDisabled || count >= maxServiceCount}
        >
          {t('addShipping')}
        </Button>
        <span className={cs.shippingCount}>
          {count}/{maxServiceCount}
        </span>
      </Field>
    </>
  );
}
