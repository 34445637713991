// @flow
import * as React from 'react';

import PicMissing from './pic-missing.svg';

import cs from './styles.pcss';

type Props = {
  src?: string | null,
  alt?: string | null,
};

export default function Thumbnail({ src, alt }: Props): React.Node {
  const [showFallback, setShowFallbak] = React.useState(!src);

  if (showFallback) {
    return <PicMissing className={cs.image} />;
  }

  return (
    <img
      onError={() => setShowFallbak(true)}
      src={src}
      className={cs.image}
      alt={alt || 'Item'}
    />
  );
}
