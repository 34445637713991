// @flow

import upperFirst from 'lodash-es/upperFirst';
import isPlainObject from 'lodash-es/isPlainObject';
import { assocPath } from 'ramda';
import type { GraphqlValidationError } from './form';

export const addTypenames = (
  obj: { [string]: any },
  prefix: string
): {| __typename: string |} =>
  Object.keys(obj).reduce(
    (acc, key) => {
      if (obj[key] !== undefined) {
        if (isPlainObject(obj[key])) {
          const typename = `${upperFirst(prefix)}${upperFirst(key)}`;
          const objectValue = obj[key];

          acc[key] = addTypenames(objectValue, typename);
        } else {
          acc[key] = obj[key];
        }
      }

      return acc;
    },
    { __typename: prefix }
  );

export const omitTypenames = (obj: {
  [string]: any,
}): { ... } | { [string]: any } =>
  isPlainObject(obj)
    ? Object.keys(obj).reduce((acc, key) => {
        if (key !== '__typename') {
          if (isPlainObject(obj[key])) {
            acc[key] = omitTypenames(obj[key]);
          } else {
            acc[key] = obj[key];
          }
        }
        if("gpsrCategory" == key && true != obj[key]){
          acc["gpsrCategory"] = null;
        }

        return acc;
      }, {})
    : obj;

export const getFlatGQLErrors = (
  backendErrors: Array<GraphqlValidationError>
): any =>
  backendErrors
    ? backendErrors.reduce((acc, { message }) => [...acc, message], [])
    : [];

export type FieldErrors = { [string]: string | FieldErrors };

export const getFieldsGQLErrors = (
  errors: Array<GraphqlValidationError>
): ?FieldErrors =>
  errors && errors.length
    ? errors.reduce(
        (acc: FieldErrors, err: GraphqlValidationError) =>
          assocPath(
            err.fields && err.fields.length ? err.fields : ['common'],
            err.message,
            acc
          ),
        {}
      )
    : null;
