// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  children?: any,
  className?: string,
};

function OneColumnLayout({ children, className }: Props): React.Element<'div'> {
  return (
    <div className={cx('oneColumnLayout', className)}>
      {children &&
        children.length > 1 &&
        children
          .filter((element) => !!element)
          .map((element, index) => {
            const isLastElement = index + 1 === children.length;
            return (
              <div key={String(children.length * index)}>
                {element}
                {!isLastElement && <div className={cs.space} />}
              </div>
            );
          })}
      {children && !children.length && children}
    </div>
  );
}

export default OneColumnLayout;
