// @flow
import * as React from 'react';
import cx from 'classnames';

import Checkbox from '../checkbox';

import cs from './styles.pcss';

type Props = {
  id: string,
  url: string,
  onRemove?: (index: number) => void,
  innerRef?: React.Ref<any>,
  dragged: boolean,
  style?: any,
  description: string,
  checked: boolean,
  onChanged: (e: any) => void,
};

export default function DraggableProgramsItem({
  id,
  url,
  dragged,
  style,
  innerRef,
  description,
  checked,
  onChanged,
  ...props
}: Props): React.Node {
  return (
    // $FlowFixMe
    <div
      ref={innerRef}
      className={cx(cs.image, {
        [cs.dragged]: dragged,
        [cs.mainImage]: true,
      })}
      style={style}
      {...props}
    >
      <div
        className={cs.bgImage}
        style={{
          backgroundImage: `url("${url}")`,
        }}
      >
        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            left: '10px',
            fontSize: '15px',
          }}
        >
          {description}
        </div>
      </div>

      <Checkbox
        key={`${id}.cost.free`}
        name={`${id}.cost.free`}
        checked={checked}
        disabled={false}
        onChange={(e) => {
          onChanged(e);
        }}
      />
    </div>
  );
}
