// @flow

import * as React from 'react';
import classNames from 'classnames/bind';

import { COLORS } from '../../utils/delivery-profiles';

import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  title: string,
  color: number,
  className?: string,
  size?: 'small' | 'normal',
};

function ShippingProfilesBadge({
  title,
  color,
  className,
  size = 'normal',
}: Props): React.Node {
  const badgeClassName = cx('badge', className, cs[`size-${size}`]);
  const backgroundColor = COLORS[color] || '#C7C7C7';

  return (
    <div className={badgeClassName} style={{ backgroundColor }}>
      {title[0]}
    </div>
  );
}

export default ShippingProfilesBadge;
