// @flow
import * as React from 'react';
import cx from 'classnames';

import Icon from '../icon';
import Link, { type Props as LinkProps } from '../link';

import cs from './styles.pcss';

type Props = {
  ...LinkProps,
  nowrap?: boolean,
};

export default function ExternalLink({
  children,
  className,
  nowrap,
  ...rest
}: Props): React.Node {
  return (
    <Link
      {...rest}
      className={cx(className, { [cs.nowrap]: nowrap })}
      target="_blank"
    >
      {children}
      <Icon className={cs.icon} name="link" />
    </Link>
  );
}
