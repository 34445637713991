// @flow
import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { pathOr } from 'ramda';
import { FORM_ERROR } from 'final-form';

import FEEDBACK_ANALYTICS from '../../analytics/feedback';
import { SendFeedback } from '../../shared-queries/SendFeedback.graphql';
import { FeedbackQuery } from '../../shared-queries/FeedbackQuery.graphql';
import errorsLocale from '../../utils/i18n/errors-locale';

import FeedbackRussianPost from '.';

import type { SendFeedback as FeedbackMutationResult } from '../../typings/product-query.flow';

export default function ConnectedFeedbackRussianPost(): React.Node {
  const { data, loading } = useQuery(FeedbackQuery);
  const [sendFeedback] = useMutation(SendFeedback);

  if (loading) {
    return null;
  }

  const email = pathOr<string, string | number, string, any>(
    '',
    ['viewer', 'accounts', 0, 'email'],
    data
  );

  const handleSendFeedback = ({
    email: emailInput,
    body,
    tracking,
    caseId,
    attachments,
  }) => {
    const attachedFiles = attachments
      .map((u) =>
        u.state === 'finished' ? `${u.file.name} - ${u.meta.url}` : null
      )
      .filter(Boolean);

    return sendFeedback({
      variables: {
        input: {
          place: 'ruPostFeedback',
          selectedOptions: ['custom'],
          contact: emailInput,
          imagesUrls: attachedFiles.length > 0 ? attachedFiles : undefined,
          body: [
            body,
            `Email: ${emailInput}`,
            tracking ? `Tracking: ${tracking}` : null,
            caseId ? `Case Id: ${caseId}` : null,
          ]
            .filter(Boolean)
            .join('\n'),
        },
      },
    }).then((mutationResult: { data: FeedbackMutationResult }) => {
      const result = mutationResult.data.feedbackCreate;

      if (result && result.success) {
        FEEDBACK_ANALYTICS.sendRuPost(true);

        return Promise.resolve();
      }
      if (result && result.errors && result.errors.length) {
        FEEDBACK_ANALYTICS.sendRuPost(false);
        return { [FORM_ERROR]: result.errors.join(', ') };
      }

      FEEDBACK_ANALYTICS.sendRuPost(false);
      return { [FORM_ERROR]: errorsLocale('generic_error') };
    });
  };

  return (
    <FeedbackRussianPost email={email} sendFeedback={handleSendFeedback} />
  );
}
