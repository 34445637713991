// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { prettyArrayJoin } from '../../../utils/string';
import { useApolloLightbox } from '../../../hooks/use-apollo-lightbox';
import Icon from '../../../basic-components/icon';
import Field from '../../../basic-components/field';
import ShippingExcludedCountriesLightbox from '../../shipping-excluded-countries-lightbox';

import cs from './styles.pcss';
import t from '../locale';
import { ShippingProfileAdditional } from '../operations.graphql';
import type {
  ShippingProfileAdditional_regions as Regions,
  GetShowcases_showcases as ShowCase,
} from '../../../typings/product-query.flow';

const cx = classNames.bind(cs);

type Props = {
  name: string,
  // countries: { [string]: string },
  veryLargeScreen?: boolean,
  excludedCountries: Array<string>,
  readOnly: boolean,
  onChange?: (Array<string>) => void,
  currentShowcase?: ShowCase,
};

function formatExcludedCountriesTitle(
  codes: Array<string>,
  countries: { [string]: string }
): string {
  if (codes.length === 0) {
    return t('fields.excludedCountries.placeholder');
  }

  return prettyArrayJoin(
    codes.map((code) => countries[code]),
    3
  );
}

const getCountriesMap = (regions: Array<Regions>) =>
  (regions || []).reduce(
    (acc: any, { code, name }: Regions) => ({
      ...acc,
      [code]: name,
    }),
    {}
  );

export default function ExcludedCountriesLightbox({
  name,
  excludedCountries,
  veryLargeScreen = true,
  readOnly = false,
  onChange,
  currentShowcase,
}: Props): React.Node {
  const { data: currenciesData } = useQuery(ShippingProfileAdditional);

  const { regions } = currenciesData || {};
  const countriesMap = getCountriesMap(regions || []);

  const { openLightbox } = useApolloLightbox(name);

  const onOpen = () => {
    openLightbox();
  };

  const [selectedCountries, setSelectedCountries] = React.useState(
    excludedCountries || []
  );

  const onSelectedChange = (v) => {
    if (readOnly) {
      return;
    }
    setSelectedCountries(v);
    if (onChange != null) {
      onChange(v);
    }
  };
  const domesticShowcases = {};
  if (currentShowcase != null) {
    const code =
      currentShowcase.site.code === 'UK' ? 'GB' : currentShowcase.site.code;
    domesticShowcases[code] = currentShowcase;
  }

  return (
    <div>
      <input type="hidden" name={name} value={selectedCountries} />

      <Field
        label={t('fields.excludedCountries.label')}
        className={cx('fieldWrapper')}
        fieldClassName={cx('field')}
        descriptionClassName={cx('description')}
        description={
          <button
            type="button"
            className={cx('excludedCountriesButton')}
            onClick={onOpen}
          >
            <Icon
              name="settings"
              className={cx('excludedCountriesIcon')}
              size={veryLargeScreen ? 16 : 24}
            />
          </button>
        }
      >
        <button
          type="button"
          className={cx('excludedCountriesButton')}
          onClick={onOpen}
        >
          {formatExcludedCountriesTitle(selectedCountries || [], countriesMap)}
        </button>
      </Field>

      <ShippingExcludedCountriesLightbox
        id={name}
        value={selectedCountries}
        onChange={onSelectedChange}
        readOnly={readOnly}
        domesticShowcases={domesticShowcases}
        currentShowcase={currentShowcase}
      />
    </div>
  );
}
