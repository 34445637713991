// @flow
import * as React from 'react';
import { compose } from 'ramda';

import MultiInput from '../../../basic-components/multi-input';

import withFiltersContext from '../with-filters-context';
import withLabel from '../with-label';

type PropsT = {
  onChange: (Array<string>) => void,
  value: ?Array<number>,
  options: Array<{ label: string, value: string }>,
  isMobile?: boolean,
  adaptive?: boolean,
};

function MultiInputField({
  onChange,
  value,
  options,
  isMobile,
  adaptive,
  ...restProps
}: PropsT) {
  const values = value || [];
  return (
    // $FlowFixMe
    <MultiInput
      size={isMobile && adaptive ? 'medium' : 'small'}
      onChange={onChange}
      values={values}
      {...restProps}
    />
  );
}

// $FlowFixMe
export default compose(withLabel, withFiltersContext)(MultiInputField);
