// @flow

import { createAction } from 'redux-actions';
import type { Scope } from '../typings/errors';

export const setScopeErrors: {
  (
    payload: mixed,
    ...rest: Array<any>
  ): { error?: boolean, payload: any, type: string, ... },
  +toString: () => string,
  ...
} = createAction(
  'ERRORS/SET_SCOPE_ERRORS',
  (payload: { scope: string, errors: Scope }) => payload
);

export const setKeyErrors: {
  (
    payload: mixed,
    ...rest: Array<any>
  ): { error?: boolean, payload: any, type: string, ... },
  +toString: () => string,
  ...
} = createAction(
  'ERRORS/SET_KEY_ERRORS',
  (payload: { scope: string, key: string, errors: Array<string> }) => payload
);

export const clearScopeErrors: {
  (
    payload: mixed,
    ...rest: Array<any>
  ): { error?: boolean, payload: any, type: string, ... },
  +toString: () => string,
  ...
} = createAction('ERRORS/CLEAR_SCOPE_ERRORS', (payload: string) => payload);

export const clearKeyErrors: {
  (
    payload: mixed,
    ...rest: Array<any>
  ): { error?: boolean, payload: any, type: string, ... },
  +toString: () => string,
  ...
} = createAction(
  'ERRORS/CLEAR_KEY_ERRORS',
  (payload: { scope: string, key: string }) => payload
);

export const clearErrors: {
  (
    payload: mixed,
    ...rest: Array<any>
  ): { error?: boolean, payload: any, type: string, ... },
  +toString: () => string,
  // eslint-disable-next-line prettier/prettier
  ...
  // $FlowFixMe
} = createAction('ERRORS/CLEAR_ERRORS');
