// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import { FormSpy } from 'react-final-form';
import type { FormSpyRenderProps } from 'react-final-form';

import FormField from '../../../hocs/final-form/form-field';
import { getVisibleError } from '../../../hocs/final-form/utils';

import Field from '../../../basic-components/field';
import Input from '../../../basic-components/input';

import t from '../locale';

import cs from './styles.pcss';

const cx = classNames.bind(cs);

const isEmpty = (value: string) => !value || value.trim() === '';

const hasCityOrPostalCode = (_, { city, postalCode }) =>
  isEmpty(city) && isEmpty(postalCode)
    ? t('fields.location.validate.empty')
    : null;

type Fields = {
  city: string,
  postalCode: string,
};

type Props = {
  fieldClassname?: string,
};

function LocationField({ fieldClassname }: Props): React.Node {
  return (
    <FormSpy>
      {({ form }: FormSpyRenderProps<Fields>) => {
        const cityError = getVisibleError(form.getFieldState('city'));
        const postalCodeError = getVisibleError(
          form.getFieldState('postalCode')
        );

        return (
          <Field
            label={t('fields.location.label')}
            error={cityError || postalCodeError || null}
            fieldClassName={fieldClassname}
          >
            <FormField
              name="city"
              validate={hasCityOrPostalCode}
              Component={Input}
              grid={6}
              placeholder={t('fields.location.placeholder.city')}
              className={cx('input')}
            />
            <FormField
              name="postalCode"
              validate={hasCityOrPostalCode}
              Component={Input}
              grid={6}
              placeholder={t('fields.location.placeholder.postalCode')}
            />
          </Field>
        );
      }}
    </FormSpy>
  );
}

export default LocationField;
