// @flow
import * as React from 'react';
import cx from 'classnames';

import settings from '../../settings';
import { useDropdown } from '../../hooks/use-dropdown';
import VerticalMenu from '../../basic-components/vertical-menu';
import { type Props as OptionT } from '../../basic-components/vertical-menu/option';

import ButtonWithTooltip from '../button-with-tooltip';
import OnboardingHighlight from '../../basic-components/onborading-highlight';

import UserIcon from './profile.svg';
import cs from './styles.pcss';
import t from './locale';

type UserInfoPropsT = {
  name: string,
  onExit: () => void,
  className?: string,
  multi: boolean,
};

type GetUserOptionsPropsT = {
  name: string,
  onExit: () => void,
};

export const getUserOptions = ({
  name,
  onExit,
}: GetUserOptionsPropsT): OptionT[] => [
  {
    icon: 'user',
    text: name,
    href: settings.path.accounts,
    external: false,
  },
  {
    icon: 'settings',
    text: t('settings'),
    href: settings.path.settings,
    external: false,
  },
  {
    icon: 'logout',
    text: t('logout'),
    onClick: () => {
      onExit();
    },
  },
];

export default function UserInfo({
  name,
  onExit,
  className,
  multi,
}: UserInfoPropsT): React.Node {
  const { opened, handleToggle, ref } = useDropdown('userDropdown');

  const options = getUserOptions({ name, onExit });

  return (
    <div className={cx(cs.userInfo, className)} ref={ref}>
      <ButtonWithTooltip
        onClick={handleToggle}
        className={cx(cs.button, { [cs.opened]: opened })}
        text={t('tooltip')}
        tooltipHidden={opened}
        gap={-2}
      >
        <OnboardingHighlight stepNumber={8} round>
          <div className={cs.toggler}>
            <UserIcon className={cx(cs.icon, { [cs.multi]: multi })} />
            {multi && <UserIcon className={cs.iconSecond} />}
          </div>
        </OnboardingHighlight>
      </ButtonWithTooltip>

      {name && (
        <VerticalMenu
          id="userDropdown"
          className={cs.menu}
          arrowClassName={cs.menu}
          opened={opened}
          options={options}
        />
      )}
    </div>
  );
}
