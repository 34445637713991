//  @flow

import type { EventPayload, GTMEvent, DataLayer } from '../typings/analytics';

declare var dataLayer: DataLayer;

const isGTMInitialized = () =>
  typeof global.dataLayer !== 'undefined' && global.dataLayer !== null;

export function createEventPayload(eventdata: EventPayload): GTMEvent {
  const eventdataPlaceholder = {
    label: undefined,
    value: undefined,
    ni: undefined,
  };

  // We have to make sure that all eventdata fields are definded
  // to prevent GTM problem from #EBAYMAG-1383
  return {
    event: 'gaEvent',
    eventdata: {
      ...eventdataPlaceholder,
      ...eventdata,
    },
  };
}

export function sendEvent(payload: EventPayload): Promise<void> {
  return new Promise((resolve) => {
    const event = createEventPayload(payload);

    if (isGTMInitialized()) {
      const timeoutId = setTimeout(resolve, 500);

      dataLayer.push({
        ...event,
        eventCallback: () => {
          clearTimeout(timeoutId);
          resolve();
        },
      });
    } else {
      resolve();
    }
  });
}

let pageCounter = 1;

export function getPage(): string {
  pageCounter += 1;

  return pageCounter.toString();
}

export const payloadCreator =
  (category: string): any =>
  (action: string, label?: string, value?: number): any => {
    let defaultPayload = { category, action };
    if (label) {
      defaultPayload = { ...defaultPayload, label };
    }

    if (typeof value !== 'undefined') {
      defaultPayload = { ...defaultPayload, value };
    }

    return defaultPayload;
  };
