// @flow

import * as React from 'react';
import classNames from 'classnames/bind';
import cs from './styles.pcss';

const cx = classNames.bind(cs);

type Props = {
  className?: string,
  errors: ?$ReadOnlyArray<React.Node>,
  compact?: boolean,
  hidden?: boolean,
};

export default function Errors({
  errors,
  className,
  compact,
  hidden,
}: Props): React.Node {
  if (!errors || !errors.length) {
    return null;
  }

  if (hidden) {
    return null;
  }

  return (
    <div className={cx('errors', { compact }, className)}>
      {errors.map((error, index) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <div key={index} className={cx('error')}>
          {error}
        </div>
      ))}
    </div>
  );
}
