// @flow

// Utility function for exhaustive match (https://blog.jez.io/union-types-flow-reason/)
export function impossible<T>(x: empty): T {
  throw new Error(`${x} case is impossible.`);
}

// check email format
export function emailValidate(email: string): boolean {
  const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return reg.test(email);
}
