// @flow
import * as React from 'react';
import cx from 'classnames';

import LogoIcon from './logo.svg';
import cs from './styles.pcss';

type Props = {
  className?: string,
};

export default function Logo({ className }: Props): React.Node {
  return <LogoIcon className={cx(cs.logo, className)} />;
}
