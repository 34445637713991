// @flow

import * as React from 'react';
import cx from 'classnames';
import { dropRepeats } from 'ramda';
import Icon from '../icon';

import cs from './styles.pcss';

export type Props = {
  name?: string,
  innerRef?: React.Ref<any>,
  valuesSeparators?: Array<string>,
  values: string[],
  size?: 'small' | 'medium',
  inputValue?: string,
  tabIndex?: number,
  icon?: React.Node,
  placeholder?: string,
  className?: string,
  disabled?: boolean,
  error?: boolean,
  addOnEnter?: boolean,
  onInputChange?: (string) => void,
  onKeyDown?: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void,
  onFocus?: (event: SyntheticFocusEvent<HTMLInputElement>) => void,
  onBlur?: (event: SyntheticFocusEvent<HTMLInputElement>) => void,
  onChange: (values: string[]) => void,
  autoComplete?: 'on' | 'off',
  renderValue?: (value: string) => string,
};

export default function MultiInput({
  name,
  values,
  tabIndex = -1,
  placeholder,
  className,
  error,
  size = 'medium',
  disabled,
  autoComplete,
  valuesSeparators = [';', ','],
  icon = null,
  addOnEnter = true,
  onFocus,
  onBlur,
  onChange = () => {},
  onKeyDown = () => {},
  renderValue = (value) => value,
  innerRef,
  onInputChange,
  inputValue: initialInputValue,
}: Props): React.Node {
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [focused, setFocused] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const container = React.useRef();
  const input = React.useRef();

  const handleContainerBlur = (e: SyntheticEvent<HTMLElement>) => {
    if (container && e.target === container.current) {
      setSelectedIndex(-1);
    }
  };

  const focusInput = () => {
    if (input && input.current) {
      input.current.focus();
    }
  };

  const updateInput = (value: string) => {
    if (onInputChange) {
      onInputChange(value);
    } else {
      setInputValue(value);
    }
  };

  const removeElement = (element: string): void => {
    onChange(values.filter((v) => v !== element));
    updateInput('');
  };

  const handleRemoveButtonClick = (
    e: SyntheticEvent<HTMLElement>,
    value: string
  ) => {
    e.stopPropagation();
    removeElement(value);
    focusInput();
  };

  const addElements = (elements: Array<string>): void => {
    if (!elements.length) return;

    // removes duplications and empty elements
    const newElements = dropRepeats(values.concat(elements)).filter(Boolean);

    onChange(newElements);
    updateInput('');
  };

  // if force flag is set it will always add the last value to 'values'
  const processValue = (value: string, force = false) => {
    const separator = (valuesSeparators || []).find((sep) =>
      value.includes(sep)
    );

    if (separator || force) {
      // create new element when user enters `valuesSeparator`
      const newElements = value.split(separator);
      addElements(newElements);
    } else {
      updateInput(value);
    }
  };

  const handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    processValue(value);
  };

  const handleFocus = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    setFocused(false);
    processValue(inputValue, true);

    if (onBlur) {
      onBlur(e);
    }
  };

  const selectPreviousElement = (e: ?SyntheticEvent<HTMLInputElement>) => {
    if (!input || !container || !input.current || !container.current) {
      return;
    }

    if (input.current.selectionStart === 0 && values.length > 0) {
      if (e) {
        e.preventDefault();
      }

      container.current.focus();

      let currentId = selectedIndex;
      if (currentId === -1) {
        currentId = values.length;
      }

      setSelectedIndex(Math.max(currentId - 1, 0));
    }
  };

  const handleKeyboardRemove = (
    backspace: boolean = false,
    e: ?SyntheticEvent<HTMLInputElement> = null
  ) => {
    if (selectedIndex >= 0 && selectedIndex < values.length) {
      removeElement(values[selectedIndex]);

      if (selectedIndex === values.length - 1) {
        focusInput();
      }
    } else if (backspace) {
      // if nothing is selected and backspace key is pressed then select last element
      selectPreviousElement(e);
    }
  };

  const getInputValue = () => {
    if (onInputChange && typeof initialInputValue !== 'undefined') {
      return initialInputValue;
    }

    return inputValue;
  };

  const selectNextElement = (e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (selectedIndex === values.length - 1) {
      focusInput();
    }

    setSelectedIndex((prev) => Math.min(prev + 1, values.length));
  };

  const handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    switch (e.keyCode) {
      case 8: // backspace
        handleKeyboardRemove(true, e);
        return;
      case 13: // enter
        if (addOnEnter) {
          e.preventDefault();
          addElements([getInputValue()]);
        } else if (onKeyDown) {
          onKeyDown(e);
        }
        return;
      case 37: // left
        selectPreviousElement(e);
        break;
      case 39: // right
        selectNextElement(e);
        break;
      case 46: // delete
        handleKeyboardRemove();
        return;

      default:
        if (onKeyDown) {
          onKeyDown(e);
        }
    }
  };

  const renderIcon = () => {
    if (!icon) return null;

    return <div className={cs.icon}>{icon}</div>;
  };

  const wrapperClassName = cx(
    cs.wrapper,
    {
      [cs.focused]: focused,
      [cs.error]: error,
      [cs.disabled]: disabled,
      [cs.empty]: !values.length,
    },
    cs[size],
    className
  );

  return (
    <div
      role="button"
      tabIndex={tabIndex}
      className={wrapperClassName}
      onClick={focusInput}
      onKeyDown={handleKeyDown}
      ref={container}
      onBlur={handleContainerBlur}
    >
      <div className={cs.values}>
        {values.map((val, index) => (
          <div
            key={val}
            className={cx(cs.value, {
              [cs.disabled]: disabled,
              [cs.isSelected]: index === selectedIndex,
            })}
          >
            <div className={cs.valueCaption}>
              {renderValue ? renderValue(val) : val}
            </div>
            <button
              type="button"
              disabled={disabled}
              onClick={(e) => handleRemoveButtonClick(e, val)}
              className={cs.deleteButton}
            >
              <Icon name="closeCircle" className={cs.deleteIcon} />
            </button>
          </div>
        ))}
        <input
          ref={(elem) => {
            input.current = elem;
            if (innerRef && innerRef instanceof Function) {
              innerRef(elem);
            }
          }}
          name={name}
          disabled={disabled}
          value={getInputValue()}
          className={cs.input}
          placeholder={values.length ? ' ' : placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete={autoComplete || 'on'}
        />
      </div>
      {renderIcon()}
    </div>
  );
}
