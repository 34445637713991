// @flow

import * as React from 'react';
import cx from 'classnames';

import cs from './styles.pcss';

type Props = {
  className?: string,
  children?: React.Node,
};

export default function TableBody({ className, children }: Props): React.Node {
  return (
    <div className={cx(cs.body, className)}>
      <div className={cs.content}>{children}</div>
    </div>
  );
}
