import { createActions } from 'redux-actions';
import { identity } from 'ramda';

export const {
  account: { setDhlTocAccepted },
} = createActions({
  account: {
    SET_DHL_TOC_ACCEPTED: identity,
  },
});
